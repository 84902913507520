import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import { Link } from 'react-router-dom';
import Floral from "assets/img/havilla/floral_big.svg";
import PasswordField from "client-side/components/PasswordField/PasswordField";
import { ClientSideContext } from '../../ClientSideContext';
import { Backdrop, CircularProgress } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";

import axios from 'axios';
import {
    baseUrl,
    requestConfig,
    prefix
} from '../../../Environment.js';
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp.js';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField.js';

const useStyles = theme => ({
    ...styles(theme),
    containerLogin: {
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            padding: "50px 40px 100px",
        },
        [theme.breakpoints.up('md')]: {
            padding: "7% 20% 0",
        },
    },
    floral: {
        marginLeft: "20%",
        marginTop: "-80px",
        [theme.breakpoints.down('sm')]: {
            width: "100px",
            position: "absolute",
            right: "-50px"
        },
    },
    textField: {
        width: "80%",
    },
    buttonSend : {
        width : '80%'
    }
});

const required_field_names = [
    'email',
]

class ForgotPassword extends Component {
    static contextType = ClientSideContext;

    constructor(props) {
        super(props);

        this.state = {
            openBackdrop: false,
            email: '',
            email_error: "",

            snackbarType: 'info',
            snackbarMessage: '',
            snackbarCounter: 0,
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    componentDidMount() {
    }

    componentDidUpdate(nextProps, nextState) {
        for (let i = 0; i < required_field_names.length; i++) {
            let field_name = required_field_names[i];
            if (this.state[field_name + "_error"] !== "") {
                if (this.state[field_name] !== nextState[field_name]) {
                    this.setState({ [field_name + "_error"]: "" });
                }
            }
        }

        return true;
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        let is_ok = true;
        for (let i = 0; i < required_field_names.length; i++) {
            let field_name = required_field_names[i];
            let field_name_text = required_field_names[i].replace('_', ' ');
            if (this.state[field_name] === "" || this.state[field_name] === null) {
                this.setState({ [field_name + "_error"]: field_name_text + " is required" });
                is_ok = false;
            }
        }
        
        if (is_ok) {
            this.setState({ openBackdrop: true });
            let data = {
                email: this.state.email,
            }
            await axios.post(`${baseUrl}/password/email`,
                data,
                requestConfig
            ).then((res) => {
                if (res.data.success) {
                    this.handleOpenSnackbar({
                        type: 'success',
                        message: "Request sent, please check your email"
                    });
                    this.setState({ openBackdrop: false });

                    setTimeout(() => {
                        let path = prefix + `/`;
                        this.props.history.push(path);
                    }, 3000);
                } else {
                    this.setState({ openBackdrop: false });
                    this.handleOpenSnackbar({
                        type: 'error',
                        message: res.data.message
                    });
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: 'success',
                    message: 'Request sent, please check your email'
                });
                console.log(e)
                console.log("Error : ", e);
            })
        }
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });
    render() {
        const { classes } = this.props;
        return (
            <Container maxWidth="xl" className={`${classes.container} ${classes.containerLogin}`}>
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{'color': '#AF986D'}} />
                </Backdrop>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.counter}
                />
                <Grid container className={classes.formContainer} onSubmit={this.handleSubmit}>
                    <Grid item md={5} xs={12}>
                        <h3 className={classes.smallerTitle}>{this.context.language == 'en' ? 'Forgot Password' : 'Lupa Password'}</h3>
                        <form className={classes.root} autoComplete="off">
                        <CustomTextField
                            width='80%'
                            error={this.state.email_error !== ""}
                            helperText={this.state.email_error}
                            autoFocus={true}
                            value={this.state.email}
                            onChange={this.onChange} name="email" type="email" label="Email *" />
                            <br /><br />
                            <br />
                            <Button type='submit' variant="outlined" className={`${classes.buttonDark} ${classes.buttonSend}`}>
                                {this.context.language == 'en' ? 'Send Forgot Password Request' : 'Kirim Permintaan Lupa Password'}
                            </Button>
                        </form>
                    </Grid>
                    <Grid item md={1} xs={12}>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <img className={classes.floral} src={Floral} alt="floral"></img>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default withStyles(useStyles)(ForgotPassword) 
