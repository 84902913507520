import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import { Link } from 'react-router-dom';
import Floral from "assets/img/havilla/floral_big.svg";
import PasswordField from "client-side/components/PasswordField/PasswordField";

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import { Backdrop, CircularProgress } from '@material-ui/core';

import axios from 'axios';
import {
    baseUrl,
    requestConfig,
    prefix
} from '../../../Environment.js';
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp.js';

const useStyles = theme => ({
    ...styles(theme),
    containerLogin: {
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            padding: "50px 40px 100px",
        },
        [theme.breakpoints.up('md')]: {
            padding: "7% 20% 0",
        },
    },
    floral: {
        marginLeft: "20%",
        marginTop: "-80px",
        [theme.breakpoints.down('sm')]: {
            width: "100px",
            position: "absolute",
            right: "-50px"
        },
    },
    textField: {
        width: "80%",
    }
});

const field_names = [
    'password',
    'password_confirmation'
]

class ForgotPassword extends Component {
    // static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            openBackdrop: false,
            token: this.props.location.pathname.replace(`/forgot_password/`, ""),

            password: '',
            password_confirmation: '',

            password_error: "",
            password_confirmation_error: "",

            snackbarType: 'info',
            snackbarMessage: '',
            snackbarCounter: 0,
        }

        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    componentDidMount() {
        // console.log(this.state)
    }

    componentDidUpdate(nextProps, nextState) {
        for (let i = 0; i < field_names.length; i++) {
            let field_name = field_names[i];
            if (this.state[field_name + "_error"] !== "") {
                if (this.state[field_name] !== nextState[field_name]) {
                    this.setState({ [field_name + "_error"]: "" });
                }
            }
        }

        return true;
    }

    handlePasswordChange = (newValue) => {
        this.setState({
            password: newValue,
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        let is_ok = true;
        for (let i = 0; i < field_names.length; i++) {
            let field_name = field_names[i];
            let field_name_text = field_names[i].replace('_', ' ');
            if (this.state[field_name] === "" || this.state[field_name] === null) {
                this.setState({ [field_name + "_error"]: field_name_text + " is required" });
                is_ok = false;
            }
        }

        const {
            password,
            password_confirmation } = this.state;
        // perform all neccassary validations
        if (password !== password_confirmation) {
            is_ok = false;
            this.setState({
                password_confirmation_error: "Re-Password Didn't Match!"
            });
        }

        if (is_ok) {
            this.setState({ openBackdrop: true });
            let data = {
                token: this.state.token,
                password: this.state.password,
                password_confirmation: this.state.password_confirmation
            }
            await axios.post(`${baseUrl}/password/reset`,
                data,
                requestConfig
            ).then((res) => {
                if (res.data.success) {
                    this.setState({ openBackdrop: false });
                    this.handleOpenSnackbar({
                        type: 'success',
                        message: "Password changed!"
                    });
                    setTimeout(() => {
                        let path = prefix + `/`;
                        this.props.history.push(path);
                    }, 3000);
                } else {
                    this.setState({ openBackdrop: false });
                    this.handleOpenSnackbar({
                        type: 'error',
                        message: "Your Time To Change Password Is Up, Please Request Another One"
                    });
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: 'error',
                    message: "Your Time To Change Password Is Up, Please Request Another One"
                });
                console.log(e)
                console.log("Error : ", e);
            })
        }

    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });
    render() {
        const { classes } = this.props;
        return (
            <Container maxWidth="xl" className={`${classes.container} ${classes.containerLogin}`}>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.counter}
                />
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{'color': '#AF986D'}} />
                </Backdrop>
                <Grid container className={classes.formContainer} onSubmit={this.handleSubmit}>
                    <Grid item md={5} xs={12}>
                        <h3 className={classes.smallerTitle}>Let's Create Your New Password!</h3>
                        <form className={classes.root} autoComplete="off">
                            <PasswordField
                                error={this.state.password_error !== ""}
                                helperText={this.state.password_error}
                                hideIcon={true}
                                type="password" onChange={this.onChange} name="password" label="New Password" />
                            <br /><br />
                            <PasswordField
                                error={this.state.password_confirmation_error !== ""}
                                helperText={this.state.password_confirmation_error}
                                hideIcon={true}
                                type="password" onChange={this.onChange} name="password_confirmation" label="Re-Type Your New Password" />
                            <br /><br />
                            <br /><br />

                            <Button type='submit' variant="outlined" className={classes.buttonDark}>Change Password</Button>
                        </form>
                    </Grid>
                    <Grid item md={1} xs={12}>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <img className={classes.floral} src={Floral} alt="floral"></img>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default withStyles(useStyles)(ForgotPassword) 
