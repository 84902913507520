import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

// core components Admin
import Admin from "admin/layouts/Admin.js";
import HttpsRedirect from 'react-https-redirect';

// core components Client Side
import "assets/css/material-dashboard-react.css?v=1.8.0";
import ClientSide from "client-side/layouts/ClientSide";
import ScrollToTop from "ScrollToTop";
import LoginAdmin from "admin/views/Login/LoginAdmin";

import {
  prefix
} from 'Environment.js';

const hist = createBrowserHistory();
ReactDOM.render(
  <HttpsRedirect>
    <Router history={hist}>
      <ScrollToTop>
        <div className="App">
          <div className="container">
            <Switch>
              <Route exact path={`${prefix}/admin/login`} component={LoginAdmin} />
              <Route path={`${prefix}/admin`} component={Admin} />
              <Route path={`${prefix}`} component={ClientSide} />
            </Switch>
          </div>
        </div>
      </ScrollToTop>
    </Router>
  </HttpsRedirect>
  ,
  document.getElementById("root")
);
