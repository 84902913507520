import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Check from "assets/img/havilla/check.svg";
import CheckWhite from "assets/img/havilla/check-white.svg";


function StyledCheckbox(props) {
  const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      backgroundColor: props.custom_color,
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
          boxShadow: 'inset 0 0 0 3px black, inset 0 -1px 0 rgba(16,22,26,.1)',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: props.custom_color,
      '&:before': {
        display: 'block',
        width: '40px',
        height: '40px',
        backgroundImage: `url(${props.custom_color === '#363F57' ? CheckWhite : Check})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        content: '""',
      },
      'input:hover ~ &': {
          boxShadow: 'inset 0 0 0 3px black, inset 0 -1px 0 rgba(16,22,26,.1)',
      },
    },
  });
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}

export default function CustomColorCheckbox(props) {
  return (
    <StyledCheckbox {...props}/>
  );
}