import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CustomIngredientCheckbox from 'client-side/components/CustomCheckBox/CustomIngredientCheckbox';

const useStyles = makeStyles((theme) => ({
    img : {
        height: '350px',
        width: '400px',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '100%',
            maxWidth : '98vw',
        },
        maxHeight: '350px',
        maxWidth: '400px',
    },
    ingredientName : {
        fontFamily : 'Martel Sans',
        fontSize : '24px',
        textAlign : 'center',
        padding : '0px 32px 0px 32px'
    },
    ingredientDesc : {
        fontFamily : 'Martel Sans',
        fontSize : '16px',
        textAlign : 'center',
        padding : '0px 32px 0px 32px',
    },
    ingredientContainer : {
        // marginLeft : '32px',
        maxWidth : '650px',
        width: 'auto'
    },
    ingredientLink : {
        fontFamily: 'Martel Sans',
        fontSize: "14px",
        textDecorationLine: 'underline',
        // '&:hover' : {
        //     textDecorationLine: 'underline',
        // }
    },
    ingredientLinkContainer :{
        textAlign : 'left',
        padding : '17px 32px 0px 32px'
    },
    emptyLastItem : {
        [theme.breakpoints.down('sm')]: {
            display : 'none'
        },
        marginLeft : '0rem',
    }
}));

function IngredientCard(props) {
    const classes = useStyles();
    // const [checked, setChecked] = useState(false)

    const handleCheckboxChange = (e) =>{
        props.handleSelectedIngredient(props.id,!props.checked);
        // setChecked(!checked);
    }

    return (
        <Grid className={`${classes.ingredientContainer}`} 
            container 
            justify='space-between' 
            alignItems='center' 
            direction='column'
        >
            <Grid item xs={12} >
                <img className={classes.img} src={props.img} alt={props.imgAlt}/>
            </Grid>
            <Grid className={classes.ingredientName} item xs={12}>
                {props.name}
            </Grid>
            <Grid className={classes.ingredientDesc} item xs={12}>
                {props.desc}
            </Grid>
            {
                props.max_pick > 1 || props.max_pick === null ?
                    <Grid className={classes.checkBoxContainer} item xs={12}>
                        <CustomIngredientCheckbox onChange={handleCheckboxChange} checked={props.checked}/>
                    </Grid>
                :
                null
            }
        </Grid>
    )
}

export default IngredientCard
