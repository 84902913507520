import React, { Component } from 'react'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import { baseUrl, requestConfigAdmin } from 'Environment';
import { withStyles, TextField, MenuItem } from '@material-ui/core';
import globalStyles from "assets/jss/client-side/App.js";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import GridItem from 'admin/components/Grid/GridItem';
import Card from "admin/components/Card/Card.js";
import CardHeader from "admin/components/Card/CardHeader.js";
import CardBody from "admin/components/Card/CardBody.js";
import GridContainer from 'admin/components/Grid/GridContainer';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField';
import CardFooter from 'admin/components/Card/CardFooter';
import Button from "admin/components/CustomButtons/Button.js";
import { ProductDetailVariant } from './ProductDetailVariant';
import CustomMaterialTable from './CustomMaterialTable';
import {
    prefix
} from '../../../Environment.js';

const CustomTextfieldRaw = withStyles({
    root: {
        width: '100%',
        '& label.Mui-focused': {
            color: 'black',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);

const useStyles = theme => ({
    ...globalStyles(theme),
    ...dashboardStyle,
    // USED IN PRODUCT DETAIL VARIANT
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'auto'
    },
    paperModal: {
        [theme.breakpoints.down('sm')]: {
            padding: '1rem',
            width: '100%',
        },
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: '0',
        width: '672px',
        height: '389px',
    },
    closeIconModal: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer'
    },
    contentContainerModal: {
        padding: '10px 30px',
    },
    contentTitleModal: {
        padding: '20px 30px',
        fontSize: '20px',
        fontFamily: 'Martel Sans',
        fontWeight: 'bold',
        borderBottom: '1px solid rgba(0,0,0, 0.3)'
    },
    imgPreview: {
        width: '250px',
        cursor: 'pointer',
        '&:hover + .delete-button': {
            marginTop: '-17px',
        }
    },
    deletePict: {
        transition: 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)',
        float: 'right',
        marginTop: '-37px',
        display: 'block',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            marginTop: '-17px',
        },
    },
    tempPictContainer: {
        paddingTop: '20px',
        overflow: 'hidden',
        '&:hover .delete-button': {
            marginTop: '-17px',
        }
    },
    img: {
        width: '50px',
        height: '50px',
    },
    buttonTable: {
        minWidth: '104px'
    },
    borderHr: {
        margin: '20px 0',
        width: '100%',
        border: '1px solid #fafafa'
    }
    // END USED IN PRODUCT DETAIL VARIANT

});

const required_fields = [
    'name',
    'sku',
    'category_id',
    // 'origin',
    'caffeine',
    'short_desc_id',
    'short_desc_eng',
    'sub_products'
]

export class ProductDetail extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props)

        this.state = {
            type: "update",

            id: null,
            name: "",
            name_error : "",

            caffeine: 0,
            caffeine_error: "",

            category_id: '',
            category_id_error: '',
            
            desc: "",

            origin: "",
            origin_error: "",

            short_desc_eng: "",
            short_desc_eng_error: "",

            short_desc_id: "",
            short_desc_id_error: "",

            sku: "",
            sku_error: "",

            sub_category_id: '',
            sub_products: [],

            categories: [],
            sub_categories: [],
            desc_object: [],
            brewing_styles: [],

            feels: [0, 0, 0, 0, 0],
            desc_object_update_count : 0,
            brewing_styles_update_count : 0,
            first_load : true
        }

        this.name_ref = React.createRef();
        this.sku_ref = React.createRef();
        this.category_id_ref = React.createRef();
        // this.origin_ref = React.createRef();
        this.short_desc_eng_ref = React.createRef();
        this.short_desc_id_ref = React.createRef();
        this.caffeine_ref = React.createRef();
    }

    getCategories = () => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        axios.get(`${baseUrl}/productcategory`,
            requestConfigAdmin
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState({
                    categories: data,
                }, () => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    getSubCategories = (category_id) => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        axios.get(`${baseUrl}/productcategory/get_sub_category/${category_id}`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    sub_categories: res.data.data
                }, () => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    getProduct = () => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        const id = this.props.match.params.id;
        axios.get(`${baseUrl}/products/getProductInfoOnly/${id}`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.getSubCategories(res.data.data.category_id);
                this.setState({
                    ...res.data.data,
                    desc_object: JSON.parse(res.data.data.desc)
                }, () => {
                    if(this.state.first_load){
                        this.setState({
                            first_load : false,
                            desc_object_update_count : 1,
                            brewing_styles_update_count : 1
                        })
                    }
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        this.getCategories();
        if (id !== "add") {
            this.getProduct();
            this.setState({
                type: "update"
            })
        } else {
            this.setState({
                type: "add"
            })
        }
    }

    componentDidUpdate(nextProps, nextState){
        for (let i = 0; i < required_fields.length; i++) {
            let field_name = required_fields[i];
            if(this.state[field_name+"_error"] !== ""){
                if(this.state[field_name] !== nextState[field_name]){
                    this.setState({ [field_name+"_error"]: "" });
                }
            }
        }

        return true;
    }

    onChangeCategory = (e) => {
        let category_id = e.target.value
        this.setState({
            category_id: category_id,
            sub_category_id: null
        })
        this.getSubCategories(category_id)
    }
    

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });
    
    onFeelingChange = (index, e) => {
        this.setState({
            feels: this.state.feels.map((feel, i) => {
                if (i === index) {
                    feel = e.target.value
                }
                return feel;
            })
            // feels[i]: e.target.value 
        });
    }

    handleValidation = () =>{
        let is_ok = true;
        for (let i = 0; i < required_fields.length; i++) {
            let field_name = required_fields[i];
            let field_name_text = required_fields[i].replace(/_/g,' ');
            if(this.state[field_name] === "" || this.state[field_name] === null || this.state[field_name].length === 0){
                is_ok = false;
                if(field_name !== 'sub_products'){
                    this[field_name+"_ref"].current.focus();
                    this.setState({ [field_name+"_error"]: field_name_text+" is required" });
                }else{
                    if(this.state.type === 'add'){
                        this.context.dispatchSnackbarContent({ 
                            type: "SET_SNACKBAR_CONTENT", 
                            color : "danger",
                            message : "Product variants are required"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    }else{
                        is_ok = true
                    }
                }
            }
        }
        return is_ok;
    }

    handleSubmitProductInfo = () => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })

        let is_ok = this.handleValidation();
        if(is_ok){
            if(this.state.type === "update") {
                let data = {
                    id: this.state.id,
                    name: this.state.name,
                    caffeine: this.state.caffeine,
                    category_id: this.state.category_id,
                    desc: JSON.stringify(this.state.desc_object),
                    origin: this.state.origin,
                    short_desc_eng: this.state.short_desc_eng,
                    short_desc_id: this.state.short_desc_id,
                    sku: this.state.sku,
                    sub_category_id: this.state.sub_category_id,
                    brewing_styles: JSON.stringify(this.state.brewing_styles),
                    feels: JSON.stringify(this.state.feels)
                }
                axios.put(`${baseUrl}/products/${data.id}`,
                    data,
                    requestConfigAdmin
                ).then((res) => {
                    if (res.data.success) {
                        this.getProduct();
                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "success",
                            message: "Update Product Info Success!"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                    } else {
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
    
                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "danger",
                            message: res.data.message
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log(res.data.message, res.data.data)
                    }
                }, (e) => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
    
                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: "Error : "+e.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log("Error : ", e);
                })
            } else {
                let add_header = {
                    ...requestConfigAdmin.headers,
                    'Content-Type': 'multipart/form-data'
                }
    
                let formData = new FormData();
                formData.append('name', this.state.name);
                formData.append('caffeine', this.state.caffeine);
                formData.append('category_id', this.state.category_id);
                formData.append('desc', JSON.stringify(this.state.desc_object));
                formData.append('origin', this.state.origin);
                formData.append('short_desc_eng', this.state.short_desc_eng);
                formData.append('short_desc_id', this.state.short_desc_id);
                formData.append('sku', this.state.sku);
                formData.append('sub_category_id', this.state.sub_category_id === null ? "" : this.state.sub_category_id);
                formData.append('brewing_styles', JSON.stringify(this.state.brewing_styles));
                formData.append('sub_products', JSON.stringify(this.state.sub_products));
                formData.append('feels', JSON.stringify(this.state.feels));
    
                for (let i = 0; i < this.state.sub_products.length; i++) {
                    const sub_product = this.state.sub_products[i];
    
                    for (let j = 0; j < sub_product.images.length; j++) {
                        const img = sub_product.images[j];
                        formData.append('sub_product_' + i + '_images[' + j + ']', img);
                    }
                }
    
                axios.post(`${baseUrl}/products`,
                    formData,
                    { headers: add_header },
                ).then((res) => {
                    if (res.data.success) {
                        let path = prefix + `/admin/products`;
                        this.props.history.push(path);
                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "success",
                            message: "Add New Product Success!"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
    
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                    } else {
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
    
                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "danger",
                            message: res.data.message
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log(res.data.message, res.data.data)
                    }
                }, (e) => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
    
                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: "Error : "+e.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log("Error : ", e);
                })
            }
        }else{
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
        }

    }

    setSubProducts = (newValue) => {
        this.setState({
            sub_products: newValue
        }, () => {
        })
    }

    onChangeTable = (newValue, varName) => {
        this.setState((prevState=> ({
            [varName]: newValue,
            [`${varName}_update_count`] : prevState[`${varName}_update_count`]+1
        })),
        ()=>{
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="gray">
                            <h4 className={classes.cardTitleWhite}>Product Info</h4>
                            <p>Product General Information</p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem container item sm={12} md={6}>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            value={this.state.name}
                                            inputRef={this.name_ref}
                                            error={this.state.name_error !== ""}
                                            helperText={this.state.name_error}
                                            type="text" onChange={this.onChange} name="name" label="Name" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={4}>
                                        <CustomTextField
                                            value={this.state.sku}
                                            inputRef={this.sku_ref}
                                            error={this.state.sku_error !== ""}
                                            helperText={this.state.sku_error}
                                            type="text" onChange={this.onChange} name="sku" label="SKU" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={4}>
                                        <CustomTextfieldRaw
                                            select
                                            label="Category"
                                            name="category_id"
                                            value={this.state.category_id}
                                            inputRef={this.category_id_ref}
                                            error={this.state.category_id_error !== ""}
                                            helperText={this.state.category_id_error}
                                            onChange={this.onChangeCategory}
                                        >
                                            {
                                                this.state.categories.map((category, i) => {
                                                    return <MenuItem value={category.id} key={category.id}>{category.name}</MenuItem>
                                                })
                                            }
                                        </CustomTextfieldRaw>
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={4}>
                                        <CustomTextfieldRaw
                                            select
                                            label="Sub Category"
                                            name="sub_category_id"
                                            value={
                                                this.state.sub_category_id === null ? '' :
                                                    this.state.sub_categories.length <= 0 ? '' : this.state.sub_category_id
                                            }
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value={null}><em>None</em></MenuItem>
                                            {
                                                this.state.sub_categories.map((sub_category, i) => {
                                                    return <MenuItem value={sub_category.id} key={sub_category.id}>{sub_category.name}</MenuItem>
                                                })
                                            }
                                        </CustomTextfieldRaw>
                                    </GridItem>
                                    <GridItem sm={8} xs={8} md={8}>
                                        {/* <CustomTextField
                                            value={this.state.origin}
                                            inputRef={this.origin_ref}
                                            error={this.state.origin_error !== ""}
                                            helperText={this.state.origin_error}
                                            type="text" onChange={this.onChange} name="origin" label="Origin" /> */}
                                    </GridItem>
                                    <GridItem sm={4} xs={4} md={4}>
                                        <CustomTextfieldRaw
                                            select
                                            label="Caffeine"
                                            name="caffeine"
                                            value={this.state.caffeine === null ? '' : this.state.caffeine}
                                            inputRef={this.caffeine_ref}
                                            error={this.state.caffeine_error !== ""}
                                            helperText={this.state.caffeine_error}
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value='0'>0</MenuItem>
                                            <MenuItem value='1'>1</MenuItem>
                                            <MenuItem value='2'>2</MenuItem>
                                            <MenuItem value='3'>3</MenuItem>
                                            <MenuItem value='4'>4</MenuItem>
                                            <MenuItem value='5'>5</MenuItem>
                                        </CustomTextfieldRaw>
                                    </GridItem>
                                </GridItem>
                                <GridItem container item sm={12} md={6}>
                                    <GridItem sm={12} xs={12} md={12}>
                                        <CustomTextField
                                            label="Short Description English"
                                            name="short_desc_eng"
                                            otherOptions={{
                                                multiline: true,
                                                rows: 2
                                            }}
                                            inputRef={this.short_desc_eng_ref}
                                            error={this.state.short_desc_eng_error !== ""}
                                            helperText={this.state.short_desc_eng_error}
                                            onChange={this.onChange}
                                            value={this.state.short_desc_eng === null ? '' : this.state.short_desc_eng}
                                        >
                                        </CustomTextField>
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={12}>
                                        <CustomTextField
                                            label="Short Description Indonesia"
                                            name="short_desc_id"
                                            otherOptions={{
                                                multiline: true,
                                                rows: 2
                                            }}
                                            inputRef={this.short_desc_id_ref}
                                            error={this.state.short_desc_id_error !== ""}
                                            helperText={this.state.short_desc_id_error}
                                            value={this.state.short_desc_id === null ? '' : this.state.short_desc_id}
                                            onChange={this.onChange}
                                        >
                                        </CustomTextField>
                                    </GridItem>
                                </GridItem>
                                {
                                    this.state.category_id === 1 || this.state.category_id === 5 ?
                                        <React.Fragment>
                                            <hr className={classes.borderHr}></hr>
                                            <GridItem container item sm={12} md={12}>
                                                <GridItem sm={2}>
                                                    <h4><b>Tea Feeling</b></h4>
                                                </GridItem>
                                                <GridItem sm={2}>
                                                    <CustomTextfieldRaw
                                                        select
                                                        label="Energetic"
                                                        name="energetic"
                                                        value={this.state.feels[0]}
                                                        onChange={(e) => this.onFeelingChange(0, e)}
                                                    // onChange={this.onFeelingChange.bind(this, 0)}
                                                    >
                                                        <MenuItem value='0'>0</MenuItem>
                                                        <MenuItem value='1'>1</MenuItem>
                                                        <MenuItem value='2'>2</MenuItem>
                                                        <MenuItem value='3'>3</MenuItem>
                                                        <MenuItem value='4'>4</MenuItem>
                                                        <MenuItem value='5'>5</MenuItem>
                                                    </CustomTextfieldRaw>
                                                </GridItem>

                                                <GridItem sm={2}>
                                                    <CustomTextfieldRaw
                                                        select
                                                        label="Relaxed"
                                                        name="relaxed"
                                                        value={this.state.feels[1]}
                                                        onChange={(e) => this.onFeelingChange(1, e)}
                                                    >
                                                        <MenuItem value='0'>0</MenuItem>
                                                        <MenuItem value='1'>1</MenuItem>
                                                        <MenuItem value='2'>2</MenuItem>
                                                        <MenuItem value='3'>3</MenuItem>
                                                        <MenuItem value='4'>4</MenuItem>
                                                        <MenuItem value='5'>5</MenuItem>
                                                    </CustomTextfieldRaw>
                                                </GridItem>

                                                <GridItem sm={2}>
                                                    <CustomTextfieldRaw
                                                        select
                                                        label="Fit"
                                                        name="fit"
                                                        value={this.state.feels[2]}
                                                        onChange={(e) => this.onFeelingChange(2, e)}
                                                    >
                                                        <MenuItem value='0'>0</MenuItem>
                                                        <MenuItem value='1'>1</MenuItem>
                                                        <MenuItem value='2'>2</MenuItem>
                                                        <MenuItem value='3'>3</MenuItem>
                                                        <MenuItem value='4'>4</MenuItem>
                                                        <MenuItem value='5'>5</MenuItem>
                                                    </CustomTextfieldRaw>
                                                </GridItem>

                                                <GridItem sm={2}>
                                                    <CustomTextfieldRaw
                                                        select
                                                        label="Inspired"
                                                        name="inspired"
                                                        value={this.state.feels[3]}
                                                        onChange={(e) => this.onFeelingChange(3, e)}
                                                    >
                                                        <MenuItem value='0'>0</MenuItem>
                                                        <MenuItem value='1'>1</MenuItem>
                                                        <MenuItem value='2'>2</MenuItem>
                                                        <MenuItem value='3'>3</MenuItem>
                                                        <MenuItem value='4'>4</MenuItem>
                                                        <MenuItem value='5'>5</MenuItem>
                                                    </CustomTextfieldRaw>
                                                </GridItem>

                                                <GridItem sm={2}>
                                                    <CustomTextfieldRaw
                                                        select
                                                        label="Delightful"
                                                        name="delightful"
                                                        value={this.state.feels[4]}
                                                        onChange={(e) => this.onFeelingChange(4, e)}
                                                    >
                                                        <MenuItem value='0'>0</MenuItem>
                                                        <MenuItem value='1'>1</MenuItem>
                                                        <MenuItem value='2'>2</MenuItem>
                                                        <MenuItem value='3'>3</MenuItem>
                                                        <MenuItem value='4'>4</MenuItem>
                                                        <MenuItem value='5'>5</MenuItem>
                                                    </CustomTextfieldRaw>
                                                </GridItem>
                                            </GridItem>
                                            <hr className={classes.borderHr}></hr>
                                        </React.Fragment>
                                        :
                                        ""
                                }

                                <GridItem item sm={12} md={12}>
                                    <CustomMaterialTable 
                                        name="desc_object"
                                        title="Long Description"
                                        data={this.state.desc_object}
                                        onChange={this.onChangeTable}
                                        count={this.state.desc_object_update_count}
                                        options={{
                                            search: false,
                                            exportButton: false,
                                            pageSize: 5,
                                            actionsColumnIndex: 4
                                        }}
                                    />
                                </GridItem>
                                <GridItem item sm={12} md={12}>
                                    <CustomMaterialTable 
                                        name="brewing_styles"
                                        title="Brewing Styles"
                                        data={this.state.brewing_styles}
                                        onChange={this.onChangeTable}
                                        count={this.state.brewing_styles_update_count}
                                        options={{
                                            search: false,
                                            exportButton: false,
                                            pageSize: 5,
                                            actionsColumnIndex: 7
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {this.state.type !== "add" ?
                                <Button color="info" onClick={this.handleSubmitProductInfo}>Update Product Info</Button>
                                :
                                null
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
                <ProductDetailVariant {...this.props} setSubProducts={this.setSubProducts} />
                {this.state.type === "add" ?
                    <GridItem item container xs={12} >
                        <Button color="info" onClick={this.handleSubmitProductInfo}>Store New Product</Button>
                    </GridItem>
                    :
                    null
                }
            </GridContainer>
        )
    }
}

export default withStyles(useStyles)(ProductDetail)
