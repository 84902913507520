import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Floral from "assets/img/havilla/floral_big.svg";

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import { refreshHeader, baseUrl, requestConfig } from '../../../../Environment.js';
import axios from 'axios';
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp.js';
import { ClientSideContext } from '../../../ClientSideContext';
import { dictionary } from '../../../../Dictionary';
const useStyles = theme => ({
    ...styles(theme),
    containerLogin: {
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            padding: "50px 40px 100px",
        },
        [theme.breakpoints.up('md')]: {
            padding: "7% 20% 0",
        },
    },
    floral: {
        marginLeft: "100%",
        marginTop: "-280px",
        [theme.breakpoints.down('sm')]: {
            display: 'none',
            width: "100px",
            position: "absolute",
            right: "-50px"
        },
    },
    textField: {
        width: "80%",
    }
});

class Logout extends Component {
    static contextType = ClientSideContext;
    constructor(props) {
        super(props)

        this.state = {
            snackbarType: 'info',
            snackbarMessage: '',
            snackbarCounter: 0,
        }
    }

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    handleLogout = () => {
        axios.get(`${baseUrl}/logout`, requestConfig).then((res) => {
            if (res.data.success) {
                localStorage.removeItem('auth');
                localStorage.removeItem('profile_picture');
                localStorage.removeItem('cart');
                refreshHeader();
                let path = this.props.prefix + `/`;
                this.props.history.push(path);
            } else {
                this.handleOpenSnackbar({
                    type: 'error',
                    message: res.data.message
                });
                this.setState({ openBackdrop: false });
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "error",
                message: `${e.message}`
            })
            console.log("Error : ", e);
        })
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });
    render() {
        const { classes } = this.props;
        return (
            <Container maxWidth="xl" className={`${classes.container} ${classes.containerLogin}`}>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.counter}
                />
                <Grid container className={classes.formContainer}>
                    <Grid item md={7} xs={12}>
                        <h3 className={classes.smallerTitle}>
                            {dictionary['See You Soon!'][this.context.language]}
                        </h3>
                        <p>
                            {dictionary['Are You Sure You Want to Logout?'][this.context.language]}</p>

                        <Grid container style={{ marginTop: 40 }}>
                            <Grid item xs={6} style={{ paddingRight: 20 }}>
                                <Link to={this.props.prefix + "/"}>
                                    <Button variant="outlined" className={classes.buttonLight}>Cancel</Button>
                                </Link>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="outlined" className={classes.buttonDark} onClick={this.handleLogout}>Yes, Log Out</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1} xs={12}>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <img className={classes.floral} src={Floral} alt="floral"></img>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default withStyles(useStyles)(Logout) 
