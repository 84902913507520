import React, { Component } from 'react'
import { withStyles, Container, Grid, Button } from '@material-ui/core';
import styles from "assets/jss/client-side/App.js";
import CustomLoading from './CustomLoading';
import Chamomile from "assets/img/havilla/own_blend.jpg";
import CustomColorCheckbox from 'client-side/components/CustomCheckBox/CustomColorCheckbox';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField';
import CustomIngredientCheckbox from 'client-side/components/CustomCheckBox/CustomIngredientCheckbox';
import InfoIcon from "assets/img/havilla/info-icon-small.svg";
import { ClientSideContext } from '../../ClientSideContext';
import axios from 'axios';
import { baseUrl, requestConfig } from 'Environment';
import LoadingScreenTimer from 'client-side/components/LoadingScreen/LoadingScreenTimer';
import { dictionary } from 'Dictionary';
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp';
import Grow from '@material-ui/core/Grow';

const useStyles = theme => ({
    ...styles(theme),
    img: {
        width: '300px',
        height: '600px',
        [theme.breakpoints.down('sm')]: {
            margin: 'auto'
        },
    },
    pageTitle: {
        marginTop: '2rem',
        fontSize: '28px',
        textAlign: 'center',
        fontFamily: 'BlaakLight',
    },
    pageDesc: {
        marginTop: '16px',
        fontSize: '16px',
        fontFamily: 'Martel Sans',
        textAlign: 'center',
    },
    labelChooseColor: {
        display: 'none',
        paddingTop: '24px',
        fontFamily: 'Martel Sans',
        fontWeight: 'bold',
        fontSize: '16px',
    },
    colorPickerContainer: {
        display: 'none',
        marginTop: '16px',
        marginLeft: '-9px',
    },
    labelNameYourBlend: {
        paddingTop: '24px',
        fontFamily: 'Martel Sans',
        fontWeight: 'bold',
        fontSize: '16px',
    },
    textFieldContainer: {
        marginTop: 14
    },
    teaAwardsContainer: {
        marginTop: '15px',
        marginLeft: '-9px',
    },
    teaAwardsLabel: {
        fontFamily: 'Martel Sans',
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '6px',
        marginTop: '1px'
    },
    infoIcon: {
        width: "13px",
        height: "13px",
        marginLeft: '10px'
    }
});

let timer;
export class PageComplete extends Component {
    static contextType = ClientSideContext;
    constructor(props) {
        super(props)

        this.state = {
            openBackdrop: false,
            language: '',

            timer: null,
            loading: true,
            color: "#7B8032",
            name: "",
            maker_name: "",
            tea_awards: false,

            snackbarType: 'info',
            snackbarMessage: '',
            snackbarCounter: 0,
        }
    }

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    openBackdrop = () => {
        this.setState({
            openBackdrop: true
        })
    }

    closeBackdrop = () => {
        this.setState({
            openBackdrop: false
        })
    }

    setFalseLoading = () => {
        this.setState({
            loading: false
        })
    }

    componentDidUpdate(nextProps, nextState) {
        if (this.state.language !== this.context.language) {
            this.setState({
                language: this.context.language
            })
        }

        return true;
    }

    componentDidMount() {
        this.setState({
            language: this.context.language,
        })
        let time = Math.round(Math.random() * (6000 - 6010)) + 6000;
        timer = setTimeout(this.setFalseLoading, time);

        const { ownProductIngredients } = this.context;
        const ownProductIngredientsContext = JSON.parse(ownProductIngredients);

        if (ownProductIngredientsContext.length < 1) {
            let path = this.props.prefix + `/create_your_own/create`;
            this.props.history.push(path);
        }
    }

    componentWillUnmount() {
        clearTimeout(timer);
    }

    handleColorCheckboxChange = (color) => {
        this.setState({
            color: color
        })
    }

    handleTeaAwardsCheckBox = () => {
        this.setState((prevState) => ({
            tea_awards: !prevState.tea_awards
        }))
    }

    handleSubmit = async () => {
        // this.openBackdrop();
        const { dispatchCartItems } = this.context;
        const { ownProductIngredients } = this.context;
        const { dispatchOwnProductIngredients } = this.context;
        let auth = localStorage.getItem('auth') ? true : false;

        if ((JSON.parse(ownProductIngredients)).length > 0) {
            let data = {
                color: this.state.color,
                name: this.state.name,
                maker_name: this.state.maker_name,
                tea_awards: this.state.tea_awards,
                own_product_details: ownProductIngredients
            }

            axios.post(`${baseUrl}/ownproducts`,
                data,
                requestConfig
            ).then((res) => {
                if (res.data.success) {
                    dispatchOwnProductIngredients({
                        type: "UPDATE_OWN_PRODUCT_INGREDIENTS",
                        new_own_product_ingredients: []
                    })
                    localStorage.setItem('own_product_ingredients', JSON.stringify([]));

                    let id = res.data.data.id
                    var own_product_ids = JSON.parse(localStorage.getItem('own_product_ids'));
                    if (own_product_ids === null) {
                        localStorage.setItem('own_product_ids', JSON.stringify([id]));
                    }
                    else {
                        let temp = [
                            ...own_product_ids,
                            id
                        ]
                        localStorage.setItem('own_product_ids', JSON.stringify(temp));
                    }

                    var own_product_ids = JSON.parse(localStorage.getItem('own_product_ids'));

                    if (auth) {
                        axios.post(`${baseUrl}/add_to_cart_own`,
                            { own_product_id: own_product_ids[0] },
                            requestConfig)
                            .then((res) => {
                                if (res.data.success) {
                                    dispatchCartItems({
                                        type: "ADD_TO_CART",
                                        newItem: res.data.data,
                                    })

                                    localStorage.setItem('own_product_ids', JSON.stringify([]));

                                } else {
                                    console.log(res.data.message, res.data.data)
                                    this.setState({
                                        snackbarType: 'error',
                                        snackbarMessage: 'Failed Added To Cart',
                                    });
                                }
                            }, (e) => {
                                console.log("Error : ", e);
                                this.setState({
                                    snackbarType: 'error',
                                    snackbarMessage: 'Failed Added To Cart',
                                });
                            })

                        let path = this.props.prefix + `/product_buying`;
                        this.props.history.push(path);
                    }
                    else {
                        this.handleOpenSnackbar({
                            type: 'success',
                            message: "Your Own Product Successfully Saved! Please Login to Continue Your Order."
                        });
                        let path = this.props.prefix + `/login`;
                        this.props.history.push(path);
                    }
                } else {
                    this.handleOpenSnackbar({
                        type: 'error',
                        message: res.data.message
                    });
                    // this.closeBackdrop();
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                // this.closeBackdrop();
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${e}`
                })
                console.log("Error : ", e);
            })
        } else {
            this.handleOpenSnackbar({
                type: "error",
                message: `Cannot Store Blend, Please make a new Blend`
            })
        }
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    render() {
        let { classes } = this.props
        return (
            <Container maxWidth="xl" className={`${classes.container} `}>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.counter}
                />
                {
                    this.state.loading ?
                        <CustomLoading language={this.state.language} />
                        :
                        this.state.openBackdrop ?
                            <LoadingScreenTimer language={this.state.language} />
                            :
                            <React.Fragment>
                                <Grid container spacing={2} justify="center">
                                    <Grid item xs={12} className={classes.pageTitle}>
                                        {dictionary['Own Blend Complete Headline'][this.state.language]}
                                    </Grid>
                                    <Grid item xs={12} className={classes.pageDesc}>
                                        {dictionary['Own Blend Complete Sub Headline'][this.state.language]}
                                    </Grid>

                                    {/* <Grow in={true} timeout={500}> */}
                                    <Grid
                                        item xs={12} md={6}
                                        container
                                        // className={classes.imgPreview}
                                        justify="flex-end"
                                    >
                                        <img className={classes.img} src={Chamomile} alt="preview own product"></img>
                                    </Grid>
                                    {/* </Grow> */}

                                    <Grid
                                        item xs={12} md={6}
                                    >
                                        <Grid item xs={12} className={classes.labelChooseColor}>
                                            {dictionary['Own Blend Complete Choose Color'][this.state.language]}
                                        </Grid>
                                        <Grid item xs={12} className={classes.colorPickerContainer}>
                                            <CustomColorCheckbox custom_color="#7B8032" onChange={() => this.handleColorCheckboxChange('#7B8032')} checked={this.state.color === "#7B8032"} />
                                            <CustomColorCheckbox custom_color="#E64006" onChange={() => this.handleColorCheckboxChange('#E64006')} checked={this.state.color === "#E64006"} />
                                            <CustomColorCheckbox custom_color="#363F57" onChange={() => this.handleColorCheckboxChange('#363F57')} checked={this.state.color === "#363F57"} />
                                            <CustomColorCheckbox custom_color="#D0527D" onChange={() => this.handleColorCheckboxChange('#D0527D')} checked={this.state.color === "#D0527D"} />
                                            <CustomColorCheckbox custom_color="#EFC988" onChange={() => this.handleColorCheckboxChange('#EFC988')} checked={this.state.color === "#EFC988"} />
                                        </Grid>
                                        <Grid item xs={12} className={classes.labelNameYourBlend}>
                                            {dictionary['Own Blend Complete Name Blend'][this.state.language]}
                                        </Grid>
                                        <Grid item md={6} xs={12} className={classes.textFieldContainer}>
                                            <CustomTextField
                                                width="80%"
                                                type="text" onChange={this.onChange} name="name" label={this.state.language === 'en' ? "Name" : "Nama"} />
                                        </Grid>
                                        <Grid item md={6} xs={12} className={classes.textFieldContainer}>
                                            <CustomTextField
                                                width="80%"
                                                type="text" onChange={this.onChange} name="maker_name" label={this.state.language === 'en' ? "Created By" : "Dibuat Oleh"} />
                                        </Grid>
                                        <Grid item md={6} xs={12} className={classes.teaAwardsContainer}
                                            container
                                            alignItems="center"
                                        >
                                            <CustomIngredientCheckbox
                                                onChange={this.handleTeaAwardsCheckBox}
                                                checked={this.state.tea_awards}
                                            />
                                            <Grid className={classes.teaAwardsLabel}>
                                                {dictionary['Own Blend Complete Tea Awards'][this.state.language]}
                                            </Grid>
                                            <img
                                                className={classes.infoIcon}
                                                src={InfoIcon} alt="info icon"></img>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}
                                        container
                                        spacing={2}
                                        justify="center"
                                        alignItems="center"
                                        style={{
                                            marginTop: '20px',
                                            marginBottom: '20px'
                                        }}
                                    >

                                        <Grid item xs={12} md={2}>
                                            <Button variant="outlined" className={classes.buttonDark} style={{ width: '100%' }}
                                                onClick={this.handleSubmit}
                                            >
                                                {dictionary['Own Blend Complete Button'][this.state.language]}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                }

            </Container>
        )
    }
}

export default withStyles(useStyles)(PageComplete)
