import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';

import OurSocialActImage from "assets/img/havilla/about_us_our_social_acts.png";
import OurPartnerStoreImage from "assets/img/havilla/about_us_our_partners_store.png";
import PostLargeGeneral from 'client-side/components/Post/PostLargeGeneral';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";

import {
    prefix
} from '../../../Environment.js';
import { dictionary } from '../../../Dictionary';
import { ClientSideContext } from '../../ClientSideContext';


const useStyles = theme => ({
    ...styles(theme),
    containerLogin: {
    },
    banner: {
        width: '100%'
    },
    textField: {
        width: "80%",
    },
    menuIcon: {
        margin: 'auto',
        display: 'block'
    },
    questionBox: {
        border: '1px solid grey',
        padding: 25,
        margin: '45px 10px 25px 10px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0
        }
    },
    moreContainer: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: 10
        },
        marginTop: 50
    }
});

class AboutUsMore extends Component {
    static contextType = ClientSideContext;

    constructor(props) {
        super(props);

        this.state = {
            openBackdrop: false,

            email: '',
            password: '',
        }
    }


    onChange = (e) => this.setState({ [e.target.name]: e.target.value })
    render() {
        const { classes } = this.props;
        return (
            <Grid container className={classes.moreContainer}>
                <Grid item xs={12} md={12}>
                    <h5 className={classes.smallTitle}>{dictionary['More about Havilla'][this.context.language]}</h5>
                </Grid>
                <Grid container spacing={5}>
                    <PostLargeGeneral
                        xs={12}
                        md={6}
                        img={OurSocialActImage}
                        title={dictionary['Our Social Acts'][this.context.language]}
                        desc={dictionary['Our Social Acts Caption'][this.context.language]}
                        linkText={dictionary['Our Social Acts CTA'][this.context.language]}
                        linkTo={`${prefix}/about_us/social_acts`}
                    />

                    <PostLargeGeneral
                        xs={12}
                        md={6}
                        img={OurPartnerStoreImage}
                        title={dictionary['Our Partners Store'][this.context.language]}
                        desc={dictionary['Our Partners Store Caption'][this.context.language]}
                        linkText={dictionary['Our Partners Store CTA'][this.context.language]}
                        linkTo={`${prefix}/about_us/our_partners`}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(useStyles)(AboutUsMore) 
