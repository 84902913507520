import React, { Component } from 'react'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import { baseUrl, requestConfigAdmin } from 'Environment';
import { withStyles } from '@material-ui/core';
import globalStyles from "assets/jss/client-side/App.js";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import GridItem from 'admin/components/Grid/GridItem';
import Card from "admin/components/Card/Card.js";
import CardHeader from "admin/components/Card/CardHeader.js";
import CardBody from "admin/components/Card/CardBody.js";
import GridContainer from 'admin/components/Grid/GridContainer';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField';
import CardFooter from 'admin/components/Card/CardFooter';
import Button from "admin/components/CustomButtons/Button.js";
import {
    prefix
} from '../../../Environment.js';

const useStyles = theme => ({
    ...globalStyles(theme),
    ...dashboardStyle,
});


export class OwnProductIngredientTypeDetail extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props)

        this.state = {
            type: "update",

            id: null,
            name_id: "",
            name_en: "",
            max_free: "",

        }
    }

    getOwnProductIngredientType = () => {
        const id = this.props.match.params.id;
        axios.get(`${baseUrl}/own_product_ingredient_types/${id}`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    ...res.data.data,
                }, () => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        if (id !== "add") {
            this.getOwnProductIngredientType();
            this.setState({
                type: "update"
            })
        } else {
            this.setState({
                type: "add"
            })
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmitOwnProductIngredientType = () => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        let data = {
            id: this.state.id,
            name_id: this.state.name_id,
            name_en: this.state.name_en,
            max_free: this.state.max_free
        }
        if (this.state.type === "update") {
            axios.put(`${baseUrl}/own_product_ingredient_types/${data.id}`,
                data,
                requestConfigAdmin
            ).then((res) => {
                if (res.data.success) {
                    this.getOwnProductIngredientType();
                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "success",
                        message: "Update Own Product Ingredient Type Info Success!"
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                } else {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: res.data.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: "Error : "+e.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log("Error : ", e);
            })
        } else {
            axios.post(`${baseUrl}/own_product_ingredient_types`,
                data,
                requestConfigAdmin,
            ).then((res) => {
                if (res.data.success) {
                    let path = prefix + `/admin/own_product_ingredient_types`;
                    this.props.history.push(path);
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "success",
                        message: "Add New Own Product Ingredient Type Success!"
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                } else {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: res.data.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: "Error : "+e.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log("Error : ", e);
            })
        }

    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="gray">
                            <h4 className={classes.cardTitleWhite}>Own Product Ingredient Type</h4>
                            <p>Manage Own Product Ingredient Type</p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem container item sm={12} md={6}>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            value={this.state.name_en}
                                            // error={this.state.name_error !== ""}
                                            // helperText={this.state.name_error}
                                            type="text" onChange={this.onChange} name="name_en" label="Name English" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            value={this.state.name_id}
                                            // error={this.state.name_error !== ""}
                                            // helperText={this.state.name_error}
                                            type="text" onChange={this.onChange} name="name_id" label="Name Indonesia" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            value={this.state.max_free}
                                            // error={this.state.max_free_error !== ""}
                                            // helperText={this.state.max_free_error}
                                            type="number" onChange={this.onChange} name="max_free" label="Max Free" />
                                    </GridItem>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {this.state.type !== "add" ?
                                <Button color="info" onClick={this.handleSubmitOwnProductIngredientType}>Update Own Product Ingredient Type</Button>
                                :
                                <Button color="info" onClick={this.handleSubmitOwnProductIngredientType}>Store New Own Product Ingredient Type</Button>
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

export default withStyles(useStyles)(OwnProductIngredientTypeDetail)
