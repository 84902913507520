import React, { Component } from 'react'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import { baseUrl, requestConfigAdmin } from 'Environment';
import { withStyles, TextField, MenuItem } from '@material-ui/core';
import globalStyles from "assets/jss/client-side/App.js";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import GridItem from 'admin/components/Grid/GridItem';
import Card from "admin/components/Card/Card.js";
import CardHeader from "admin/components/Card/CardHeader.js";
import CardBody from "admin/components/Card/CardBody.js";
import GridContainer from 'admin/components/Grid/GridContainer';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField';
import CardFooter from 'admin/components/Card/CardFooter';
import Button from "admin/components/CustomButtons/Button.js";
import { VoucherRequirement } from './VoucherRequirement';
import Datepicker from 'client-side/components/Datepicker/Datepicker';
import moment from 'moment';
import {
    prefix
} from '../../../Environment.js';

const CustomTextfieldRaw = withStyles({
    root: {
        width: '100%',
        '& label.Mui-focused': {
            color: 'black',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);

const useStyles = theme => ({
    ...globalStyles(theme),
    ...dashboardStyle,
    // USED IN PRODUCT DETAIL VARIANT
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'auto'
    },
    paperModal: {
        [theme.breakpoints.down('sm')]: {
            padding: '1rem',
            width: '100%',
        },
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: '0',
        width: '672px',
        height: '389px',
    },
    closeIconModal: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer'
    },
    contentContainerModal: {
        padding: '10px 30px',
    },
    contentTitleModal: {
        padding: '20px 30px',
        fontSize: '20px',
        fontFamily: 'Martel Sans',
        fontWeight: 'bold',
        borderBottom: '1px solid rgba(0,0,0, 0.3)'
    },
    imgPreview: {
        width: '250px',
        cursor: 'pointer',
        '&:hover + .delete-button': {
            marginTop: '-17px',
        }
    },
    deletePict: {
        transition: 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)',
        float: 'right',
        marginTop: '-37px',
        display: 'block',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            marginTop: '-17px',
        },
    },
    tempPictContainer: {
        paddingTop: '20px',
        overflow: 'hidden',
        '&:hover .delete-button': {
            marginTop: '-17px',
        }
    },
    img: {
        width: '50px',
        height: '50px',
    },
    buttonTable: {
        minWidth: '104px'
    },
    borderHr: {
        margin: '20px 0',
        width: '100%',
        border: '1px solid #fafafa'
    }
    // END USED IN PRODUCT DETAIL VARIANT

});

const required_fields = [
    'name',
    'description',
    'code',
    'type',
    'discount',
    'max_discount',
    'valid_from',
    'valid_to',
]

export class VoucherDetail extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props)

        this.state = {
            page_type: "update",

            id: null,
            name: "",
            name_error: "",

            description: "",
            description_error: "",

            code: '',
            code_error: '',

            types: [
                {
                    'id': 'percentage',
                    'name': 'Percentage'
                },
                {
                    'id': 'amount',
                    'name': 'Amount'
                },
                {
                    'id': 'shipping_cost',
                    'name': 'Shipping Cost'
                },
            ],
            type: "",
            type_error: '',

            discount: "",
            discount_error: "",

            max_discount: "",
            max_discount_error: "",

            valid_from: null,
            valid_from_error: "",

            valid_to: null,
            valid_to_error: "",

            qty: "",
            qty_error: "",

            voucher_requirements: [],
        }

        this.name_ref = React.createRef();
        this.description_ref = React.createRef();
        this.code_ref = React.createRef();
        this.type_ref = React.createRef();
        this.discount_ref = React.createRef();
        this.max_discount_ref = React.createRef();
        this.valid_from_ref = React.createRef();
        this.valid_to_ref = React.createRef();
        this.qty_ref = React.createRef();
    }

    stringToUpperCase = (str) => {
        return str.toUpperCase();
    }

    getVoucher = () => {
        const id = this.props.match.params.id;
        axios.get(`${baseUrl}/vouchers/${id}`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    ...res.data.data,
                }, () => {
                    let valid_from_formatted = moment(this.state.valid_from).format('YYYY-MM-DD');
                    let valid_to_formatted = moment(this.state.valid_to).format('YYYY-MM-DD');
                    this.setState({
                        valid_from : valid_from_formatted,
                        valid_to : valid_to_formatted,
                    })
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        if (id !== "add") {
            this.getVoucher();
            this.setState({
                page_type: "update"
            })
        } else {
            this.setState({
                page_type: "add"
            })
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
        }
    }

    componentDidUpdate(nextProps, nextState) {
        for (let i = 0; i < required_fields.length; i++) {
            let field_name = required_fields[i];
            if (this.state[field_name + "_error"] !== "") {
                if (this.state[field_name] !== nextState[field_name]) {
                    this.setState({ [field_name + "_error"]: "" });
                }
            }
        }

        return true;
    }

    onChange = (e) => {
        if (e.target.name === 'code') {
            this.setState({ [e.target.name]: this.stringToUpperCase(e.target.value) })
        } else if (e.target.name === 'discount') {
            this.setState({
                [e.target.name]: e.target.value
            }, () => {
                this.handleValidationDiscount('max_discount', 'discount');
            })
        } else if (e.target.name === 'max_discount') {
            this.setState({
                [e.target.name]: e.target.value
            }, () => {
                this.handleValidationDiscount('discount', 'max_discount');
            })
        }
        else if (e.target.name === 'type') {
            this.setState({
                [e.target.name]: e.target.value
            }, () => {
                this.setState({
                    discount: '',
                    max_discount: ''
                })
            })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    handleValidationDiscount = (name, target) => {
        if (this.state.type === 'amount' || this.state.type === 'shipping_cost') {
            this.setState({
                [name]: this.state[target]
            })
        }
    }

    handleValidation = () => {
        let is_ok = true;
        for (let i = 0; i < required_fields.length; i++) {
            let field_name = required_fields[i];
            let field_name_text = required_fields[i].replace('_', ' ');
            if (this.state[field_name] === "" || this.state[field_name] === null || this.state[field_name].length === 0) {
                is_ok = false;
                this[field_name + "_ref"].current.focus();
                this.setState({ [field_name + "_error"]: field_name_text + " is required" });

            }
        }
        return is_ok;
    }

    handleValidFromToValidation = () => {
        let valid_from_date = moment(this.state.valid_from)
        let valid_to_date = moment(this.state.valid_to)
        if (valid_from_date > valid_to_date) {
            this.setState({
                valid_to: null
            })
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: "Valid To cannot be before Valid From"
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
        }
    }

    handleErrorFromRequest = (errors) => {
        for (let i = 0; i < required_fields.length; i++) {
            let field_name = required_fields[i];
            let field_name_text = required_fields[i].replace('_', ' ');
            if (errors[field_name] !== undefined) {
                this[field_name + "_ref"].current.focus();
                this.setState({ [field_name + "_error"]: errors[field_name][0] });

            }
        }
    }

    handleSubmitVoucherInfo = () => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })

        let is_ok = this.handleValidation();
        if (is_ok) {
            if (this.state.page_type === "update") {
                let data = {
                    id: this.state.id,
                    name: this.state.name,
                    description: this.state.description,
                    code: this.state.code,
                    type: this.state.type,
                    discount: this.state.discount,
                    max_discount: this.state.max_discount,
                    valid_from: this.state.valid_from,
                    valid_to: this.state.valid_to,
                    qty: this.state.qty,
                }

                axios.put(`${baseUrl}/vouchers/${data.id}`,
                    data,
                    requestConfigAdmin
                ).then((res) => {
                    if (res.data.success) {
                        this.getVoucher();
                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "success",
                            message: "Update Voucher Info Success!"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    } else {
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "danger",
                            message: res.data.message
                        })

                        if (res.data.data) {
                            this.handleErrorFromRequest(res.data.data);
                        }
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log(res.data.message, res.data.data)
                    }
                }, (e) => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: "Error : "+e.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log("Error : ", e);
                })
            } else {
                let data = {
                    name: this.state.name,
                    description: this.state.description,
                    code: this.state.code,
                    type: this.state.type,
                    discount: this.state.discount,
                    max_discount: this.state.max_discount,
                    valid_from: this.state.valid_from,
                    valid_to: this.state.valid_to,
                    qty: this.state.qty,
                    voucher_requirements: JSON.stringify(this.state.voucher_requirements)
                }
                axios.post(`${baseUrl}/vouchers`,
                    data,
                    requestConfigAdmin,
                ).then((res) => {
                    if (res.data.success) {
                        let path = prefix + `/admin/vouchers`;
                        this.props.history.push(path);
                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "success",
                            message: "Add New Voucher Success!"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})

                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                    } else {
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "danger",
                            message: res.data.message
                        })

                        if (res.data.data) {
                            this.handleErrorFromRequest(res.data.data);
                        }
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log(res.data.message, res.data.data)
                    }
                }, (e) => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: "Error : "+e.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log("Error : ", e);
                })
            }
        } else {
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
        }

    }

    handleValidFromChange = (newValue) => {
        this.setState({
            valid_from: newValue,
        }, () => {
            this.handleValidFromToValidation();
        })
    }

    handleValidToChange = (newValue) => {
        this.setState({
            valid_to: newValue,
        }, () => {
            this.handleValidFromToValidation();
        })
    }

    setVoucherRequirements = (newValue) => {
        this.setState({
            voucher_requirements: newValue
        }, () => {
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="gray">
                            <h4 className={classes.cardTitleWhite}>Voucher Info</h4>
                            <p>Voucher General Information</p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem container item sm={12} md={12}>
                                    <GridItem sm={12} xs={12} md={3}>
                                        <CustomTextField
                                            value={this.state.code}
                                            inputRef={this.code_ref}
                                            error={this.state.code_error !== ""}
                                            helperText={this.state.code_error}
                                            type="text" onChange={this.onChange} name="code" label="Code" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={3}>
                                        <CustomTextfieldRaw
                                            select
                                            label="Type"
                                            name="type"
                                            value={this.state.type}
                                            inputRef={this.type_ref}
                                            error={this.state.type_error !== ""}
                                            helperText={this.state.type_error}
                                            onChange={this.onChange}
                                        >
                                            {
                                                this.state.types.map((type, i) => {
                                                    return <MenuItem value={type.id} key={type.id}>{type.name}</MenuItem>
                                                })
                                            }
                                        </CustomTextfieldRaw>
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={6}>
                                        <CustomTextField
                                            value={this.state.name}
                                            inputRef={this.name_ref}
                                            error={this.state.name_error !== ""}
                                            helperText={this.state.name_error}
                                            type="text" onChange={this.onChange} name="name" label="Name" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            value={this.state.description}
                                            inputRef={this.description_ref}
                                            error={this.state.description_error !== ""}
                                            helperText={this.state.description_error}
                                            otherOptions={{
                                                multiline: true,
                                                rows: 2
                                            }}
                                            type="text" onChange={this.onChange} name="description" label="Description" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={6}>
                                        <CustomTextField
                                            disabled={this.state.type === ''}
                                            value={this.state.discount}
                                            inputRef={this.discount_ref}
                                            error={this.state.discount_error !== ""}
                                            helperText={this.state.discount_error}
                                            type="number" onChange={this.onChange} name="discount" label="Discount (Discount value based on type chosen)" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={6}>
                                        <CustomTextField
                                            disabled={this.state.type === ''}
                                            value={this.state.max_discount}
                                            inputRef={this.max_discount_ref}
                                            error={this.state.max_discount_error !== ""}
                                            helperText={this.state.max_discount_error}
                                            type="number" onChange={this.onChange} name="max_discount" label="Max Discount (Rp)" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={4}>
                                        <Datepicker
                                            id="valid_from"
                                            width="100%"
                                            label="Valid From"
                                            value={this.state.valid_from}
                                            inputRef={this.valid_from_ref}
                                            error={this.state.valid_from_error !== ""}
                                            helperText={this.state.valid_from_error}
                                            name="valid_from" handleDateChange={this.handleValidFromChange}></Datepicker>
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={4}>
                                        <Datepicker
                                            id="valid_to"
                                            width="100%"
                                            label="Valid To"
                                            value={this.state.valid_to}
                                            inputRef={this.valid_to_ref}
                                            error={this.state.valid_to_error !== ""}
                                            helperText={this.state.valid_to_error}
                                            name="valid_to" handleDateChange={this.handleValidToChange}></Datepicker>
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={4}>
                                        <CustomTextField
                                            value={this.state.qty}
                                            inputRef={this.qty_ref}
                                            error={this.state.qty_error !== ""}
                                            helperText={this.state.qty_error}
                                            type="number" onChange={this.onChange} name="qty" label="Quantity (Leave it blank = No quantity limit)" />
                                    </GridItem>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {this.state.page_type !== "add" ?
                                <Button color="info" onClick={this.handleSubmitVoucherInfo}>Update Voucher Info</Button>
                                :
                                null
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
                <VoucherRequirement {...this.props} setVoucherRequirements={this.setVoucherRequirements} />
                {this.state.page_type === "add" ?
                    <GridItem item container xs={12} >
                        <Button color="info" onClick={this.handleSubmitVoucherInfo}>Store New Voucher</Button>
                    </GridItem>
                    :
                    null
                }
            </GridContainer>
        )
    }
}

export default withStyles(useStyles)(VoucherDetail)
