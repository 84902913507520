import React, { Component, useContext } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Banner from "assets/img/havilla/about_us_image.png";
import HavillaGiftsIcon from "assets/img/havilla/about_us_havilla_gifts.svg";
import AlarmIcon from "assets/img/havilla/about_us_alarm.svg";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";

import {
    baseUrl,
    requestConfig
} from '../../../Environment.js';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField.js';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { ClientSideContext } from '../../ClientSideContext';
import { dictionary } from 'Dictionary';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    panelSummary: { background: '#222222', color: 'white' }
    ,
    panelDetails:
        { background: '#121212', color: 'white' }
    ,
    expand_icon: {
        color: "white"
    },
}));

export default function CustomizedExpansionPanels() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('panel1');
    const { language } = useContext(ClientSideContext)

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Container maxWidth="xl" style={{ backgroundColor: '#000000' }}>
            <Container maxWidth="md">
                <h2 style={{ textAlign: 'center', fontFamily: 'BlaakLight', padding: '40px 0 60px 0', color: 'white' }}>
                    {dictionary['Frequently Asked Questions'][language]}
                </h2>
                <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expand_icon} />} aria-controls="panel1d-content" id="panel1d-header" className={classes.panelSummary}>
                        <Typography style={{ fontSize: 23, fontFamily: 'Martel Sans' }}>{dictionary['FAQ Question 1'][language]}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.panelDetails}>
                        <Typography style={{ fontFamily: 'Martel Sans', fontSize: 15 }}>
                            {dictionary['FAQ Answer 1'][language]}
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expand_icon} />} aria-controls="panel2d-content" id="panel2d-header" className={classes.panelSummary}>
                        <Typography style={{ fontSize: 23, fontFamily: 'Martel Sans' }}>
                            {dictionary['FAQ Question 2'][language]}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.panelDetails}>
                        <Typography style={{ fontFamily: 'Martel Sans', fontSize: 15 }}>
                            {dictionary['FAQ Answer 2'][language]}
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expand_icon} />} aria-controls="panel3d-content" id="panel3d-header" className={classes.panelSummary}>
                        <Typography style={{ fontSize: 23, fontFamily: 'Martel Sans' }}>
                            {dictionary['FAQ Question 3'][language]}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.panelDetails}>
                        <Typography style={{ fontFamily: 'Martel Sans', fontSize: 15 }}>
                            {dictionary['FAQ Answer 3'][language]}
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expand_icon} />} aria-controls="panel4d-content" id="panel4d-header" className={classes.panelSummary}>
                        <Typography style={{ fontSize: 23, fontFamily: 'Martel Sans' }}>
                            {dictionary['FAQ Question 4'][language]}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.panelDetails}>
                        <Typography style={{ fontFamily: 'Martel Sans', fontSize: 15 }}>
                            {dictionary['FAQ Answer 4'][language]}
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel square expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expand_icon} />} aria-controls="panel5d-content" id="panel5d-header" className={classes.panelSummary}>
                        <Typography style={{ fontSize: 23, fontFamily: 'Martel Sans' }}>
                            {dictionary['FAQ Question 5'][language]}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.panelDetails}>
                        <Typography style={{ fontFamily: 'Martel Sans', fontSize: 15 }}>
                            {dictionary['FAQ Answer 5'][language]}
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel square expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expand_icon} />} aria-controls="panel6d-content" id="panel6d-header" className={classes.panelSummary}>
                        <Typography style={{ fontSize: 23, fontFamily: 'Martel Sans' }}>
                            {dictionary['FAQ Question 6'][language]}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.panelDetails}>
                        <Typography style={{ fontFamily: 'Martel Sans', fontSize: 15 }}>
                            {dictionary['FAQ Answer 6'][language]}
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {/* <ExpansionPanel square expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expand_icon} />} aria-controls="panel7d-content" id="panel7d-header" className={classes.panelSummary}>
                        <Typography style={{fontSize: 23, fontFamily: 'Martel Sans'}}>
                            {dictionary['FAQ Question 7'][language]}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography style={{fontFamily: 'Martel Sans', fontSize:15}}>
                            {dictionary['FAQ Answer 7'][language]}
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel> */}
                <ExpansionPanel square expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expand_icon} />} aria-controls="panel8d-content" id="panel8d-header" className={classes.panelSummary}>
                        <Typography style={{ fontSize: 23, fontFamily: 'Martel Sans' }}>
                            {dictionary['FAQ Question 8'][language]}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.panelDetails}>
                        <Typography style={{ fontFamily: 'Martel Sans', fontSize: 15 }}>
                            {dictionary['FAQ Answer 8'][language]}
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel square expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expand_icon} />} aria-controls="panel9d-content" id="panel9d-header" className={classes.panelSummary}>
                        <Typography style={{ fontSize: 23, fontFamily: 'Martel Sans' }}>
                            {dictionary['FAQ Question 9'][language]}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.panelDetails}>
                        <Typography style={{ fontFamily: 'Martel Sans', fontSize: 15 }}>
                            {dictionary['FAQ Answer 9'][language]}
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel square expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expand_icon} />} aria-controls="panel10d-content" id="panel10d-header" className={classes.panelSummary}>
                        <Typography style={{ fontSize: 23, fontFamily: 'Martel Sans' }}>
                            {dictionary['FAQ Question 10'][language]}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.panelDetails}>
                        <Typography style={{ fontFamily: 'Martel Sans', fontSize: 15 }}>
                            {dictionary['FAQ Answer 10'][language]}
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Container>
        </Container>
    );
}
