import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
// import { Link } from 'react-router-dom';
import Floral from "assets/img/havilla/floral_big.svg";
// import PasswordField from "client-side/components/PasswordField/PasswordField";

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import Datepicker from 'client-side/components/Datepicker/Datepicker';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField';
import axios from 'axios';
import {
    baseUrl,
    requestConfig,
    prefix
} from '../../../Environment.js';
import GenderRadioButton from 'client-side/components/CustomRadioButton/GenderRadioButton.js';
import PasswordField from 'client-side/components/PasswordField/PasswordField.js';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { ClientSideContext } from '../../ClientSideContext';
import { dictionary } from 'Dictionary.js';
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp.js';

const useStyles = theme => ({
    ...styles(theme),
    containerLogin: {
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            padding: "50px 40px 100px",
        },
        [theme.breakpoints.up('md')]: {
            padding: "7% 20% 0",
        },
    },
    floral: {
        marginLeft: "20%",
        marginTop: "-80px",
        [theme.breakpoints.down('sm')]: {
            width: "100px",
            position: "absolute",
            right: "-50px"
        },
    },
    buttonRegister: {
        marginTop: '68px'
    },
});

const field_names = [
    'first_name',
    'last_name',
    'email',
    'phone_number',
    'gender',
    'birthday',
    'password',
    'password_confirmation'
]

class Register extends Component {
    static contextType = ClientSideContext;
    state = {
        openBackdrop: false,
        language: '',

        password: '',
        password_confirmation: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        gender: null,
        birthday: null,

        first_name_error: "",
        last_name_error: "",
        email_error: "",
        phone_number_error: '',
        birthday_error: "",
        password_error: "",
        password_confirmation_error: "",
        gender_error: "",

        snackbarType: 'info',
        snackbarMessage: '',
        snackbarCounter: 0,
        // dll
    };

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    componentDidMount() {
        this.setState({
            language: this.context.language
        })
    }

    componentDidUpdate(nextProps, nextState) {
        for (let i = 0; i < field_names.length; i++) {
            let field_name = field_names[i];
            if (this.state[field_name + "_error"] !== "") {
                if (this.state[field_name] !== nextState[field_name]) {
                    this.setState({ [field_name + "_error"]: "" });
                }
            }
        }

        

        return true;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let is_ok = true;
        for (let i = 0; i < field_names.length; i++) {
            let field_name = field_names[i];
            let field_name_text = field_names[i].replace('_', ' ');
            if (this.state[field_name] === "" || this.state[field_name] === null) {
                this.setState({ [field_name + "_error"]: field_name_text + " is required" });
                is_ok = false;
            }
        }

        const {
            password,
            password_confirmation } = this.state;
        // perform all neccassary validations
        if (password !== password_confirmation) {
            is_ok = false;
            this.setState({
                password_confirmation_error: "Re-Password Didn't Match!"
            });
        }
        if (is_ok) {
            this.setState({ openBackdrop: true });
            let data = {};

            for (let i = 0; i < field_names.length; i++) {
                const field = field_names[i];
                if (field === "birthday") {
                    var d = new Date(this.state[field]);
                    data[field] = d.getFullYear() + "-" + String(d.getMonth() + 1).padStart(2, '0') + "-" + String(d.getDate()).padStart(2, '0');
                } else {
                    data[field] = this.state[field]
                }
            }

            axios.post(`${baseUrl}/register`,
                data,
                requestConfig
            ).then((res) => {
                if (res.data.success) {
                    let path = prefix + `/login`;

                    this.handleOpenSnackbar({
                        type: 'success',
                        message: "Register success, please check your email and verify your email"
                    });
                    this.setState({ openBackdrop: false });
                    setTimeout(() => {
                        this.props.history.push(path);
                    }, 3000);
                } else {
                    let errors = res.data.data;
                    this.setState({ openBackdrop: false });

                    for (let i = 0; i < field_names.length; i++) {
                        let field_name = field_names[i];
                        if (errors[field_name] !== undefined) {
                            this.setState({ [field_name + "_error"]: errors[field_name][0] });
                        }
                    }
                    // console.log(res.data.message,res.data.data)
                }
            }, (e) => {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${e.message}`
                })
                console.log("Error : ", e);
            })
        }
    }

    handleDateChange = (newValue) => {
        this.setState({
            birthday: newValue,
        })
    }

    handleGenderChange = (event) => {
        this.setState({
            gender: event.target.value,
        })
    };
    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    render() {
        const { classes } = this.props;
        return (
            <Container maxWidth="xl" className={`${classes.container} ${classes.containerLogin}`}>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.counter}
                />
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{'color': '#AF986D'}} />
                </Backdrop>
                <Grid container className={classes.formContainer}>
                    <Grid item xl={8} lg={8} md={11} xs={12}>
                        <h3 className={classes.smallerTitle}>{dictionary['Register Headline'][this.context.language]}</h3>
                        <p>{dictionary['Register Sub Headline'][this.context.language]}</p>
                        <br />
                        <form className={classes.root} autoComplete="off" onSubmit={this.handleSubmit} >
                            <Grid container className={classes.formContainer} spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <CustomTextField
                                        width="80%"
                                        error={this.state.first_name_error !== ""}
                                        helperText={this.state.first_name_error}
                                        type="text" onChange={this.onChange} name="first_name" label={dictionary['First Name'][this.context.language]} />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <CustomTextField
                                        width="80%"
                                        error={this.state.last_name_error !== ""}
                                        helperText={this.state.last_name_error}
                                        type="text" onChange={this.onChange} name="last_name" label={dictionary['Last Name'][this.context.language]} />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <CustomTextField
                                        width="80%"
                                        error={this.state.email_error !== ""}
                                        helperText={this.state.email_error}
                                        type="text" onChange={this.onChange} name="email" label="Email" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <CustomTextField
                                        width="80%"
                                        error={this.state.phone_number_error !== ""}
                                        helperText={this.state.phone_number_error}
                                        type="text" onChange={this.onChange} name="phone_number" label={dictionary['Phone Number'][this.context.language]} />
                                </Grid>
                                <Grid item md={6} xs={6}>
                                    <GenderRadioButton
                                        error={this.state.gender_error !== ""}
                                        value={this.state.gender}
                                        onChange={this.handleGenderChange}
                                        gender_error={this.state.gender_error}
                                    ></GenderRadioButton>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Datepicker
                                        label={dictionary['Date of Birth'][this.context.language]}
                                        error={this.state.birthday_error !== ""}
                                        helperText={this.state.birthday_error}
                                        name="birthday" value={this.state.birthday} handleDateChange={this.handleDateChange}></Datepicker>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <PasswordField
                                        error={this.state.password_error !== ""}
                                        helperText={this.state.password_error}
                                        hideIcon={true}
                                        type="password" onChange={this.onChange} name="password" label="Password" />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <PasswordField
                                        error={this.state.password_confirmation_error !== ""}
                                        helperText={this.state.password_confirmation_error}
                                        hideIcon={true}
                                        type="password" onChange={this.onChange} name="password_confirmation" 
                                        label={dictionary['Re-Type Your Password'][this.context.language]} />
                                </Grid>
                            </Grid>
                            {
                                dictionary['Register Button'][this.context.language] ?
                                    <Button type="submit" variant="outlined" className={`${classes.buttonDark} ${classes.buttonRegister}`}>{dictionary['Register Button'][this.context.language]}</Button>
                                :
                                    null
                            }
                        </form>
                    </Grid>
                    <Grid item xl={4} lg={4} md={1} xs={12}>
                        <img className={classes.floral} src={Floral} alt="floral"></img>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default withStyles(useStyles)(Register) 
