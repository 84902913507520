import React, { Component } from 'react'
import MaterialTable from 'material-table'

import ButtonAdmin from "admin/components/CustomButtons/Button.js";
import axios from 'axios';
import {
    baseUrl,
    requestConfigAdmin
} from '../../../Environment.js';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import {
    prefix
} from '../../../Environment.js';
const useStyles = theme => ({
    ...styles(theme),
});

const columns = [
    { title: 'Name', field: 'name' },
    { title: 'City', field: 'regency.name' },
    { title: 'Type', field: 'type' },
    { title: 'Latitude', field: 'lat' },
    { title: 'Longitude', field: 'lng' },
]

export class StoreLocator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            storeLocations: [],
        }
    }

    componentDidMount = async () => {
        this.setState({ openBackdrop: true });
        await axios.get(`${baseUrl}/storelocations`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    storeLocations: res.data.data
                }, () => {
                })
            } else {
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            console.log("Error : ", e);
        })
    }

    render() {
        return (
            <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    columns={columns}
                    // data={[{ name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 }]}
                    data={this.state.storeLocations}
                    title={<ButtonAdmin color="info" onClick={() => this.props.history.push(prefix + '/admin/storelocator/detail/add')}>Add New Store Location</ButtonAdmin>}
                    options={{
                        search: true,
                        exportButton: true,
                        pageSize: 10
                    }}
                    onRowClick={(event, rowData) => this.props.history.push(prefix + '/admin/storelocator/detail/' + rowData.id)}
                // detailPanel={rowData => {
                //     console.log(rowData);
                //     return (
                //         <Container maxWidth="xl" className={`${classes.container} ${classes.contentContainer}`}>
                //             <Grid container>
                //                 <Grid item md={6} xs={12}>
                //                     <CustomTextField
                //                         value={rowData.name}
                //                         // error={this.state.first_name_error !== ""}
                //                         // disabled={this.state.disabled}
                //                         // helperText={this.state.first_name_error}
                //                         type="text" name="name" label="Store Name" />
                //                 </Grid>
                //                 <Grid item md={6} xs={12}>
                //                     <CustomTextField
                //                         value={this.state.first_name}
                //                         error={this.state.first_name_error !== ""}
                //                         disabled={this.state.disabled}
                //                         helperText={this.state.first_name_error}
                //                         type="text" onChange={this.onChange} name="first_name" label="First Name" />
                //                 </Grid>
                //             </Grid>
                //         </Container>
                //     )
                // }}
                />
            </div>
        )
    }
}

export default withStyles(useStyles)(StoreLocator) 