import React, { Component } from 'react'
import { withStyles, Container, Grid, Button, TextField, Backdrop, CircularProgress } from '@material-ui/core';
import styles from "assets/jss/client-side/App.js";
import Flickity from "react-flickity-component";
import "assets/css/flickity.css";
import 'flickity-as-nav-for';
import { isMobile } from "react-device-detect";
import { Link } from 'react-router-dom';
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
} from "react-share";

import {
    FacebookShareCount,
    OKShareCount,
    PinterestShareCount,
    RedditShareCount,
    TumblrShareCount,
    VKShareCount
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
} from "react-share";
import CloseIcon from "assets/img/havilla/close_icon.svg";
import ReactPixel from 'react-facebook-pixel';

import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import CircleBlack from "assets/img/havilla/circle-black.svg";
import CircleGrey from "assets/img/havilla/circle-grey.svg";
import LeafEmpty from "assets/img/havilla/leaf-empty.svg";
import LeafFilled from "assets/img/havilla/leaf-filled.svg";
import ShareIcon from "assets/img/havilla/share-icon.svg";
import HeartFilled from "assets/img/havilla/heart_filled.svg";
import HeartEmpty from "assets/img/havilla/heart_empty.svg";
import ScaleIcon from "assets/img/havilla/scale-icon.svg";
import TemperatureIcon from "assets/img/havilla/temperature-icon.svg";
import TimerIcon from "assets/img/havilla/timer-icon.svg";
import FoodPairingIcon from "assets/img/havilla/food-pairing-icon.svg";
import ServingIcon from "assets/img/havilla/serving-icon.svg";
import ProductCardGeneral from 'client-side/components/ProductCard/ProductCardGeneral';
import axios from 'axios';
import { baseUrl, prefix } from 'Environment';
import { requestConfig } from 'Environment';
import { ClientSideContext } from '../../ClientSideContext';
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp';
import Skeleton from '@material-ui/lab/Skeleton';

import { dictionary } from '../../../Dictionary';
const useStyles = theme => ({
    ...styles(theme),
    mainCarousel: {
        '& img': {
            width: "100%",
            // minWidth: '550px',
            [theme.breakpoints.down('md')]: {
                width: '100%',
                minWidth: '0',
            },
        },
    },
    navCarousel: {
        width: '100%',
        '& img': {
            // marginRight : '32px',
            width: "5rem",
            height: "5rem",
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        '& img.is-nav-selected': {
            boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'
        },
    },
    productCategory: {
        fontSize: '0.7rem',
        border: '1px solid #AF986D',
        color: '#AF986D',
        borderRadius: '16px',
        padding: '2px 10px 0',
        textTransform: 'uppercase',
        width: 'auto'
    },
    categoryContainer: {
        marginTop: '30px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0px',
        }
    },
    productName: {
        marginTop: '16px',
        fontSize: '40px',
        fontFamily: 'Martel Sans',
        fontWeight:'bold'
    },
    wishlistText: {
        fontSize: '12px',
        padding: '20px 0px',
        fontFamily: 'Martel Sans'
    },
    shareIcon: {
        marginRight: '20px',
        marginLeft: '30px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        },
    },
    productDesc: {
        fontSize: '16px',
        marginTop: '16px',
        fontFamily: 'Martel Sans'
    },
    leafContainer: {
        marginTop: '16px',
        fontFamily: 'Martel Sans',
        fontSize: '12px',
    },
    leaf: {
        marginRight: '14px'
    },
    lineSeparator: {
        bac: '1px solid #f0f0f0',
        opacity: 0.2,
        marginTop: '20px',
        marginBottom: '20px'
    },
    caffeineDesc: {
        marginLeft: '27px',
        color: '#AF986D',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 10,
            marginLeft: '16%',
        },
    },
    listDesc: {
        fontFamily: 'Martel Sans',
        marginLeft: '18px',
        '& li': {
            fontSize: '10px',
            margin: '10px 0',
            '& span': {
                fontSize: '16px',
            }
        },
    },
    chooseVariant: {
        [theme.breakpoints.down('sm')]: {
            marginRight: 15
        },
        fontSize: '16px',
    },
    price: {
        marginTop: '28px',
        fontSize: '24px',
        marginRight: '1rem',
    },
    btnCircle: {
        marginTop: 10,
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '1.1rem',
        borderRadius: '1rem',
        color: '#AF986D',
        border: '2px solid #AF986D',
        width: 25,
        height: 25,
        cursor: 'pointer',
        float: 'left'
    },
    qtyInput: {
        width: 40,
        marginTop: 5,
        float: 'left',
        margin: '0 15px'
    },
    inputQty: {
        color: '#AF986D',
        borderBottom: '1px solid #AF986D'
    },
    quantityChanger: {
        marginTop: '15px',
    },
    buttonCheckoutATC: {
        fontSize: '12px',
        minHeight: "53.6px",
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    buttonCheckoutATCContainer: {
    },
    brewingSuggestion: {
        fontSize: '28px',
        fontFamily: 'BlaakLight',
        textAlign: 'center'
    },
    teaFeeling: {
        fontSize: '28px',
        fontFamily: 'BlaakLight',
        textAlign: 'center',
        marginBottom: '48px'
    },
    brewingNote: {
        marginTop: '16px',
        fontSize: '12px',
        fontFamily: 'BlaakLight',
        textAlign: 'center'
    },
    buttonBrewingContainer: {
        textAlign: 'center',
        marginTop: ' 32px'
    },
    brewingContentContainer: {
        marginTop: '30px',
    },
    brewingImgContainer: {
        textAlign: 'center',
    },
    titleIcon: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            marginTop: '0px',
        },
        marginTop: '20px',
        fontWeight: 'bold',
        fontSize: '16px',
        textAlign: 'center'
    },
    descIcon: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
        fontSize: '16px',
        marginTop: '8px',
    },
    containerHowIconContent: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '30px'
        },
    },
    multipleInfusionIcon: {
        fontFamily: 'BlaakLight',
        fontSize: '28px',
        lineHeight: '40px',
        width: '64px',
        height: '64px',
        padding: '10px',
        color: "#AF986D"
    },
    feelingText: {
        textAlign: 'center',
        fontFamily: 'Martel Sans',
        fontSize: '16px',
    },
    feelingValue: {
        marginTop: '8px',
        textAlign: 'center',
    },
    circle: {
        marginRight: '8px',

    },
    groupFeeling: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: 25
        }
    },
    closeIcon: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer'
    },
    paper: {
        [theme.breakpoints.down('sm')]: {
            padding: '1rem',
            width: '80%',
        },
        position: 'absolute',
        width: '30%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: '3rem 4rem'
    },
    modalStyle: {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    },
    soldOutText: {
        color: '#AF986D',
        fontSize: "24px",
        fontFamilty: 'Martel Sans',
    },
    originalPrice: {
        fontFamilty: 'Martel Sans',
        fontSize: '15px',
        color: '#CFCFCF',
    }
});

let HomepageCarousel = null;

export class ProductDetail extends Component {
    static contextType = ClientSideContext;
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            openBackdrop: false,
            openModal: false,

            related_products: [],
            quantity: 1,
            product: null,
            desc_objects: [],

            variant_selected: null,
            brewing_style_selected: null,

            caffeine_desc: [
                {
                    'id': 'Sangat Rendah',
                    'en': 'Very Low'
                },
                {
                    'id': 'Rendah',
                    'en': 'Low'
                },
                {
                    'id': 'Sedang',
                    'en': 'Medium'
                },
                {
                    'id': 'Tinggi',
                    'en': 'High'
                },
                {
                    'id': 'Sangat Tinggi',
                    'en': 'Very High'
                }
            ],

            snackbarType: 'info',
            snackbarMessage: '',
            snackbarCounter: 0,

        }
        HomepageCarousel = React.memo(props => {
            if (props.variant_selected !== null) {
                let variant = props.variant_selected;
                let photos = JSON.parse(variant.photo_url);
                if (photos.length > 0) {

                    const { classes } = this.props;
                    const flickityOptions = {
                        pageDots: isMobile ? true : false,
                        cellAlign: 'center',
                        prevNextButtons: false,
                        draggable: true,
                    }
                    const flickityNavOptions = {
                        asNavFor: '.' + classes.mainCarousel,
                        groupCells: 6,
                        contain: true,
                        pageDots: false
                    };
                    return (
                        <React.Fragment>
                            <Flickity id="mainCarousel" className={classes.mainCarousel} options={flickityOptions}>
                                {
                                    photos.length > 0 ?
                                        photos.map((photo, i) => {
                                            return <img key={i} src={photo} alt={`${i}`}></img>
                                        })
                                        :
                                        // insert default photo for no photo
                                        null
                                }
                            </Flickity>
                            <Grid container justify="center" style={{ marginTop: 15 }}>
                                <Grid item md={9}>
                                    <Flickity className={classes.navCarousel} options={flickityNavOptions}>
                                        {
                                            photos.length > 0 ?
                                                photos.map((photo, i) => {
                                                    return <img key={i} src={photo} alt={`${i}`}></img>
                                                })
                                                :
                                                // insert default photo for no photo
                                                null
                                        }
                                    </Flickity>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )
                } else {
                    return null
                }
            } else {
                return null
            }
        });
    }

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    openBackdrop = () => {
        this.setState({
            openBackdrop: true
        })
    }

    closeBackdrop = () => {
        this.setState({
            openBackdrop: false
        })
    }

    handleSelectVarian = (item) => {
        this.setState({
            variant_selected: item
        })
    }

    handleSelectBrewingStyle = (item) => {
        this.setState({
            brewing_style_selected: item
        })
    }

    handleChangeQty = (e, operator, startingQty) => {
        var newValue = undefined;
        if (operator) {
            const math_it_up = {
                '+': function (x, y) { return x + y },
                '-': function (x, y) { return x - y }
            };
            newValue = math_it_up[operator](startingQty, 1);
        }
        else {
            newValue = parseInt(e.target.value);
        }

        if (newValue > 0) {
            this.setState({
                quantity: newValue
            })
        }
    }

    handleAddToCart = () => {
        const { dispatchCartItems } = this.context;
        let data = {
            sub_product_id: this.state.variant_selected.id,
            quantity: this.state.quantity
        }
        let auth = localStorage.getItem('auth') ? true : false;
        if (auth) {
            axios.post(`${baseUrl}/add_to_cart`,
                data,
                requestConfig)
                .then((res) => {
                    if (res.data.success) {
                        dispatchCartItems({
                            type: "ADD_TO_CART",
                            newItem: res.data.data,
                        })
                        var data = res.data.data[0].sub_product;
                        data['product_name'] = res.data.data[0].sub_product.product.name;

                        ReactPixel.trackCustom("AddToCart", data);

                        this.props.openCart();
                    } else {
                        console.log(res.data.message, res.data.data)
                        this.setState({
                            snackbarType: 'error',
                            snackbarMessage: 'Failed Added To Cart',
                        });
                    }
                }, (e) => {
                    console.log("Error : ", e);
                    this.setState({
                        snackbarType: 'error',
                        snackbarMessage: 'Failed Added To Cart',
                    });
                })
        } else {
            let currentCart = JSON.parse(localStorage.getItem('cart'));
            let format = [{
                id: currentCart ? currentCart.length + 1 : 1,
                is_own: 0,
                product_id: null,
                own_product: null,
                qty: 0,
                sub_product: null,
            }]

            axios.get(`${baseUrl}/subproducts/${data.sub_product_id}`,
                data,
                requestConfig)
                .then((res) => {
                    if (res.data.success) {
                        let result = res.data.data;
                        format[0].product_id = result.id;
                        format[0].sub_product = result;
                        format[0].qty = this.state.quantity;
                        format[0].thumbnail_url = result.photo_url === "[]" ? null : result.photo_url;
                        format[0].is_guest = 1;
                        dispatchCartItems({
                            type: "ADD_TO_CART",
                            newItem: format,
                        })
                        this.props.openCart();
                    } else {
                        console.log(res.data.message, res.data.data)
                        this.setState({
                            snackbarType: 'error',
                            snackbarMessage: 'Failed Added To Cart',
                        });
                    }
                }, (e) => {
                    console.log("Error : ", e);
                    this.setState({
                        snackbarType: 'error',
                        snackbarMessage: 'Failed Added To Cart',
                    });
                })


        }
    }

    handleToggleWishlist = async () => {
        let auth = localStorage.getItem('auth') ? true : false;
        if (auth) {
            await axios.get(`${baseUrl}/toggle_wishlist/${this.state.product.id}`,
                requestConfig)
                .then((res) => {
                    if (res.data.success) {
                        this.getProduct();
                    } else {
                        console.log(res.data.message, res.data.data)
                    }
                }, (e) => {
                    console.log("Error : ", e);
                })
        } else {
            this.setState((prevState, props) => ({
                counter: prevState.counter + 1,
                snackbarType: "error",
                snackbarMessage: `You Must Logged In to Add This Item to Your Wishlist`
            }));
        }

    }

    getProduct = () => {
        // const id = this.props.location.pathname.replace(this.props.prefix + '/product/', "");
        const slug = this.props.location.pathname.split("/").pop();
        const id = slug.split('-')[0]

        axios.get(`${baseUrl}/products/${id}`, requestConfig).then((res) => {
            if (res.data.success) {
                this.setState({
                    product: res.data.data,
                    desc_objects: JSON.parse(res.data.data.desc),
                    variant_selected: res.data.data.main_sub_product,
                    brewing_style_selected: res.data.data.brewing_styles.length > 0 ? res.data.data.brewing_styles[0] : null,
                }, () => {
                    this.closeBackdrop();
                    this.setState({
                        loading: false
                    })
                })

                ReactPixel.trackCustom("ViewProduct", res.data.data);
            } else {
                this.handleOpenSnackbar({
                    type: "error",
                    message: res.data.message
                })
                this.closeBackdrop();
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.handleOpenSnackbar({
                type: "error",
                message: `${e.message}`
            })
            this.closeBackdrop();
            console.log("Error : ", e);
        })
    }

    getProductRelated = () => {
        const id = this.props.location.pathname.replace(prefix + '/product/', "");
        let data = {
            product_ids: [id],
            limit: 3
        }
        axios.post(`${baseUrl}/products/get_products_related`, data, requestConfig).then((res) => {
            if (res.data.success) {
                this.setState({
                    related_products: res.data.data,
                }, () => {
                    this.closeBackdrop();
                })
            } else {
                this.handleOpenSnackbar({
                    type: "error",
                    message: res.data.message
                })
                this.closeBackdrop();
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.handleOpenSnackbar({
                type: "error",
                message: `${e.message}`
            })
            this.closeBackdrop();
            console.log("Error : ", e);
        })
    }

    componentDidMount() {
        this.openBackdrop();
        this.getProduct();
        this.getProductRelated();
        this.setState({
            language: this.context.language
        })
    }

    componentDidUpdate() {
        // if (this.context.language !== this.context.language) {
        //     this.setState({
        //         language: this.context.language
        //     })
        // }
    }

    renderCaffeine = () => {
        const { classes } = this.props

        let el = []
        for (let i = 0; i < 5; i++) {
            if (this.state.product !== null) {
                if (this.state.product.caffeine <= i) {
                    el.push(<img key={i} className={classes.leaf} src={LeafEmpty} alt="leaf empty"></img>)
                } else {
                    el.push(<img key={i} className={classes.leaf} src={LeafFilled} alt="leaf filled"></img>)
                }
            } else {
                el.push(<img key={i} className={classes.leaf} src={LeafEmpty} alt="leaf empty"></img>)
            }
        }
        return el
    }

    renderFeels = (feels) => {
        const { classes } = this.props

        let el = []
        for (let i = 0; i < 5; i++) {
            if (feels <= i) {
                el.push(<img key={i} className={classes.circle} src={CircleGrey} alt="circle grey"></img>)
            } else {
                el.push(<img key={i} className={classes.circle} src={CircleBlack} alt="circle black"></img>)
            }
        }
        return el
    }

    handleOpenModal = () => {
        this.setState({
            openModal: true
        })
    };

    handleCloseModal = () => {
        this.setState({
            openModal: false
        })
    };

    render() {
        const { classes } = this.props

        return (
            <Container maxWidth="xl" className={classes.container}>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.counter}
                />
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{ 'color': '#AF986D' }} />
                </Backdrop>
                <Modal
                    open={this.state.openModal}
                    onClose={this.handleCloseModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    closeAfterTransition
                >
                    <Fade in={this.state.openModal}>
                        <div className={`${classes.modalStyle} ${classes.paper}`}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <img className={classes.closeIcon} src={CloseIcon} alt="close icon" onClick={this.handleCloseModal}></img>

                                    <h3>Share to: </h3>
                                    <TwitterShareButton
                                        url={window.location.href}
                                        title={'Checkout the product from Havilla Tea! '}
                                        className="Demo__some-network__share-button">
                                        <TwitterIcon
                                            size={32}
                                            round />
                                    </TwitterShareButton>
                                    <FacebookShareButton
                                        url={window.location.href}
                                        title={'Checkout the product from Havilla Tea! '}
                                        className="Demo__some-network__share-button">
                                        <FacebookIcon style={{ marginLeft: 10 }}
                                            size={32}
                                            round />
                                    </FacebookShareButton>
                                    <WhatsappShareButton
                                        url={window.location.href}
                                        title={'Checkout the product from Havilla Tea! '}
                                        className="Demo__some-network__share-button">
                                        <WhatsappIcon style={{ marginLeft: 10 }}
                                            size={32}
                                            round />
                                    </WhatsappShareButton>
                                    <LineShareButton
                                        url={window.location.href}
                                        title={'Checkout the product from Havilla Tea! '}
                                        className="Demo__some-network__share-button">
                                        <LineIcon style={{ marginLeft: 10 }}
                                            size={32}
                                            round />
                                    </LineShareButton>
                                    <TelegramShareButton
                                        url={window.location.href}
                                        title={'Checkout the product from Havilla Tea! '}
                                        className="Demo__some-network__share-button">
                                        <TelegramIcon style={{ marginLeft: 10 }}
                                            size={32}
                                            round />
                                    </TelegramShareButton>
                                </Grid>
                            </Grid>
                        </div>
                    </Fade>
                </Modal>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.SnackbarCounter}
                />
                <Grid container justify='center'>
                    <Grid item xs={12} sm={11} md={10} container spacing={4}>
                        <Grid item xs={12} md={5} style={{ marginTop: 28.5 }}>
                            <HomepageCarousel variant_selected={this.state.variant_selected}></HomepageCarousel>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Grid item xs={12} className={classes.categoryContainer}>
                                {
                                    this.state.product !== null ?
                                        this.state.product.sub_category_id !== null ?
                                            <span className={classes.productCategory}>
                                                {this.state.product.product_sub_category.name}
                                            </span>
                                            :
                                            <span className={classes.productCategory}>
                                                {this.state.product.product_category.name}
                                            </span>
                                        :
                                        <Skeleton
                                            variant="rect" width={61} height={24}
                                            animation="wave"
                                        />
                                    // <span className={classes.productCategory}>
                                    //     LOADING...
                                    // </span>
                                }
                            </Grid>
                            <Grid item xs={12} md={12} container className={classes.productName}>
                                {
                                    this.state.product !== null ?
                                        this.state.product.name
                                        :
                                        <Skeleton
                                            variant="rect" width={200} height={56}
                                            animation="wave"
                                        />
                                }
                                <img className={`${classes.shareIcon} ${classes.linkPointer}`} src={ShareIcon} alt="share icon" onClick={this.handleOpenModal}></img>
                                {
                                    this.state.product !== null ?
                                        this.state.product.wishlisted === true ?
                                            <img
                                                onClick={() => this.handleToggleWishlist()}
                                                style={{ marginRight: '10px' }}
                                                src={HeartFilled}
                                                alt="heart filled icon"
                                                className={`${classes.linkPointer}`}
                                            />
                                            :
                                            <img
                                                onClick={() => this.handleToggleWishlist()}
                                                style={{ marginRight: '10px' }}
                                                src={HeartEmpty}
                                                alt='wishlist icon empty'
                                                className={`${classes.linkPointer}`}
                                            />
                                        :
                                        <img
                                            style={{ marginRight: '10px' }}
                                            src={HeartEmpty}
                                            alt='wishlist icon empty'
                                            className={`${classes.linkPointer}`}
                                        />
                                }


                                <span className={classes.wishlistText}>
                                    {
                                        this.state.product !== null ?
                                            `(${this.state.product.wishlist_count})`
                                            :
                                            '0'
                                    }
                                    {
                                        this.context.language === 'id' ?
                                            ' favorit'
                                            :
                                            ' wishlist'
                                    }
                                </span>
                            </Grid>
                            <Grid item xs={12} md={12} className={classes.productDesc}>
                                {
                                    this.state.product !== null ?
                                        this.context.language === 'id' ?
                                            this.state.product.short_desc_id
                                            :
                                            this.state.product.short_desc_eng
                                        :
                                        <Skeleton
                                            variant="rect" width="100%" height={22}
                                            animation="wave"
                                        />
                                }
                            </Grid>

                            {
                                this.state.product !== null ?
                                    this.state.product.caffeine > 0 ?
                                        <Grid item xs={12} md={7} container alignItems="center" className={classes.leafContainer}>
                                            {this.renderCaffeine()}

                                            <span className={classes.caffeineDesc}>
                                                {
                                                    this.state.product !== null ?
                                                        `${this.context.language === 'en' ? this.state.caffeine_desc[this.state.product.caffeine - 1]['en'] : ''} ${this.context.language === 'id' ? 'Kafein' : 'Caffeine'} ${this.context.language === 'id' ? this.state.caffeine_desc[this.state.product.caffeine - 1]['id'] : ''}`
                                                        :
                                                        'Loading...'
                                                }
                                            </span>
                                        </Grid>
                                        :
                                        null
                                    :
                                    <Grid item xs={12} md={7} container alignItems="center" className={classes.leafContainer}>
                                        {this.renderCaffeine()}

                                        <span className={classes.caffeineDesc}>
                                            {
                                                this.state.product !== null ?
                                                    `${this.state.caffeine_desc[this.state.product.caffeine]} Caffeine`
                                                    :
                                                    <Skeleton
                                                        variant="rect" width={100} height={16}
                                                        animation="wave"
                                                    />
                                            }
                                        </span>
                                    </Grid>
                            }

                            <hr className={classes.lineSeparator} />
                            {
                                !this.state.loading ?
                                    this.state.desc_objects.length > 0 ?
                                        <React.Fragment>
                                            <Grid item xs={12}>
                                                <ul className={classes.listDesc}>
                                                    {
                                                        this.state.desc_objects.map((desc_object, i) => {
                                                            return (
                                                                <li key={i}>
                                                                    <span>
                                                                        {
                                                                            this.context.language === 'id' ?
                                                                                <React.Fragment>
                                                                                    <b>{desc_object.title_id}:</b> {desc_object.desc_id}
                                                                                </React.Fragment>
                                                                                :
                                                                                <React.Fragment>
                                                                                    <b>{desc_object.title_en}:</b> {desc_object.desc_en}
                                                                                </React.Fragment>
                                                                        }
                                                                    </span>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </Grid>
                                            <hr className={classes.lineSeparator} />
                                        </React.Fragment>
                                        :
                                        null
                                    :
                                    <Skeleton
                                        variant="rect" width="100%" height={150}
                                        animation="wave"
                                    />
                            }


                            {
                                this.state.product !== null ?
                                    this.state.product.sub_products.length > 1 ?
                                        <Grid item xs={9}
                                            container
                                            spacing={1}
                                            justify="flex-start"
                                            alignItems="center"
                                        >
                                            <Grid item md={5} lg={4} xl={3}>
                                                <p className={classes.chooseVariant}>
                                                    {
                                                        this.context.language === 'id' ?
                                                            'Pilih Varian: '
                                                            :
                                                            'Choose Variant: '
                                                    }

                                                </p>
                                            </Grid>
                                            {
                                                this.state.product.sub_products.map((item, i) => {
                                                    return (
                                                        <Button style={{ marginRight: '10px' }} key={item.id}
                                                            onClick={() => this.handleSelectVarian(item)}
                                                            className={this.state.variant_selected.id === item.id ? classes.selectedButtonSmall : classes.buttonSmall}
                                                        >
                                                            {item.varian}
                                                        </Button>
                                                    )
                                                })
                                            }
                                        </Grid>
                                        :
                                        null
                                    :
                                    <Skeleton
                                        variant="rect" width="100%" height={38}
                                        animation="wave"
                                    />
                            }

                            {
                                this.state.product !== null ?
                                    this.state.product.is_active ?
                                        <React.Fragment>
                                            <Grid item md={12} container alignItems='center'>
                                                <Grid item xs={5} md={4} className={classes.price}>Rp&nbsp;{
                                                    this.state.variant_selected !== null ?
                                                        this.state.variant_selected.discount !== null && this.state.variant_selected.discount > 0 ?
                                                            <React.Fragment>
                                                                {
                                                                    // this.numberWithCommas(this.state.variant_selected.price)
                                                                    `${this.numberWithCommas(this.state.variant_selected.price - (this.state.variant_selected.price * (this.state.variant_selected.discount / 100)))}`
                                                                }
                                                                <p className={classes.originalPrice}>
                                                                    {/* {`${this.state.variant_selected.discount}%`}&nbsp; */}
                                                                    <del>
                                                                        {`Rp${this.numberWithCommas(this.state.variant_selected.price)}`}
                                                                    </del>
                                                                </p>
                                                            </React.Fragment>
                                                            :
                                                            this.numberWithCommas(this.state.variant_selected.price)
                                                        :
                                                        0
                                                }</Grid>
                                                <Grid item xs={12} sm={5} md={4} className={classes.quantityChanger}>
                                                    <div
                                                        className={`${classes.btnCircle} noselect`}
                                                        onClick={(e) => this.handleChangeQty(e, '-', this.state.quantity)}
                                                    >
                                                        -
                                                    </div>
                                                    <TextField
                                                        onChange={(e) => this.handleChangeQty(e, null, null)}
                                                        className={classes.qtyInput}
                                                        value={this.state.quantity}
                                                        // label="Number"
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            className: classes.inputQty,
                                                        }}
                                                    />
                                                    <div
                                                        className={`${classes.btnCircle} noselect`}
                                                        onClick={(e) => this.handleChangeQty(e, '+', this.state.quantity)}
                                                    >
                                                        +
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                        :
                                        <p className={`${classes.soldOutText}`}>{dictionary['SOLD OUT'][this.context.language]}</p>
                                    :
                                    <Skeleton
                                        variant="rect" width="100%" height={38}
                                        animation="wave"
                                    />
                            }

                            {
                                this.state.product !== null ?
                                    <Grid item xs={12} container style={{ marginTop: '24px' }} justify={isMobile ? 'center' : 'flex-start'}>
                                        {/* <Grid item xs={12} md={12} className={classes.buttonCheckoutATCContainer}> */}
                                        {/* <Link to="">
                                                <Button variant="outlined" className={`${classes.buttonLight} ${classes.buttonCheckoutATC}`} style={{ width: '100%' }}>
                                                    Proceed to Checkout
                                                </Button>
                                            </Link> */}
                                        <Button disabled={!this.state.product.is_active} variant="outlined" className={`${classes.buttonDark} ${classes.buttonCheckoutATC}`} onClick={this.handleAddToCart}>
                                            {this.context.language === 'id' ? 'Masukkan ke keranjang' : 'Add to Cart'}
                                        </Button>
                                        {/* </Grid> */}
                                        {/* <Grid item xs={5} md={4} className={classes.buttonCheckoutATCContainer}>
                                            
                                        </Grid> */}
                                    </Grid>
                                    :
                                    <Skeleton
                                        variant="rect" width="100%" height={48}
                                        animation="wave"
                                    />
                            }
                        </Grid>
                    </Grid>

                    {
                        this.state.product !== null ?
                            this.state.product.category_id === 1 || this.state.product.category_id === 5 ?
                                <Grid style={{ marginTop: '40px' }}
                                    item xs={12} sm={11} md={10} container justify="center">
                                    <Grid item xs={12} className={classes.teaFeeling}>
                                        Tea Feeling
                                    </Grid>

                                    {
                                        this.state.product.product_feels.map((item, i) => {
                                            return (
                                                <Grid key={i} item xs={12} md={2} container justify='center' className={classes.groupFeeling}>
                                                    <Grid item xs={12} className={classes.feelingText}>
                                                        {/* {item.feels.name} */}
                                                        {
                                                            dictionary['Feeling'][i][this.context.language]
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.feelingValue}>
                                                        {this.renderFeels(item.value)}
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>

                                :
                                null
                            :
                            null
                    }
                    {
                        this.state.product !== null ?
                            this.state.product.brewing_styles.length > 0 ?
                                <Grid style={{ marginTop: '80px' }}
                                    item xs={12} sm={11} md={10} container justify="center">
                                    <Grid item xs={12} className={classes.brewingSuggestion}>
                                        Brewing & Tasting Suggestions
                                    </Grid>
                                    <Grid item xs={12} md={4} className={classes.brewingNote}>
                                        *Please note that brewing recommendation is made as a guideline only, but you can adjust everything to match your preferred tea infusion!
                                    </Grid>

                                    <Grid item xs={9} className={classes.buttonBrewingContainer}
                                        spacing={0}
                                        container
                                        justify="center"
                                        alignItems="center"
                                    >

                                        {
                                            this.state.product.brewing_styles.map((item, i) => {
                                                return (
                                                    <Grid key={i} item xs={6} md={2}>
                                                        <Button
                                                            onClick={() => this.handleSelectBrewingStyle(item)}
                                                            className={this.state.brewing_style_selected.id === item.id ? classes.selectedButtonSmall : classes.buttonSmall}
                                                        >
                                                            {item.name}
                                                        </Button>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}
                                        container
                                        justify='center'
                                        alignItems='flex-start'
                                        spacing={2}
                                        className={classes.brewingContentContainer}
                                    >
                                        <Grid item xs={10} md={4} container justify="center" alignItems='flex-start' >
                                            <Grid container item xs={4} md={12}>
                                                <Grid item md={12} xs={4} className={classes.brewingImgContainer}>
                                                    <img src={ScaleIcon} alt="scale icon"></img>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={8} md={12} justify="center">
                                                <Grid item md={12} xs={12} className={classes.titleIcon}>
                                                    Brewing Ratio
                                                </Grid>
                                                <Grid item md={12} xs={12} className={classes.descIcon}>
                                                    {this.state.brewing_style_selected.brewing_ratio}
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={10} md={4} container justify="center" alignItems='flex-start'>
                                            <Grid container item xs={4} md={12}>
                                                <Grid item md={12} xs={4} className={classes.brewingImgContainer}>
                                                    <img src={TemperatureIcon} alt="temperature icon"></img>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={8} md={12} justify="center">
                                                <Grid item md={12} xs={12} className={classes.titleIcon}>
                                                    Water Temperature
                                                </Grid>
                                                <Grid item md={12} xs={12} className={classes.descIcon}>
                                                    {this.state.brewing_style_selected.water_temp}
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={10} md={4} container justify="center" alignItems='flex-start'>
                                            <Grid container item xs={4} md={12}>
                                                <Grid item md={12} xs={4} className={classes.brewingImgContainer}>
                                                    <img src={TimerIcon} alt="timer icon"></img>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={8} md={12} justify="center">
                                                <Grid item md={12} xs={12} className={classes.titleIcon}>
                                                    Steeping Time
                                                </Grid>
                                                <Grid item md={12} xs={12} className={classes.descIcon}>
                                                    {this.state.brewing_style_selected.brewing_time}
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={10} md={4} container justify="center" alignItems='flex-start' style={{ marginTop: 20 }}>
                                            <Grid container item xs={4} md={12}>
                                                <Grid item md={12} xs={4} className={`${classes.brewingImgContainer} ${classes.multipleInfusionIcon}`}>
                                                    {this.state.brewing_style_selected.multiple_infusion}x
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={8} md={12} justify="center">
                                                <Grid item md={12} xs={12} className={classes.titleIcon}>
                                                    Multiple Infusion
                                                </Grid>
                                                <Grid item md={12} xs={12} className={classes.descIcon}>
                                                    {this.state.brewing_style_selected.multiple_infusion} times
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={10} md={4} container justify="center" alignItems='flex-start' style={{ marginTop: 20 }}>
                                            <Grid container item xs={4} md={12}>
                                                <Grid item md={12} xs={4} className={classes.brewingImgContainer}>
                                                    <img src={ServingIcon} alt="serving icon"></img>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={8} md={12} justify="center">
                                                <Grid item md={12} xs={12} className={classes.titleIcon}>
                                                    Serving Suggestions
                                                </Grid>
                                                <Grid item md={12} xs={12} className={classes.descIcon}>
                                                    {this.state.brewing_style_selected.suggestions}
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={10} md={4} container justify="center" alignItems='flex-start' style={{ marginTop: 20 }}>
                                            <Grid container item xs={4} md={12}>
                                                <Grid item md={12} xs={4} className={classes.brewingImgContainer}>
                                                    <img src={FoodPairingIcon} alt="food pairing icon"></img>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={8} md={12} justify="center">
                                                <Grid item md={12} xs={12} className={classes.titleIcon}>
                                                    Food Pairing
                                                </Grid>
                                                <Grid item md={12} xs={12} className={classes.descIcon}>
                                                    {this.state.brewing_style_selected.food_pairing}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                null
                            :
                            null
                    }
                    <Grid item xs={12} md={11} className={classes.sectionTitle} style={{marginBottom:15}}>
                        {this.context.language === 'id' ? 'Orang-orang juga menyukai ini' : 'People also like'}

                    </Grid>
                    <Grid style={{ minHeight: '600px' }} item xs={12} md={11} container
                        spacing={3}>
                        {
                            this.state.related_products.length > 0 ?
                                this.state.related_products.map(item => {
                                    // alert(window.location.pathname.split("/")[2]);
                                    return (
                                        <Grid item lg={4} md={6} sm={6} xs={12} key={item.id}>
                                            <ProductCardGeneral thumbnail={item.thumbnail_url} handleOpenSnackbar={this.handleOpenSnackbar} detail={item} key={item.id} openCart={this.props.openCart} />
                                        </Grid>
                                    )
                                })
                                :
                                <Grid item xs={12} className={classes.noResult}>No Result Found...</Grid>
                        }
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default withStyles(useStyles)(ProductDetail)
