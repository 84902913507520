import { Component } from "react"
import { withRouter } from "react-router-dom"

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
      if(this.props.location.pathname.indexOf("/profile")  < 0 && this.props.location.pathname.indexOf("/admin") < 0){
        if (this.props.location !== prevProps.location) {
          window.scrollTo(0, 0)
        }
      }
      if(this.props.location.pathname.indexOf("/admin") >= 0 && this.props.location.pathname.indexOf('/admin/login') < 0){
        if(this.props.location !== prevProps.location){
          const container = document.querySelector('#perfect-scrollbar-container');
          container.scrollTop = 0;
        }
      }
    }
  
    render() {
      return this.props.children
    }
  }
  
  export default withRouter(ScrollToTop)