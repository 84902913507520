import React, { useState, useEffect } from 'react'
import TimerIcon from "assets/img/havilla/timer-icon.svg";
import IngredientsIcon from "assets/img/havilla/own-ingredients-icon.svg";
import ScaleIcon from "assets/img/havilla/scale-icon.svg";
import TeaPouchIcon from "assets/img/havilla/tea-pouch-icon.svg";
import WizardIcon from "assets/img/havilla/own-wizard-icon.svg";
import { Grid, makeStyles, Grow } from '@material-ui/core';
import { dictionary } from 'Dictionary';

const useStyles = makeStyles((theme) => ({
    loadingScreenContainer : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '70vh',
    },
    loadingText : {
        fontFamily: 'Martel Sans',
        position : 'absolute',
        top : '25rem',
        left : '0',
        right : '0',
    },
    imgContainer : {
        position : 'absolute',
        top : '21rem',
        left : '0',
        right : '0',
    }
}));

function CustomLoading(props) {
    let interval;
    const classes = useStyles();
    const [count, setCount] = useState(0);
    useEffect(() => {
        interval = setInterval(()=>{
            if(count === 2){
                setCount(0)
            }else{
                setCount(count+1)
            }
        }, 2000);
        return function cleanup() {
            clearInterval(interval);
        };
    }, [count])

    

    return (
        <Grid container className={classes.loadingScreenContainer}>
            <Grow in={count === 0}
                style={{ transformOrigin: '0 0 0' }}
                {...(count === 0 ? { timeout: 800 } : {})}
            >
                <Grid item xs={12} className={classes.imgContainer} style={{display : count === 0 ? 'block' : 'none'}}>
                    <img src={IngredientsIcon} alt="timer icon"></img>
                </Grid>
            </Grow>
            <Grow in={count === 0}
                style={{ transformOrigin: '0 0 0' }}
                {...(count === 0 ? { timeout: 1200 } : {})}
            >
                <Grid item xs={12} className={classes.loadingText}>
                    {dictionary['Loading V1'][props.language]}
                </Grid>
            </Grow>

            <Grow in={count === 1}
                style={{ transformOrigin: '0 0 0' }}
                {...(count === 1 ? { timeout: 800 } : {})}
            >
                <Grid item xs={12} className={classes.imgContainer} style={{display : count === 1 ? 'block' : 'none'}}>
                    <img src={ScaleIcon} alt="scale icon"></img>
                </Grid>
            </Grow>
            <Grow in={count === 1}
                style={{ transformOrigin: '0 0 0' }}
                {...(count === 1 ? { timeout: 1200 } : {})}
            >
                <Grid item xs={12} className={classes.loadingText}>
                    {dictionary['Loading V2'][props.language]}
                </Grid>
            </Grow>

            <Grow in={count === 2}
                style={{ transformOrigin: '0 0 0' }}
                {...(count === 2 ? { timeout: 800 } : {})}
            >
                <Grid item xs={12} className={classes.imgContainer} style={{display : count === 2 ? 'block' : 'none'}}>
                    <img src={WizardIcon} style={{marginTop : '10px', marginLeft : '0px'}} alt="tea pouch icon"></img>
                </Grid>
            </Grow>
            <Grow in={count === 2}
                style={{ transformOrigin: '0 0 0' }}
                {...(count === 2 ? { timeout: 1200 } : {})}
            >
                <Grid item xs={12} className={classes.loadingText}>
                    {dictionary['Loading V3'][props.language]}
                </Grid>
            </Grow>
        </Grid>
    )
}

export default CustomLoading
