import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
// import Container from '@material-ui/core/Container';
// import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import axios from 'axios';
import BcaIcon from "assets/img/havilla/bca.svg";

// import Datepicker from 'client-side/components/Datepicker/Datepicker';
import CloseIcon from "assets/img/havilla/close_icon.svg";
import Suddhabumi from "assets/img/havilla/Suddhabumi.png";
import Chamomile from "assets/img/havilla/own_blend_square.jpg";
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp';
import CopasIcon from "assets/img/havilla/copas_icon.svg";

import Fade from '@material-ui/core/Fade';

import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import {
    baseUrl,
    requestConfig
} from '../../../Environment.js';
import { Backdrop, CircularProgress } from '@material-ui/core';

import { ClientSideContext } from '../../ClientSideContext';
import { dictionary } from '../../../Dictionary';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
const useStyles = theme => ({
    ...styles(theme),
    textField: {
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        [theme.breakpoints.up('md')]: {
        },
        width: "100%",
    },
    iconEdit: {
        marginTop: `-8px`
    },
    boxOutline: {
        padding: '1.2rem',
    },
    paper: {
        [theme.breakpoints.down('sm')]: {
            padding: '1rem',
            width: '100%',
        },
        position: 'absolute',
        width: '65%',
        backgroundColor: "#121212",
        color: "white",
        boxShadow: theme.shadows[5],
        padding: '3rem 4rem',
    },
    closeIcon: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer',
    },
    selectedIconType: {
        border: '1px solid black',
        padding: '5px'
    },
    unselectedIconType: {
        padding: '6px'
    },
    addNewContainer: {
        [theme.breakpoints.down('md')]: {
            paddingTop: 0,
        },
        [theme.breakpoints.down('lg')]: {
            paddingTop: '5%'
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '12%'
        },
        height: '100%'
    },
    modalStyle: {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        overflowY: 'auto',
        height: '80%',
        // height: 'auto',
        display: 'block',
        [theme.breakpoints.up('xl')]: {
            height: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            height: '100%'
        }
    },
    productBackground: {
        // background: 'white',
        height: '100%',
        width: '100%',
        // padding: '0 20px',
        position: 'relative'
    },
    productBackgroundOrder: {
        // background: 'white',
        height: 'auto',
        // padding: '0 15px 0 15px',
        [theme.breakpoints.up('sm')]: {
            minHeight: '285px',
        },
        position: 'relative'
    },
    productImage: {
        height: 'auto',
        width: '100%',
    },
    productImageOrder: {
        width: '100%',
    },
    heartIcon: {
        position: 'absolute',
        right: '20px',
        top: "20px"
    },
    onProcess: {
        fontSize: '0.8rem',
        borderRadius: '16px',
        padding: '2px 10px 0',
        textTransform: 'uppercase',
        width: 'auto',
        background: '#C8E2C4',
        color: '#4A7C0B'
    },
    onWaiting: {
        fontSize: '0.8rem',
        borderRadius: '16px',
        padding: '2px 10px 0',
        textTransform: 'uppercase',
        width: 'auto',
        background: '#FFE4B5',
        color: '#9B7207'
    },
    onDelivery: {
        fontSize: '0.8rem',
        borderRadius: '16px',
        padding: '2px 10px 0',
        textTransform: 'uppercase',
        width: 'auto',
        background: 'rgba(173, 226, 255, 0.3)',
        color: '#489ECE'
    },
    borderHr: {
        marginTop: 15,
        marginBottom: 15,
        border: '0.5px solid black'
    },
    VAContainer: {
        color:'#AF986D',
        marginTop: 15,
        background: '#222222',
        borderRadius: '16px',
        padding: '5px 15px 15px 15px',
        width: '350px'
    },
    VANo: {
        fontWeight: 'bold',
        paddingRight: 15,
        lineHeight: 0,
        fontSize: 24,
        borderRight: '1px solid #CFCFCF'
    },
    copasIcon: {
        marginTop: 10,
        marginBottom: -5,
        marginLeft: 15,
        cursor: 'pointer'
    },
    paymentInfoContainer: {
        border: '1px solid #AF986D',
        borderRadius: '10px',
    },
    onCanceled: {
        fontSize: '0.8rem',
        borderRadius: '16px',
        padding: '2px 10px 0',
        textTransform: 'uppercase',
        width: 'auto',
        background: '#de9dac',
        color: 'white'
    },
});
const borderStandart = {
    backgroundColor : '#121212',
    // border: '1px solid #f0f0f0',
}

export class OrderCard extends Component {
    static contextType = ClientSideContext;
    constructor(props) {
        super(props);

        this.state = {
            openBackdrop: false,
            open: false,

            snackbarType: 'success',
            snackbarMessage: '',

            orders: [],
            selectedModal: null,

            id: '',
            order_details: [],
            user: [],
            address: [],
            status: '',
            notes: '',

            card_notes: '',
            for_gift: '',
            full_name: '',
            street_address: '',
            phone_no: '',
            postal_code: '',
            province_id: '',
            city_id: '',
            tracking_no: '',

            shipping_service: '',
            shipping_cost: '',

            total_amount: 0,
            resendGift: false,
            repeatOrder: false,
            paymentInfo: false,

            discount: 0,
            code: '',
            va_copied_info: '',
            va_number: ''
        }

        // this.handlePasswordChange = this.handlePasswordChange.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount = async () => {
        this.setState({ openBackdrop: true });
        await axios.get(`${baseUrl}/orders`, requestConfig).then((res) => {
            if (res.data.success) {
                this.setState({
                    orders: res.data.data
                }, () => {
                    this.setState({ openBackdrop: false });
                })
            } else {
                this.setState({ openBackdrop: false });
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            console.log("Error : ", e);
        })
    }

    handleOpenModal = i => {
        this.setState({
            open: true,
            selectedModal: i
        }, () => {
        })

        const order = this.state.orders.find(x => x.id === i);
        // const address = this.state.orders[i];
        this.setState({
            id: i,
            tracking_no: order.tracking_no,
            for_gift: order.for_gift,
            order_details: order.order_details,
            user: order.user,
            address: order.address,
            status: order.status,
            notes: order.notes,
            card_notes: order.card_notes,
            total_amount: order.total_amount < 0 ? 0 : order.total_amount,
            full_name: order.full_name,
            street_address: order.street_address,
            phone_no: order.phone_no,
            postal_code: order.postal_code,
            province_id: order.province_id,
            city_id: order.city_id,
            shipping_service: order.shipping_service,
            shipping_cost: order.shipping_cost,
            discount: order.discount !== null ? order.discount : 0,
            code: order.code,
            va_number: order.va_number,
            va_copied_info: ''
        });

        if (order.status === "On Delivery") {
            this.setState({
                resendGift: true,
                repeatOrder: true,
                paymentInfo: false
            })
        }
        else if (order.status === "Waiting For Payment") {
            this.setState({
                resendGift: false,
                repeatOrder: false,
                paymentInfo: true
            })
        }
        else {
            // On Process
            this.setState({
                resendGift: false,
                repeatOrder: false,
                paymentInfo: false
            })
        }
    };

    handleClose = () => {
        this.setState({
            open: false,
            // id: '',
            // order_details: [],
            // user: [],
            // address: [],
            // status: '',
            // notes: '',
            // total: 0,
            // resendGift: true,
            // repeatOrder: true
        })
    };

    handleUpdateAddress = async () => {
        const state = this.state;
        await axios.put(`${baseUrl}/addresses/${this.state.id}`,
            state,
            requestConfig)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        // addresses: [...this.state.orders, res.data.data]
                        addresses: this.state.orders.map(address => {
                            if (address.id === res.data.data.id) {
                                address = res.data.data
                            }
                            return address;
                        })
                    }, () => {
                        this.handleClose();
                    })
                } else {
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                console.log("Error : ", e);
            })

    }

    handleRepeatOrder = async () => {
        const { dispatchCartItems } = this.context;
        const state = this.state;

        this.setState({ openBackdrop: true });
        // console.log(this.state)
        await axios.post(`${baseUrl}/profile/reorder_order`,
            { order_id: state.id },
            requestConfig)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        snackbarType: 'success',
                        snackbarMessage: 'Success Added To Cart',
                        openBackdrop: false
                    });

                    res.data.data.forEach(cartItem => {
                        dispatchCartItems({
                            type: "ADD_TO_CART",
                            newItem: cartItem,
                        })
                    });

                    // dispatchCartItems({
                    //     type: "ADD_TO_CART",
                    //     newItem: res.data.data,
                    // })

                    this.handleClose();
                    this.props.openCart();
                } else {
                    this.setState({
                        snackbarType: 'failed',
                        snackbarMessage: 'Failed Added To Cart',
                        openBackdrop: false
                    });
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.setState({
                    snackbarType: 'failed',
                    snackbarMessage: 'Failed Added To Cart',
                    openBackdrop: false
                });
                console.log("Error : ", e);
            })

    }

    renderOrders = () => {
        const { classes } = this.props;

        return this.state.orders.map((item, i) => {
            // alert(window.location.pathname.split("/")[2]);
            return (
                <Grid item xl={4} lg={6} sm={6} xs={12} onClick={() => this.handleOpenModal(item.id)} className={` ${classes.linkPointer}`}
                    key={item.id}>
                    <div style={borderStandart}>
                        <Grid container>
                            <Grid item sm={6} xs={12}>
                                {
                                    item.order_details[0].is_own === 0 ?
                                        <React.Fragment>
                                            {
                                                item.thumbnail_url !== undefined ?
                                                <Grid container className={classes.productBackgroundOrder} alignItems="center" justify="center">
                                                    <img src={item.thumbnail_url} className={classes.productImageOrder} alt='product img'></img>
                                                </Grid>
                                                :
                                                <Grid container className={classes.productBackgroundOrder} alignItems="center" justify="center">
                                                    <img src={Suddhabumi} alt='product img' className={classes.productImageOrder}></img>
                                                </Grid>
                                            }
                                        </React.Fragment>
                                        :
                                        <Grid container className={classes.productBackground} alignItems="center" justify="center">
                                            <img src={Chamomile} alt='product img' className={classes.productImage}></img>
                                        </Grid>
                                }
                                {/* {
                                    item.thumbnail_url !== undefined ?
                                        <Grid container className={classes.productBackgroundOrder} alignItems="center" justify="center">
                                            <img src={item.thumbnail_url} className={classes.productImageOrder} alt='product img'></img>
                                        </Grid>
                                        :
                                        <Grid container className={classes.productBackgroundOrder} alignItems="center" justify="center">
                                            <img src={Suddhabumi} alt='product img' className={classes.productImageOrder}></img>
                                        </Grid>
                                } */}
                                {/* <Grid container className={classes.productBackground} alignItems="center" justify="center">
                                    <img src={Suddhabumi} alt='product img' className={classes.productImageOrder}></img>
                                </Grid> */}
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <div className={classes.boxOutline}>
                                    <label>{new Intl.DateTimeFormat('en-GB', {
                                        month: 'long',
                                        day: '2-digit',
                                        year: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                    }).format(new Date(item.created_at))}</label><br />
                                    {/* <label>Order No : #{item.id}</label> */}
                                    <p className={classes.titleMediumSize} style={{ marginTop: 15 }}>
                                        {
                                            item.order_details[0].is_own === 0 ?
                                                item.order_details[0].sub_product.product.name
                                                :
                                                item.order_details[0].own_product.name
                                        }
                                    </p>
                                    {item.order_details.length - 1 > 0 ?
                                        <p><u>{dictionary['And'][this.context.language]} {item.order_details.length - 1} {dictionary['other items'][this.context.language]}</u></p> : ''
                                    }
                                    <br />
                                    {
                                        item.for_gift === 0 ?
                                            <p>{dictionary['Deliver To'][this.context.language]} :</p>
                                            :
                                            <p>{dictionary['Gift For'][this.context.language]} : </p>
                                    }
                                    {
                                        item.for_gift === 0 ?
                                            <p>{item.street_address}</p>
                                            :
                                            <p>{item.full_name}<br />{item.street_address}</p>
                                    }
                                    {/* <p>{item.street_address}</p> */}
                                    <br />
                                    <span className={
                                        item.status.toString() === "On Process" ?
                                            classes.onProcess
                                            : item.status.toString() === "On Delivery" ?
                                                classes.onDelivery
                                                : item.status.toString() === "Waiting For Payment" ?
                                                    classes.onWaiting
                                                    :
                                                    classes.onCanceled
                                    }>
                                        {dictionary[item.status][this.context.language]}
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            )
        })
    }

    renderOrderDetails = () => {
        const { classes } = this.props;
        // console.log(this.state)
        return this.state.order_details.map((item, i) => {
            return (
                <Grid container spacing={3} key={item.id}>
                    <Grid item xs={3}>
                        {
                            item.is_own === 0 ?
                                <Link to={`${this.props.prefix}/product/${item.sub_product.product.url_slug}`}>
                                    {
                                        item.thumbnail_url !== undefined ?
                                            <Grid container className={classes.productBackground} alignItems="center" justify="center">
                                                <img src={item.thumbnail_url} className={classes.productImage} alt='product img'></img>
                                            </Grid>
                                            :
                                            <Grid container className={classes.productBackground} alignItems="center" justify="center">
                                                <img src={Suddhabumi} alt='product img' className={classes.productImage}></img>
                                            </Grid>
                                    }
                                </Link>
                                :
                                <Grid container className={classes.productBackground} alignItems="center" justify="center">
                                    <img src={Chamomile} alt='product img' className={classes.productImage}></img>
                                </Grid>
                        }

                        {/* <Grid container className={classes.productBackground} alignItems="center" justify="center">
                            <img src={Suddhabumi} alt='product img' className={classes.productImage}></img>
                        </Grid> */}
                    </Grid>
                    <Grid item xs={6} style={{ paddingTop: 35 }}>
                        <p><b>({item.qty}X)</b> -&nbsp;
                            {
                                item.is_own === 0 ?
                                    item.sub_product.product.name
                                    :
                                    item.own_product.name
                            }
                            <br />
                        </p>
                        {
                            item.is_own === 0 ?
                                item.sub_product.product.sub_products.length > 1 ?
                                    <p>varian : {item.sub_product.varian}</p>
                                    :
                                    ''
                                :
                                ''
                        }
                        {
                            item.is_own === 0 ?
                                <p>@Rp. {(item.price).toLocaleString('ID')}</p>
                                :
                                <p>@Rp. {(item.own_product.price).toLocaleString('ID')}</p>
                        }

                    </Grid>
                    <Grid item xs={3} style={{ paddingTop: 60 }}>
                        {
                            item.is_own === 0 ?
                                <p style={{ float: 'right' }}>Rp. {(item.price * item.qty).toLocaleString('ID')}</p>
                                :
                                <p style={{ float: 'right' }}>Rp. {(item.own_product.price * item.qty).toLocaleString('ID')}</p>
                        }
                    </Grid>
                </Grid>
            )
        })
    }

    handleCopy = () => {
        navigator.clipboard.writeText(this.state.va_number);
        this.setState({
            va_copied_info: 'VA Number Copied!'
        })
    }

    renderModal = () => {
        const { classes } = this.props;
        // Check to see if there's a selected post. If so, render it.
        return (
            <div className={`${classes.modalStyle} ${classes.paper}`}>
                <Grid container spacing={4}>
                    <Grid item sm={6} xs={12}>

                        <img className={classes.closeIcon} src={CloseIcon} alt="close icon" onClick={this.handleClose}></img>
                        {/* <h2 className={classes.titleMediumSize} style={{ marginBottom: 15 }}>Order No: #{this.state.id}</h2> */}

                        <div>{this.renderOrderDetails()}</div>
                        <hr className={classes.borderHr}></hr>
                        <p>{dictionary['Shipping Cost'][this.context.language]}<span style={{ float: 'right' }}>Rp. {this.state.shipping_cost.toLocaleString('ID')}</span></p>
                        {
                            this.state.discount > 0 ?
                                <p>Discount ({this.state.code})<span style={{ float: 'right', color: 'green' }}>- Rp. {parseInt(this.state.discount).toLocaleString('ID')}</span></p>
                                :
                                ''
                        }
                        <hr className={classes.borderHr}></hr>
                        <p><b>{dictionary['Grand Total'][this.context.language]}<span style={{ float: 'right' }}>Rp. {this.state.total_amount.toLocaleString('ID')}</span></b></p>
                        <hr className={classes.borderHr}></hr>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                        <h2 className={classes.titleMediumSize} style={{ marginBottom: 15 }}>{dictionary['Delivery Information'][this.context.language]}</h2>
                        <p>{dictionary['Name of Recipient'][this.context.language]}
                            {
                                this.state.for_gift === 0 ?
                                    <span style={{ float: 'right' }}>
                                        {this.state.user.first_name} {this.state.user.last_name}
                                    </span>
                                    :
                                    <span style={{ float: 'right' }}>
                                        {this.state.full_name}
                                    </span>
                            }
                        </p>
                        <hr className={classes.borderHr}></hr>
                        <p>{dictionary['Recipient Phone Number'][this.context.language]}
                            <span style={{ float: 'right' }}>
                                {this.state.phone_no}
                            </span>
                        </p>
                        <hr className={classes.borderHr}></hr>
                        <p>{dictionary['Delivery Using'][this.context.language]}<span style={{ float: 'right' }}>{this.state.shipping_service}</span></p>
                        <hr className={classes.borderHr}></hr>
                        {
                            this.state.tracking_no ?
                                <React.Fragment>
                                    <p>{dictionary['Tracking No'][this.context.language]}<span style={{ float: 'right' }}>{this.state.tracking_no}</span></p>
                                    <hr className={classes.borderHr}></hr>
                                </React.Fragment>
                                :
                                ''
                        }
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                {dictionary['Delivery Address'][this.context.language]}
                            </Grid>
                            <Grid item xs={8}>
                                <span style={{ float: 'right', textAlign: 'right' }}>
                                    {this.state.street_address}
                                </span>
                            </Grid>
                        </Grid>
                        <hr className={classes.borderHr}></hr>
                        <p>Order Status<span style={{ float: 'right' }}
                            className={
                                this.state.status.toString() === "On Process" ?
                                    classes.onProcess
                                    : this.state.status.toString() === "On Delivery" ?
                                        classes.onDelivery
                                        : this.state.status.toString() === "Waiting For Payment" ?
                                            classes.onWaiting
                                            :
                                            classes.onCanceled
                            }>

                            {this.state.status ?
                                dictionary[this.state.status][this.context.language]
                                : ''
                            }</span></p>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: 25 }}>
                    {
                        this.state.paymentInfo === true ?
                            <Grid item xs={12} className={classes.paymentInfoContainer} style={{ padding: 30 }}>
                                <img src={BcaIcon} alt="close icon"></img>
                                <br /><br />
                                <p>{dictionary["Please finish your payment in 1x24 hour"][this.context.language]}</p>
                                <p>{dictionary["Otherwise, we are very sorry that we cannot continue to proceed your order"][this.context.language]}</p>
                                <br />
                                <p>{dictionary["Here is your Virtual Account Number"][this.context.language]}:</p>

                                <div className={classes.VAContainer}>
                                    <span className={classes.VANo}>{this.state.va_number}</span>
                                    <img className={classes.copasIcon} src={CopasIcon} alt="close icon"
                                        onClick={this.handleCopy}
                                    ></img>
                                    <p style={{ marginTop: 5 }}>{this.state.va_copied_info}</p>
                                </div>
                                <br /><br />
                                <b>{dictionary["How to pay using BCA Virtual Account"][this.context.language]}</b>
                                <p>
                                    1. {dictionary["How to pay Point 1"][this.context.language]}<br />
                                2. {dictionary["How to pay Point 2"][this.context.language]}<br />
                                3. {dictionary["How to pay Point 3"][this.context.language]}<br />
                                4. {dictionary["How to pay Point 4"][this.context.language]}<br />
                                5. {dictionary["How to pay Point 5"][this.context.language]}<br />
                                </p>
                            </Grid>
                            :
                            ''
                    }
                    <Grid item sm={6} xs={12}>
                        {
                            this.state.resendGift === true ?
                                <Link to={
                                    {
                                        pathname: `${this.props.prefix}/product_buying`,
                                        state: {
                                            recipient: 'gift',
                                        }
                                    }
                                }>
                                    <Button variant="outlined" className={classes.buttonLight} onClick={this.handleRepeatOrder} style={{ width: '100%' }}>
                                        Re-Send as Gift
                                </Button>
                                </Link>
                                :
                                ''
                        }
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        {
                            this.state.resendGift === true ?
                                <Button variant="outlined" className={classes.buttonDark} onClick={this.handleRepeatOrder} style={{ width: '100%' }}>
                                    Repeat Order
                                </Button>
                                :
                                ''
                        }
                    </Grid>
                </Grid>

            </div>
        );

    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.counter}
                />
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{'color': '#AF986D'}} />
                </Backdrop>
                {this.renderOrders()}

                {this.state.orders.length === 0 ?

                    <Grid item xs={12}>
                        <p style={{ textAlign: 'center' }}>You have no order at the moment.</p>
                    </Grid>
                    :
                    ""
                }
                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    closeAfterTransition
                >
                    <Fade in={this.state.open}>
                        {/* {body} */}
                        <div>{this.renderModal()}</div>
                    </Fade>
                </Modal>
            </React.Fragment >
        )
    }
}

export default withStyles(useStyles)(OrderCard) 
