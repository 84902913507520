
import React, { useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {isMobile} from "react-device-detect";
import {ClientSideContext} from '../../ClientSideContext';

const useStyles = makeStyles((theme) => ({
    itemTab : {
        fontSize : '24px',
        textTransform: 'none',
    }
}));

const CustomTab = withStyles((theme) => ({
    root: {
        // '& .MuiTab-wrapper':{
        //     fontFamily: 'BlaakLight',
        // },
        textTransform: 'none',
        opacity : 0.5,
        color : 'white',
        '&:hover': {
            fontWeight : 'bold',
            opacity: 1,
        },
        '&$selected': {
            fontWeight : 'bold',
            opacity: 1, 
            color: '#AF986D',
            '& span' : {
                borderBottom: '1px solid black',
                paddingBottom: '5px',
            }
        },
        '& span' :{
            width : 'auto',
        },
        width : 'auto',
        minWidth : '0px',
        paddingRight : '20px',
        paddingLeft : '20px'

    },
    selected: {

    },
}))((props) => <Tab disableRipple {...props} />);

const CustomTabs = withStyles({
    indicator: {
        display: 'none',
    },
})(Tabs);
export default function TabNav(props) {
    const classes = useStyles();
    const {language} = useContext(ClientSideContext)

    const handleChange = (e, newValue) => {
        e.preventDefault()
        props.handleNavChange(e,newValue)
    };

    return (
        <React.Fragment>
            <CustomTabs 
                style={props.styles}
                className={`${classes.tabNav} ${props.className}`} 
                value={props.value}
                onChange={handleChange} 
                variant = {isMobile ? 'scrollable' : props.variant}
                scrollButtons = "auto"
                centered={isMobile ? false : props.centered}
            >
                {
                    props.navItems.map(item=>{
                        let label = ''
                        if(item.name !== undefined){
                            label = item.name
                        }else{
                            label = item[`name_${language}`]
                        }
                        return <CustomTab 
                            style={props.tabStyles} 
                            key={item.id} 
                            value={item.id} 
                            className={classes.itemTab} 
                            disableRipple 
                            label={label}/>
                    })
                }
            </CustomTabs>
        </React.Fragment>  
    );
}

TabNav.defaultProps = {
    styles : {},
    className : '',
    tabStyles : {},
    variant: isMobile ? 'scrollable' : 'standard',
    centered : false
}