
import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core'
import ButtonAdmin from "admin/components/CustomButtons/Button.js";

function Confirmation(props) {
    return (
        <Dialog
            open={props.openDialog}
            onClose={() => props.handleCloseDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description"
                    dangerouslySetInnerHTML={{ __html: props.message }} 
                >
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ButtonAdmin color="info" onClick={() => props.handleCloseDialog(false)}>CANCEL</ButtonAdmin>
                <ButtonAdmin color="danger" onClick={() => props.handleCloseDialog(true)}>YES</ButtonAdmin>
            </DialogActions>
        </Dialog>
    )
}

export default Confirmation
