import React, { Component } from 'react'
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// import Slider from 'infinite-react-carousel';
import {isMobile} from "react-device-detect";
import HeadlineDesktop from "assets/img/havilla/headline_desktop.png";
import HeadlineMobile from "assets/img/havilla/headline_mobile.png";
// Image
// import Tea1 from "assets/img/havilla/Chamomile.png";
// import Tea2 from "assets/img/havilla/Dong Ding.png";
// import Tea3 from "assets/img/havilla/Whitty Rose.png";
import TeaBig1 from "assets/img/havilla/home_tea.png";
import Tisane1 from "assets/img/havilla/home_tisane.png";
import ThumbsUpIcon from "assets/img/havilla/thumbs_up_icon.svg";
import BagIcon from "assets/img/havilla/bag_icon.svg";
import GraduateIcon from "assets/img/havilla/graduate_icon.svg";
import LoveIcon from "assets/img/havilla/love_icon.svg";
import MagicIcon from "assets/img/havilla/magic_icon.svg";
import { Link } from 'react-router-dom';
// import CustomModalWImage from 'client-side/components/CustomModals/CustomModalWImage';
import BestProduct from 'client-side/components/BestProduct/BestProduct';
import TabNav from 'client-side/components/TabNav/TabNav';
import PostsContainer from 'client-side/components/PostsContainer/PostsContainer';
// import ModalImage from "assets/img/havilla/modal1.png";
import { dictionary } from '../../../Dictionary';
import { ClientSideContext } from '../../ClientSideContext';
import Flickity from "react-flickity-component";
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';

import axios from 'axios';
import {
    baseUrl,
    requestConfig,
    prefix
} from '../../../Environment.js';
// Styles
import styles from "assets/jss/client-side/App.js";
import "assets/css/flickity.css";

const useStyles = theme => ({
    ...styles(theme),
    titleQuote: {
        fontSize: '32px',
        fontStyle: 'italic'
    },
    descQuote: {
        marginTop: '44px',
        fontSize: '24px',
    },
    textLabelIcon: {
        fontSize: '12px',
        marginTop: '13px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0px',
            paddingLeft: '20px',
            textAlign: 'left'
        },
    },
    whyContentContainer: {
        marginTop: '24px',
        marginBottom: '50px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0px',
        },
    },
    brClass: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    teaTalksTitle: {
        marginTop: '60px',
        marginBottom: '20px',
        fontSize: '28px'
    },
    articlesContainer: {
        paddingLeft: '3.5rem !important',
        paddingRight: '0px !important',
        marginTop: '32px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '10px !important',
            paddingRight: '10px !important'
        },
    },
    firstTitle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '30px'
            // paddingLeft: '25px !important',
            // paddingRight: '25px !important',
        }
    }
});

let HomepageCarousel = null;
class Homepage extends Component {
    static contextType = ClientSideContext;

    constructor(props) {
        super(props)
        
        this.state = {
            openBackdrop: false,
            language: '',
            articleCategoryId: 0,
            articleCategories: [],
            articles: [],
            loadArticles: false,
            sliders: [],
            loadSlider: false,

            snackbarType: 'info',
            snackbarMessage: '',
            snackbarCounter: 0,
        }

        HomepageCarousel = React.memo(props => {
            const { classes } = this.props;
            const flickityOptions = {
                initialIndex: 1,
                // groupCells: isMobile ? 3 : '40%',
                pageDots: false,
                cellAlign: 'left',
                prevNextButtons: false,
                // freeScroll: true,
                draggable: true,
                wrapAround: true,
                autoPlay: 2100
            }

            if (props.sliders.length > 0) {
                return (
                    <Grow in={this.state.loadSlider} timeout={500}>
                        <Grid item xs={12} style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                            <Flickity className={classes.sliderContainer} options={flickityOptions}>
                                {
                                    props.sliders.length < 6 ?
                                        [...props.sliders, ...props.sliders].map((item, i) => {
                                            // alert(window.location.pathname.split("/")[2]);
                                            return (
                                                <div key={i} className={classes.sliderItem}>
                                                    <img src={item.photo_url} alt={`homepage slider ${item.id}`}></img>
                                                </div>
                                            )
                                        })
                                        :
                                        props.sliders.map(item => {
                                            // alert(window.location.pathname.split("/")[2]);
                                            return (
                                                <div key={item.id} className={classes.sliderItem}>
                                                    <img src={item.photo_url} alt={`homepage slider ${item.id}`}></img>
                                                </div>
                                            )
                                        })
                                }

                            </Flickity>
                        </Grid>
                    </Grow>
                )
            } else {
                return (
                    <Grid item xs={12} style={{ paddingRight: '0px', paddingLeft: '0px', height: 500 }}>
                    </Grid>
                );
            }
        });
    }

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    componentDidMount() {
        this.getArticleCategories();
        this.getSliders();
        this.setState({
            language: this.context.language
        })
    }

    componentDidUpdate() {
        // if (this.context.language !== this.context.language) {
        //     this.setState({
        //         language: this.context.language
        //     })
        // }
    }

    getSliders = () => {
        this.setState({ openBackdrop: false });
        let data = {
            type: 'homepage'
        }
        axios.post(`${baseUrl}/sliders/get_by_type`,
            data,
            requestConfig
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState({
                    sliders: data,
                    loadSlider: true
                })
                this.setState({ openBackdrop: false });
            } else {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${res.data.message}`
                })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            console.log("Error : ", e);
        })
    }

    handleArticleCategoryChange = (e, newValue) => {
        e.preventDefault()
        this.setState({
            articleCategoryId: newValue,
            loadArticles: false
        }, () => {
            this.getArticles();
        });
    }

    getArticleCategories = () => {
        axios.get(`${baseUrl}/article_categories`,
            requestConfig
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data.active;
                this.setState((prevState, props) => ({
                    articleCategories: data,
                    articleCategoryId: data[0] ? data[0].id : null
                }), () => {
                    this.getArticles();
                })
                // this.setState({ openBackdrop:false});
            } else {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${res.data.message}`
                })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            console.log("Error : ", e);
        })
    }

    getArticles = () => {
        this.setState({ openBackdrop: true });
        let data = {
            category_id: this.state.articleCategoryId,
            limit: 5
        }
        axios.post(`${baseUrl}/articles/get_articles_by_category`,
            data,
            requestConfig
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState({
                    articles: data.data,
                    loadArticles: true
                    // nextPageLink : data.next_page_url
                })
                this.setState({ openBackdrop: false });
            } else {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${res.data.message}`
                })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            console.log("Error : ", e);
        })
    }

    render() {
        const { classes } = this.props;

        return (
            <Container maxWidth="xl" className={classes.container}>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.counter}
                />
                <Grid
                    className={classes.containerHeadline}
                    container
                    spacing={2}
                    justify="center"
                    alignItems="center"
                >
                    <Grid
                        container
                        item
                        spacing={2}
                        xs={12}
                        justify="center"
                        alignItems="center"
                    >
                        <img src={isMobile ? HeadlineMobile : HeadlineDesktop} alt="thumbs up icon"></img>
                        {/* <Grid item xs={12} md={5} xl={4} className={`${classes.title} ${classes.firstTitle}`}>
                            {dictionary['First Section Title'][this.context.language]}
                        </Grid> */}
                        {/* <Grid item xs={12} className={classes.desc}>
                            {dictionary['First Section Desc'][this.context.language]}
                        </Grid> */}

                        <Fade in={true} timeout={700} style={{marginTop:20}}>
                            <Grid
                                container
                                item
                                spacing={2}
                                xs={12}
                                justify="center"
                                alignItems="center"
                            >
                                {/* <Grid item xs={12} md={2}>
                                    <Link to={`${prefix}/create_your_own`}>
                                        <Button variant="outlined" className={classes.buttonLight} style={{ width: '100%' }}>
                                            {dictionary['Button Create'][this.context.language] !== undefined ? dictionary['Button Create'][this.context.language] : ''}
                                        </Button>
                                    </Link>
                                </Grid> */}
                                <Grid item xs={12} md={2}>
                                    <Link to={{
                                        pathname: `${prefix}/tea_shop/tea`,
                                        state: {
                                            category_id: 1
                                        }
                                    }}>
                                        <Button variant="outlined" className={classes.buttonDark} style={{ width: '75%' }}>
                                            {dictionary['Button Create'][this.context.language] !== undefined ? dictionary['Button Shop'][this.context.language] : ''}
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Fade>

                        <HomepageCarousel sliders={this.state.sliders} />

                        {/* <Grid item xs={12} md={6} xl={5} className={`${classes.title} ${classes.titleQuote}`}>
                            {dictionary['Quote'][this.context.language]}
                        </Grid>
                        <Grid item xs={12} className={`${classes.desc} ${classes.descQuote}`}>
                            {dictionary['Quote By'][this.context.language]}
                        </Grid> */}

                        <Grid item xs={12}>
                            {dictionary['Why'][this.context.language]}
                        </Grid>

                        <Grid item xs={12} sm={8} md={7}
                            container
                            justify='center'
                            alignItems='flex-start'
                            spacing={2}
                            className={classes.whyContentContainer}
                        >
                            <Grid item xs={12} md={2} container justify="center" alignItems='center' >
                                <Grid item md={12} xs={4}>
                                    <img src={ThumbsUpIcon} alt="thumbs up icon"></img>
                                </Grid>
                                <Grid item md={11} xs={8} className={classes.textLabelIcon}>
                                    {dictionary['Why Point 1'][this.context.language]}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={2} container justify="center" alignItems='center'>
                                <Grid item md={12} xs={4}>
                                    <img src={BagIcon} alt="bag icon"></img>
                                </Grid>
                                <Grid item md={12} xs={8} className={classes.textLabelIcon}>
                                    {dictionary['Why Point 2'][this.context.language]}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={2} container justify="center" alignItems='center'>
                                <Grid item md={12} xs={4}>
                                    <img src={GraduateIcon} alt="graduate icon"></img>
                                </Grid>
                                <Grid item md={8} xs={8} className={classes.textLabelIcon}>
                                    {dictionary['Why Point 3'][this.context.language]}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={2} container justify="center" alignItems='center'>
                                <Grid item md={12} xs={4}>
                                    <img src={LoveIcon} alt="love icon"></img>
                                </Grid>
                                <Grid item md={12} xs={8} className={classes.textLabelIcon}>
                                    {dictionary['Why Point 4'][this.context.language]}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={2} container justify="center" alignItems='center'>
                                <Grid item md={12} xs={4}>
                                    <img src={MagicIcon} alt="magic icon"></img>
                                </Grid>
                                <Grid item md={this.context.language !== 'en' ? 7 : 8} xs={8} className={classes.textLabelIcon}>
                                    {dictionary['Why Point 5'][this.context.language]}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            item xs={12}
                            container
                        >
                            <Grid item xs={false} lg={2}></Grid>
                            <BestProduct
                                image={TeaBig1}
                                imageAlt='tea big'
                                description={dictionary['Best Tea'][this.context.language]}
                                linkText={dictionary['Best Tea Link'][this.context.language]}
                                linkTo={{
                                    pathname: `${prefix}/tea_shop/tea`,
                                    state: {
                                        category_id: 1
                                    }
                                }}
                            />
                            <BestProduct
                                image={Tisane1}
                                imageAlt='tea big2'
                                description={dictionary['Best Tisane'][this.context.language]}
                                linkText={dictionary['Best Tisane Link'][this.context.language]}
                                linkTo={{
                                    pathname: `${prefix}/tea_shop/tisane`,
                                    state: {
                                        category_id: 2
                                    }
                                }}
                            />
                            <Grid item xs={false} md={2}></Grid>
                        </Grid>

                        <Grid item xs={12} className={`${classes.teaTalksTitle} ${classes.title}`}>
                            {dictionary['TeaTalks'][this.context.language]}
                        </Grid>

                        {
                            this.state.articleCategories.length > 0 ?
                                <TabNav
                                    value={this.state.articleCategoryId}
                                    handleNavChange={this.handleArticleCategoryChange}
                                    navItems={this.state.articleCategories}
                                />
                                :
                                null
                        }
                        <Fade in={this.state.loadArticles} timeout={700}>
                            <Grid item xs={12} className={classes.articlesContainer}>
                                {
                                    this.state.articles.length > 0 ?
                                        <PostsContainer articles={this.state.articles} />
                                        :
                                        <Grid item xs={12} className={classes.noResult}>No articles at the moment...</Grid>
                                }
                            </Grid>
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withStyles(useStyles)(Homepage) 