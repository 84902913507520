import React from 'react'
import MaterialTable from 'material-table';
import { TextField, withStyles } from '@material-ui/core';

const CustomTextfieldRaw = withStyles({
    root: {
        width: '100%',
        '& label.Mui-focused': {
            color: 'black',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);

const columns = {
    columns_desc_object : [
        {
            title: 'Title English',
            field: 'title_en',
            editComponent: props => (
                <CustomTextfieldRaw
                    multiline={true}
                    rows={3}
                    type="text"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        {
            title: 'Title Indonesia',
            field: 'title_id',
            editComponent: props => (
                <CustomTextfieldRaw
                    multiline={true}
                    rows={3}
                    type="text"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        {
            title: 'Description English',
            field: 'desc_en',
            editComponent: props => (
                <CustomTextfieldRaw
                    multiline={true}
                    rows={3}
                    type="text"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        {
            title: 'Description Indonesia',
            field: 'desc_id',
            editComponent: props => (
                <CustomTextfieldRaw
                    multiline={true}
                    rows={3}
                    type="text"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
    ],

    columns_brewing_styles : [
        {
            title: 'Name',
            field: 'name',
            editComponent: props => (
                <CustomTextfieldRaw
                    multiline={true}
                    rows={3}
                    type="text"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        {
            title: 'Brewing Ratio',
            field: 'brewing_ratio',
            editComponent: props => (
                <CustomTextfieldRaw
                    multiline={true}
                    rows={3}
                    type="text"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        {
            title: 'Water Temperature',
            field: 'water_temp',
            editComponent: props => (
                <CustomTextfieldRaw
                    multiline={true}
                    rows={3}
                    type="text"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        {
            title: 'Brewing Time',
            field: 'brewing_time',
            editComponent: props => (
                <CustomTextfieldRaw
                    multiline={true}
                    rows={3}
                    type="text"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        {
            title: 'Multiple Infusion',
            field: 'multiple_infusion',
            editComponent: props => (
                <CustomTextfieldRaw
                    multiline={true}
                    rows={3}
                    type="text"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        {
            title: 'Suggestions',
            field: 'suggestions',
            editComponent: props => (
                <CustomTextfieldRaw
                    multiline={true}
                    rows={3}
                    type="text"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        {
            title: 'Food Pairing',
            field: 'food_pairing',
            editComponent: props => (
                <CustomTextfieldRaw
                    multiline={true}
                    rows={3}
                    type="text"
                    value={props.value}
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
    ]
}

function CustomMaterialTable(props) {
    return (
        <MaterialTable
            title={<h4><b>{props.title}</b></h4>}
            options={props.options}
            columns={columns[`columns_${props.name}`]}
            data={props.data}
            editable={{
                onRowAdd: newData =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();
                            const data = [...props.data];
                            data.push(newData);
                            props.onChange(data,props.name);
                        }, 600);
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();
                            if (oldData) {
                                const data = [...props.data];
                                data[data.indexOf(oldData)] = newData;
                                props.onChange(data,props.name);
                            }
                        }, 600);
                    }),
                onRowDelete: oldData =>
                    new Promise(resolve => {
                        setTimeout(() => {
                            resolve();
                            const data = [...props.data];
                            data.splice(data.indexOf(oldData), 1);
                            props.onChange(data,props.name);
                        }, 600);
                    }),
            }}
        />
    )
}

function areEqual(prevProps, nextProps) {
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
   return prevProps.count === nextProps.count
}

export default React.memo(CustomMaterialTable, areEqual);
