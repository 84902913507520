import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField.js';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';

import { MenuItem } from '@material-ui/core';

import axios from 'axios';
import {
    baseUrl,
    requestConfigAdmin,
    prefix
} from '../../../Environment.js';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
const useStyles = theme => ({
    ...styles(theme),
    autoCompleteRoot: {
        '& label': {
            color: 'white',
        },
        '& label.Mui-disabled': {
            color: 'grey',
        },
        '& .MuiInput-root': {
            color: '#AF986D',
            '&:hover fieldset': {
                borderBottomColor: 'red',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#cfcfcf',
        },
        '& .MuiInput-root:hover': {
            '.MuiInput-underline:before': {
                borderBottomColor: '#cfcfcf',
            },
        },
        '& .MuiInput-underline-hover:before': {
            borderBottomColor: 'white',
        },
        '& label.Mui-focused': {
            color: '#AF986D',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#AF986D',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        },
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
    },
    autoCompletePaper: {
        backgroundColor: '#222222',
        borderRadius: 0,
        '& .MuiMenuItem-root': {
            background: '#222222'
        },
        '& .MuiMenuItem-root:hover': {
            backgroundColor: 'white',
            color: 'black',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#121212',
            borderRadius: 0,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#AF986D',
            borderRadius: 0,
            '&:hover': {
                backgroundColor: 'white',
            },
        }
    },
    autoCompleteOption: {
        color: 'white',
        '&:hover': {
            backgroundColor: 'white',
            color: 'black',
        },
    },
});

const CustomTextfieldRaw = withStyles({
    root: {
        width: '100%',
        '& label': {
            color: 'white',
        },
        '& label.Mui-disabled': {
            color: 'grey',
        },
        '& .MuiInput-root': {
            color: '#AF986D',
            '&:hover fieldset': {
                borderBottomColor: 'red',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#cfcfcf',
        },
        '& .MuiInput-root:hover': {
            '.MuiInput-underline:before': {
                borderBottomColor: '#cfcfcf',
            },
        },
        '& .MuiInput-underline-hover:before': {
            borderBottomColor: 'white',
        },
        '& label.Mui-focused': {
            color: '#AF986D',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#AF986D',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);

const field_names = [
    'name',
    'lat',
    'lng',
    'type',
]

export class StoreLocatorDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listCities: [
                {
                    value: '',
                    label: ''
                }
            ],
            name: '',
            lat: '',
            lng: '',
            regency_id: '',
            type: '',
            address: '',
            url: '',

            name_error: '',
            lat_error: '',
            lng_error: '',
            regency_id_error: '',
            type_error: '',
            address_error: ''
        }
    }

    onChange = (e, newValue) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onCityChange = (e, newValue) => {
        if (newValue) {
            this.setState({
                regency_id: newValue.value
            }, () => {

            });
            // this.setState({
            //     district_disabled: false,
            // })

        }
        // getDistrictsByRegency/{regency_id}
    }

    handleDelete = async () => {
        const state = this.state;
        await axios.delete(`${baseUrl}/storelocations/${this.state.id}`,
            state,
            requestConfigAdmin)
            .then((res) => {
                if (res.data.success) {
                    this.props.history.push(prefix + '/admin/storelocator/')
                    // this.setState({
                    //     // addresses: [...this.state.addresses, res.data.data]
                    //     addresses: [...this.state.addresses.filter(address => address.id !== this.state.id)]
                    // }, () => {
                    //     this.handleClose();
                    // })
                } else {
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                console.log("Error : ", e);
            })
    }

    handleUpdate = async () => {
        const state = this.state;
        let is_ok = true;
        for (let i = 0; i < field_names.length; i++) {
            let field_name = field_names[i];
            let field_name_text = field_names[i].replace('_', ' ');
            if (this.state[field_name] === "" || this.state[field_name] === null) {
                this.setState({ [field_name + "_error"]: field_name_text + " is required" });
                is_ok = false;
            }
        }

        if (is_ok) {
            if (this.props.match.params.id !== 'add') {
                await axios.put(`${baseUrl}/storelocations/${this.state.id}`,
                    state,
                    requestConfigAdmin)
                    .then((res) => {
                        if (res.data.success) {
                            this.props.history.push(prefix + '/admin/storelocator/')
                        } else {
                            console.log(res.data.message, res.data.data)
                        }
                    }, (e) => {
                        console.log("Error : ", e);
                    })
            }
            else {
                await axios.post(`${baseUrl}/storelocations`,
                    state,
                    requestConfigAdmin)
                    .then((res) => {
                        if (res.data.success) {
                            this.props.history.push(prefix + '/admin/storelocator/')
                        } else {
                            console.log(res.data.message, res.data.data)
                        }
                    }, (e) => {
                        console.log("Error : ", e);
                    })
            }
        }
    }

    componentDidUpdate(nextProps, nextState) {
        for (let i = 0; i < field_names.length; i++) {
            let field_name = field_names[i];
            if (this.state[field_name + "_error"] !== "") {
                if (this.state[field_name] !== nextState[field_name]) {
                    this.setState({ [field_name + "_error"]: "" });
                }
            }
        }

        return true;
    }

    componentDidMount = async () => {
        if (this.props.match.params.id !== 'add') {
            await axios.get(`${baseUrl}/storelocations/${this.props.match.params.id}`, requestConfigAdmin).then((res) => {
                if (res.data.success) {
                    this.setState({
                        id: res.data.data.id,
                        name: res.data.data.name,
                        lat: res.data.data.lat,
                        lng: res.data.data.lng,
                        address: res.data.data.address,
                        regency_id: res.data.data.regency_id,
                        type: res.data.data.type,
                        url: res.data.data.url
                    }, () => {

                    })
                } else {
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                console.log("Error : ", e);
            })
        }


        await axios.get(`${baseUrl}/regencies`).then((res) => {
            if (res.data.success) {
                this.setState({
                    listCities: res.data.data.map(item => {
                        return (
                            {
                                value: item.id,
                                label: item.name
                            }
                        )
                    })
                }, () => {
                })
            } else {
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            console.log("Error : ", e);
        })

    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Container maxWidth="xl" className={`${classes.container} ${classes.contentContainer}`}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <CustomTextField
                                onChange={this.onChange}
                                value={this.state.name}
                                error={this.state.name_error !== ""}
                                helperText={this.state.name_error}
                                type="text" name="name" label="Store Name" />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextfieldRaw
                                select
                                label="Store Type"
                                name="type"
                                value={this.state.type === null ? '' : this.state.type}
                                error={this.state.type_error !== ""}
                                helperText={this.state.type_error}
                                onChange={this.onChange}
                            >
                                <MenuItem value='Stockist'>Stockist</MenuItem>
                                <MenuItem value='Cafe'>Cafe</MenuItem>
                                <MenuItem value='Cafe & Stockist'>Cafe & Stockist</MenuItem>
                            </CustomTextfieldRaw>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextField
                                onChange={this.onChange}
                                value={this.state.address}
                                error={this.state.address_error !== ""}
                                helperText={this.state.address_error}
                                type="text" name="address" label="Address" />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                classes={{ root: classes.autoCompleteRoot, listbox: classes.autoCompletePaper, paper: classes.autoCompletePaper, option: classes.autoCompleteOption }}
                                value={this.state.listCities[this.state.listCities.findIndex(city => city.value.toString() === this.state.regency_id.toString())] || this.state.listCities[0]}
                                name="regency_id"
                                onChange={this.onCityChange}
                                options={this.state.listCities}
                                getOptionLabel={(option) => option.label}
                                // getOptionLabel={(option) => option.title}
                                renderInput={(params) => <TextField {...params}
                                    label="City" />}
                            />

                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextField
                                value={this.state.lat}
                                error={this.state.lat_error !== ""}
                                helperText={this.state.lat_error}
                                type="text" onChange={this.onChange} name="lat" label="Latitude" />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextField
                                value={this.state.lng}
                                error={this.state.lng_error !== ""}
                                helperText={this.state.lng_error}
                                type="text" onChange={this.onChange} name="lng" label="Longitude" />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <CustomTextField
                                value={this.state.url}
                                type="text" onChange={this.onChange} name="url" label="Google Maps URL" />
                        </Grid>

                        <Grid item xs={12} md={12} style={{ marginTop: "20px", textAlign: 'center' }}>
                            <Button variant="outlined" className={classes.buttonLight} onClick={this.handleDelete} style={{ marginRight: 15 }}>Delete</Button>
                            <Button type="submit" variant="outlined" className={classes.buttonDark} onClick={this.handleUpdate}>Save</Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }
}

export default withStyles(useStyles)(StoreLocatorDetail) 
