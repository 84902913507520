import React, { Component } from 'react'
import { Grid,
    //  IconButton 
    } from '@material-ui/core';
import Container from '@material-ui/core/Container';
// import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
// import ProfileBG from "assets/img/havilla/profile_bg.png";
// import ProfileBGMobile from "assets/img/havilla/profile_bg_mobile.png";
// import ProfilePicture from "assets/img/havilla/profile_picture_example.png";
// import EditIcon from "assets/img/havilla/edit_btn.svg";
// import DateFnsUtils from "@date-io/date-fns"; // choose your lib
// import PasswordField from "client-side/components/PasswordField/PasswordField";
// import {
//     // DatePicker,
//     // TimePicker,
//     // DateTimePicker,
//     KeyboardDatePicker,
//     MuiPickersUtilsProvider,
// } from '@material-ui/pickers';


import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
// import Datepicker from 'client-side/components/Datepicker/Datepicker';
import OrderCard from 'admin/components/OrderCard/OrderCard';
const useStyles = theme => ({
    ...styles(theme),
    textField: {
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        [theme.breakpoints.up('md')]: {
        },
        width: "80%"
    },
    boxOutline: {
        padding: '1.2rem',
        width: '100%',
        height: '100%'
    },
    iconEdit: {
        marginTop: `-8px`
    }
});


class Order extends Component {

    render() {
        const { classes } = this.props;
        // const {
        //     startDate
        // } = this.state;
        return (
            <Container maxWidth="xl" className={`${classes.container} ${classes.contentContainer}`}>
                <Grid container
                    spacing={2}>
                    <OrderCard></OrderCard>
                </Grid>
            </Container>
        )
    }
}

export default withStyles(useStyles)(Order) 
