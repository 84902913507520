import React, { Component } from 'react'
import Container from '@material-ui/core/Container';
import ProfileBG from "assets/img/havilla/profile_bg.png";
import ProfileBGMobile from "assets/img/havilla/profile_bg_mobile.png";
import ProfilePicture from "assets/img/havilla/default_pp.png";
import TabNavProfile from 'client-side/components/TabNav/TabNavProfile';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import axios from 'axios';
import {
    baseUrl,
    prefix
} from '../../../Environment.js';
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp.js';
import { ClientSideContext } from '../../ClientSideContext';
import { dictionary } from '../../../Dictionary';
const useStyles = theme => ({
    ...styles(theme),
    containerProfile: {
        [theme.breakpoints.down('sm')]: {
            backgroundSize: `cover`,
            backgroundImage: `url(${ProfileBGMobile})`,
        },
        [theme.breakpoints.up('md')]: {
        },
        backgroundImage: `url(${ProfileBG})`,
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        height: `13rem`,
        width: `100%`,
        position: `relative`,
        marginBottom: `2rem`,
    },
    profilePicture: {
        [theme.breakpoints.down('sm')]: {
            left: `0`,
            right: `0`,
            margin: `auto`
        },
        [theme.breakpoints.up('md')]: {
        },
        // backgroundImage: `url(${ProfilePicture})`,
        objectFit: 'cover',
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        height: `8rem`,
        width: `8rem`,
        borderRadius: `100%`,
        position: `absolute`,
        left: `4rem`,
        top: `7rem`
    },
    customerNo: {
        [theme.breakpoints.down('sm')]: {
            width: `auto`,
            position: `relative`,
            textAlign: `center`,
            left: `0`,
            top: `2.5rem`
        },
        [theme.breakpoints.up('md')]: {
        },
        fontWeight: `300`,
        position: `absolute`,
        color: `white`,
        width: `500px`,
        left: `14rem`,
        top: `8.2rem`
    },
    customerName: {
        [theme.breakpoints.down('sm')]: {
            width: `auto`,
            position: `relative`,
            textAlign: `center`,
            left: `0`,
            top: `1.5rem`
        },
        [theme.breakpoints.up('md')]: {
        },
        position: `absolute`,
        color: `white`,
        fontFamily: 'BlaakLight',
        width: `500px`,
        left: `14rem`,
        top: `8.4rem`
    },
    tabContainer: {
        margin: `3rem 1rem 0`,
    }
});


class Profile extends Component {
    static contextType = ClientSideContext;
    // static contextType = GlobalContext;
    constructor(props) {
        super(props)
        this.state = {
            selectedTabId: 0,
            navItems: [
                {
                    title: "My Orders",
                    value: 0,
                    link: `order`
                },
                {
                    title: 'My Account',
                    value: 1,
                    link: `account`
                },
                // {
                //     title: 'My Own Blend',
                //     value: 2,
                //     link: `own_blend`
                // },
                {
                    title: 'My Wishlist',
                    value: 3,
                    link: `wishlist`
                },
                {
                    title: 'Logout',
                    value: 4,
                    link: `logout`
                }
            ],
            preview: '',
            raw: '',
            customerNo: '',
            fullName: '',

            snackbarType: 'info',
            snackbarMessage: '',
            snackbarCounter: 0,
        }
    }

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    componentWillUnmount() {
        window.onscroll = null;
    }


    componentDidMount = () => {
        // console.log(this.context.profilePicture);
        // this.setState({
        //     preview: this.context.profilePicture
        // })
        let authLS = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null
        var customerNo = ""
        var fullName = ""
        if (authLS) {
            customerNo = authLS.user.id;
            fullName = authLS.user.first_name + ' ' + authLS.user.last_name;
        }
        else {
            let path = prefix + `/`;
            this.props.history.push(path);
        }

        this.setState({
            customerNo: customerNo,
            fullName: fullName
        })
        // await axios.get(`${baseUrl}/profile_picture`, requestConfig).then((res) => {
        //     if (res.data.success) {
        //         this.setState({
        //             preview: res.data.data.photo_url
        //         });
        //     } else {
        //         console.log(res.data.message, res.data.data)
        //     }
        // }, (e) => {
        //     console.log("Error : ", e);
        // })
    }

    handleChange = (e) => {
        if (e.target.files.length) {
            // this.context.changeProfilePicture(URL.createObjectURL(e.target.files[0]));
            this.setState({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            }, () => {
                this.handleUpload();
            });
        }
    };

    handleUpload = async (e) => {
        // e.preventDefault();
        const formData = new FormData();
        formData.append("image", this.state.raw);
        const requestConfig = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + (localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).access_token : null),
                'Content-Type': 'multipart/form-data'
            }
        }
        // const headers = [...requestConfig.headers,  {'Content-Type': 'multipart/form-data'}]

        await axios.post(`${baseUrl}/profile/update_profile_pic`, formData, requestConfig).then((res) => {
            if (res.data.success) {

                const authObject = JSON.parse(localStorage.getItem('auth'))
                authObject.user.photo_url = res.data.data.photo_url;
                localStorage.setItem('auth', JSON.stringify(authObject));

                this.handleOpenSnackbar({
                    type: "success",
                    message: "Profile Picture Updated"
                })
                setTimeout(() => { window.location.reload() }, 1500)
                // this.setState({
                //     preview: res.data.data.photo_url,
                // });
            } else {
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            console.log("Error : ", e);
        })


        // await fetch("YOUR_URL", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "multipart/form-data"
        //   },
        //   body: formData
        // });
    }

    handleTabChange = (newValue) => {
        this.setState({
            selectedTabId: newValue
        }, () => {
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.counter}
                />
                <div style={{ background: '#000000' }}>
                    <Container maxWidth="xl" className={`${classes.containerProfile}`}>

                        <img src={localStorage.getItem('auth') ?
                            !JSON.parse(localStorage.getItem('auth')).user.photo_url ?
                                ProfilePicture : JSON.parse(localStorage.getItem('auth')).user.photo_url
                            : ProfilePicture}
                            className={classes.profilePicture} alt="profile pic" />
                        <input
                            className={`${classes.profilePicture} ${classes.linkPointer}`}
                            type="file"
                            id="upload-button"
                            style={{ opacity: 0 }}
                            onChange={this.handleChange}
                        />

                        {/* <div onClick={this.handleUpload} className={`${classes.profilePicture} ${classes.linkPointer}`}></div> */}
                        {/* <button onClick={this.handleUpload}>Upload</button> */}
                        <p className={classes.customerNo}>Customer No : {this.state.customerNo}</p>
                        <h3 className={classes.customerName}>{this.state.fullName}</h3>
                        {
                            this.state.customerNo == 4 ?
                                <Link to={`${prefix}/admin`}>
                                    <p>Go to admin page</p>
                                </Link>
                                :
                                ''
                        }
                    </Container>
                    <Container maxWidth="xl" className={classes.tabContainer}>
                        <TabNavProfile
                            value={this.state.selectedTabId}
                            navItems={this.state.navItems}
                            handleNavChange={this.handleTabChange}
                            prefix={this.props.prefix}
                        ></TabNavProfile>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(Profile) 
