import React, { Component } from 'react'
import { Grid } from '@material-ui/core';

import { Link } from 'react-router-dom';
// import Container from '@material-ui/core/Container';
// import TextField from '@material-ui/core/TextField';
// import axios from 'axios';

// import Datepicker from 'client-side/components/Datepicker/Datepicker';
// import HomeIcon from "assets/img/havilla/home_icon.svg";
import SpecialPrice from "assets/img/havilla/special_price.svg";
import HeartEmpty from "assets/img/havilla/heart_empty.svg";
import HeartFilled from "assets/img/havilla/heart_filled.svg";
import Suddhabumi from "assets/img/havilla/Suddhabumi.png";
import Chamomile from "assets/img/havilla/own_blend_square.jpg";
import {
    baseUrl,
    requestConfig,
    prefix
} from '../../../Environment.js';
import axios from 'axios';
// import Modal from '@material-ui/core/Modal';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import CustomModalAddToCart from '../CustomModals/CustomModalAddToCart.js';
import { ClientSideContext } from '../../ClientSideContext';
import { dictionary } from '../../../Dictionary';
const useStyles = theme => ({
    ...styles(theme),
    textField: {
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        [theme.breakpoints.up('md')]: {
        },
        width: "80%"
    },
    iconEdit: {
        marginTop: `-8px`
    },
    boxOutline: {
        padding: '1.2rem',
        width: '100%',
        height: 'auto',
        maxHeight: '250px'
    },
    productTitle: {
        fontSize: '1.25rem',
    },
    productSubCategory: {
        fontSize: '0.7rem',
        border: '1px solid grey',
        borderRadius: '16px',
        padding: '2px 10px',
        textTransform: 'uppercase',
        float: 'right',
        display: 'inline-block'
    },
    productBackground: {
        background: '#f0f0f0',
        height: 'auto',
        // padding: '0 15px 0 15px',
        // [theme.breakpoints.up('sm')]: {
        //     minHeight: '451px',
        // },
        position: 'relative'
    },
    opacitySoldOut : {
        opacity : '0.6'
    },
    heartIcon: {
        position: 'absolute',
        right: '20px',
        top: "20px",
    },
    productImage: {
        width: '100%',
        height: '100%'
    },
    soldOutText: {
        fontSize: "14px",
        fontFamilty : 'Martel Sans',
        fontWeight : 'bold',
    },
    specialPriceBanner : {
        width : '96px',
        height : '96px',
        position : 'absolute',
    }
});
const borderStandart = {
    border: '1px solid #f0f0f0',
}

export class ProductCard extends Component {
    static contextType = ClientSideContext;
    constructor(props) {
        super(props);
        // console.log(props)

        this.state = {
            modalOpen: false,
            wishlisted: true,
            buttonType: ''
        }

        // console.log(this.props.detail, "product card");
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.modalOpen !== nextState.modalOpen) return true
        if (this.state.wishlisted !== nextState.wishlisted) return true
        if (this.state.buttonType !== nextState.buttonType) return true
        return false;
    }

    handleToggleWishlist = async (product_id, wishlist_id) => {
        await axios.get(`${baseUrl}/toggle_wishlist/${product_id}`,
            requestConfig)
            .then((res) => {
                if (res.data.success) {
                    this.props.handleToggleWishlist(wishlist_id);
                    this.setState({
                        wishlisted: !this.state.wishlisted
                    })
                } else {
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                console.log("Error : ", e);
            })

    }

    handleCloseModal = (newValue) => {
        this.setState({
            modalOpen: newValue
        })
    }

    handleAddToCart = async (own_product_id, buttonType) => {
        const { type } = this.props;

        // if (type !== 'own') {
        // add to cart for reguler product
        this.setState({
            modalOpen: true,
            buttonType: buttonType
        })
        // }
        // else {
        // add to cart for own blend product
        // await axios.post(`${baseUrl}/add_to_cart_own`,
        //     { own_product_id: own_product_id },
        //     requestConfig)
        //     .then((res) => {
        //         if (res.data.success) {
        //             // this.props.handleOpenSnackbar({
        //             //     type: "success",
        //             //     message: "Success Added to Cart"
        //             // })
        //         } else {
        //             console.log(res.data.message, res.data.data)
        //         }
        //     }, (e) => {
        //         console.log("Error : ", e);
        //     })
        // }
    }

    render() {
        const { classes } = this.props;
        // console.log(this.props)
        const { type, detail } = this.props;
        return (
            <React.Fragment>
                {
                    type !== 'own' ?
                        // for product that have sub products
                        <CustomModalAddToCart
                            type={this.state.buttonType}
                            openCart={this.props.openCart}
                            products={detail.product}
                            open={this.state.modalOpen}
                            handleCloseModal={this.handleCloseModal}
                            is_own={0}
                            thumbnail={this.props.thumbnail}
                        ></CustomModalAddToCart>
                        :
                        // for my own blend product
                        <CustomModalAddToCart
                            type={this.state.buttonType}
                            openCart={this.props.openCart}
                            products={detail}
                            open={this.state.modalOpen}
                            handleCloseModal={this.handleCloseModal}
                            is_own={1}
                            thumbnail={this.props.thumbnail}
                        ></CustomModalAddToCart>
                }
                {
                    type !== 'own' ?
                        <Grid container className={`${classes.productBackground} ${detail.product.is_active == 0 ? classes.opacitySoldOut : ''}`}
                        // alignItems="center" justify="center"
                        >
                            {type !== 'own' ?
                                (
                                    detail.wishlisted === true ?
                                        <img onClick={() => this.handleToggleWishlist(detail.product_id, detail.id)}
                                            src={HeartFilled} alt='wishlist icon filled'
                                            className={`${classes.heartIcon} 
                                                ${classes.linkPointer}`}></img>
                                        :
                                        <img onClick={() => this.handleToggleWishlist(detail.product_id, detail.id)}
                                            src={HeartEmpty}
                                            alt='wishlist icon empty'
                                            className={`${classes.heartIcon} ${classes.linkPointer}`}></img>
                                )
                                :
                                ''
                            }
                            {/* <Link
                                to={`/product/${detail.product.url_slug}`}
                            >
                                <img src={Suddhabumi} alt='product img'></img>
                            </Link> */}

                            <Link to={prefix + `/product/${detail.product.url_slug}`}>
                                {
                                    this.props.thumbnail !== null ?
                                        <React.Fragment>
                                            {
                                                detail.product.sub_product_have_discount ?
                                                    <img src={SpecialPrice} className={classes.specialPriceBanner} alt='special price banner'></img>
                                                :
                                                    null
                                            }
                                            <img src={this.props.thumbnail}
                                                className={classes.productImage}
                                                // style={{ maxWidth: '364', maxHeight: '364px' }} 
                                                alt='product img'></img>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {
                                                detail.product.sub_product_have_discount ?
                                                    <img src={SpecialPrice} className={classes.specialPriceBanner} alt='special price banner'></img>
                                                :
                                                    null
                                            }
                                            <img src={Suddhabumi} className={classes.productImage} alt='product img'></img>
                                        </React.Fragment>
                                        // INSERT DEFAULT NO PHOTO HERE
                                }
                            </Link>
                        </Grid>
                        :
                        <Grid container className={classes.productBackground} alignItems="center" justify="center">
                            <img src={Chamomile} className={classes.productImage} alt='product img'></img>
                        </Grid>

                }

                <div className={classes.boxOutline} style={borderStandart}>
                    <Grid container spacing={1}>
                        <Grid item md={7} lg={6} xl={7}>
                            {type !== 'own' ?
                                <Link
                                    className={`${classes.productTitle}`}
                                    to={prefix + `/product/${detail.product.url_slug}`}>
                                    {detail.product.name}
                                </Link>
                                :
                                <p className={`${classes.productTitle}`}>
                                    {detail.name}
                                </p>
                            }
                        </Grid>
                        <Grid item md={5} lg={6} xl={5}>
                            {
                                type !== 'own' ?
                                    detail.product.sub_category_id ?
                                        <span className={classes.productSubCategory}>
                                            {detail.product.product_sub_category.name}
                                        </span> :
                                        <span className={classes.productSubCategory}>
                                            {detail.product.product_category.name}
                                        </span>
                                    :
                                    <span className={classes.productSubCategory}>Unique Blend</span>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {
                                type !== 'own' ?
                                    detail.product ?
                                        <p>{
                                            this.context.language == 'en' ?
                                                detail.product.short_desc_eng.length > 80 ?
                                                    detail.product.short_desc_eng.substring(0, 80) + ' ...'
                                                    :
                                                    detail.product.short_desc_eng
                                                :
                                                detail.product.short_desc_id.length > 80 ?
                                                    detail.product.short_desc_id.substring(0, 80) + ' ...'
                                                    :
                                                    detail.product.short_desc_id
                                        }</p>
                                        : ''
                                    :
                                    <React.Fragment>
                                        a Special Custom Blend By {detail.maker_name}. <br />
                                        Ingredients: &nbsp;
                                        {
                                            detail.own_product_details.map((detail, i) => {
                                                return '[' + detail.own_product_ingredient.name_en + '] '
                                            })
                                        }
                                    </React.Fragment>
                            }
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '15px' }}>
                            {
                                type === 'own' ?
                                    <React.Fragment>
                                        <button
                                            className={`${classes.linkPointerWUnderline} ${classes.link} ${classes.linkStyledButton}`}
                                            onClick={() => this.handleAddToCart(detail.id, "atc")}
                                        >
                                            {dictionary['Add to Cart'][this.context.language]}
                                            {/* Add to Cart */}
                                        </button>
                                        <button
                                            className={`${classes.linkPointerWUnderline} ${classes.link} ${classes.linkStyledButton}`}
                                            onClick={() => this.handleAddToCart(detail.product.id, "gift",detail.product.is_active)}
                                            style={{ marginLeft: '15px' }}
                                        >
                                            {dictionary['Send as Gift'][this.context.language]}
                                            {/* Send as Gift */}
                                        </button>
                                    </React.Fragment>
                                :
                                    detail.product.is_active == 0 ?
                                        <p className={`${classes.soldOutText}`}>{dictionary['SOLD OUT'][this.context.language]}</p>
                                    :
                                        <React.Fragment>
                                            <button
                                                className={`${classes.linkPointerWUnderline} ${classes.link} ${classes.linkStyledButton}`}
                                                onClick={() => this.handleAddToCart(detail.product.id, "atc", detail.product.is_active)}
                                            >
                                                {dictionary['Add to Cart'][this.context.language]}
                                                {/* Add to Cart */}
                                            </button>
                                            <button
                                                className={`${classes.linkPointerWUnderline} ${classes.link} ${classes.linkStyledButton}`}
                                                onClick={() => this.handleAddToCart(detail.product.id, "gift",detail.product.is_active)}
                                                style={{ marginLeft: '15px' }}
                                            >
                                                {dictionary['Send as Gift'][this.context.language]}
                                                {/* Send as Gift */}
                                            </button>
                                        </React.Fragment>
                                }
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(ProductCard) 
