import React, {
    createContext,
    useReducer,
    useEffect, 
} from 'react'
import { adminReducer } from './adminReducer';

export const AdminContext = createContext();

const AdminContextProvider = (props) => {
    const [toggleBackdrop, dispatchToggleBackdrop] = useReducer(adminReducer, false)
    useEffect(() => {
    }, [toggleBackdrop])

    const [toggleSnackbar, dispatchToggleSnackbar] = useReducer(adminReducer, false)
    useEffect(() => {
    }, [toggleSnackbar])

    const [snackbarContent, dispatchSnackbarContent] = useReducer(adminReducer, false)
    useEffect(() => {
    }, [snackbarContent])


    return (
        <AdminContext.Provider value={{
            toggleBackdrop,
            toggleSnackbar,
            snackbarContent,

            dispatchToggleBackdrop,
            dispatchToggleSnackbar,
            dispatchSnackbarContent
        }}>
            {props.children}
        </AdminContext.Provider>
    )
}

export default AdminContextProvider
