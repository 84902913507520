import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import { Container, Grid, Button } from '@material-ui/core';
import TeapotIcon from "assets/img/havilla/own-teapot-icon.svg";
import ChooseIcon from "assets/img/havilla/own-choosetopping-icon.svg";
import CustomizeIcon from "assets/img/havilla/own-customize-icon.svg";
import { Link } from 'react-router-dom';
import { dictionary } from 'Dictionary';
import { ClientSideContext } from '../../ClientSideContext';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
    ...styles(theme),
    headlineTitle: {
        fontFamily: 'BlaakLight',
        fontSize: '28px',
    },
    containerHeadlineHow: {
        marginTop: '2rem',
        textAlign: 'center'
    },
    headlineText: {
        fontFamily: 'Martel Sans',
        fontSize: '16px',
    },
    howImgContainer: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'right',
        },
    },
    titleIcon: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            marginTop: '0px',
        },
        marginTop: '20px',
        fontWeight: 'bold',
        fontSize: '16px',
        textAlign: 'center'
    },
    titleIconPoint1: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            marginTop: '0px',
            minHeight: '0px',
        },
        minHeight: '44.8px',
        marginTop: '20px',
        fontWeight: 'bold',
        fontSize: '16px',
        textAlign: 'center'
    },
    descIcon: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
        fontSize: '16px',
        marginTop: '16px',
    },
    howContentContainer: {
        marginTop: '30px',
    },
    containerHowIconContent: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '30px'
        },
    },
    noteText: {
        marginTop: '48px',
        fontFamily: 'Martel Sans',
        fontSize: '12px',
    },
    buttonOkContainer: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: '2rem',
        },
        margin: "30px 0"
    }
}));

function PageHow(props) {
    const classes = useStyles();
    const { language } = useContext(ClientSideContext)

    return (
        <Container maxWidth="xl" className={`${classes.container}`}>
            <Fade in={true} timeout={700}>
                <Grid
                    className={classes.containerHeadlineHow}
                    container
                    spacing={2}
                    justify="center"
                    alignItems="center"
                >
                    <Grid item sm={12} className={classes.headlineTitle}>
                        {dictionary['Own Blend How Headline'][language]}
                    </Grid>
                    <Grid item xs={11} className={classes.headlineText}>
                        {dictionary['Own Blend How Sub Headline'][language]}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}
                        container
                        justify='center'
                        alignItems='flex-start'
                        spacing={2}
                        className={classes.howContentContainer}
                    >
                        <Grid item xs={12} md={3} container justify="center" alignItems='flex-start'>
                            <Grid container item xs={4} md={12} justify="center" >
                                <img src={TeapotIcon} alt="teapot icon"></img>
                            </Grid>
                            <Grid container item xs={8} md={12} justify="center">
                                <Grid item md={4} xs={12} className={classes.titleIconPoint1}>
                                    {dictionary['Own Blend How Point 1 Title'][language]}
                                </Grid>
                                <Grid item md={10} xs={12} className={classes.descIcon}>
                                    {dictionary['Own Blend How Point 1 Desc'][language]}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} container justify="center" alignItems='flex-start' className={classes.containerHowIconContent}>
                            <Grid container item xs={4} md={12} justify="center">
                                <img src={ChooseIcon} alt="choose icon"></img>
                            </Grid>
                            <Grid container item xs={8} md={12} justify="center">
                                <Grid item md={5} xs={12} className={classes.titleIcon}>
                                    {dictionary['Own Blend How Point 2 Title'][language]}
                                </Grid>
                                <Grid item md={10} xs={12} className={classes.descIcon}>
                                    {dictionary['Own Blend How Point 2 Desc'][language]}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} container justify="center" alignItems='flex-start' className={classes.containerHowIconContent}>
                            <Grid container item xs={4} md={12} justify="center">
                                <img src={CustomizeIcon} alt="customize icon"></img>
                            </Grid>
                            <Grid container item xs={8} md={12} justify="center">
                                <Grid item md={6} xs={12} className={classes.titleIcon}>
                                    {dictionary['Own Blend How Point 3 Title'][language]}
                                </Grid>
                                <Grid item md={10} xs={12} className={classes.descIcon}>
                                    {dictionary['Own Blend How Point 3 Desc'][language]}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={11} className={classes.noteText}>
                        {dictionary['Own Blend How Notes'][language]}<br/>
                        {dictionary['Own Blend How Notes 2'][language]}
                        <b>{dictionary['Days Own Blend'][language]}</b><br/>
                        {dictionary['Own Blend How Notes 4'][language]}<br/>
                        {dictionary['Own Blend How Notes 3'][language]}
                    </Grid>
                    <Grid item xs={11} className={classes.buttonOkContainer}>
                        <Link to={`${props.prefix}/create_your_own/create`}>
                            <Button variant="outlined" className={`${classes.buttonDark}`}>
                                {dictionary['Own Blend How Button'][language]}
                            </Button>
                        </Link>
                    </Grid>

                </Grid>
            </Fade>
        </Container>
    )
}

export default PageHow
