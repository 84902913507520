/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import {
  Switch, Route, Redirect,
  // Router 
} from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "admin/components/Navbars/Navbar.js";
import Footer from "admin/components/Footer/Footer.js";
import Sidebar from "admin/components/Sidebar/Sidebar.js";
// import FixedPlugin from "admin/components/FixedPlugin/FixedPlugin.js";

import routes from "admin/routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import HavillaLogo from "assets/img/havilla/floral.svg";
import bgImage from "assets/img/sidebar-2.jpg";
import AdminContextProvider from 'admin/AdminContext';
import BackdropComp from "admin/components/Backdrop/BackdropComp";
import SnackbarComp from "admin/components/Snackbar/SnackbarComp";
import axios from "axios";
import { baseUrl } from "Environment";
import { requestConfigAdmin } from "Environment";
import { refreshHeaderAdmin } from "Environment";
import {
  prefix
} from '../../Environment.js';

let ps;


const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route exact
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from={`${prefix}/admin`} to={`${prefix}/admin/orders`} />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {

  const [auth, setAuth] = useState(localStorage.getItem('auth_admin') ? JSON.parse(localStorage.getItem('auth_admin')) : null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  if (auth === null) {
    let path = prefix + `/admin/login`;
    rest.history.push(path);
  } else {
    if (!isLoggedIn) {
      // check admin or not
      axios.get(`${baseUrl}/check_user`, requestConfigAdmin).then((res) => {
        if (res.data.success) {
          if (!res.data.data.result) {
            localStorage.removeItem('auth_admin');
            refreshHeaderAdmin();
            let path = prefix + `/admin/login`;
            rest.history.push(path);
          } else {
            setIsLoggedIn(true);
          }
        } else {
          console.log(res.data.message, res.data.data)
        }
      }, (e) => {
        console.log("Error : ", e);
      })
    }
  }
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  // const [image, setImage] = React.useState(bgImage);
  // const [color, setColor] = React.useState("blue");
  // const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // const handleImageClick = image => {
  //   setImage(image);
  // };
  // const handleColorClick = color => {
  //   setColor(color);
  // };
  // const handleFixedClick = () => {
  //   if (fixedClasses === "dropdown") {
  //     setFixedClasses("dropdown show");
  //   } else {
  //     setFixedClasses("dropdown");
  //   }
  // };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  return (
    <AdminContextProvider>
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={"HAVILLA"}
          logo={HavillaLogo}
          image={bgImage}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color="blue"
          {...rest}
        />
        <div id="perfect-scrollbar-container" className={classes.mainPanel} ref={mainPanel}>
          <Navbar
            routes={routes}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />
          <SnackbarComp></SnackbarComp>
          <BackdropComp></BackdropComp>
          {
            isLoggedIn ?
              <React.Fragment>
                {getRoute() ? (
                  <div className={classes.content}>
                    <div className={classes.container}>{switchRoutes}</div>
                  </div>
                ) : (
                    <div className={classes.map}>{switchRoutes}</div>
                  )}
                {getRoute() ? <Footer /> : null}
              </React.Fragment>
              :
              null
          }
          {/* <FixedPlugin
              handleImageClick={handleImageClick}
              handleColorClick={handleColorClick}
              bgColor={color}
              bgImage={image}
              handleFixedClick={handleFixedClick}
              fixedClasses={fixedClasses}
            /> */}
        </div>
      </div>
    </AdminContextProvider>
  );
}
