import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    img: {
        // width: '100%',
        width : '650px',
        height: '300px',
        // height: '100%',
        [theme.breakpoints.down('sm')]: {
            width : '80vw',
            minWidth: '260px',
            minHeight: '140px',
            maxWidth: '650px',
            maxHeight: '300px',
        },
        [theme.breakpoints.down('xs')]: {
            width : '80vw',
            height : '140px',
            minWidth: '260px',
            minHeight: '140px',
            maxWidth: '650px',
            maxHeight: '300px',
        },
        // maxHeight: '300px',
        // maxWidth: '650px',
    },
    articleTitle: {
        fontFamily: 'Martel Sans',
        fontSize: '24px',
        textAlign: 'left',
        padding: '14px 32px 0px 32px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        },
    },
    articleDesc: {
        fontFamily: 'Martel Sans',
        fontSize: '16px',
        textAlign: 'left',
        padding: '24px 32px 0px 32px',
    },
    postContainer: {
        // [theme.breakpoints.down('sm')]: {
        //     marginRight: '15px',
        // },
        // [theme.breakpoints.up('sm')]: {
        //     marginLeft: '15px',
        // },
        marginRight: '25px',
        maxWidth: '650px',
        width: 'auto',
        backgroundColor : '#121212'
    },
    articleLink: {
        fontFamily: 'Martel Sans',
        fontSize: "14px",
        textDecorationLine: 'underline',
        color: '#AF986D',
        '&:hover': {
            color: 'white'
            // textDecorationLine: 'underline',
        }
        // '&:hover' : {
        //     textDecorationLine: 'underline',
        // }
    },
    articleLinkContainer: {
        textAlign: 'left',
        padding: '17px 32px 0px 32px',
    },
    emptyLastItem: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        marginLeft: '0rem',
    },
    borderBox: {
        // [theme.breakpoints.up('sm')]: {
        marginTop: '-8px',
        // },
        // border: '1px solid #f4f4f4',
        paddingBottom: 20,
        width: '100%'
    }
}));

function PostLarge(props) {
    const classes = useStyles();

    return (
        <Grid className={`${classes.postContainer}`}
            container
            justify='space-between'
            alignItems='flex-start'
            direction='column'
        >
            <Grid item xs={12} >
                <img className={classes.img} src={props.img} alt={props.imgAlt} />
            </Grid>
            <Grid item xs={12} className={classes.borderBox}>
                <Grid className={classes.articleTitle} item xs={12}>
                    {props.title}
                </Grid>
                <Grid className={classes.articleDesc} item xs={12}>
                    {props.desc}
                </Grid>
                <Grid className={classes.articleLinkContainer} item xs={12}>
                    <Link to={props.linkTo} className={classes.articleLink}>
                        {props.linkText}
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PostLarge
