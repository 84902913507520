import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Banner from "assets/img/havilla/about_us_image.jpg";
import HavillaGiftsIcon from "assets/img/havilla/about_us_havilla_gifts.svg";
import PartnershipIcon from "assets/img/havilla/about_us_partnership.svg";
import AlarmIcon from "assets/img/havilla/about_us_alarm.svg";
import axios from 'axios';
import Fade from '@material-ui/core/Fade';

import LogosAston from "assets/img/havilla/partners_logo/Partner-LogosAston-Madiun.png";
import LogosBatik from "assets/img/havilla/partners_logo/Partner-LogosBatik-Keris.png";
import LogosBCA from "assets/img/havilla/partners_logo/Partner-LogosBCA-PRIORITAS.png";
import LogosCommon from "assets/img/havilla/partners_logo/Partner-LogosCommon-Grounds.png";
import LogosHayati from "assets/img/havilla/partners_logo/Partner-LogosHayati.png";
import LogosJambuluwuk from "assets/img/havilla/partners_logo/Partner-LogosJambuluwuk.png";
import LogosAnchor from "assets/img/havilla/partners_logo/Partner-LogosAnchor.png";
import LogosDomicile from "assets/img/havilla/partners_logo/Partner-LogosDomicile.png";
import LogosKopi from "assets/img/havilla/partners_logo/Partner-LogosKopi-Toko-Djawa.png";
import LogosSoSoGood from "assets/img/havilla/partners_logo/Partner-LogosSoSoGood.png";
import LogosTanamera from "assets/img/havilla/partners_logo/Partner-LogosTanamera.png";
import LogosTinkerlust from "assets/img/havilla/partners_logo/Partner-LogosTinkerlust.png";
import LogosUber from "assets/img/havilla/partners_logo/Partner-LogosUber.png";

import LogosDharma from "assets/img/havilla/partners_logo/Partner-LogosDharmawangsa.png";
import LogosFloat from "assets/img/havilla/partners_logo/Partner-LogosFloat.png";
import LogosDanamon from "assets/img/havilla/partners_logo/Partner-LogosDanamon.png";
import LogosBriP from "assets/img/havilla/partners_logo/Partner-LogosBriP.png";
import LogosBluedoors from "assets/img/havilla/partners_logo/Partner-LogosBluedoors.png";

import AboutUsMore from 'client-side/views/AboutUs/AboutUsMore';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import { dictionary } from '../../../Dictionary';
import { ClientSideContext } from '../../ClientSideContext';

import {
    baseUrl,
    requestConfig,
    prefix
} from '../../../Environment.js';
import { Backdrop, CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { SpaOutlined } from '@material-ui/icons';

const useStyles = theme => ({
    ...styles(theme),
    containerLogin: {
    },
    banner: {
        width: '100%'
    },
    textField: {
        width: "80%",
    },
    menuIcon: {
        margin: 'auto',
        display: 'block'
    },
    marqueeIcon: {
        float: 'left',
        marginRight: 50,
        width: '120px',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    questionBox: {
        border: '1px solid grey',
        padding: 25,
        margin: '45px 10px 25px 10px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0
        }
    },
    moreContainer: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: 10
        },
        marginTop: 50
    },
    mobileLogo: {
        margin: 'auto',
        display: 'block',
        align: 'center',
        width: '100%'
    },
    heading1: {
        color: 'white',
        fontFamily: 'Blaaklight',
        '& span': {
            color: 'white',
            fontFamily: 'Blaaklight'
        },
        '& p': {
            color: 'white',
            fontFamily: 'Blaaklight'
        }
    },
    heading2: {
        color: 'white',
        fontFamily: 'Blaaklight',
        '& p': {
            color: 'white',
            fontFamily: 'Blaaklight'
        },
        '& span': {
            color: 'white',
            fontFamily: 'Blaaklight'
        }
    },
});

export class AboutUs extends Component {
    static contextType = ClientSideContext;

    constructor(props) {
        super(props);

        this.state = {
            openBackdrop: false,

            email: '',
            password: '',
            cms: {},
            load_cms: false
        }
    }

    componentDidMount() {
        this.getCmsContent()
    }

    componentDidUpdate() {
    }

    getCmsContent = () => {
        this.setState({ openBackdrop: true });

        axios.get(`${baseUrl}/cms`,
            requestConfig
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState({
                    cms: data,
                    load_cms: true
                })
                this.setState({ openBackdrop: false });
            } else {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${res.data.message}`
                })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            console.log("Error : ", e);
        })
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value })
    render() {
        const { classes } = this.props;
        return (
            <Container maxWidth="xl" className={`${classes.container}`}>
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{ 'color': '#AF986D' }} />
                </Backdrop>
                <Container maxWidth="lg">
                    <Fade in={this.state.load_cms} timeout={700}>
                        <Grid container spacing={8}>
                            <Grid item md={12} xs={12}>
                                <h3 className={classes.smallerTitle} style={{ textAlign: 'center' }}>
                                    {this.state.cms.about_us_heading_1 ?
                                        this.context.language === 'id' ?
                                            <span className={classes.heading1}
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_heading_1.content_id }}>
                                            </span>
                                            :
                                            <span className={classes.heading1}
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_heading_1.content_eng }}>
                                            </span>
                                        :
                                        null
                                    }
                                </h3>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <img className={classes.banner} src={this.state.cms.about_us_image ? JSON.parse(this.state.cms.about_us_image.photo_urls)[0] : ''} alt="banner"></img>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <h3 className={classes.smallerTitle}>
                                    {this.state.cms.about_us_heading_2 ?
                                        this.context.language === 'id' ?
                                            <span className={classes.heading2}
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_heading_2.content_id }}>
                                            </span>
                                            :
                                            <span className={classes.heading2}
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_heading_2.content_eng }}>
                                            </span>
                                        :
                                        null
                                    }
                                </h3>
                                <p style={{ fontSize: '16px', lineHeight: '25px', textAlign: 'justify' }}>
                                    {this.state.cms.about_us_paragraph ?
                                        this.context.language === 'id' ?
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_paragraph.content_id }}>
                                            </span>
                                            :
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_paragraph.content_eng }}>
                                            </span>
                                        :
                                        null
                                    }
                                </p>

                                <Link to={`${prefix}/about_us/detail`}>
                                    <Button variant="outlined" className={`${classes.buttonDark}`} style={{ marginTop: 15 }}>
                                        {dictionary['Read their Story'][this.context.language]}
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Fade>

                    <Grid container spacing={8}>
                        <Grid item md={12} xs={12}>
                            <h3 className={classes.smallerTitle} style={{ textAlign: 'center' }}>
                                {dictionary['Build Partnership with Us'][this.context.language]}
                            </h3>
                            <p style={{ textAlign: 'center', marginBottom: 25 }}
                            >
                                {dictionary['These brands put their trust on us'][this.context.language]}
                                {/* These brands put their trust on us. Now it’s your time */}
                            </p>
                            <Box display={{ sm: 'block', md: 'none' }}>
                                <Grid container spacing={3}>
                                    {this.state.cms.partner_logos ?
                                        JSON.parse(this.state.cms.partner_logos.photo_urls).map((logo, i) => {
                                            return (
                                                <Grid item xs={6} md={4} key={i}>
                                                    <img src={logo} className={classes.mobileLogo}></img>
                                                </Grid>
                                            )
                                        })
                                        :
                                        ""
                                    }
                                </Grid>
                            </Box>
                            <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
                                <marquee behavior="scroll" direction="left" style={{ width: '155%' }}>
                                    {this.state.cms.partner_logos ?
                                        JSON.parse(this.state.cms.partner_logos.photo_urls).map((logo, i) => {
                                            return (
                                                <img src={logo} className={classes.marqueeIcon} key={i}></img>
                                            )
                                        })
                                        :
                                        ""
                                    }
                                </marquee>
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <img src={HavillaGiftsIcon} className={classes.menuIcon} alt="HavillaGiftsIcon"></img>
                            <h5 className={classes.smallTitle} style={{ textAlign: 'center', marginTop: 20 }}>
                                {this.state.cms.about_us_build_havilla_gifts_heading ?
                                    this.context.language === 'id' ?
                                        <span
                                            dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_build_havilla_gifts_heading.content_id }}>
                                        </span>
                                        :
                                        <span
                                            dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_build_havilla_gifts_heading.content_eng }}>
                                        </span>
                                    :
                                    null
                                }
                            </h5>
                            <p style={{ textAlign: 'center', marginBottom: 20 }}>
                                {this.state.cms.about_us_build_havilla_gifts_paragraph ?
                                    this.context.language === 'id' ?
                                        <span
                                            dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_build_havilla_gifts_paragraph.content_id }}>
                                        </span>
                                        :
                                        <span
                                            dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_build_havilla_gifts_paragraph.content_eng }}>
                                        </span>
                                    :
                                    null
                                }
                            </p>
                            <a href={`${prefix}/havilla_gifts`}>
                                <p className={classes.linkPointerWUnderline} style={{ textAlign: 'center' }}>
                                    {dictionary['Havilla Gifts CTA'][this.context.language]}
                                </p>
                            </a>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <img src={PartnershipIcon} className={classes.menuIcon} alt="PartnershipIcon"></img>
                            <h5 className={classes.smallTitle} style={{ textAlign: 'center', marginTop: 20 }}>
                                {this.state.cms.about_us_build_partnership_heading ?
                                    this.context.language === 'id' ?
                                        <span
                                            dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_build_partnership_heading.content_id }}>
                                        </span>
                                        :
                                        <span
                                            dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_build_partnership_heading.content_eng }}>
                                        </span>
                                    :
                                    null
                                }
                            </h5>
                            <p style={{ textAlign: 'center', marginBottom: 20 }}>
                                {this.state.cms.about_us_build_partnership_paragraph ?
                                    this.context.language === 'id' ?
                                        <span
                                            dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_build_partnership_paragraph.content_id }}>
                                        </span>
                                        :
                                        <span
                                            dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_build_partnership_paragraph.content_eng }}>
                                        </span>
                                    :
                                    null
                                }
                            </p>
                            <a href={`${prefix}/havilla_partnership`}>
                                <p className={classes.linkPointerWUnderline} style={{ textAlign: 'center' }}>
                                    {dictionary['Partnership & Wholesale CTA'][this.context.language]}
                                </p>
                            </a>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.questionBox}>
                        <Grid item md={1} xs={12}>
                            <img src={AlarmIcon} alt="AlarmIcon"></img>
                        </Grid>

                        <Grid item md={8} xs={12}>
                            <h5 className={classes.smallTitle}>
                                {dictionary['Got Questions'][this.context.language]}
                            </h5>
                            <p>
                                {dictionary['Got Questions 2'][this.context.language]}
                            </p>
                        </Grid>

                        <Grid item md={3} xs={12}>
                            <Link to={`${prefix}/about_us/faq`}>
                                <Button variant="outlined" className={`${classes.buttonDark}`} style={{ marginTop: 5 }}>

                                    {dictionary['Got Questions Button'][this.context.language]}
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>

                    <AboutUsMore>
                    </AboutUsMore>

                </Container>
            </Container >
        )
    }
}

export default withStyles(useStyles)(AboutUs) 
