import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core";
import React from "react";
import { useStyles } from "./CustomSelect.styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const CustomSelect = (props) => {
	const classes = useStyles();
	const {
		id,
		label,
		name,
		options,
		control,
		rules,
		language,
		error,
		helperText,
		selectClasses,
		value,
		hasSearch,
		...otherSelectProps
	} = props;

	if (!!hasSearch) {
		return (
			<Autocomplete
				{...otherSelectProps}
				id={name}
				name={name}
				options={["", ...options.map((option) => `${option.value}`)]}
				renderOption={(option) =>
					option == "" ? (
						<>
							<em>None</em>
						</>
					) : (
						options.find((optionObj) => optionObj.value == option)
							.text
					)
				}
				filterOptions={(optionIds, { inputValue }) => {
					const filteredOptions = optionIds.filter((optionId) => {
						const optionObj = options.find(
							(option) => optionId == option.value
						);
						if (!!optionObj) {
							return (
								optionObj.text.search(
									new RegExp(inputValue, "i")
								) > -1
							);
						} else {
							return true;
						}
					});
					return filteredOptions;
				}}
				value={value}
				renderInput={({
					inputProps: { value, ...otherInputProps },
					...otherParams
				}) => {
					const optionObj = options.find(
						(option) => option.value == value
					);
					return (
						<TextField
							label={label}
							{...otherParams}
							inputProps={{ ...otherInputProps }}
							{...otherSelectProps}
							value={(!!optionObj && optionObj.text) || value}
						/>
					);
				}}
			/>
		);
	} else {
		return (
			<FormControl
				key={id}
				className={classes.formControl}
				error={error}
				id={name}
				name={name}
				{...otherSelectProps}
			>
				<InputLabel id={`${id}Label`}>{label}</InputLabel>
				<Select
					key={id}
					labelId={`${id}Label`}
					id={name}
					label={label}
					{...otherSelectProps}
					classes={selectClasses}
					value={value}
				>
					<MenuItem value="">
						<em>None</em>
					</MenuItem>
					{options.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option[`text`]}
						</MenuItem>
					))}
				</Select>
				<FormHelperText>{helperText}</FormHelperText>
			</FormControl>
		);
	}
};

export default CustomSelect;
