
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {isMobile} from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    itemTab : {
        fontFamily: 'Martel Sans',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '16px',
    }
}));

const CustomTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        opacity : 0.5,
        color : 'white',
        '&:hover': {
            opacity: 1,
        },
        '&$selected': {
            opacity: 1,
            color : '#AF986D',
        },
        '& span' :{
            width : 'auto',
        },
        width : 'auto',
        minWidth : '0px',
        paddingRight : '20px',
        paddingLeft : '20px'
    },
    selected: {

    },
}))((props) => <Tab disableRipple {...props} />);

const CustomTabs = withStyles({
    indicator: {
        display: 'none'
    },
})(Tabs);
export default function TabNavSmall(props) {
    const classes = useStyles();

    const handleChange = (e, newValue) => {
        e.preventDefault()
        props.handleNavChange(e,newValue)
    };

    return (
        <React.Fragment>
            <CustomTabs 
                style={props.styles}
                className={classes.tabNav} 
                value={props.value}
                onChange={handleChange} 
                variant = {isMobile ? 'scrollable' : props.variant}
                scrollButtons = "auto"
                centered={isMobile ? false : props.centered}
            >
                {
                    props.navItems.map(item=>{
                        return <CustomTab key={item.id} value={item.id} className={classes.itemTab} disableRipple label={item.name}/>
                    })
                }
            </CustomTabs>
        </React.Fragment>  
    );
}

TabNavSmall.defaultProps = {
    styles : {},
    variant: isMobile ? 'scrollable' : 'standard',
    centered : false
}