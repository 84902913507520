import React from 'react'
import "./MenuIconCustom.css"

function MenuIconCustom() {
    return (
        <div id="menu_icon" className="btn not-active">
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export default MenuIconCustom

            