import React, { Component } from 'react'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import { baseUrl, requestConfigAdmin } from 'Environment';
import { withStyles, Grid, FormHelperText } from '@material-ui/core';
import globalStyles from "assets/jss/client-side/App.js";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import GridItem from 'admin/components/Grid/GridItem';
import Card from "admin/components/Card/Card.js";
import CardHeader from "admin/components/Card/CardHeader.js";
import CardBody from "admin/components/Card/CardBody.js";
import GridContainer from 'admin/components/Grid/GridContainer';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField';
import CardFooter from 'admin/components/Card/CardFooter';
import Button from "admin/components/CustomButtons/Button.js";
import {
    prefix
} from '../../../Environment.js';
import CloseIcon from "assets/img/havilla/close_icon_black.svg";
import CustomModalWImage from 'client-side/components/CustomModals/CustomModalWImage';
import { Link } from 'react-router-dom';

const useStyles = theme => ({
    ...globalStyles(theme),
    ...dashboardStyle,
    imgPreview: {
        cursor: 'pointer',
        height: '250px',
        maxWidth : '530px',
        '&:hover + .delete-button': {
            marginTop: '-17px',
        }
    },
    deletePict: {
        transition: 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)',
        marginTop: '-47px',
        display: 'block',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            marginTop: '-17px',
        },
    },
    tempPictContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: '30px',
        overflow: 'hidden',
        '&:hover .delete-button': {
            marginTop: '-17px',
        }
    },
});

const required_fields = [
    'title',
    'content',
    'link_to',
    'link_text',
    'image_url',
]

export class BannerPopUp extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props)

        this.state = {
            title: "",
            title_error: "",
            content: "",
            content_error: "",
            link_to: "",
            link_to_error: "",
            link_text: "",
            link_text_error: "",
            image_url: "",
            image_url_error: "",
            count_for_update: 0,

            preview_open : false
        }

        this.title_ref = React.createRef();
        this.content_ref = React.createRef();
        this.link_to_ref = React.createRef();
        this.link_text_ref = React.createRef();
        this.image_url_ref = React.createRef();
    }

    handleCloseModal = (newValue) => {
        this.setState({
            preview_open: newValue
        }, () => {
        })
    }

    handleOpenModal = () =>{
        this.setState({
            preview_open: true
        }, () => {
        })
    }

    getLatestBanner = () => {
        this.setState((prevState => ({
            count_for_update: prevState.count_for_update + 1
        })))
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        axios.get(`${baseUrl}/banner_pop_up/get_latest`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    ...res.data.data,
                }, () => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    scrolToNonInput = (ref) => {
        const container = document.querySelector('#perfect-scrollbar-container');
        container.scrollTop = ref.current.offsetTop;
    }

    handleValidation = () =>{
        let is_ok = true;
        for (let i = 0; i < required_fields.length; i++) {
            let field_name = required_fields[i];
            let field_name_text = required_fields[i].replace('_',' ');
            if(this.state[field_name] === "" || this.state[field_name] === null || this.state[field_name].length === 0){
                is_ok = false;
                if (field_name === 'image_url') {
                    this.scrolToNonInput(this[field_name + "_ref"]);
                } else {
                    this[field_name + "_ref"].current.focus();
                }
                this.setState({ [field_name + "_error"]: field_name_text + " is required" });
            }
        }
        return is_ok;
    }

    handleActivateBannerPopUp = () =>{
        let is_ok = this.handleValidation();

        if (is_ok) {
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
            let requestConfigAdd = {
                ...requestConfigAdmin.headers,
                'Content-Type': 'multipart/form-data'
            }

            let formData = new FormData();
            formData.append('title', this.state.title);
            formData.append('content', this.state.content);
            formData.append('link_to', this.state.link_to);
            formData.append('link_text', this.state.link_text);
            formData.append('image_url', this.state.image_url);

            axios.post(`${baseUrl}/banner_pop_up/activate`,
                formData,
                requestConfigAdd
            ).then((res) => {
                if (res.data.success) {
                    this.getLatestBanner();
                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "success",
                        message: "Banner Activated!"
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                } else {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: res.data.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: "Error : "+ e.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log("Error : ", e);
            })
        }
    }

    onChangeImages = (e) => {
        if (e.target.files.length > 0) {
            let file = e.target.files[0];
            this.setState({
                [e.target.name]: file
            }, () => {
            })
        }
    }

    handleDeleteTempImg = (ref) => {
        this.setState({
            [ref]: null
        }, () => {
            if (this.state[ref] === null) {
                document.getElementById(ref).value = "";
            }
        });
    }

    componentDidMount() {
        this.getLatestBanner();
    }

    componentDidUpdate(nextProps, nextState) {
        for (let i = 0; i < required_fields.length; i++) {
            let required_field = required_fields[i];
            if (this.state[required_field + "_error"] !== "") {
                if (this.state[required_field] !== nextState[required_field]) {
                    this.setState({ [required_field + "_error"]: "" });
                }
            }
        }

        return true;
    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                <CustomModalWImage
                    open={this.state.preview_open}
                    handleCloseModal={this.handleCloseModal}
                    content={this.state.content}
                    link_to={this.state.link_to}
                    link_text={this.state.link_text}
                    title={this.state.title}
                    img={typeof this.state.image_url === 'object' && this.state.image_url !== null ? URL.createObjectURL(this.state.image_url) : `${this.state.image_url}?v=${this.state.count_for_update}`}
                />
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="gray">
                            <h4 className={classes.cardTitleWhite}>Active Banner Pop Up</h4>
                            <p>Manage Active Banner Pop Up</p>
                        </CardHeader>
                        <CardBody>
                        <GridContainer>
                                <GridItem container item sm={12} md={6}>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            value={this.state.title}
                                            error={this.state.title_error !== ""}
                                            helperText={this.state.title_error}
                                            inputRef={this.title_ref}
                                            type="text" onChange={this.onChange} name="title" label="Title" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            label="Content"
                                            name="content"
                                            otherOptions={{
                                                multiline: true,
                                                rows: 3
                                            }}
                                            error={this.state.content_error !== ""}
                                            helperText={this.state.content_error}
                                            onChange={this.onChange}
                                            value={this.state.content === null ? '' : this.state.content}
                                            inputRef={this.content_ref}
                                        />
                                    </GridItem>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            value={this.state.link_text}
                                            error={this.state.link_text_error !== ""}
                                            helperText={this.state.link_text_error}
                                            inputRef={this.link_text_ref}
                                            type="text" onChange={this.onChange} name="link_text" label="Button Text" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            value={this.state.link_to}
                                            error={this.state.link_to_error !== ""}
                                            helperText={this.state.link_to_error}
                                            inputRef={this.link_to_ref}
                                            type="text" onChange={this.onChange} name="link_to" label="Link To" />
                                    </GridItem>
                                </GridItem>
                                <GridItem container item sm={12} md={6}>
                                    <Grid item xs={12}>
                                        <FormHelperText>Banner Image (Recommended Size : 300 x 400)</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} container alignItems='center'>
                                        <input
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="image_url"
                                            name='image_url'
                                            type="file"
                                            onChange={this.onChangeImages}
                                        />
                                        <label htmlFor="image_url" ref={this.image_url_ref} style={{ marginRight: '15px' }}>
                                            <Button color="info" component="span">
                                                Upload Banner Image
                                            </Button>
                                        </label>
                                        <FormHelperText error style={{ textTransform: 'capitalize' }}>{this.state.image_url_error}</FormHelperText>
                                    </Grid>
                                    <Grid item container md={12} xs={12} style={{ height: '310px' }}>
                                        {
                                            this.state.image_url != null ?
                                                <div className={classes.tempPictContainer}>
                                                    {
                                                        typeof this.state.image_url === 'object' && this.state.image_url !== null ?
                                                            <img className={classes.imgPreview} alt={`preview ${this.state.image_url.name}`} src={URL.createObjectURL(this.state.image_url)} />
                                                            :
                                                            <img className={classes.imgPreview} alt={`preview banner`} src={`${this.state.image_url}?v=${this.state.count_for_update}`} />
                                                    }
                                                    <div className={`${classes.deletePict} delete-button`} >
                                                        <img src={CloseIcon} alt="close icon" onClick={() => this.handleDeleteTempImg('image_url')}></img>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </Grid>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Grid container>
                                <Grid md={2}> 
                                    <Button color="warning" onClick={this.handleOpenModal}>Preview Banner</Button>
                                </Grid>
                                <Grid md={2}> 
                                    <Button color="info" onClick={this.handleActivateBannerPopUp}>Activate Banner Pop Up</Button>
                                </Grid>
                            </Grid>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

export default withStyles(useStyles)(BannerPopUp)
