import React, { Component } from 'react'
import { Grid, IconButton, CircularProgress, Backdrop } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
// import ProfileBG from "assets/img/havilla/profile_bg.png";
// import ProfileBGMobile from "assets/img/havilla/profile_bg_mobile.png";
// import ProfilePicture from "assets/img/havilla/profile_picture_example.png";
import EditIcon from "assets/img/havilla/edit_btn.svg";

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
// import Datepicker from 'client-side/components/Datepicker/Datepicker';
import AddressCard from 'client-side/components/AddressCard/AddressCard';

import axios from 'axios';
import {
    baseUrl,
    requestConfig,
    prefix
} from '../../../../Environment.js';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField.js';
import GenderRadioButton from 'client-side/components/CustomRadioButton/GenderRadioButton.js';
import Datepicker from 'client-side/components/Datepicker/Datepicker';
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp.js';
import { ClientSideContext } from '../../../ClientSideContext';
import { dictionary } from '../../../../Dictionary';

const useStyles = theme => ({
    ...styles(theme),
    textField: {
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        [theme.breakpoints.up('md')]: {
        },
        width: "80%"
    },
    boxOutline: {
        padding: '1.2rem',
        width: '100%',
        height: '100%'
    },
    iconEdit: {
        marginTop: `-8px`
    }
});

const field_names = [
    'first_name',
    'last_name',
    'email',
    'phone_number',
    'gender',
    'birthday',
]

class AccountDetails extends Component {
    static contextType = ClientSideContext;
    // static contextType = GlobalContext
    constructor(props) {
        super(props)

        this.state = {
            openBackdrop: false,

            disabled: true,
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            gender: null,
            birthday: null,

            first_name_error: "",
            last_name_error: "",
            email_error: "",
            phone_number_error: '',
            birthday_error: "",
            gender_error: "",

            snackbarType: 'info',
            snackbarMessage: '',
            snackbarCounter: 0,
        }
    }

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    componentDidMount() {
        this.getUserDetails();
        this.setState({ openBackdrop: false })
    }

    componentDidUpdate(nextProps, nextState) {
        for (let i = 0; i < field_names.length; i++) {
            let field_name = field_names[i];
            if (this.state[field_name + "_error"] !== "") {
                if (this.state[field_name] !== nextState[field_name]) {
                    this.setState({ [field_name + "_error"]: "" });
                }
            }
        }

        return true;
    }

    getUserDetails = async () => {
        let authLS = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null
        await axios.get(`${baseUrl}/getCurrentUserInfo`, requestConfig).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                authLS.user = data;
                localStorage.setItem('auth', JSON.stringify(authLS));

                for (let i = 0; i < field_names.length; i++) {
                    const field = field_names[i];
                    this.setState({ [field]: data[field] });
                }
            } else {
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            console.log("Error : ", e);
        })
    }

    handleEdit = () => {
        this.setState({
            disabled: !this.state.disabled
        });
    }

    handleSubmit = (e) => {
        let authLS = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null;
        if (authLS) {
            e.preventDefault();
            let is_ok = true;
            for (let i = 0; i < field_names.length; i++) {
                let field_name = field_names[i];
                let field_name_text = field_names[i].replace('_', ' ');
                if (this.state[field_name] === "" || this.state[field_name] === null) {
                    this.setState({ [field_name + "_error"]: field_name_text + " is required" });
                    is_ok = false;
                }
            }

            if (is_ok) {
                this.setState({ openBackdrop: true });
                let data = {};

                for (let i = 0; i < field_names.length; i++) {
                    const field = field_names[i];
                    if (field === "birthday") {
                        var d = new Date(this.state[field]);
                        data[field] = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
                    } else {
                        data[field] = this.state[field]
                    }
                }
                axios.put(`${baseUrl}/updateUser`,
                    data,
                    requestConfig
                ).then((res) => {
                    if (res.data.success) {
                        this.handleOpenSnackbar({
                            type: "success",
                            message: "Account Updated!"
                        })
                    } else {
                        let errors = res.data.data;

                        for (let i = 0; i < field_names.length; i++) {
                            let field_name = field_names[i];
                            if (errors[field_name] !== undefined) {
                                this.setState({ [field_name + "_error"]: errors[field_name][0] });
                            }
                        }
                        // console.log(res.data.message,res.data.data)
                    }
                }, (e) => {
                    console.log("Error : ", e);
                })
            }
        } else {
            this.handleOpenSnackbar({
                type: "danger",
                message: "Failed to update, please Login to proceed"
            })
            let path = prefix + `/login`;
            this.props.history.push(path);
        }
    }

    handleChangePassword = () => {
        this.setState({ openBackdrop: true });
        let authLS = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null
        let data = {
            email: authLS.user.email
        }
        axios.post(`${baseUrl}/password/email`,
            data,
            requestConfig
        ).then((res) => {
            if (res.data.success) {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "success",
                    message: "Change password email already sent please check your email"
                })
            } else {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${res.data.message}`
                })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "danger",
                message: `${e.message}`
            })
            console.log("Error : ", e);
        })

    }

    handleDateChange = (newValue) => {
        this.setState({
            birthday: newValue,
        })
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    render() {
        const { classes } = this.props;
        return (
            <Container maxWidth="xl" className={`${classes.container} ${classes.contentContainer}`}>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.counter}
                />
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{'color': '#AF986D'}} />
                </Backdrop>
                <Grid container
                    spacing={5}>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={11} xs={11}>
                                <p className={classes.titleMediumSize}>{dictionary['PERSONAL DETAILS'][this.context.language]}</p>
                            </Grid>
                            <Grid item md={1} xs={1}>
                                <IconButton edge="start" color="inherit" aria-label="edit" onClick={this.handleEdit} className={classes.iconEdit}>
                                    <img src={EditIcon} alt="edit icon"></img>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <form className={classes.root} autoComplete="off" onSubmit={this.handleSubmit} >
                            <Grid container className={classes.formContainer} spacing={3}>
                                <Grid item sm={6} xs={12}>
                                    <CustomTextField
                                        value={this.state.first_name}
                                        error={this.state.first_name_error !== ""}
                                        disabled={this.state.disabled}
                                        helperText={this.state.first_name_error}
                                        type="text" onChange={this.onChange} name="first_name" label={dictionary['First Name'][this.context.language]} />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomTextField
                                        value={this.state.last_name}
                                        error={this.state.last_name_error !== ""}
                                        disabled={this.state.disabled}
                                        helperText={this.state.last_name_error}
                                        type="text" onChange={this.onChange} name="last_name" label={dictionary['Last Name'][this.context.language]} />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomTextField
                                        value={this.state.email}
                                        error={this.state.email_error !== ""}
                                        disabled={this.state.disabled}
                                        helperText={this.state.email_error}
                                        type="text" onChange={this.onChange} name="email" label="Email" />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <CustomTextField
                                        value={this.state.phone_number}
                                        error={this.state.phone_number_error !== ""}
                                        disabled={this.state.disabled}
                                        helperText={this.state.phone_number_error}
                                        type="text" onChange={this.onChange} name="phone_number" label={dictionary['Phone Number'][this.context.language]} />
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <GenderRadioButton
                                        error={this.state.gender_error !== ""}
                                        value={this.state.gender}
                                        onChange={this.onChange}
                                        gender_error={this.state.gender_error}
                                        disabled={this.state.disabled}
                                    ></GenderRadioButton>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Datepicker
                                        error={this.state.birthday_error !== ""}
                                        disabled={this.state.disabled}
                                        helperText={this.state.birthday_error}
                                        name="birthday" value={this.state.birthday} handleDateChange={this.handleDateChange}></Datepicker>
                                </Grid>
                                <Grid item xs={12} sm={6} style={{ marginTop: "20px" }}>
                                    {!this.state.disabled ? <Button onClick={this.handleSubmit} variant="outlined" className={classes.buttonDark} >{dictionary['Save Changes'][this.context.language]}</Button> : null}
                                </Grid>
                                <Grid item xs={12} sm={6} style={{ marginTop: "20px", paddingTop: '12px', alignItems: 'center' }}>
                                    {!this.state.disabled ? <button type="button" onClick={this.handleChangePassword} className={classes.linkStyledButton} style={{color:'#AF986D'}}><u>{dictionary['Change password'][this.context.language]}</u></button> : null}
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <p className={classes.titleMediumSize} style={{ marginBottom: '20px' }}>{dictionary['SAVED ADDRESS'][this.context.language]}</p>
                        <Grid container
                            spacing={2}>
                            <AddressCard editMode={true}></AddressCard>

                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default withStyles(useStyles)(AccountDetails) 
