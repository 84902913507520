import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { baseUrl, requestConfigAdmin } from 'Environment'
import { Grid, withStyles } from '@material-ui/core';
import styles from "assets/jss/client-side/App.js";
import ButtonAdmin from "admin/components/CustomButtons/Button.js";
import { AdminContext } from '../../AdminContext';
import Confirmation from 'admin/components/CustomDialogs/Confirmation';
import IOSSwitch from 'admin/components/Switch/IOSSwitch';
import ArrowUpward from "assets/img/havilla/arrow-upward.svg";
import ArrowDownward from "assets/img/havilla/arrow-downward.svg";
import {
    prefix
} from '../../../Environment.js';

const useStyles = theme => ({
    ...styles(theme),
    buttonTable: {
        minWidth: '104px'
    },
    stepText: {
        fontWeight: 'bold',
        fontSize: '28px'
    },
    img: {
        cursor: 'pointer'
    }
});

export class OwnProductStep extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props)

        this.state = {
            activeOwnProductSteps: [],
            notActiveOwnProductSteps: [],

            openDialog: false,
            tempDeleted: null,
        }
    }

    componentDidMount = () => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        this.getOwnProductSteps();
    }

    getOwnProductSteps = () => {
        axios.get(`${baseUrl}/own_product_steps`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    activeOwnProductSteps: res.data.data.active,
                    notActiveOwnProductSteps: res.data.data.not_active,
                }, () => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    handleActiveToggle = (e) => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        let id = (e.target.name).split('-')[1];
        let data = {
            id: id,
        }
        axios.post(`${baseUrl}/own_product_steps/toggle_active`,
            data,
            requestConfigAdmin
        ).then((res) => {
            if (res.data.success) {
                this.getOwnProductSteps();
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "success",
                    message: "Update Own Product Step Success!"
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            } else {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            console.log("Error : ", e);
        })
    }

    handleDeleteClick = (data) => {
        this.setState({
            tempDeleted: data,
            openDialog: true,
        })
    }

    handleCloseDialog = (action) => {
        if (action) {
            axios.delete(`${baseUrl}/own_product_steps/${this.state.tempDeleted.id}`,
                requestConfigAdmin
            ).then((res) => {
                if (res.data.success) {
                    this.getOwnProductSteps();
                    this.setState({
                        tempDeleted: null,
                        openDialog: false,
                    }, () => {
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "success",
                            message: "Own Product Step Deleted"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    })
                } else {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: res.data.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: e.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log("Error : ", e);
            })
        } else {
            this.setState({
                tempDeleted: null,
                openDialog: false,
            })
        }
    }

    handleArrowClick = (rowData, direction) => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        let data = {
            id: rowData.id,
            direction: direction,
        }

        axios.post(`${baseUrl}/own_product_steps/change_step`,
            data,
            requestConfigAdmin
        ).then((res) => {
            if (res.data.success) {
                this.getOwnProductSteps();
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "success",
                    message: "Update Own Product Step Success!"
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            } else {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            console.log("Error : ", e);
        })
    }

    render() {
        const { classes } = this.props;
        const columns = [
            {
                title: 'Name English',
                field: 'name_en',
            },
            {
                title: 'Name Indonesia',
                field: 'name_id',
            },
            {
                title: 'Step',
                field: 'step',
                render: (rowData) => {
                    return (
                        <Grid
                            container
                            alignItems="center"
                        >
                            <Grid item container xs={2} alignItems="center" className={classes.stepText}>
                                {rowData.step}
                            </Grid>
                            <Grid item container xs={10} alignItems='center'>
                                <Grid item xs={12}>
                                    <img className={classes.img} src={ArrowUpward} alt={`${rowData.id} arrow upward`}
                                        onClick={() => this.handleArrowClick(rowData, 'up')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <img className={classes.img} src={ArrowDownward} alt={`${rowData.id} arrow downward`}
                                        onClick={() => this.handleArrowClick(rowData, 'down')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            },
            {
                title: 'Active',
                width: 400,
                render: (rowData) => {
                    return (
                        <Grid container>
                            <Grid item container xs={3} alignItems="center">
                                <IOSSwitch
                                    checked={rowData.is_active === 1 ? true : false}
                                    onChange={this.handleActiveToggle}
                                    name={`is_active-${rowData.id}`}
                                />
                            </Grid>
                            <Grid item container xs={8} justify="center">
                                <Grid item xs={6} container justify="center">
                                    <ButtonAdmin className={classes.buttonTable} color="info"
                                        onClick={
                                            (e) => this.props.history.push(prefix + '/admin/own_product_steps/' + rowData.id)
                                        }
                                    >
                                        EDIT
                                    </ButtonAdmin>
                                </Grid>
                                <Grid item xs={6} container justify="center">
                                    <ButtonAdmin className={classes.buttonTable}
                                        color="danger"
                                        onClick={() => this.handleDeleteClick(rowData)}
                                    >
                                        DELETE
                                    </ButtonAdmin>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            },
        ]
        return (
            <div style={{ maxWidth: '100%' }}>
                <Confirmation
                    openDialog={this.state.openDialog}
                    message={`You are about to delete own product ingredient step <b>${this.state.tempDeleted ? `${this.state.tempDeleted.name_en} | ${this.state.tempDeleted.name_id}` : ""}</b>, 
                    this action will delete all items related to it (Own Product Ingredients),are you sure you want to delete it?`}
                    handleCloseDialog={this.handleCloseDialog}
                />
                <h4 style={{ background: 'white', padding: '10px 25px' }}><b>Active</b></h4>
                <MaterialTable
                    columns={columns}
                    data={this.state.activeOwnProductSteps}
                    title={
                        <React.Fragment>
                            <ButtonAdmin color="info" onClick={() => this.props.history.push(prefix + '/admin/own_product_steps/add')}>
                                ADD NEW STEP
                            </ButtonAdmin>
                        </React.Fragment>
                    }
                    options={{
                        search: true,
                        exportButton: false,
                        pageSize: 5
                    }}
                />
                <br />
                <br />
                <h4 style={{ background: 'white', padding: '10px 25px' }}><b>Inactive</b></h4>
                <MaterialTable
                    columns={columns}
                    data={this.state.notActiveOwnProductSteps}
                    title=""
                    options={{
                        search: true,
                        exportButton: false,
                        pageSize: 5
                    }}
                />
            </div>
        )
    }
}

export default withStyles(useStyles)(OwnProductStep)
