import React, { PureComponent } from 'react'
import ButtonAdmin from "admin/components/CustomButtons/Button.js";
import CloseIcon from "assets/img/havilla/close_icon.svg";
import { Grid, withStyles, Switch, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField';
import Card from 'admin/components/Card/Card';
import CardHeader from 'admin/components/Card/CardHeader';
import CardBody from 'admin/components/Card/CardBody';
import Button from "admin/components/CustomButtons/Button.js";
import MaterialTable from 'material-table';
import { AdminContext } from '../../AdminContext';
import Confirmation from 'admin/components/CustomDialogs/Confirmation';
import axios from 'axios';
import { baseUrl ,requestConfigAdmin } from 'Environment';

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[400],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
    }))(({ classes, ...props }) => {
    return (
        <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
        }}
        {...props}
        />
    );
});


export class OwnProductIngredient extends PureComponent {
    static contextType = AdminContext;
    constructor(props) {
        super(props)
    
        this.state = {
            type : 'update',
            modalType : 'add',
            modalOpen : false,
            tempDeleted : null,
            openDialog : false,

            own_product_step_id : '',

            own_product_ingredients: [],
            form_name_id : "",
            form_name_en : "",
            form_desc_id : "",
            form_desc_en : "",
            form_image_url : null,

            oldData : null,
            count_for_update : 0,

        }
    }

    cleanForm = () =>{
        this.setState({
            form_name_id : "",
            form_name_en : "",
            form_desc_id : "",
            form_desc_en : "",
            form_image_url : null,
        })
    }

    getOwnProductIngredients = () =>{
        this.setState((prevState=> ({
            count_for_update : prevState.count_for_update+1
        })))
        let data = {
            own_product_step_id: this.state.own_product_step_id,
        }
        axios.post(`${baseUrl}/own_product_ingredients/get_by_step`,
            data,
            requestConfigAdmin
        ).then((res)=>{
            if(res.data.success){
                let data = res.data.data;
                this.setState({
                    own_product_ingredients : [...data.active, ...data.not_active],
                },()=>{
                })
            }else{
                this.context.dispatchSnackbarContent({ 
                    type: "SET_SNACKBAR_CONTENT", 
                    color : "danger",
                    message : res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log(res.data.message,res.data.data)
            }
        },(e)=>{
            this.context.dispatchSnackbarContent({ 
                type: "SET_SNACKBAR_CONTENT", 
                color : "danger",
                message : "Error"
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            console.log("Error : ",e);
        })
    }

    componentDidMount(){
        const id = this.props.match.params.id;

        if(id !== "add"){
            this.setState({
                type : "update",
                own_product_step_id : id
            },()=>{
                this.getOwnProductIngredients()
            }) 
        }else{
            this.setState({
                type : "add"
            }) 
        }
    }
    
    handleToggleModal = (type, data) => {
        if(type === 'update'){
            this.setState({
                form_name_id : data.name_id,
                form_name_en : data.name_en,
                form_desc_id : data.desc_id,
                form_desc_en : data.desc_en,
                form_image_url : data.image_url,
                modalType : 'update',
                oldData : data
            })
        }else{
            this.setState({
                modalType : 'add',
            })
        }
        this.setState((prevState) => ({
            modalOpen: !prevState.modalOpen
        }),()=>{
        })
    }

    handleDeleteTempImg = (ref) =>{
        this.setState({
            [ref] : null
        },()=>{
            if(this.state[ref] === null){
                document.getElementById(ref).value = "";
            }
        });
    }

    handleSubmitOwnProductIngredient = () => {
        if(this.state.type === 'add'){
            if(this.state.modalType === "add"){
                let newData = {
                    name_id : this.state.form_name_id,
                    name_en : this.state.form_name_en,
                    desc_id : this.state.form_desc_id,
                    desc_en : this.state.form_desc_en,
                    image_url : this.state.form_image_url
                }
                this.setState(prevState => {
                    const own_product_ingredients = [...prevState.own_product_ingredients];
                    own_product_ingredients.push(newData);
                    return { 
                        ...prevState, 
                        own_product_ingredients,
                    };
                },()=>{
                    this.handleToggleModal();
                    this.props.setOwnProductIngredients(this.state.own_product_ingredients);
                    this.cleanForm();
                })
            }else{
                let newData = {
                    ...this.state.oldData,
                    name_id : this.state.form_name_id,
                    name_en : this.state.form_name_en,
                    desc_id : this.state.form_desc_id,
                    desc_en : this.state.form_desc_en,
                    image_url : this.state.form_image_url
                }

                this.setState(prevState => {
                    const own_product_ingredients = [...prevState.own_product_ingredients];
                    own_product_ingredients[own_product_ingredients.indexOf(this.state.oldData)] = newData;
                    return { ...prevState, own_product_ingredients };
                },()=>{
                    this.handleToggleModal();
                    this.props.setOwnProductIngredients(this.state.own_product_ingredients);
                    this.cleanForm();
                });
            }
        }else{
            if(this.state.modalType === "add"){
                let add_header = {
                    ...requestConfigAdmin.headers,
                    'Content-Type': 'multipart/form-data'
                }

                let formData = new FormData();
                formData.append('own_product_step_id', this.state.own_product_step_id);
                formData.append('name_id', this.state.form_name_id);
                formData.append('name_en', this.state.form_name_en);
                formData.append('desc_id', this.state.form_desc_id); 
                formData.append('desc_en', this.state.form_desc_en); 
                formData.append('image_url', this.state.form_image_url);
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })

                axios.post(`${baseUrl}/own_product_ingredients`,
                    formData,
                    {headers : add_header},
                ).then((res)=>{
                    if(res.data.success){
                        this.getOwnProductIngredients();
                        this.context.dispatchSnackbarContent({ 
                            type: "SET_SNACKBAR_CONTENT", 
                            color : "success",
                            message : "Add New Own Product Ingredient Success!"
                        })
                        this.handleToggleModal();
                        this.cleanForm();
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                        
                    }else{
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
        
                        this.context.dispatchSnackbarContent({ 
                            type: "SET_SNACKBAR_CONTENT", 
                            color : "danger",
                            message : res.data.message
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log(res.data.message,res.data.data)
                    }
                },(e)=>{
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
        
                    this.context.dispatchSnackbarContent({ 
                        type: "SET_SNACKBAR_CONTENT", 
                        color : "danger",
                        message : "Error"
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log("Error : ",e);
                })
            }else{
                let add_header = {
                    ...requestConfigAdmin.headers,
                    'Content-Type': 'multipart/form-data'
                }

                let formData = new FormData();
                formData.append('_method', "PUT");
                formData.append('name_id', this.state.form_name_id);
                formData.append('name_en', this.state.form_name_en);
                formData.append('desc_id', this.state.form_desc_id); 
                formData.append('desc_en', this.state.form_desc_en); 
                formData.append('image_url', this.state.form_image_url);
                
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })

                axios.post(`${baseUrl}/own_product_ingredients/${this.state.oldData.id}`,
                    formData,
                    {headers : add_header},
                ).then((res)=>{
                    if(res.data.success){
                        this.getOwnProductIngredients();
                        this.context.dispatchSnackbarContent({ 
                            type: "SET_SNACKBAR_CONTENT", 
                            color : "success",
                            message : "Update Own Product Ingredient Success!"
                        })
                        this.handleToggleModal();
                        this.cleanForm();
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                        
                    }else{
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
        
                        this.context.dispatchSnackbarContent({ 
                            type: "SET_SNACKBAR_CONTENT", 
                            color : "danger",
                            message : res.data.message
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log(res.data.message,res.data.data)
                    }
                },(e)=>{
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
        
                    this.context.dispatchSnackbarContent({ 
                        type: "SET_SNACKBAR_CONTENT", 
                        color : "danger",
                        message : "Error"
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log("Error : ",e);
                })
            }
        }
    }

    handleDeleteOwnProductIngredient = (data) => {
        this.setState({
            tempDeleted : data,
            openDialog : true,
        })
    }

    handleCloseConfirmationDialog = (action) =>{
        if(action){
            if(this.state.type === 'add'){
                this.setState(prevState => {
                    const own_product_ingredients = [...prevState.own_product_ingredients];
                    own_product_ingredients.splice(own_product_ingredients.indexOf(this.state.tempDeleted), 1);
                    return { ...prevState, own_product_ingredients };
                },() =>{
                    this.setState({
                        tempDeleted : null,
                        openDialog : false,
                    })
                    this.props.setOwnProductIngredients(this.state.own_product_ingredients);
                });
            }else{
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
                axios.delete(`${baseUrl}/own_product_ingredients/${this.state.tempDeleted.id}`,
                    requestConfigAdmin
                ).then((res)=>{
                    if(res.data.success){
                        this.getOwnProductIngredients();
                        this.setState({
                            tempDeleted : null,
                            openDialog : false,
                        },()=>{
                            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                            this.context.dispatchSnackbarContent({ 
                                type: "SET_SNACKBAR_CONTENT", 
                                color : "success",
                                message : "Product Deleted"
                            })
                            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        })
                    }else{
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                        this.context.dispatchSnackbarContent({ 
                            type: "SET_SNACKBAR_CONTENT", 
                            color : "danger",
                            message : res.data.message
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log(res.data.message,res.data.data)
                    }
                },(e)=>{
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({ 
                        type: "SET_SNACKBAR_CONTENT", 
                        color : "danger",
                        message : e
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log("Error : ",e);
                })
            }
        }else{
            this.setState({
                tempDeleted : null,
                openDialog : false,
            })
        }
    }

    handleActiveToggle = (rowData) => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        let data = {
            id : rowData.id,
        }
        axios.post(`${baseUrl}/own_product_ingredients/toggle_active`,
            data,
            requestConfigAdmin
        ).then((res)=>{
            if(res.data.success){
                this.getOwnProductIngredients();
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                this.context.dispatchSnackbarContent({ 
                    type: "SET_SNACKBAR_CONTENT", 
                    color : "success",
                    message : "Update Own Product Ingredient Success!"
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            }else{
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({ 
                    type: "SET_SNACKBAR_CONTENT", 
                    color : "danger",
                    message : res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log(res.data.message,res.data.data)
            }
        },(e)=>{
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

            this.context.dispatchSnackbarContent({ 
                type: "SET_SNACKBAR_CONTENT", 
                color : "danger",
                message : e
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            console.log("Error : ",e);
        })
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });
    
    onChangeImage = (e) =>{
        if(e.target.files.length > 0){
            let file = e.target.files[0];
            this.setState({
                [e.target.name] : file
            }, ()=>{
            })
        }
    }

    render() {
        const {classes} = this.props
        const columns_own_product_ingredient = [
            { 
                title: 'Name English', 
                field: 'name_en',
            },
            { 
                title: 'Name Indonesia', 
                field: 'name_en',
            },
            { 
                title: 'Short Desc English', 
                field: 'desc_en',
            },
            { 
                title: 'Short Desc Indonesia', 
                field: 'desc_id',
            },
            { 
                title: 'Image', 
                render: (rowData) => {
                    return (
                        <Grid 
                            container
                        >
                            {
                                rowData.image_url != null ?
                                    <Grid item xs={12}>
                                        {
                                            typeof rowData.image_url === 'object' && rowData.image_url !== null ?
                                                <img className={classes.img} alt={`${rowData.name} ${rowData.image_url.name}`} src={URL.createObjectURL(rowData.image_url)}></img>
                                            : 
                                                <img className={classes.img} alt={`${rowData.name} own product`} src={`${rowData.image_url}?v=${this.state.count_for_update}`}></img>
                                        }
                                    </Grid>
                                :
                                    null
                            }
                            
                        </Grid>
                    )
                }
            },
            { 
                title: this.state.type !== "add" ? 'Active' : "", 
                width : 250,
                render: (rowData) => {
                    return (
                        <Grid container>
                            {
                                this.state.type !== "add" ?
                                    <Grid item container xs={3} alignItems="center">
                                        <IOSSwitch 
                                            checked={rowData.is_active === 1} 
                                            onChange={() => this.handleActiveToggle(rowData)} 
                                        />
                                    </Grid>
                                : 
                                    null
                            }
                            <Grid item container xs={9} justify="center">
                                <Grid item xs={12} container justify="center">
                                    <ButtonAdmin className={classes.buttonTable} color="info" 
                                        onClick={()=>this.handleToggleModal('update',rowData)}
                                    >
                                        EDIT
                                    </ButtonAdmin>
                                </Grid>
                                <Grid item xs={12} container justify="center">
                                    <ButtonAdmin className={classes.buttonTable} color="danger" onClick={() => this.handleDeleteOwnProductIngredient(rowData)}>DELETE</ButtonAdmin>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            },
        ]
        
        return (
            <React.Fragment>
                <Confirmation
                    openDialog={this.state.openDialog}
                    message={`You are about to delete own product ingredient <b>${this.state.tempDeleted ? `${this.state.tempDeleted.name_en} | ${this.state.tempDeleted.name_id}` : ""}</b>, 
                    are you sure you want to delete it?`}
                    handleCloseDialog={this.handleCloseConfirmationDialog}
                />
                <Dialog 
                    fullWidth={true}
                    maxWidth="md"
                    open={this.state.modalOpen} 
                    onClose={this.handleToggleModal}>
                    <DialogTitle>Add New Own Product Ingredient</DialogTitle>
                    <DialogContent>
                            <img className={classes.closeIconModal} src={CloseIcon} alt="close icon" onClick={this.handleToggleModal}></img>
                            <Grid container>
                                <Grid item container xs={12} className={classes.contentContainerModal}>
                                    <Grid container className={classes.formContainer} spacing={3}>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                otherOptions={{
                                                    autoFocus : true
                                                }}
                                                width="100%" 
                                                type="text" 
                                                value={this.state.form_name_en}
                                                onChange={this.onChange} name="form_name_en" label="Name English" />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                width="100%" 
                                                type="text" 
                                                value={this.state.form_name_id}
                                                onChange={this.onChange} name="form_name_id" label="Name Indonesia" />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                width="100%" 
                                                value={this.state.form_desc_en}
                                                type="text" onChange={this.onChange} name="form_desc_en" label="Short Description English" />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                width="100%" 
                                                value={this.state.form_desc_id}
                                                type="text" onChange={this.onChange} name="form_desc_id" label="Short Description Indonesia" />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <input
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                id="form_image_url"
                                                name="form_image_url"
                                                type="file"
                                                onChange={this.onChangeImage}
                                            />
                                            <label htmlFor="form_image_url">
                                                <Button color="info" component="span" >
                                                    Upload Image
                                                </Button>
                                            </label> 
                                        </Grid>
                                        <Grid item container md={12} xs={12}>
                                            {
                                                this.state.form_image_url != null ?
                                                    <Grid className={classes.tempPictContainer} item container md={4} xs={6}>
                                                        {
                                                            typeof this.state.form_image_url === 'object' && this.state.form_image_url !== null ? 
                                                                <img className={classes.imgPreview} alt={`preview ${this.state.form_image_url.name}`} src={URL.createObjectURL(this.state.form_image_url)}/>
                                                            :
                                                                <img className={classes.imgPreview} alt={`preview own product ingredient`} src={this.state.form_image_url}/>
                                                        }
                                                        <div className={`${classes.deletePict} delete-button`} >
                                                            <img src={CloseIcon} alt="close icon" onClick={() => this.handleDeleteTempImg('form_image_url')}></img>
                                                        </div>
                                                    </Grid>
                                                :
                                                    null
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="success" style={{marginRight: '12px'}} onClick={this.handleSubmitOwnProductIngredient}>
                            SAVE
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid item xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="gray">
                            <h4 className={classes.cardTitleWhite}>Own Product Ingredients</h4>
                            <p>Handle Own Product Ingredients</p>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <Grid item sm={12} md={12}>
                                    <ButtonAdmin color="info" onClick={()=>this.handleToggleModal('add')}>ADD NEW OWN PRODUCT INGREDIENT</ButtonAdmin>    
                                </Grid> 
                                <Grid item sm={12} md={12}>
                                    <MaterialTable
                                        title={null}
                                        components={{
                                            Toolbar: props => (
                                                null
                                            )                                    
                                        }}
                                        options={{
                                            search: false,
                                            exportButton: false,
                                            pageSize: 5,
                                            actionsColumnIndex : 6
                                        }}
                                        columns={columns_own_product_ingredient}
                                        data={this.state.own_product_ingredients}
                                    />
                                </Grid>
                            </Grid>
                        </CardBody>
                        {/* <CardFooter>
                            {this.state.type !=="add" ? 
                                <Button color="info" onClick={this.handleSubmitProductInfo}>Update Own Product Ingredient</Button>
                            :
                                null
                            }
                        </CardFooter> */}
                    </Card>
                </Grid>
            </React.Fragment>
        )
    }
}

export default (OwnProductIngredient)
