import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import { TextField, MenuItem, Select } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";

import axios from 'axios';
import { baseUrl } from 'Environment';
import { requestConfig } from 'Environment';

import { Backdrop, CircularProgress } from '@material-ui/core';
import { ClientSideContext } from '../../ClientSideContext';
import GoogleMaps from './GoogleMaps.js';

import JneIcon from "assets/img/havilla/jne_logo.svg";
import TokopediaIcon from "assets/img/havilla/tokopedia_logo.png";

const CustomTextfieldRaw = withStyles({
    root: {
        '& label': {
            color: 'white',
        },
        '& label.Mui-disabled': {
            color: 'grey',
        },
        '& .MuiInput-root': {
            color: '#AF986D',
            '&:hover fieldset': {
                borderBottomColor: 'red',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#cfcfcf',
        },
        '& .MuiInput-root:hover': {
            '.MuiInput-underline:before': {
                borderBottomColor: '#cfcfcf',
            },
        },
        '& .MuiInput-underline-hover:before': {
            borderBottomColor: 'white',
        },
        '& label.Mui-focused': {
            color: '#AF986D',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#AF986D',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);

const useStyles = theme => ({
    ...styles(theme),
    containerLogin: {
    },
    banner: {
        width: '100%'
    },
    textField: {
        width: "80%",
    },
    menuIcon: {
        margin: 'auto',
        display: 'block'
    },
    questionBox: {
        border: '1px solid grey',
        padding: 25,
        margin: '45px 10px 25px 10px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0
        }
    },
    moreContainer: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: 10
        },
        marginTop: 50
    },
    storeCard: {
        // border: '1px solid #f0f0f0',
        background: '#121212',
        padding: 15,
        height: "100%",
    },
    filterLabel: {
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right', marginTop: 12, marginRight: 15
        },
    },
    jneIcon: {
        [theme.breakpoints.up('sm')]: {
            float: 'right'
        },
        [theme.breakpoints.down('md')]: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',
        },
    },
    tokopediaIcon: {
        [theme.breakpoints.down('md')]: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '50%',
        },
    },
    autoCompleteRoot: {
        '& label': {
            color: 'white',
        },
        '& label.Mui-disabled': {
            color: 'grey',
        },
        '& .MuiInput-root': {
            color: '#AF986D',
            '&:hover fieldset': {
                borderBottomColor: 'red',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#cfcfcf',
        },
        '& .MuiInput-root:hover': {
            '.MuiInput-underline:before': {
                borderBottomColor: '#cfcfcf',
            },
        },
        '& .MuiInput-underline-hover:before': {
            borderBottomColor: 'white',
        },
        '& label.Mui-focused': {
            color: '#AF986D',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#AF986D',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        },
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
    },
    autoCompletePaper: {
        backgroundColor: '#222222',
        borderRadius: 0,
        '& .MuiMenuItem-root': {
            background: '#222222'
        },
        '& .MuiMenuItem-root:hover': {
            backgroundColor: 'white',
            color: 'black',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#121212',
            borderRadius: 0,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#AF986D',
            borderRadius: 0,
            '&:hover': {
                backgroundColor: 'white',
            },
        },
        '&:focus': {
            backgroundColor: 'white',
            color: 'black',
        },
    },
    autoCompleteOption: {
        color: 'white',
        '&:hover': {
            backgroundColor: 'white',
            color: 'black',
        },
    },
    menuItemOption: {
        color: 'white',
    },
});

class AboutUs extends Component {
    static contextType = ClientSideContext;

    constructor(props) {
        super(props)

        this.state = {
            openBackdrop: false,
            store_locations: [],
            listCities: [{ value: '', label: '' }],
            type: '',
            regency_id: ''
        }
    }

    getStoreLocations = async () => {
        await axios.post(`${baseUrl}/store_locations`,
            {
                type: this.state.type,
                regency_id: this.state.regency_id
            },
            requestConfig)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        store_locations: res.data.data,
                    });
                } else {
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                console.log("Error : ", e);
            })
    }

    componentDidMount = async () => {
        this.getStoreLocations();

        await axios.get(`${baseUrl}/regencies`).then((res) => {
            if (res.data.success) {
                this.setState({
                    listCities: res.data.data.map(item => {
                        return (
                            {
                                value: item.id,
                                label: item.name
                            }
                        )
                    })
                }, () => {
                })
            } else {
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            console.log("Error : ", e);
        })
    }

    onCityChange = (e, newValue) => {
        if (newValue != null) {
            this.setState({ regency_id: newValue.value }, () => { this.getStoreLocations() });
        }
        else {
            this.setState({ regency_id: '' }, () => { this.getStoreLocations() });
        }
    }

    onTypeChange = (e, newValue) => {
        this.setState({ type: e.target.value }, () => { this.getStoreLocations() });
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value })
    render() {
        const { classes } = this.props;
        return (
            <Container maxWidth="xl" className={`${classes.container}`} style={{ width: '100%', padding: 0, margin: 0 }}>
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{ 'color': '#AF986D' }} />
                </Backdrop>

                <GoogleMaps store_locations={this.state.store_locations}></GoogleMaps>

                <Container maxWidth="lg" style={{ marginTop: 20 }}>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <h3 className={classes.smallerTitle} style={{ textAlign: 'center' }}>
                                Our Partners
                            </h3>

                            <Grid container>
                                <Grid item md={1} xs={12}>
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <h3 className={classes.filterLabel} style={{}}>I'm in</h3>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <Autocomplete
                                        classes={{ root: classes.autoCompleteRoot, listbox: classes.autoCompletePaper, paper: classes.autoCompletePaper, option: classes.autoCompleteOption }}
                                        value={this.state.listCities[this.state.listCities.findIndex(city => city.value.toString() === this.state.regency_id.toString())] || null}
                                        name="regency_id"
                                        onChange={this.onCityChange}
                                        options={this.state.listCities}
                                        getOptionLabel={(option) => option.label}
                                        // getOptionLabel={(option) => option.title}
                                        renderInput={(params) => <CustomTextfieldRaw {...params}
                                            label=" " />}
                                    />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <h3 className={classes.filterLabel}>and I want to</h3>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <CustomTextfieldRaw
                                        select
                                        SelectProps={{
                                            MenuProps: {
                                                classes: { paper: classes.autoCompletePaper }
                                            }
                                        }}
                                        label=" "
                                        name="type"
                                        value={this.state.type === null ? '' : this.state.type}
                                        onChange={this.onTypeChange}
                                    >
                                        <MenuItem classes={{ root: classes.menuItemOption }} value='Stockist'>purchase some tea</MenuItem>
                                        <MenuItem classes={{ root: classes.menuItemOption }} value='Cafe'>get served on the spot</MenuItem>
                                        <MenuItem classes={{ root: classes.menuItemOption }} value='Cafe & Stockist'>get served and purchase some tea</MenuItem>
                                        <MenuItem classes={{ root: classes.menuItemOption }} value=''>All of them above</MenuItem>
                                    </CustomTextfieldRaw>
                                </Grid>
                                <Grid item md={1} xs={12}></Grid>
                            </Grid>
                        </Grid>
                        {
                            this.state.store_locations.map((store_location, i) => {
                                return (
                                    <Grid item md={4} xs={12} key={i}>
                                        <div className={classes.storeCard}>
                                            <p style={{ fontSize: 22 }}>
                                                {store_location.name}
                                            </p>
                                            <p style={{ fontWeight: 300, marginTop: 15 }}>
                                                {store_location.address}
                                            </p>
                                            <p style={{ fontWeight: 300, marginTop: 15 }}>
                                                ({store_location.type} at {store_location.regency.name})
                                            </p>
                                            <div style={{ marginTop: 15 }}>
                                                <a href={store_location.url} target="_blank"
                                                    className={classes.linkPointerWUnderline}>
                                                    View on Map
                                                </a>
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Container maxWidth="md" style={{ marginTop: 50 }}>
                        <h1 className={classes.smallerTitle} style={{ textAlign: 'center' }}>
                            Can’t find store in your location?
                        </h1>
                        <p style={{ textAlign: 'center' }}>No worries. We can ship our products to your place, our complete instruction will make sure you can enjoy Havilla experience anywhere. Currently, we shop through:</p>

                        <Grid
                            container
                            spacing={4}
                            style={{ marginTop: 30 }}
                        >
                            <Grid item md={6} xs={12}>
                                <img src={JneIcon} alt="jne icon" className={classes.jneIcon}></img>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <img src={TokopediaIcon} className={classes.tokopediaIcon} alt="tokopedia icon"></img>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </Container>
        )
    }
}

export default withStyles(useStyles)(AboutUs) 
