import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    img : {
        maxHeight: '292px',
        height: '292px',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '100%',
            maxWidth : '98vw',
        },
    },
    articleTitle : {
        fontFamily : 'Martel Sans',
        fontSize : '24px',
        textAlign : 'left',
        padding : '14px 32px 0px 32px'
    },
    articleDesc : {
        fontFamily : 'Martel Sans',
        fontSize : '16px',
        textAlign : 'left',
        padding : '24px 32px 0px 32px',
    },
    postContainer : {
        marginLeft : '32px',
        maxWidth : '292px',
        // minHeight : '451px',
        width: 'auto'
    },
    articleLink : {
        fontFamily: 'Martel Sans',
        fontSize: "14px",
        textDecorationLine: 'underline',
        // '&:hover' : {
        //     textDecorationLine: 'underline',
        // }
    },
    articleLinkContainer :{
        textAlign : 'left',
        padding : '17px 32px 0px 32px'
    },
    emptyLastItem : {
        [theme.breakpoints.down('sm')]: {
            display : 'none'
        },
        marginLeft : '0rem',
    }
}));

function PostSmall(props) {
    const classes = useStyles();
    return (
        <Grid className={`${classes.postContainer} ${props.additionalClass}`} 
            container 
            justify='space-between' 
            alignItems='flex-start' 
            direction='column'
        >
            <Grid item xs={12} >
                <img className={classes.img} src={props.img} alt={props.img}/>
            </Grid>
            <Grid className={classes.articleTitle} item xs={12}>
                {props.title}
            </Grid>
            <Grid className={classes.articleLinkContainer} item xs={12}>
                <Link to='#' className={classes.articleLink}>
                    {props.linkText}
                </Link>
            </Grid>
        </Grid>
    )
}

export default PostSmall
