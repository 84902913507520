import React, {useState,useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {isMobile} from "react-device-detect";
import Flickity from "react-flickity-component";
import "assets/css/flickity.css";
import IngredientCard from './IngredientCard';
import TeaBig1 from "assets/img/havilla/Greenpeach.png";
import Arrow from "assets/img/havilla/right-arrow.svg";
import {ClientSideContext} from '../../ClientSideContext';

const useStyles = makeStyles((theme) => ({
    itemTab : {
        fontFamily : 'Martel Sans',
        fontSize : '24px',
        textTransform: 'none',
    },
    rightFadeLong : {
        position : 'absolute',
        width: '680px',
        height: '413px',
        transform: 'matrix(-1, 0, 0, 1, 0, 0)',
        background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%)',
        top : '14rem',
        right : '0rem',
        pointerEvents: 'none',
        [theme.breakpoints.down('sm')]: {
            display : 'none'
        },
    },
    leftFadeLong : {
        position : 'absolute',
        width: '680px',
        height: '413px',
        background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%)',
        top : '14rem',
        left : '0rem',
        pointerEvents: 'none',
        [theme.breakpoints.down('sm')]: {
            display : 'none'
        },
    },
    rightFadeShort : {
        position : 'absolute',
        width: '300px',
        height: '413px',
        transform: 'matrix(-1, 0, 0, 1, 0, 0)',
        background: 'linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)',
        top : '14rem',
        right : '0rem',
        pointerEvents: 'none',
        [theme.breakpoints.down('sm')]: {
            display : 'none'
        },
    },
    leftFadeShort : {
        position : 'absolute',
        width: '300px',
        height: '413px',
        background: 'linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)',
        top : '14rem',
        left : '0rem',
        pointerEvents: 'none',
        [theme.breakpoints.down('sm')]: {
            display : 'none'
        },
    },
    customNextButton :{
        position : 'absolute',
        top : '28rem',
        overflow : 'hidden',
        right : '-3rem',
        width : '0px',
        background : 'none',
        transition : 'all 0.7s cubic-bezier(0.19, 1, 0.22, 1)',
        '&.active' :{
            right : '3rem',
            width : '44px',
        },
        '&:hover' : {
            background : 'none',
        },
        [theme.breakpoints.down('sm')]: {
            display : 'none'
        },
    },
    customPrevButton : {
        position : 'absolute',
        top : '28rem',
        overflow : 'hidden',
        left : '-3rem',
        width : '0px',
        background : 'none',
        '&:hover' : {
            background : 'none',
        },
        transition : 'all 0.7s cubic-bezier(0.19, 1, 0.22, 1)',
        '&.active' :{
            left : '3rem',
            width : '44px',
        },
        [theme.breakpoints.down('sm')]: {
            display : 'none'
        },
    },
    emptyLastItem : {
        marginLeft : '0rem',
        maxWidth : '292px',
        // minHeight : '451px',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            display : 'none'
        },
    }
}));

export default function FlickityIngredient(props) {
    const classes = useStyles();

    const [flkty, setflkty] = useState(null);
    useEffect(() => {
        if(flkty !== null){
            flkty.on('change', () => {
                props.handleChangeIngredient(flkty.selectedIndex,props.own_product_ingredients[flkty.selectedIndex]);
                if(!isMobile){
                    let elBtnPrev = document.getElementById('customPrevButton');
                    let elBtnNext = document.getElementById('customNextButton');
                    let selectedIndex = flkty.selectedIndex;
                    if(selectedIndex > 0){
                        elBtnPrev.classList.add('active');
                    }else{
                        elBtnPrev.classList.remove('active');
                    }
    
                    if(selectedIndex !== (flkty.slides.length-1)){
                        elBtnNext.classList.add('active');
                    }else{
                        elBtnNext.classList.remove('active');
                    }
                }
            })
        }
    }, [flkty]);
    
    const handlePrevPost = () => {
        flkty.previous()
    }
    const handleNextPost = () => {
        flkty.next()
    }
    
    const nextButton = (
        <div id="customNextButton" onClick={handleNextPost} className={`active noselect flickity-button flickity-prev-next-button ${classes.customNextButton}`} type="button" aria-label="Previous">
            <img style={{width:'44px', height:'44px'}} src={Arrow} alt='arrow right'></img>
        </div>
    )
    const prevButton = (
        <div id="customPrevButton" onClick={handlePrevPost} className={`active noselect flickity-button flickity-prev-next-button ${classes.customPrevButton}`} type="button" aria-label="Previous">
            <img style={{width:'44px', height:'44px', transform: 'rotate(180deg)'}} src={Arrow} alt='arrow left'></img>
        </div>
    )

    const flickityOptions = {
        cellAlign: 'center',
        prevNextButtons: false,
        draggable: true,
        initialIndex: props.selected_index,
        pageDots: false,
        wrapAround: false
    }

    return (
        <React.Fragment>
            <Flickity flickityRef={c => setflkty(c)} options={flickityOptions}>
                {
                    props.own_product_ingredients.length > 0 ?
                        props.own_product_ingredients.map((ingredient, i) => {
                            return <IngredientCard 
                                key={i}
                                id={ingredient.id}
                                img={ingredient.image_url ? ingredient.image_url : TeaBig1}
                                imgAlt={`ingredient ${ingredient[`name_${props.language}`]}`}
                                name={ingredient[`name_${props.language}`]}
                                desc={ingredient[`desc_${props.language}`]}
                                checked={ingredient.checked !== undefined ? ingredient.checked : false}
                                max_pick={props.own_product_step.max_pick}
                                handleSelectedIngredient={props.handleSelectedIngredient}
                            />
                        })
                    :
                        // insert default photo for no photo
                        null
                }
            </Flickity>
            {
                props.own_product_step.max_pick === 1 ?
                    <div className={classes.leftFadeLong}>
                    </div>
                :
                    <div className={classes.leftFadeShort}>
                    </div>

            }
            
            {
                props.own_product_step.max_pick === 1 ?
                    <div className={classes.rightFadeLong}>
                    </div>
                :
                    <div className={classes.rightFadeShort}>
                    </div>

            }
            
            {prevButton}
            {nextButton}
            
        </React.Fragment>
    );
}
