import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import {
    baseUrl,
    requestConfig,
    prefix
} from '../../../Environment.js';
import Datepicker from 'client-side/components/Datepicker/Datepicker';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField.js';
import { Backdrop, CircularProgress, MenuItem, Box } from '@material-ui/core';
import InfoIcon from "assets/img/havilla/info_icon.svg";
import CloseIcon from "assets/img/havilla/close_icon.svg";
import GreetingCard1 from "assets/img/havilla/greeting_card_1.png";
import GreetingCard2 from "assets/img/havilla/greeting_card_2.png";
import GreetingCard3 from "assets/img/havilla/greeting_card_3.png";
import ThankYouWallpaper from "assets/img/havilla/thank_you_wallpaper.png";
import ThankYouWallpaperMobile from "assets/img/havilla/thank_you_wallpaper_mobile.png";
import CopasIcon from "assets/img/havilla/copas_icon.svg";

import ReactPixel from 'react-facebook-pixel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import OrderSummary from 'client-side/components/OrderSummary/OrderSummary.js';

import RecipientRadioButton from 'client-side/components/CustomRadioButton/RecipientRadioButton.js';
import ShippingRadioButton from 'client-side/components/CustomRadioButton/ShippingRadioButton.js';
import PaymentRadioButton from 'client-side/components/CustomRadioButton/PaymentRadioButton.js';

import AddressCard from 'client-side/components/AddressCard/AddressCard';
import { ClientSideContext } from '../../ClientSideContext';
import { dictionary } from '../../../Dictionary';

const CustomTextfieldRaw = withStyles({
    root: {
        '& label': {
            color: 'white',
        },
        '& label.Mui-disabled': {
            color: 'grey',
        },
        '& .MuiInput-root': {
            color: '#AF986D',
            '&:hover fieldset': {
                borderBottomColor: 'red',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#cfcfcf',
        },
        '& .MuiInput-root:hover': {
            '.MuiInput-underline:before': {
                borderBottomColor: '#cfcfcf',
            },
        },
        '& .MuiInput-underline-hover:before': {
            borderBottomColor: 'white',
        },
        '& label.Mui-focused': {
            color: '#AF986D',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#AF986D',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);

const useStyles = theme => ({
    ...styles(theme),
    containerLogin: {
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            padding: "50px 40px 100px",
        },
        [theme.breakpoints.up('md')]: {
            padding: "7% 20% 0",
        },
    },
    floral: {
        marginLeft: "20%",
        marginTop: "-80px",
        [theme.breakpoints.down('sm')]: {
            width: "100px",
            position: "absolute",
            right: "-50px"
        },
    },
    textField: {
        width: "80%",
    },
    autoCompleteRoot: {
        '& label': {
            color: 'white',
        },
        '& label.Mui-disabled': {
            color: 'grey',
        },
        '& .MuiInput-root': {
            color: '#AF986D',
            '&:hover fieldset': {
                borderBottomColor: 'red',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#cfcfcf',
        },
        '& .MuiInput-root:hover': {
            '.MuiInput-underline:before': {
                borderBottomColor: '#cfcfcf',
            },
        },
        '& .MuiInput-underline-hover:before': {
            borderBottomColor: 'white',
        },
        '& label.Mui-focused': {
            color: '#AF986D',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#AF986D',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        },
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
    },
    autoCompletePaper: {
        backgroundColor: '#222222',
        borderRadius: 0,
        '& .MuiMenuItem-root': {
            background: '#222222'
        },
        '& .MuiMenuItem-root:hover': {
            backgroundColor: 'white',
            color: 'black',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#121212',
            borderRadius: 0,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#AF986D',
            borderRadius: 0,
            '&:hover': {
                backgroundColor: 'white',
            },
        }
    },
    autoCompleteOption: {
        color: 'white',
        '&:hover': {
            backgroundColor: 'white',
            color: 'black',
        },
    },
    menuItemOption: {
        color: 'white',
    },
    borderHr: {
        width: '100%',
        marginTop: 10,
        marginBottom: 20,
        border: '1px solid #222222',
    },
    headerContainer: {
        padding: '2rem 5px 2rem 0'
    },
    closeIcon: {
        float: 'right'
    },
    colOrderSummary: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 40,
        },
    },
    // RADIO
    radioChecked: {
        '&, & + $label': {
            color: 'black',
        },
    },
    radioDisabled: {
        '&, & + $label': {
            color: 'rgba(0, 0, 0, 0.38)',
        },
    },
    label: {},
    formControlRadio: {
        [theme.breakpoints.down('sm')]: {
            marginRight: '12px',
            width: '100%'
        },
    },
    offState: {
        color: 'lightgrey',
        marginRight: 10
    },
    onState: {
        color: '#AF986D',
        fontWeight: 'bold',
        marginRight: 10
    },
    selectedIconType: {
        border: '2px solid #AF986D',
        padding: '5px',
        width: '100%',
        cursor: 'pointer'
    },
    unselectedIconType: {
        border: '2px solid #000000',
        padding: '6px',
        width: '100%',
        cursor: 'pointer'
    },
    thankYouContainer: {
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            padding: "50px 40px 100px",
        },
        [theme.breakpoints.up('md')]: {
            padding: "12% 5% 0",
        },
        [theme.breakpoints.up('xl')]: {
            padding: "12% 5% 0 12%",
        },
    },
    thankYouWallpaper: {
        backgroundImage: `url(${ThankYouWallpaper})`,
        width: '100%',
        height: '660px',
        padding: 0,
        margin: 0,
        backgroundSize: 'cover',
        [theme.breakpoints.up('xl')]: {
            height: '900px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '200px',
            backgroundImage: `url(${ThankYouWallpaperMobile})`,
        }
    },
    buttonLightCustom: {
        marginTop: 25
    },
    buttonDarkCustom: {
        [theme.breakpoints.up('lg')]: {
            marginTop: 25,
            marginLeft: 10
        }
    },
    VAContainer: {
        marginTop: 15,
        background: '#222222',
        color: '#AF986D',
        borderRadius: '16px',
        padding: '5px 15px 15px 15px',
        width: '500px',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    VANo: {
        fontWeight: 'bold',
        paddingRight: 15,
        lineHeight: 0,
        fontSize: 24,
        borderRight: '1px solid #CFCFCF',
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        }
    },
    copasIcon: {
        marginTop: 10,
        marginLeft: 15,
        cursor: 'pointer'
    }
});

const required_field_step_one_gift = [
    'first_name',
    'last_name',
]
const required_field_step_two_gift = [
    'province_id',
    'city_id',
    'postal_code',
    'street_address',
    'phone_no'
]

export class ProductBuying extends Component {
    static contextType = ClientSideContext;

    constructor(props) {
        super(props)
        this.state = {
            openBackdrop: false,
            language: 'en',

            sender_name: '',
            sender_phone: '',
            first_name: '',
            last_name: '',
            card_notes: '',
            first_name_error: '',
            last_name_error: '',
            card_type: '1',

            listProvinces: [{ value: '', label: '' }],
            listCities: [{ value: '', label: '' }],
            city_disabled: true,
            province_id: '',
            province_name: '',
            city_id: '',
            city_name: '',
            postal_code: '',
            street_address: '',
            phone_no: '',
            notes: '',
            postal_code_error: '',
            street_address_error: '',
            phone_no_error: '',
            notes_error: '',

            currentStep: 1,
            recipient: 'me',

            available_hours: [],
            address: [],
            address_id: '',
            shipping_service: 'jne-REG',
            listShippingService: [],
            delivery_notes: '',
            delivery_date: new Date(),
            delivery_time: '',
            shipping_cost: 0,
            gosend_cost: 0,
            btnContinuePaymentDisabled: true,

            code: '',
            code_error: '',
            code_success: '',
            discount: 0,
            payment_method: 'bca',

            total_amount: 0,
            total_weight: 0,
            va_number: '',
            va_copied_info: ''
        }
    }


    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSelectIcon = (i) => {
        this.setState({
            card_type: i
        })
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (this.state.openBackdrop !== nextState.openBackdrop) return true

    //     if (this.state.currentStep !== nextState.currentStep) return true
    //     if (this.state.recipient !== nextState.recipient) return true

    //     if (this.state.sender_name !== nextState.sender_name) return true
    //     if (this.state.sender_phone !== nextState.sender_phone) return true
    //     if (this.state.card_notes !== nextState.card_notes) return true

    //     if (this.state.first_name !== nextState.first_name) return true
    //     if (this.state.last_name !== nextState.last_name) return true
    //     if (this.state.phone_number !== nextState.phone_number) return true
    //     if (this.state.card_type !== nextState.card_type) return true

    //     if (this.state.first_name_error !== nextState.first_name_error) return true
    //     if (this.state.last_name_error !== nextState.last_name_error) return true
    //     if (this.state.phone_number_error !== nextState.phone_number_error) return true
    //     if (this.state.address !== nextState.address) return true
    //     if (this.state.shipping_service !== nextState.shipping_service) return true

    //     if (this.state.payment_method !== nextState.payment_method) return true

    //     return false;
    // }

    componentDidUpdate(nextProps, nextState) {

        let requiredField = this.state.currentStep === 1 ? required_field_step_one_gift : required_field_step_two_gift
        for (let i = 0; i < requiredField.length; i++) {
            let field_name = requiredField[i];
            if (this.state[field_name + "_error"] !== "") {
                if (this.state[field_name] !== nextState[field_name]) {
                    this.setState({ [field_name + "_error"]: "" });
                }
            }
        }
        return true;
    }

    getAvailableShippingHours() {
        var date = new Date;
        var hour = date.getHours();
        var result = [];

        // set besoknya kalau udah diatas jam 16
        if (hour >= 16) {
            this.setState({
                delivery_date: new Date(date.getTime() + 86400000)
            })

            for (var i = 9; i <= 16; i++) {
                result.push(`${i}.00 - ${i + 1}.00`);
            }
        }
        else if (hour <= 7) {
            for (var i = 9; i <= 16; i++) {
                result.push(`${i}.00 - ${i + 1}.00`);
            }
        }
        else {
            for (var i = hour + 1; i <= 16; i++) {
                result.push(`${i}.00 - ${i + 1}.00`);
            }
        }

        this.setState({
            available_hours: result,
            delivery_time: result[0]
        })
    }

    checkCartForInactive() {
        const { dispatchCartItems, cartItems } = this.context;
        axios.get(`${baseUrl}/check_cart_for_inactive`, requestConfig).then((res) => {
            if (res.data.success) {
                dispatchCartItems({
                    type: "REFRESH_CART",
                    state: res.data.data,
                })
            } else {
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            console.log("Error : ", e);
        })
    }

    componentDidMount() {
        const { dispatchCartItems, cartItems } = this.context;
        this.setState({
            language: this.context.language
        })
        var cartItemsContext;
        try {
            cartItemsContext = JSON.parse(cartItems)
        } catch (e) {
            cartItemsContext = []
        }

        if (cartItemsContext.length <= 0) {
            let path = prefix + `/profile/order`;
            this.props.history.push(path);
        }

        this.checkCartForInactive();
        this.getAvailableShippingHours();

        this.setState({ openBackdrop: true });
        if (this.props.location.state !== undefined) {
            this.setState(this.props.location.state);

            if (this.props.location.state.currentStep === 1) {
                this.setState({ recipient: this.props.location.state.recipient });
                let is_ok = true;
                if (this.props.location.state.recipient === 'gift') {
                    for (let i = 0; i < required_field_step_one_gift.length; i++) {
                        let field_name = required_field_step_one_gift[i];
                        let field_name_text = required_field_step_one_gift[i].replace('_', ' ');

                        if (this.props.location.state[field_name] === "" || this.props.location.state[field_name] === null) {
                            this.setState({ [field_name + "_error"]: field_name_text + " is required" });
                            is_ok = false;
                        }
                        if (this.props.location.state[field_name] === undefined) {
                            is_ok = false;
                            this.setState({ [field_name]: '' });
                        }
                        else {
                            // set now state with state from link 
                            this.setState({ [field_name]: this.props.location.state[field_name] });
                        }
                    }

                    if (is_ok) {
                        axios.get(`${baseUrl}/rajaongkir/get_provinces`).then((res) => {
                            if (res.data.rajaongkir.status.code === 200) {
                                this.setState({
                                    listProvinces: res.data.rajaongkir.results.map(item => {
                                        // alert(window.location.pathname.split("/")[2]);
                                        return (
                                            {
                                                value: item.province_id,
                                                label: item.province
                                            }
                                        )
                                    })
                                }, () => {
                                    this.setState(previousState => ({
                                        listProvinces: [{ value: '', label: '' }, ...previousState.listProvinces]
                                    }));
                                    // console.log(this.state)
                                })
                            } else {
                                console.log(res.data.message, res.data.data)
                            }
                        }, (e) => {
                            console.log("Error : ", e);
                        })
                    }

                }
                if (is_ok) {
                    this.setState({
                        currentStep: 2
                    })
                }
            }
            else if (this.props.location.state.currentStep === 2) {
                this.getAllShippingService();
                // this.setState({ recipient: this.props.location.state.recipient });
                let is_ok = true;
                if (this.props.location.state.recipient === 'gift') {
                    for (let i = 0; i < required_field_step_two_gift.length; i++) {
                        let field_name = required_field_step_two_gift[i];
                        let field_name_text = required_field_step_two_gift[i].replace('_', ' ');

                        if (this.props.location.state[field_name] === "" || this.props.location.state[field_name] === null) {
                            this.setState({ [field_name + "_error"]: field_name_text + " is required" });
                            is_ok = false;
                        }
                        if (this.props.location.state[field_name] === undefined) {
                            is_ok = false;
                            this.setState({ [field_name]: '' });
                        }
                        else {
                            // set now state with state from link 
                            this.setState({ [field_name]: this.props.location.state[field_name] });
                        }
                    }
                }
                if (is_ok) {
                    this.setState({
                        currentStep: 3
                    })
                }
            }
            else if (this.props.location.state.currentStep === 3) {
                if (cartItemsContext.length > 0) {
                    axios.post(`${baseUrl}/teashop/store_order_from_cart`, this.props.location.state, requestConfig).then((res) => {
                        if (res.data.success) {
                            dispatchCartItems({
                                type: "REFRESH_CART",
                                state: [],
                            })
                            this.setState({
                                currentStep: 4,
                                va_number: res.data.data.va_number
                            })
                        } else {
                            console.log(res.data.message, res.data.data)
                        }
                    }, (e) => {
                        console.log("Error : ", e);
                    })
                }
            }
        }
        this.setState({ openBackdrop: false });
    }


    onProvinceChange = (e, newValue) => {
        // console.log(e);
        this.setState({ openBackdrop: true });
        if (newValue) {
            this.setState({
                province_id: newValue.value,
                province_name: newValue.label,
                city_disabled: false
            }
                , () => {
                }
            );

            axios.get(`${baseUrl}/rajaongkir/get_city_by_province/${newValue.value}`).then((res) => {
                if (res.data.rajaongkir.status.code === 200) {
                    this.setState({
                        listCities: res.data.rajaongkir.results.map(item => {
                            // alert(window.location.pathname.split("/")[2]);
                            return (
                                {
                                    value: item.city_id,
                                    label: item.type + ' ' + item.city_name
                                }
                            )
                        })
                    }, () => {
                        this.setState({
                            city_id: this.state.listCities[0].value,
                            city_name: this.state.listCities[0].label,
                            openBackdrop: false
                        }, () => {
                            this.getAllShippingService();
                        });
                        // console.log(this.state.listCities)
                    })
                } else {
                    this.setState({ openBackdrop: false });
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.setState({ openBackdrop: false });
                console.log("Error : ", e);
            })

            // this.setState({
            //     city_disabled: false,
            // })

        }
        else {
            this.setState({ openBackdrop: false });
        }
        // getDistrictsByRegency/{province_id}
    }

    onCityChange = (e, newValue) => {
        if (newValue != null)
            this.setState({
                city_id: newValue.value,
                city_name: newValue.label
            }, () => {
                this.getAllShippingService();
            });


    }

    getAllShippingService = async () => {
        this.setState({ openBackdrop: true });
        // const shipping_service = this.state.shipping_service.split('-');
        // const shippingServiceName = shipping_service[0]
        // const shippingServiceType = shipping_service[1]
        // console.log(shippingServiceName)
        const destination = this.state.city_id ? this.state.city_id : this.state.address.city_id

        await axios.post(`${baseUrl}/rajaongkir/get_cost`,
            { origin: 23, destination: destination, weight: this.state.total_weight, courier: 'jne' },
            requestConfig
        ).then((res) => {
            if (res.data.rajaongkir.status.code === 200) {

                this.setState({
                    listShippingService: res.data.rajaongkir.results[0].costs.map(item => {
                        // alert(window.location.pathname.split("/")[2]);
                        return (
                            {
                                value: item.cost[0].value,
                                service: item.service,
                                courier: 'jne',
                                label: 'JNE'
                            }
                        )
                    })
                }, () => {
                    this.setState({
                        shipping_service: this.state.listShippingService[0].courier + '-' + this.state.listShippingService[0].service,
                        openBackdrop: false,
                        btnContinuePaymentDisabled: false
                    });
                })

                // this.setState({
                //     listShippingService: [...this.state.listShippingService, res.data.rajaongkir.results[0].costs]
                // }, () => {
                //     this.setState({
                //         openBackdrop: false
                //     });
                //     console.log(this.state.listShippingService)
                // })
            } else {
                this.setState({ openBackdrop: false });
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            console.log("Error : ", e);
        })

        // await axios.post(`${baseUrl}/rajaongkir/get_cost`,
        //     { origin: 23, destination: this.state.city_id, weight: 1000, courier: 'tiki' },
        //     requestConfig
        // ).then((res) => {
        //     if (res.data.rajaongkir.status.code === 200) {
        //         this.setState({
        //             listShippingService: [...this.state.listShippingService, ...res.data.rajaongkir.results[0].costs.map(item => {
        //                 // alert(window.location.pathname.split("/")[2]);
        //                 return (
        //                     {
        //                         value: item.cost[0].value,
        //                         service: item.service,
        //                         courier: 'tiki',
        //                         label: 'TIKI'
        //                     }
        //                 )
        //             })]
        //         }, () => {
        //             this.setState({
        //                 openBackdrop: false
        //             });
        //             console.log(this.state.listShippingService)
        //         })
        //     } else {
        //         this.setState({ openBackdrop: false });
        //         console.log(res.data.message, res.data.data)
        //     }
        // }, (e) => {
        //     this.setState({ openBackdrop: false });
        //     console.log("Error : ", e);
        // })

        this.getShippingCost()
    }

    getShippingCost = async () => {
        if (this.state.shipping_service !== 'gosend') {
            const selectedShippingService = this.state.listShippingService.find(x => x.courier + '-' + x.service === this.state.shipping_service);

            if (selectedShippingService)
                this.setState({
                    shipping_cost: selectedShippingService.value
                })
        }
        else {
            this.getGosendCost()
        }
    }

    getGosendCost = () => {
        // List kode pos dalam range 5km:
        // - Laswi: 40273
        // - Gudang Utara: 40113
        // - Bangka: 40113
        // - Banda: 40115
        // - Seram: 40115
        // - Merdeka: 40113
        // - Jend A. Yani: 40113
        // - Kemuning: 40117
        // - Anggrek: 40113
        // - RE.Martadinata: 40113
        // - Asia Afrika: 40112
        // - Lengkong Besar: 40261
        // - Ibu Inggit Garnasih: 40242
        // - Otto Iskandar: 40111
        // - Tegalega: 40243
        // - Kiara Condong: 40281
        // - Ibrahim Adjie: 40283
        // - Terusan Jakarta: 40291

        const postal_code = this.state.postal_code ? this.state.postal_code : this.state.address.postal_code

        if (postal_code === '40273' || postal_code === '40113' || postal_code === '40115'
            || postal_code === '40117' || postal_code === '40112' || postal_code === '40261'
            || postal_code === '40242' || postal_code === '40111' || postal_code === '40243'
            || postal_code === '40281' || postal_code === '40283' || postal_code === '40291') {
            this.setState({
                shipping_cost: 10000,
                gosend_cost: 10000
            })
        }
        else {
            this.setState({
                shipping_cost: 15000,
                gosend_cost: 15000
            })
        }
    }

    onShippingServiceChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            if (this.state.city_id || this.state.address.city_id)
                this.getShippingCost()
        });
    }

    onPostalCodeChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            if (this.state.shipping_service === 'gosend')
                this.getGosendCost()
        });
    }

    renderStepOne = () => {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid item md={1} xs={12}>
                </Grid>
                <Grid item md={6} xs={12}>
                    <h1 className={classes.smallerTitle}>{dictionary['Product Buying Step 1 Headline'][this.context.language]},&nbsp;
                        {localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).user.first_name : null}
                    </h1>
                    <hr className={classes.borderHr}></hr>
                    <h5><b>{dictionary['Product Buying Step 1 Sub Headline'][this.context.language]}</b></h5>
                    <RecipientRadioButton
                        value={this.state.recipient}
                        onChange={this.onChange}>
                    </RecipientRadioButton>
                    {
                        this.state.recipient === "gift" ?
                            <React.Fragment>
                                <br />
                                <br />
                                <p style={{ marginBottom: 10, fontWeight: 'bold', fontSize: '1.1rem' }}>{dictionary['Sender Information'][this.context.language]}</p>
                                <Grid container className={classes.formContainer} spacing={3}>
                                    <Grid item sm={6} xs={12}>
                                        <CustomTextField
                                            value={this.state.sender_name}
                                            type="text" onChange={this.onChange} name="sender_name" label={`${dictionary['Sender Information'][this.context.language]} (${dictionary['optional'][this.context.language]})`} />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomTextField
                                            value={this.state.sender_phone}
                                            type="text" onChange={this.onChange} name="sender_phone" label={`${dictionary['Phone Number'][this.context.language]} (${dictionary['optional'][this.context.language]})`} />
                                    </Grid>
                                </Grid>
                                <br />
                                <br />
                                <p style={{ marginBottom: 10, fontWeight: 'bold', fontSize: '1.1rem' }}>{dictionary['Recipient Information'][this.context.language]}</p>
                                <Grid container className={classes.formContainer} spacing={3}>
                                    <Grid item sm={6} xs={12}>
                                        <CustomTextField
                                            value={this.state.first_name}
                                            error={this.state.first_name_error !== ""}
                                            helperText={this.state.first_name_error}
                                            type="text" onChange={this.onChange} name="first_name" label={dictionary['First Name'][this.context.language]} />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomTextField
                                            value={this.state.last_name}
                                            error={this.state.last_name_error !== ""}
                                            helperText={this.state.last_name_error}
                                            type="text" onChange={this.onChange} name="last_name" label={dictionary['Last Name'][this.context.language]} />
                                    </Grid>
                                </Grid>
                                <br />
                                <br />
                                <p style={{ marginBottom: 15, fontWeight: 'bold', fontSize: '1.1rem' }}>{dictionary['Choose Your Greeting Card'][this.context.language]}</p>

                                <Grid container className={classes.formContainer}>
                                    <Grid item sm={4} xs={6}>
                                        <img className={this.state.card_type === '1' ? classes.selectedIconType : classes.unselectedIconType} onClick={this.handleSelectIcon.bind(this, '1')} src={GreetingCard1} alt="home icon"></img>
                                    </Grid>
                                    <Grid item sm={4} xs={6}>
                                        <img className={this.state.card_type === '2' ? classes.selectedIconType : classes.unselectedIconType} onClick={this.handleSelectIcon.bind(this, '2')} src={GreetingCard2} alt="home icon"></img>
                                    </Grid>
                                    <Grid item sm={4} xs={6}>
                                        <img className={this.state.card_type === '3' ? classes.selectedIconType : classes.unselectedIconType} onClick={this.handleSelectIcon.bind(this, '3')} src={GreetingCard3} alt="home icon"></img>
                                    </Grid>
                                </Grid>
                                <br />
                                <br />
                                <p style={{ marginBottom: 15, fontWeight: 'bold', fontSize: '1.1rem' }}>{dictionary['Greeting Card Notes'][this.context.language]}</p>
                                <CustomTextField
                                    value={this.state.card_notes}
                                    type="text" onChange={this.onChange} name="card_notes" label={`(${dictionary['optional'][this.context.language]})`} />
                            </React.Fragment>
                            :
                            ''
                    }
                    <br />
                    <Link to={
                        {
                            pathname: `${this.props.prefix}/product_buying`,
                            state: this.state
                            // state: {
                            //     recipient: this.state.recipient,
                            //     first_name: this.state.first_name,
                            //     last_name: this.state.last_name,
                            //     phone_number: this.state.phone_number,
                            //     card_type: this.state.card_type,
                            //     currentStep: this.state.currentStep
                            // }
                        }
                    }>
                        <Button variant="outlined" className={classes.buttonDark} style={{ marginTop: 25 }}
                        >
                            {dictionary['Product Buying Step 1 Button'][this.context.language]}
                        </Button>
                    </Link>
                </Grid>
                <Grid item md={1} xs={12}>
                </Grid>
            </React.Fragment>
        )
    }

    handleSelectAddress = (address) => {
        this.setState({
            address: address,
            address_id: address.id,
            province_id: address.province_id,
            province_name: address.province_name,
            city_name: address.city_name,
            city_id: address.city_id,
        }, () => {
            this.getAllShippingService();
        })
    }

    handleDateChange = (newValue) => {
        this.setState({
            delivery_date: newValue
        })
    }
    renderStepTwo = () => {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid item md={1} xs={12}>
                </Grid>
                <Grid item md={6} xs={12}>
                    <h1 className={classes.smallerTitle}>{dictionary['Product Buying Step 2 Headline'][this.context.language]}!</h1>
                    <hr className={classes.borderHr}></hr>

                    {
                        this.state.recipient === "me" ?
                            <React.Fragment>
                                <p style={{ marginBottom: 15, fontWeight: 'bold', fontSize: '1.1rem' }}>{dictionary['Product Buying Step 2 Sub Headline'][this.context.language]}</p>
                                <Grid container spacing={2}>
                                    <AddressCard editMode={false} handleSelectAddress={this.handleSelectAddress}></AddressCard>
                                </Grid>
                            </React.Fragment>
                            :
                            <React.Fragment>

                                <p style={{ marginBottom: 15, fontWeight: 'bold', fontSize: '1.1rem' }}>{dictionary['Recipient Address Details'][this.context.language]}</p>
                                <Grid container className={classes.formContainer} spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <Autocomplete
                                            classes={{ root: classes.autoCompleteRoot, listbox: classes.autoCompletePaper, paper: classes.autoCompletePaper, option: classes.autoCompleteOption }}
                                            value={this.state.listProvinces[this.state.listProvinces.findIndex(city => city.value.toString() === this.state.province_id.toString())] || this.state.listProvinces[0]}
                                            name="province_id"
                                            onChange={this.onProvinceChange}
                                            options={this.state.listProvinces}
                                            getOptionLabel={(option) => option.label}
                                            // getOptionLabel={(option) => option.title}
                                            renderInput={(params) => <TextField {...params}
                                                label="Province" />}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Autocomplete
                                            classes={{ root: classes.autoCompleteRoot, paper: classes.autoCompletePaper, option: classes.autoCompleteOption }}
                                            value={this.state.listCities[this.state.listCities.findIndex(district => district.value.toString() === this.state.city_id.toString())] || this.state.listCities[0]}
                                            disabled={this.state.city_disabled}
                                            name="city_id"
                                            onChange={this.onCityChange}
                                            options={this.state.listCities}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => <TextField {...params} label="City" />}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <CustomTextField
                                            error={this.state.postal_code_error !== ""}
                                            helperText={this.state.postal_code_error}
                                            value={this.state.postal_code}
                                            name="postal_code" type="text"
                                            label="Zip Code (Wajib Harus Sesuai)" style={{ width: '100%' }} onChange={this.onPostalCodeChange} />
                                    </Grid>

                                    <Grid item md={6} xs={12}>
                                        <CustomTextField
                                            error={this.state.street_address_error !== ""}
                                            helperText={this.state.street_address_error}
                                            value={this.state.street_address}
                                            name="street_address" type="text" label="Address Details" style={{ width: '100%' }} onChange={this.onChange} />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <CustomTextField
                                            error={this.state.phone_no_error !== ""}
                                            helperText={this.state.phone_no_error}
                                            value={this.state.phone_no}
                                            name="phone_no" type="text" label="Number to Contact" style={{ width: '100%' }} onChange={this.onChange} />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <CustomTextField value={this.state.notes || ''} name="notes" type="text" label="Special Notes" style={{ width: '100%' }} onChange={this.onChange} />
                                    </Grid>
                                </Grid>
                            </React.Fragment>

                    }

                    <p style={{ marginTop: 40, marginBottom: 15, fontWeight: 'bold', fontSize: '1.1rem' }}>{dictionary['Delivery Method'][this.context.language]}</p>

                    <ShippingRadioButton
                        gosendCost={this.state.gosend_cost}
                        list={this.state.listShippingService}
                        city={this.state.city_id ? this.state.city_id : this.state.address.city_id}
                        value={this.state.shipping_service}
                        // onChange={this.onChange}>
                        onChange={this.onShippingServiceChange}>
                    </ShippingRadioButton>

                    {
                        this.state.shipping_service !== 'gosend' ?
                            <React.Fragment>
                                <p style={{ marginTop: 40, marginBottom: 15, fontWeight: 'bold', fontSize: '1.1rem' }}>{dictionary['Delivery Notes'][this.context.language]}</p>
                                <CustomTextField value={this.state.delivery_notes || ''} name="delivery_notes" type="text" label={`(${dictionary['optional'][this.context.language]})`} style={{ width: '100%' }} onChange={this.onChange} />
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <p style={{ marginTop: 40, marginBottom: 15, fontWeight: 'bold', fontSize: '1.1rem' }}>{dictionary['Delivery Time'][this.context.language]}</p>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Datepicker
                                            label="Select Date"
                                            name="delivery_date"
                                            value={this.state.delivery_date}
                                            handleDateChange={this.handleDateChange}>
                                        </Datepicker>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CustomTextfieldRaw
                                            select
                                            SelectProps={{
                                                MenuProps: {
                                                    classes: { paper: classes.autoCompletePaper }
                                                }
                                            }}
                                            label="Select Time"
                                            name="delivery_time"
                                            value={this.state.delivery_time}
                                            onChange={this.onChange}
                                        >
                                            {
                                                this.state.available_hours.map((item, i) => {
                                                    return <MenuItem classes={{ root: classes.menuItemOption }} value={item} key={item}>{item}</MenuItem>
                                                })
                                            }
                                        </CustomTextfieldRaw>
                                        {/* <TextField
                                            name="delivery_time"
                                            onChange={this.onChange}
                                            id="time"
                                            label="Select Time"
                                            type="time"
                                            defaultValue="08:30"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300, // 5 min
                                            }}
                                        /> */}
                                    </Grid>
                                </Grid>

                                <div style={{ marginTop: 20 }}>
                                    <img src={InfoIcon} alt="close icon" style={{ float: 'left', marginRight: 10 }}></img>
                                    <span>
                                        Delivery will be proceed after payment is received by our admin and delivery time might be adjusted as well.
                                    </span>
                                </div>
                            </React.Fragment>
                    }

                    {
                        this.state.btnContinuePaymentDisabled ?
                            ''
                            :
                            <Link to={
                                {
                                    pathname: `${this.props.prefix}/product_buying`,
                                    state: this.state
                                }
                            }>
                                <Button variant="outlined" className={classes.buttonDark} style={{ marginTop: 25 }}
                                    disabled={this.state.btnContinuePaymentDisabled}
                                >
                                    {dictionary['Product Buying Step 2 Button'][this.context.language]}
                                </Button>
                            </Link>
                    }

                </Grid>
                <Grid item md={1} xs={12}>
                </Grid>
            </React.Fragment>
        )
    }

    handleVoucherCheck = async () => {

        await axios.post(`${baseUrl}/vouchers/check_exist`,
            { code: this.state.code },
            requestConfig
        ).then((res) => {
            if (res.data.success) {

                axios.post(`${baseUrl}/vouchers/check_requirements`,
                    { voucher_id: res.data.data.id, city_id: this.state.city_id ? this.state.city_id : this.state.address.city_id },
                    requestConfig
                ).then((res) => {
                    if (res.data.success) {
                        var discount = 0;

                        if (res.data.data.type === 'shipping_cost') {
                            discount = this.state.shipping_cost
                        }
                        else if (res.data.data.type === 'percentage') {
                            discount = this.state.total_amount * (res.data.data.discount / 100)
                        }
                        else {
                            discount = res.data.data.discount
                        }

                        // set to max discount if more than max_discount
                        if (discount > res.data.data.max_discount) {
                            discount = res.data.data.max_discount
                            // alert(discount)
                        }

                        if (discount > 0) {
                            this.setState({
                                discount: discount
                            }, () => {
                                this.setState({
                                    openBackdrop: false,
                                    code_success: res.data.message,
                                    code_error: ''
                                });
                            })
                        }

                        // this.setState({
                        //     listShippingService: [...this.state.listShippingService, res.data.rajaongkir.results[0].costs]
                        // }, () => {
                        //     this.setState({
                        //         openBackdrop: false
                        //     });
                        //     console.log(this.state.listShippingService)
                        // })
                    } else {
                        this.setState({
                            openBackdrop: false,
                            code_error: res.data.message,
                            code_success: '',
                            discount: 0,
                            code: ''
                        });
                        // console.log(res.data.message, res.data.data)
                    }
                }, (e) => {
                    this.setState({ openBackdrop: false });
                    console.log("Error : ", e);
                })

            } else {
                this.setState({
                    openBackdrop: false,
                    code_error: res.data.message,
                    code_success: '',
                    discount: 0,
                    code: ''
                });
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            console.log("Error : ", e);
        })
    }

    renderStepThree = () => {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid item md={1} xs={12}>
                </Grid>
                <Grid item md={6} xs={12}>
                    <h1 className={classes.smallerTitle}>{dictionary['Product Buying Step 3 Headline'][this.context.language]}?</h1>
                    <hr className={classes.borderHr}></hr>

                    <p style={{ marginBottom: 15, fontWeight: 'bold', fontSize: '1.1rem' }}>{dictionary['Havilla Voucher Code'][this.context.language]}</p>
                    <p>{dictionary['Havilla Voucher Code Desc'][this.context.language]}</p>
                    <CustomTextField
                        error={this.state.code_error !== ""}
                        helperText={this.state.code_success ? this.state.code_success : this.state.code_error}
                        value={this.state.code || ''}
                        name="code" type="text"
                        label="Voucher Code"
                        onChange={this.onChange}
                        style={{ width: '100%' }} />

                    <Button variant="outlined" className={classes.buttonDark} style={{ marginTop: 25, width: 250 }}
                        onClick={this.handleVoucherCheck}>
                        {dictionary['Check'][this.context.language]}
                    </Button>

                    <p style={{ marginTop: 30, marginBottom: 15, fontWeight: 'bold', fontSize: '1.1rem' }}>{dictionary['Payment Method'][this.context.language]}</p>

                    <PaymentRadioButton
                        value={this.state.payment_method}
                        onChange={this.onChange}>
                    </PaymentRadioButton>
                    <Link to={
                        {
                            pathname: `${this.props.prefix}/product_buying`,
                            state: this.state
                        }
                    }>
                        <Button variant="outlined" className={classes.buttonDark} style={{ marginTop: 15 }}
                        >
                            {dictionary['Pay Now'][this.context.language]}
                        </Button>
                    </Link>
                </Grid>
                <Grid item md={1} xs={12}>
                </Grid>
            </React.Fragment>
        )
    }

    handleCopy = () => {
        navigator.clipboard.writeText(this.state.va_number);
        this.setState({
            va_copied_info: 'VA Number Copied!'
        })
    }

    renderFinalPage = () => {
        ReactPixel.trackCustom("Purchase", this.state);
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Box clone order={{ xs: 2, sm: 1 }}>
                    <Grid item xl={7} md={6} xs={12} className={classes.thankYouContainer}>
                        <h1 className={classes.title}>
                            {dictionary['Product Buying Step Finish Headline'][this.context.language]}!
                        </h1>
                        <p>{dictionary['Please finish your payment in 1x24 hour'][this.context.language]}.</p>
                        <p>{dictionary['Otherwise, we are very sorry that we cannot continue to proceed your order'][this.context.language]}.</p>
                        <br />
                        <p>{dictionary['Here is your Virtual Account Number'][this.context.language]}:</p>

                        <div className={classes.VAContainer}>
                            <span className={classes.VANo}>{this.state.va_number}</span>
                            <img className={classes.copasIcon} src={CopasIcon} alt="close icon"
                                onClick={this.handleCopy}
                            ></img>
                            <p style={{ marginTop: 5 }}>{this.state.va_copied_info}</p>
                        </div>
                        <a href="https://www.klikbca.com/" target="_blank">
                            <Button variant="outlined" className={`${classes.buttonLight} ${classes.buttonLightCustom}`}>
                                {dictionary['to KlikBCA website'][this.context.language]}
                            </Button>
                        </a>
                        &nbsp;&nbsp;
                        <Link to={`${this.props.prefix}/profile/order`}>
                            <Button variant="outlined" className={`${classes.buttonDark} ${classes.buttonDarkCustom}`}>
                                {dictionary['View my Order'][this.context.language]}
                            </Button>
                        </Link>
                    </Grid>
                </Box>
                <Box clone order={{ xs: 1, sm: 2 }}>
                    <Grid item xl={5} md={6} xs={12} className={classes.thankYouWallpaper}>
                    </Grid>
                </Box>
            </React.Fragment >
        )
    }


    handleTotalAmountChange = (total) => {
        this.setState({
            total_amount: total
        })
    }

    handleTotalWeightChange = (total) => {
        this.setState({
            total_weight: total
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.openBackdrop !== nextState.openBackdrop) return true
        if (this.state.sender_name !== nextState.sender_name) return true
        if (this.state.sender_phone !== nextState.sender_phone) return true
        if (this.state.first_name !== nextState.first_name) return true
        if (this.state.last_name !== nextState.last_name) return true
        if (this.state.card_notes !== nextState.card_notes) return true
        if (this.state.first_name_error !== nextState.first_name_error) return true
        if (this.state.last_name_error !== nextState.last_name_error) return true
        if (this.state.card_type !== nextState.card_type) return true
        if (this.state.listProvinces !== nextState.listProvinces) return true
        if (this.state.listCities !== nextState.listCities) return true
        if (this.state.city_disabled !== nextState.city_disabled) return true

        if (this.state.province_id !== nextState.province_id) return true
        if (this.state.city_id !== nextState.city_id) return true
        if (this.state.province_name !== nextState.province_name) return true
        if (this.state.city_name !== nextState.city_name) return true

        if (this.state.postal_code !== nextState.postal_code) return true
        if (this.state.street_address !== nextState.street_address) return true
        if (this.state.phone_no !== nextState.phone_no) return true
        if (this.state.notes !== nextState.notes) return true
        if (this.state.postal_code_error !== nextState.postal_code_error) return true
        if (this.state.street_address_error !== nextState.street_address_error) return true
        if (this.state.phone_no_error !== nextState.phone_no_error) return true
        if (this.state.notes_error !== nextState.notes_error) return true
        if (this.state.currentStep !== nextState.currentStep) return true
        if (this.state.recipient !== nextState.recipient) return true
        if (this.state.address !== nextState.address) return true
        if (this.state.shipping_service !== nextState.shipping_service) return true
        if (this.state.listShippingService !== nextState.listShippingService) return true
        if (this.state.shipping_cost !== nextState.shipping_cost) return true
        if (this.state.gosend_cost !== nextState.gosend_cost) return true
        if (this.state.code !== nextState.code) return true
        if (this.state.code_error !== nextState.code_error) return true
        if (this.state.code_success !== nextState.code_success) return true
        if (this.state.discount !== nextState.discount) return true
        if (this.state.payment_method !== nextState.payment_method) return true

        if (this.state.delivery_notes !== nextState.delivery_notes) return true
        if (this.state.delivery_date !== nextState.delivery_date) return true
        if (this.state.delivery_time !== nextState.delivery_time) return true

        if (this.state.total_amount !== nextState.total_amount) return true
        if (this.state.total_weight !== nextState.total_weight) return true
        if (this.state.va_copied_info !== nextState.va_copied_info) return true
        // total_amount: 0
        return false
    }

    render() {
        const { classes } = this.props;
        return (
            <Container maxWidth="xl" className={`${classes.container}`}
                style={this.state.currentStep === 4 ? { width: '100%', padding: 0, margin: 0 } : {}}>

                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{ 'color': '#AF986D' }} />
                </Backdrop>
                {
                    this.state.currentStep !== 4 ?
                        <Grid container className={classes.headerContainer}>
                            <Grid item md={1} xs={12}>
                            </Grid>
                            <Grid item md={7} xs={10}>
                                <span className={this.state.currentStep === 1 ? classes.onState : classes.offState}>{dictionary['Contacts'][this.context.language]}</span>
                                <span className={classes.offState}>------</span>
                                <span className={this.state.currentStep === 2 ? classes.onState : classes.offState}>{dictionary['Delivery'][this.context.language]}</span>
                                <span className={classes.offState}>------</span>
                                <span className={this.state.currentStep === 3 ? classes.onState : classes.offState}>{dictionary['Payment'][this.context.language]}</span>
                            </Grid>
                            <Grid item md={3} xs={2}>
                                <Link to={`${this.props.prefix}/tea_shop`}>
                                    <img className={classes.closeIcon} src={CloseIcon} alt="close icon" onClick={this.handleClose}></img>
                                </Link>
                            </Grid>
                            <Grid item md={1} xs={12}>
                            </Grid>
                        </Grid>
                        :
                        ''
                }

                <Grid container className={classes.formContainer}>

                    {this.state.currentStep === 1 ?
                        this.renderStepOne()
                        : this.state.currentStep === 2 ?
                            this.renderStepTwo()
                            : this.state.currentStep === 3 ?
                                this.renderStepThree()
                                :
                                this.renderFinalPage()
                    }
                    {
                        this.state.currentStep !== 4 ?
                            <React.Fragment>
                                <Grid item md={3} xs={12} className={classes.colOrderSummary}>
                                    <OrderSummary
                                        handleTotalAmountChange={this.handleTotalAmountChange}
                                        handleTotalWeightChange={this.handleTotalWeightChange}
                                        discount={this.state.discount}
                                        shippingCost={this.state.shipping_cost}
                                        openCart={this.props.openCart} />
                                </Grid>
                                <Grid item md={1} xs={12}>
                                </Grid>
                            </React.Fragment>
                            :
                            ''
                    }

                </Grid>
            </Container>
        )
    }
}

export default withStyles(useStyles)(ProductBuying)
