import React, { Component } from 'react'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import { Grid, Button, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';

import Suddhabumi from "assets/img/havilla/Suddhabumi.png";
import Chamomile from "assets/img/havilla/own_blend_square.jpg";
import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp';
import CloseIcon from "assets/img/havilla/close_icon_black.svg";
import ArrowIcon from "assets/img/havilla/back_arrow.png";
import { ClientSideContext } from '../../ClientSideContext';
import { dictionary } from '../../../Dictionary';

import axios from 'axios';
import {
  baseUrl,
  requestConfig
} from '../../../Environment.js';
const useStyles = theme => ({
  ...styles(theme),
  modal: {
    // position:'absolute',
    // bottom: '0',
    // width: '100%'
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  paper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    backgroundColor: 'white',
    color:'black',
    boxShadow: theme.shadows[5],
    position: 'absolute',
    right: 0,
    width: '40%',
    height: '100%',
  },
  closeIcon: {
    position: 'absolute',
    filter: 'brightness(1000%)',
    right: 40,
    top: 45,
    cursor: 'pointer',
    transform: 'scaleX(-1)'
  },
  deleteIcon: {
    position: 'absolute',
    right: 0,
    top: 50,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      right: 10,
    },
  },
  buttonVarian: {
    borderRadius: 0,
    background: 'white',
    border: '1px solid black',
    color: 'black',
    '&:hover': {
      backgroundColor: "black",
      color: "white"
    },
    '&:focus': {
      backgroundColor: "black",
      color: "white"
    },
    marginTop: 4
  },
  selectedButtonVarian: {
    borderRadius: 0,
    backgroundColor: "black",
    border: '1px solid black',
    color: "white",
    marginTop: 4,
    '&:hover': {
      backgroundColor: "black",
      color: "white"
    },
    '&:focus': {
      backgroundColor: "black",
      color: "white"
    },
  },
  price: {
    position: 'absolute',
    right: 30,
    bottom: 22
  },
  atcContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      marginBottom: 20
    },
    marginTop: 5
  },
  btnAtcContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: 20,
      paddingRight: 0
    },
    paddingRight: 20
  },
  chooseVariant: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'left'
    },
    marginTop: 12, textAlign: 'right', paddingRight: 15
  },
  productBackground: {
    background: '#f0f0f0',
    height: 'auto',
    width: '100%',
    // padding: '0 20px',
    position: 'relative'
  },
  productImage: {
    width: '100%',
  },
  productSubCategory: {
    fontSize: '0.7rem',
    border: '1px solid grey',
    borderRadius: '16px',
    padding: '2px 10px 0',
    textTransform: 'uppercase',
    width: 'auto'
  },
  titleCart: {
    position: 'absolute',
    height: '20%',
    background: '#222222',
    color: 'white',
    width: '100%',
    padding: '3rem 3rem 3rem',
  },
  cartContainer: {
    position: 'absolute',
    top: '20%',
    width: '100%',
    padding: '2rem 3rem 3rem',
    // background: 'black',
    maxHeight: '60%',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  productContainer: {
    position: 'relative',
  },
  totalContainer: {
    padding: '1rem 3rem 3rem',
    position: 'absolute',
    top: '80%',
    width: '100%',
    height: '20%'
  },
  borderHr: {
    position: 'absolute',
    width: '90%',
    left: '0',
    right: '0',
    margin: 'auto',
    border: '1px solid #f0f0f0',
  },
  qtyInput: {
    width: 40,
    marginTop: 5,
    float: 'left',
    margin: '0 15px',
  },
  inputQty: {
    color: '#AF986D'
  },
  btnCircle: {
    marginTop: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '1.1rem',
    borderRadius: '1rem',
    border: '2px solid #AF986D',
    color:'#AF986D',
    width: 25,
    height: 25,
    cursor: 'pointer',
    float: 'left'
  }
});

export class CustomModalCart extends Component {
  _isMounted = false;
  static contextType = ClientSideContext;

  constructor(props) {
    super(props);

    this.state = {
      language: 'en',
      // price: 0,
      // selectedVarian: 0,
      // sub_product_id: '',

      snackbarType: 'success',
      snackbarMessage: '',

      cartItems: [],
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount = async () => {
    this.setState({
      language: this.context.language
    })
    const { dispatchCartItems } = this.context;
    this._isMounted = true;
    if (localStorage.getItem('auth')) {
      await axios.get(`${baseUrl}/carts`, requestConfig).then((res) => {
        if (res.data.success) {
          // localStorage.setItem('cart', JSON.stringify(res.data.data));
          if (this._isMounted) {
            dispatchCartItems({
              type: "REFRESH_CART",
              state: res.data.data,
            })
            // localStorage.setItem('cart', JSON.stringify(res.data.data));
            // this.setState({
            //   cartItems: JSON.parse(localStorage.getItem('cart'))
            // })
          }
        } else {
          // this.setState({ openBackdrop: false });
          console.log(res.data.message, res.data.data)
        }
      }, (e) => {
        // this.setState({ openBackdrop: false });
        console.log("Error : ", e);
      })
    }
    else {
      if (!localStorage.getItem('cart')) {
        dispatchCartItems({
          type: "REFRESH_CART",
          state: [],
        })
      }
    }
  }

  handleClose = () => {
    this.props.handleCloseModal(false);
  };

  handleDeleteCartItem = async (e, cartItemId) => {
    const { dispatchCartItems } = this.context;

    await dispatchCartItems({
      type: "DELETE_FROM_CART",
      cartItemId: cartItemId,
    })

    this.setState({
      cartItems: JSON.parse(localStorage.getItem('cart'))
    })
    // filter(todo => todo.id !== id)
    if (localStorage.getItem('auth')) {
      await axios.delete(`${baseUrl}/carts/${cartItemId}`,
        requestConfig)
        .then((res) => {
          if (res.data.success) {
            // this.setState({
            //   cartItems: this.state.cartItems.filter(cartItem => cartItemId !== cartItem.id)
            // })
          } else {
            console.log(res.data.message, res.data.data)
          }
        }, (e) => {
          console.log("Error : ", e);
        })
    }
  }

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  handleChangeQty = async (e, cartItemId, operator, startingQty) => {
    const { dispatchCartItems } = this.context;
    var newValue = undefined;
    if (operator) {
      const math_it_up = {
        '+': function (x, y) { return x + y },
        '-': function (x, y) { return x - y }
      };
      newValue = math_it_up[operator](startingQty, 1);
    }
    else {
      newValue = parseInt(e.target.value);
    }

    await dispatchCartItems({
      type: "CHANGE_QTY_CART",
      cartItemId: cartItemId,
      newValue: newValue
    })

    this.setState({
      cartItems: JSON.parse(localStorage.getItem('cart'))
    })

    if (localStorage.getItem('auth')) {
      if (newValue > 0) {
        await axios.put(`${baseUrl}/carts/${cartItemId}`,
          {
            qty: newValue
          },
          requestConfig)
          .then((res) => {
            if (res.data.success) {
              // console.log('success update qty cart')
            } else {
              console.log(res.data.message, res.data.data)
            }
          }, (e) => {
            console.log("Error : ", e);
          })
      }
    }
  }
  // handleAddToCart = async () => {
  //   const state = this.state;
  //   await axios.post(`${baseUrl}/add_to_cart`,
  //     state,
  //     requestConfig)
  //     .then((res) => {
  //       if (res.data.success) {
  //         this.handleClose();
  //         this.setState({
  //           snackbarType: 'success',
  //           snackbarMessage: 'Success Added To Cart',
  //         });
  //       } else {
  //         console.log(res.data.message, res.data.data)
  //         this.setState({
  //           snackbarType: 'failed',
  //           snackbarMessage: 'Failed Added To Cart',
  //         });
  //       }
  //     }, (e) => {
  //       console.log("Error : ", e);
  //       this.setState({
  //         snackbarType: 'failed',
  //         snackbarMessage: 'Failed Added To Cart',
  //       });
  //     })
  // }
  renderCartItems = () => {
    const { classes } = this.props;
    const { cartItems } = this.context;

    var cartItemsContext;
    try {
      cartItemsContext = JSON.parse(cartItems)
    } catch (e) {
      cartItemsContext = []
    }
    // const { cartItems } = this.state;
    // var cartItems = []
    // if (localStorage.getItem('cart')) {
    //     const LS = localStorage.getItem('cart')
    //     cartItems = JSON.parse(LS);
    // }

    if (cartItemsContext.length > 0) {
      return cartItemsContext.map((item, i) => {
        return (
          <Grid container spacing={3} className={classes.productContainer} key={item.id}>
            <Grid item xs={3}>
              <img
                className={classes.deleteIcon} src={CloseIcon} alt="close icon"
                onClick={(e) => this.handleDeleteCartItem(e, item.id)}>
              </img>

              {
                item.is_own === 0 ?
                  <Link to={`${this.props.prefix}/product/${item.sub_product.product.url_slug}`}>
                    {
                      item.thumbnail_url !== null ?
                        <Grid container className={classes.productBackground} alignItems="center" justify="center">
                          <img src={
                            this.IsJsonString(item.thumbnail_url) == true ?
                              JSON.parse(item.thumbnail_url)[0]
                              :
                              item.thumbnail_url
                            // typeof (item.thumbnail_url) == "string" ?
                            //   item.thumbnail_url
                            //   :
                            //   JSON.parse(item.thumbnail_url)[0]
                          } className={classes.productImage}></img>
                        </Grid>
                        :
                        <Grid container className={classes.productBackground} alignItems="center" justify="center">
                          <img src={Suddhabumi} className={classes.productImage}></img>
                        </Grid>
                    }
                  </Link>
                  :
                  <Grid container className={classes.productBackground} alignItems="center" justify="center">
                    <img src={Chamomile} alt='product img' className={classes.productImage}></img>
                  </Grid>
              }


            </Grid>
            <Grid item xs={6} style={{ paddingTop: 20 }}>
              {
                item.is_own === 0 ?
                  item.sub_product ?
                    item.sub_product.product.product_sub_category ?
                      <React.Fragment>
                        <span className={classes.productSubCategory}>
                          {item.sub_product.product.product_sub_category.name}
                        </span>
                        <p style={{ marginTop: 10 }}>{item.sub_product.product.name} - {item.sub_product.varian}</p>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <span className={classes.productSubCategory}>
                          {item.sub_product.product.product_category.name}
                        </span>
                        <p style={{ marginTop: 10 }}>{item.sub_product.product.name} - {item.sub_product.varian}</p>
                      </React.Fragment> : ''
                  :
                  <React.Fragment>
                    <span className={classes.productSubCategory}>
                      Unique Blend
                      </span>
                    <p style={{ marginTop: 10 }}>{item.own_product.name} <br />
                      {
                        item.own_product.own_product_details.map((detail, i) => {
                          return '[' + detail.own_product_ingredient[`name_${this.context.language}`] + '] '
                        })
                      }
                    </p>
                  </React.Fragment>
              }
              <div className={`${classes.btnCircle} noselect`} onClick={(e) => this.handleChangeQty(e, item.id, '-', item.qty)}>
                -
                </div>
              <TextField
                onChange={(e) => this.handleChangeQty(e, item.id, null, null)}
                className={classes.qtyInput}
                value={item.qty}
                // label="Number"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                    className: classes.inputQty,
                }}
              />
              <div className={`${classes.btnCircle} noselect`} onClick={(e) => this.handleChangeQty(e, item.id, '+', item.qty)}>
                +
              </div>
            </Grid>
            <Grid item xs={3} style={{ paddingTop: 60 }}>
              {
                item.is_own === 0 ?
                  item.sub_product ?
                    item.sub_product.discount !== null && item.sub_product.discount > 0 ?
                      <p>Rp. {(item.sub_product.price - (item.sub_product.price * (item.sub_product.discount / 100))).toLocaleString('ID')}</p>
                      :
                      <p>Rp. {item.sub_product.price.toLocaleString('ID')}</p>
                    : ''
                  :
                  <p>Rp. {item.own_product.price.toLocaleString('ID')}</p>
              }
            </Grid>
          </Grid>
        )
      })
    }
    else {
      return (
        <p style={{ textAlign: 'center' }}>You have no item in your cart.</p>
      )
    }
  }

  countTotal = () => {
    // const { cartItems } = this.state;
    // var cartItems = []
    // if (localStorage.getItem('cart')) {
    //   const LS = localStorage.getItem('cart')
    //   cartItems = JSON.parse(LS);
    // }

    const { cartItems } = this.context;

    var cartItemsContext;
    try {
      cartItemsContext = JSON.parse(cartItems)
    } catch (e) {
      cartItemsContext = []
    }

    if (cartItemsContext) {
      return cartItemsContext.reduce((total, cartItem) => {
        return total + (cartItem.qty * (
          cartItem.is_own === 0 ?
            cartItem.sub_product ?
              cartItem.sub_product.discount !== null && cartItem.sub_product.discount > 0 ?
                (cartItem.sub_product.price - (cartItem.sub_product.price * (cartItem.sub_product.discount / 100)))
                :
                cartItem.sub_product.price
              :
              0
            :
            cartItem.own_product.price
        ))
      }, 0);
    }
    return 0
  }

  render() {
    const { classes } = this.props;
    const { cartItems } = this.context;

    var cartItemsContext;
    try {
      cartItemsContext = JSON.parse(cartItems)
    } catch (e) {
      cartItemsContext = []
    }

    return (
      <React.Fragment>
        <SnackbarComp
          snackbarType={this.state.snackbarType}
          snackbarMessage={this.state.snackbarMessage}
          counter={this.state.counter}
        />
        <Modal
          className={classes.modal}
          open={this.props.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Slide direction="left" in={this.props.open} mountOnEnter unmountOnExit>
            <div className={classes.paper}>
              <Grid container>
                <Grid item xs={12} className={classes.titleCart}>
                  <h3 className={classes.smallerTitle}>
                    {dictionary['My Cart'][this.context.language]}
                  </h3>
                  {/* <p>(Free Shipping for min purchase 200k (Jabodetabek Only)</p> */}
                  <img className={classes.closeIcon} src={ArrowIcon} alt="close icon" onClick={this.handleClose}></img>
                </Grid>
                <Grid item xs={12} className={classes.cartContainer}>
                  {
                    this.renderCartItems()
                  }
                </Grid>
                <Grid item xs={12} className={classes.totalContainer}>
                  <hr className={classes.borderHr}></hr>
                  <p style={{ margin: '25px 0' }}><b>
                    {dictionary['Grand Total'][this.context.language]}
                    <span style={{ float: 'right' }}>Rp. {this.countTotal().toLocaleString('ID')}</span></b></p>
                  {
                    cartItemsContext.length > 0 ?
                      localStorage.getItem('auth') ?
                        <Link to={`${this.props.prefix}/product_buying`} onClick={this.handleClose}>
                          <Button variant="outlined" className={classes.buttonDark} style={{ width: '100%' }}
                            onClick={this.handleAddToCart}>
                            {dictionary['Proceed to Checkout'][this.context.language]}
                          </Button>
                        </Link>
                        :
                        <Link to={`${this.props.prefix}/login`} onClick={this.handleClose}>
                          <Button variant="outlined" className={classes.buttonDark} style={{ width: '100%' }}
                            onClick={this.handleAddToCart}>
                            Please Login / Register to Checkout</Button>
                        </Link>
                      :
                      <Link to={`${this.props.prefix}/tea_shop`} onClick={this.handleClose}>
                        <Button variant="outlined" className={classes.buttonDark} style={{ width: '100%' }}
                          onClick={this.handleAddToCart}>
                          Let's Add Item to Your Cart
                        </Button>
                      </Link>
                  }

                </Grid>
              </Grid>
            </div>
          </Slide>
        </Modal >
      </React.Fragment >
    );
  }
}

export default withStyles(useStyles)(CustomModalCart)