import React, { Component } from 'react'
import axios from 'axios';
import { baseUrl } from 'Environment';
import { requestConfig } from 'Environment';
import { ClientSideContext } from '../../ClientSideContext';
import styles from "assets/jss/client-side/App.js";
import { withStyles, Container, Button, Grid } from '@material-ui/core';
import LoadingScreenTimer from 'client-side/components/LoadingScreen/LoadingScreenTimer';
import FlickitySummary from './FlickitySummary';
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp';
import { dictionary } from 'Dictionary';
import Fade from '@material-ui/core/Fade';

const useStyles = theme => ({
    ...styles(theme),
    pageStepContainer: {
        padding: '0px'
    },
    stepContainer: {
    },
    pageTitle: {
        paddingLeft: '12px',
        paddingRight: '12px',
        marginTop: '2rem',
        fontSize: '28px',
        textAlign: 'center',
        fontFamily: 'BlaakLight',
    },
    pageDesc: {
        paddingLeft: '12px',
        paddingRight: '12px',
        marginTop: '16px',
        fontSize: '16px',
        fontFamily: 'Martel Sans',
        textAlign: 'center',
    },
    priceContainer: {
        textAlign: 'center',
        fontSize: '24px',
        fontFamily: 'Martel Sans',
        marginTop: '32px',
    },
    summaryContainer: {
        textAlign: 'center',
        fontSize: '12px',
        fontFamily: 'Martel Sans',
        marginTop: '4px',

    }
});

export class PageStep extends Component {
    static contextType = ClientSideContext;
    constructor(props) {
        super(props)

        this.state = {
            openBackdrop: false,
            language: '',

            currentStep: 1,
            previous_step: null,

            selected_ingredient_flickity: null,
            selected_index_flickity: 0,

            own_product: [],
            own_product_text: "",
            summary_text: "",
            price: "",
            extras: [],

            snackbarType: 'info',
            snackbarMessage: '',
            snackbarCounter: 0,
            loadIngredients: false
        }

    }

    numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    openBackdrop = () => {
        this.setState({
            openBackdrop: true
        })
    }

    closeBackdrop = () => {
        this.setState({
            openBackdrop: false
        })
    }

    handleMakeTextIngredient = () => {
        let ingredients = this.state.own_product;
        let temp_text = dictionary['My Blend Is'][this.state.language];

        for (let i = 0; i < ingredients.length; i++) {
            const ingredient = ingredients[i];
            temp_text += i === 0 ? ` ${ingredient[`name_${this.state.language}`]}` : ` + ${ingredient[`name_${this.state.language}`]}`
        }

        this.setState({
            own_product_text: temp_text
        })
    }

    handleMakeTextSummary = () => {
        let extras = this.state.extras;
        let temp_text = `(${dictionary['1 Basic Blend'][this.state.language]}`;

        for (let i = 0; i < extras.length; i++) {
            const extra = extras[i];
            temp_text += i === (extras.length - 1) ? ` + ${extra.value} ${extra[`name_${this.state.language}`]}` : ` + ${extra.value} ${extra[`name_${this.state.language}`]}`
        }

        this.setState({
            summary_text: temp_text + ")"
        })
    }

    getPrice = () => {
        this.openBackdrop();
        let data = {
            own_product_ingredients: JSON.stringify(this.state.own_product)
        }

        axios.post(`${baseUrl}/ownproducts/get_price`, data, requestConfig).then((res) => {
            if (res.data.success) {
                this.setState({
                    price: res.data.data.price,
                    extras: res.data.data.extras,
                    loadIngredients: true
                }, () => {
                    this.handleMakeTextSummary();
                    this.closeBackdrop();
                })
            } else {
                this.handleOpenSnackbar({
                    type: "error",
                    message: res.data.message
                })
                this.closeBackdrop();
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            this.closeBackdrop();
            console.log("Error : ", e);
        })
    }

    componentDidUpdate(nextProps, nextState) {
        if (this.state.language !== this.context.language) {
            this.setState({
                language: this.context.language
            }, () => {
                this.handleMakeTextIngredient();
            })
        }

        return true;
    }

    componentDidMount() {
        this.setState({
            language: this.context.language
        })
        const { ownProductIngredients } = this.context;
        const ownProductIngredientsContext = JSON.parse(ownProductIngredients);

        if (ownProductIngredientsContext.length > 0) {
            let prev_step = this.props.location.state !== undefined ? this.props.location.state.prev_step : null
            this.setState({
                own_product: ownProductIngredientsContext,
                // selected_index_flickity : Math.round((ownProductIngredientsContext.length - 1) / 2),
                previous_step: prev_step,
            }, () => {
                this.getPrice();
                this.handleMakeTextIngredient();
            });
        } else {
            this.props.history.push({
                pathname: this.props.prefix + "/create_your_own/create"
            })
        }
    }

    handleSubmitStep = () => {
        this.props.history.push({
            pathname: this.props.prefix + "/create_your_own/complete"
        })
    }


    render() {
        const { classes } = this.props

        return (
            <Container maxWidth="xl" className={`${classes.container} ${classes.pageStepContainer}`}>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.snackbarCounter}
                />
                {
                    this.state.openBackdrop ?
                        <LoadingScreenTimer language={this.state.language} />
                        :
                        <Grid container justify="center" className={classes.stepContainer}>
                            {
                                this.state.own_product.length > 0 ?
                                    <React.Fragment>
                                        <Grid item xs={12} className={classes.pageTitle}>
                                            {dictionary['Own Blend Summary Headline'][this.state.language]}
                                        </Grid>
                                        <Grid item xs={12} className={classes.pageDesc}>
                                            {this.state.own_product_text}
                                        </Grid>
                                        <Fade in={this.state.loadIngredients} timeout={700}>
                                            <Grid item xs={12}>
                                                <FlickitySummary
                                                    selected_index={this.state.selected_index_flickity}
                                                    own_product={this.state.own_product}
                                                    language={this.state.language}
                                                />
                                            </Grid>
                                        </Fade>
                                        <Grid item xs={12} className={classes.priceContainer}>
                                            <b>{dictionary['Your Price'][this.state.language]}: </b> Rp{this.numberWithCommas(this.state.price)}
                                        </Grid>
                                        <Grid item xs={12} className={classes.summaryContainer}>
                                            {this.state.summary_text}
                                        </Grid>
                                        <Grid item xs={12}
                                            container
                                            spacing={2}
                                            justify="center"
                                            alignItems="center"
                                            style={{
                                                marginTop: '20px',
                                                marginBottom: '5px'
                                            }}
                                        >
                                            <Grid item xs={12} md={2}>
                                                <Button variant="outlined" className={classes.buttonLight} style={{ width: '100%' }}
                                                    onClick={() => this.props.history.goBack()}
                                                >
                                                    {this.state.previous_step ? `${dictionary['Back To'][this.state.language]} ${this.state.previous_step[`name_${this.state.language}`]}` : "Back"}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <Button variant="outlined" className={classes.buttonDark} style={{ width: '100%' }}
                                                    onClick={this.handleSubmitStep}
                                                >
                                                    {dictionary['Own Blend Summary Button'][this.state.language]}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                    :
                                    null
                            }
                        </Grid>
                }

            </Container>
        )
    }
}

export default withStyles(useStyles)(PageStep)
