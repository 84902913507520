import React, { Component } from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

import MarkerSvg from "assets/img/havilla/google_maps_marker.svg";

const style = {
    width: '100%',
    height: 500
}

const containerStyle = {
    position: 'relative',
    width: '100%',
    height: 500
}

export class GoogleMaps extends Component {
    render() {
        return (
            <Map google={this.props.google}
                containerStyle={containerStyle}
                style={style}
                zoom={6.92}
                initialCenter={{
                    lat: -5.9727282,
                    lng: 111.0672152
                }}
            >
                {
                    this.props.store_locations.map((store_location, i) => {
                        return (
                            <Marker
                                key={store_location.id}
                                title={store_location.name}
                                name={store_location.name}
                                position={{ lat: store_location.lat, lng: store_location.lng }}
                                icon={{
                                    url: MarkerSvg,
                                    anchor: new window.google.maps.Point(32, 32),
                                    scaledSize: new window.google.maps.Size(32, 32)
                                }} >
                            </Marker>
                        )
                    })
                }
                {/* <Marker onClick={this.onMarkerClick}
                name={'Current location'} />
 
                <InfoWindow onClose={this.onInfoWindowClose}>
                    <div>
                    <h1>{this.state.selectedPlace.name}</h1>
                    </div>
                </InfoWindow> */}
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyD8F8ZiBsCuFKlIl9i5ktCKYIFR0Va4yr0"
})(GoogleMaps)