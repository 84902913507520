import React, { Component } from 'react'

import { Grid, Button, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import {
    baseUrl,
    requestConfig,
    prefix
} from '../../../Environment.js';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField.js';
import { Backdrop, CircularProgress } from '@material-ui/core';
import Suddhabumi from "assets/img/havilla/Suddhabumi.png";
import Chamomile from "assets/img/havilla/own_blend_square.jpg";

import CloseIcon from "assets/img/havilla/close_icon.svg";
import { ClientSideContext } from '../../ClientSideContext';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import { dictionary } from 'Dictionary.js';
const useStyles = theme => ({
    ...styles(theme),
    paper: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
        width: '100%',
    },
    titleContainer: {
        [theme.breakpoints.down('xs')]: {
            background: 'white',
            color: 'black',
            borderBottom: '1px solid #f0f0f0'
        },
        background: '#222222',
        color: 'white',
        width: '100%',
        padding: '2rem 2.5rem'
    },
    productBackground: {
        // background: '#f0f0f0',
        height: 'auto',
        width: '100%',
        // padding: '0 20px',
        position: 'relative'
    },
    qtyCircle: {
        borderRadius: '100%',
        background: 'black',
        color: 'white',
        position: 'absolute',
        top: '-10px',
        right: '-10px',
        width: 25,
        height: 25,
        paddingTop: 4,
        textAlign: 'center'
    },
    productImage: {
        width: '100%',
    },
    productSubCategory: {
        fontSize: '0.7rem',
        border: '1px solid grey',
        borderRadius: '16px',
        padding: '2px 10px 0',
        textTransform: 'uppercase',
        width: 'auto'
    },
    borderHr: {
        width: '100%',
        marginTop: 5,
        margin: 'auto',
        border: '1px solid #f0f0f0',
    },
    contentContainer: {
        padding: '1.5rem 2.5rem'
    },
    cartContainer: {
        width: '100%',
        padding: '1.5rem 2.5rem 0 1.5rem',
        minHeight: 0,
        maxHeight: '405px',
        // background: 'black',
        overflow: 'auto',
        color:'black'
    },
    productContainer: {
        padding: '0 10px'
    }
});

export class OrderSummary extends Component {
    static contextType = ClientSideContext;

    constructor(props) {
        super(props)
    
        this.state = {
            language : 'en'
        }
    }

    componentDidMount(){
        this.setState({
            language: this.context.language
        })
    }

    componentDidUpdate(){
        return true;
    }
    
    renderCartItems = () => {
        const { classes } = this.props;
        const { cartItems } = this.context;

        // const { cartItems } = this.state;
        // var cartItems = []
        // if (localStorage.getItem('cart')) {
        //     const LS = localStorage.getItem('cart')
        //     cartItems = JSON.parse(LS);
        // }
        const cartItemsContext = JSON.parse(cartItems);

        if (cartItemsContext) {
            return cartItemsContext.map((item, i) => {
                return (
                    <Grid container spacing={4} className={classes.productContainer} key={item.id}>
                        <Grid item xs={5}>
                            {
                                item.is_own === 0 ?
                                    // <Link
                                    //     to={`/product/${item.sub_product.product.id}`}
                                    // >
                                    //     <Grid container className={classes.productBackground} alignItems="center" justify="center">
                                    //         <img src={Suddhabumi} alt='product img' className={classes.productImage}></img>
                                    //         <div className={classes.qtyCircle}>{item.qty}</div>
                                    //     </Grid>
                                    // </Link>
                                    <Link to={prefix + `/product/${item.sub_product.product.url_slug}`}>
                                        {
                                            item.thumbnail_url !== null ?
                                                <Grid container className={classes.productBackground} alignItems="center" justify="center">
                                                    <img src={item.thumbnail_url} className={classes.productImage} alt='product img'></img>
                                                    <div className={classes.qtyCircle}>{item.qty}</div>
                                                </Grid>
                                                :
                                                <Grid container className={classes.productBackground} alignItems="center" justify="center">
                                                    <img src={Suddhabumi} alt='product img' className={classes.productImage}></img>
                                                    <div className={classes.qtyCircle}>{item.qty}</div>
                                                </Grid>
                                        }
                                    </Link>
                                    :
                                    <Grid container className={classes.productBackground} alignItems="center" justify="center">
                                        <img src={Chamomile} alt='product img' className={classes.productImage}></img>
                                        <div className={classes.qtyCircle}>{item.qty}</div>
                                    </Grid>
                            }
                            {/* <Grid container className={classes.productBackground} alignItems="center" justify="center">
                                <img src={Suddhabumi} alt='product img' className={classes.productImage}></img>
                                <div className={classes.qtyCircle}>{item.qty}</div>
                            </Grid> */}
                        </Grid>
                        <Grid item xs={7} style={{ paddingTop: 20 }}>
                            {
                                item.is_own === 0 ?
                                    item.sub_product.product.product_sub_category ?
                                        <React.Fragment>
                                            <span className={classes.productSubCategory}>
                                                {item.sub_product.product.product_sub_category.name}
                                            </span>
                                            <p style={{ marginTop: 10 }}>{item.sub_product.product.name} - {item.sub_product.varian}</p>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <span className={classes.productSubCategory}>
                                                {item.sub_product.product.product_category.name}
                                            </span>
                                            <p style={{ marginTop: 10 }}>{item.sub_product.product.name} - {item.sub_product.varian}</p>
                                        </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <span className={classes.productSubCategory}>
                                            Unique Blend
                                        </span>
                                        <p style={{ marginTop: 10 }}>{item.own_product.name}</p>
                                        {
                                            item.own_product.own_product_details.map((detail, i) => {
                                                return '[' + detail.own_product_ingredient[`name_${this.context.language}`] + '] '
                                            })
                                        }
                                    </React.Fragment>
                            }
                            {
                                item.is_own === 0 ?
                                    item.sub_product.discount !== null && item.sub_product.discount > 0 ?
                                        <p>Rp. {(item.sub_product.price - (item.sub_product.price * (item.sub_product.discount/100))).toLocaleString('ID')}</p>
                                    :
                                        <p>Rp. {(item.qty * item.sub_product.price).toLocaleString('ID')}</p>
                                :
                                    <p>Rp. {(item.qty * item.own_product.price).toLocaleString('ID')}</p>
                            }
                        </Grid>
                    </Grid>
                )
            })
        }
        else {
            return (
                <p style={{textAlign:'center'}}>You have no item in your cart.</p>
            )
        }
    }


    countTotal = () => {
        const { cartItems } = this.context;

        const cartItemsContext = JSON.parse(cartItems);

        if (cartItemsContext) {
            const totalAmount = cartItemsContext.reduce((total, cartItem) => {
                return total + (cartItem.qty * 
                    (cartItem.is_own === 0 ? 
                        cartItem.sub_product.discount !== null && cartItem.sub_product.discount > 0 ? 
                            (cartItem.sub_product.price - (cartItem.sub_product.price * (cartItem.sub_product.discount/100))) 
                        :
                            cartItem.sub_product.price 
                    : 
                        cartItem.own_product.price)
                )
            }, 0) + this.props.shippingCost - this.props.discount;

            this.props.handleTotalAmountChange(totalAmount)

            return totalAmount
        }
        return 0
    }

    countWeight = () => {
        const { cartItems } = this.context;

        const cartItemsContext = JSON.parse(cartItems);

        if (cartItemsContext) {
            const totalAmount = cartItemsContext.reduce((total, cartItem) => {
                return total + (cartItem.qty * (cartItem.is_own === 0 ? cartItem.sub_product.weight : cartItem.own_product.weight))
            }, 0);

            this.props.handleTotalWeightChange(totalAmount)

            return totalAmount
        }
        return 0
    }

    render() {
        const { classes } = this.props;
        const { cartItems } = this.context;

        return (
            <Container maxWidth="xl" className={`${classes.paper}`}>
                <Grid container className={classes.formContainer}>
                    <Grid item xs={12} className={classes.titleContainer}>
                        <p className={classes.titleMediumSize} style={{ marginBottom: 5 }}>
                            {dictionary['Order Summary'][this.context.language]}
                        </p>
                        <p style={{ float: 'left' }}>
                            {cartItems ?
                                JSON.parse(cartItems).length : 0
                            }
                            &nbsp; {dictionary['Items'][this.context.language]}</p>
                        <div
                            onClick={this.props.openCart}
                            style={{ color: 'white', float: 'right' }}
                            className={`${classes.linkPointerWUnderline} ${classes.link}`}
                        >
                            Edit</div>
                    </Grid>
                    <Grid item xs={12} className={classes.cartContainer}>
                        {
                            this.renderCartItems()
                        }
                    </Grid>
                    <Grid item xs={12} className={classes.contentContainer}>
                        <hr className={classes.borderHr} style={{display:'none'}}></hr>
                        <p style={{ margin: '15px 0', display:'none' }}>{dictionary['Total Weight'][this.context.language]}<span style={{ float: 'right' }}>
                            {this.countWeight().toLocaleString('ID')} gram
                        </span></p>
                        <hr className={classes.borderHr}></hr>
                        {
                            this.props.shippingCost > 0 ?
                                <p style={{ margin: '15px 0' }}>{dictionary['Delivery Fee'][this.context.language]}
                                    <span style={{ float: 'right' }}>
                                        Rp. {this.props.shippingCost.toLocaleString('ID')}
                                    </span>
                                </p>
                                :
                                ''
                        }
                        {
                            this.props.discount > 0 ?
                                <p style={{ margin: '15px 0', color: 'green' }}>{dictionary['Voucher Discount'][this.context.language]}
                                    <span style={{ float: 'right', color: 'green' }}>
                                        - Rp. {parseInt(this.props.discount).toLocaleString('ID')}
                                    </span>
                                </p>
                                :
                                ''
                        }
                        <p style={{ margin: '15px 0', color:'black' }}><b>{dictionary['Grand Total'][this.context.language]}<span style={{ float: 'right', color:'black' }}>
                            Rp. {this.countTotal().toLocaleString('ID')}
                        </span></b></p>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default withStyles(useStyles)(OrderSummary)
