import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from "assets/img/havilla/close_icon.svg";
import { Grid, Button } from '@material-ui/core';
import styles from "assets/jss/client-side/App.js";
import { Link } from 'react-router-dom';
import { prefix } from 'Environment';

export default function CustomModalWImage(props) {
  const useStyles = makeStyles((theme) => ({
    ...styles(theme),
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        overflowY: 'auto',
        height: '100%',
        width: '100%',
        display: 'block'
      },
    },
    paper: {
      [theme.breakpoints.down('sm')]: {
          padding: '1rem',
          width: '100%',
      },
      position: 'absolute',
      backgroundColor: '#222222',
      color:'white',
      boxShadow: theme.shadows[5],
      padding: '0',
      width : '672px',
      height : 'auto',
    },
    closeIcon: {
      position: 'absolute',
      right: 20,
      top: 20,
      cursor: 'pointer'
    },
    contentContainer :{
      paddingTop : '73px',
      [theme.breakpoints.up('sm')]: {
        paddingLeft : '60px',
        paddingRight : '50px',
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop : '10px',
        // paddingLeft : '20px',
      },
    },
    imgContainer : {
      // maxHeight : '400px',
      padding: '0px'
    },
    modalContentText: {
      fontSize: '16px',
      width: '300px',
      marginTop: '24px'
    },
    gridContainer : {
      [theme.breakpoints.up('sm')]: {
        maxHeight: '400px'
      },
    },
    img : {
      [theme.breakpoints.up('sm')]: {
        height: '400px',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop : '40px',
        width : '100%',
      },
    }
  }));
  const classes = useStyles();

  const handleClose = () => {
    props.handleCloseModal(false);
  };

  return (
    <React.Fragment>
      <Modal
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <img className={classes.closeIcon} src={CloseIcon} alt="close icon" onClick={handleClose}></img>
            <Grid container className={classes.gridContainer}>
              <Grid item xs={12} md={5} className={classes.imgContainer}>
                <img src={props.img} alt='modal pict' className={classes.img}></img>
              </Grid>
              <Grid item xs={12} md={7} className={classes.contentContainer}>
                <h2>{props.title}</h2>
                <p className={classes.modalContentText}>
                    {props.content}
                </p>
                <Grid
                    style={{ marginTop: '16px' }}
                    container
                    spacing={2}
                    justify="center"
                    alignItems="flex-center"
                >
                    <Grid item xs={12} md={10}>
                        <Link to={`${prefix}${props.link_to}`} target="_blank">
                            <Button variant="outlined" className={classes.buttonDark} style={{ width: '105%' }}>{props.link_text}</Button>
                        </Link>
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}