import React, { useContext } from 'react'
import { makeStyles, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';

import { dictionary } from 'Dictionary';
import { ClientSideContext } from '../../ClientSideContext';

const useStyles = makeStyles((theme) => ({
    radioRoot: {
        '& + $label': {
            color: 'white',
        },
        '&': {
            color: 'white',
        },
    },
    radioChecked: {
        '&, & + $label': {
            color: '#AF986D'
        },
        '&': {
            color: '#AF986D',
        }
    },
    radioDisabled: {
        '&, & + $label': {
            color: 'grey',
        },
    },
    label: {},
    buttonRegister: {
        marginTop: '68px'
    },
    formControlRadio: {
        [theme.breakpoints.down('sm')]: {
            marginRight: '12px',
            width: '100%'
        },
    }
}));

function RecipientRadioButton(props) {
    const classes = useStyles();
    const { language } = useContext(ClientSideContext)
    return (
        <FormControl component="fieldset" className={classes.formControlRadio}>
            <RadioGroup row aria-label="recipient" name="recipient" value={props.value} onChange={props.onChange}>
                <FormControlLabel
                    classes={{ label: classes.label }}
                    value="me" control={
                        <Radio classes={{
                            root: classes.radioRoot,
                            checked: classes.radioChecked,
                            disabled: classes.radioDisabled,
                        }}
                            color='default' />
                    }
                    label={dictionary['For Me'][language]}
                />
                <FormControlLabel
                    classes={{ label: classes.label }}
                    value="gift"
                    control={
                        <Radio classes={{
                            root: classes.radioRoot,
                            checked: classes.radioChecked,
                            disabled: classes.radioDisabled,
                        }}
                            color='default' />
                    }
                    label={dictionary['a Gift for someone else'][language]}
                />
            </RadioGroup>
            <FormHelperText style={{
                position: "absolute",
                bottom: '-1.2rem',
                textTransform: 'capitalize'
            }}>{props.gender_error}</FormHelperText>
        </FormControl>
    )
}

export default RecipientRadioButton
