import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { baseUrl, requestConfigAdmin } from 'Environment'
import { Grid, withStyles } from '@material-ui/core';
import styles from "assets/jss/client-side/App.js";
import ButtonAdmin from "admin/components/CustomButtons/Button.js";
import { AdminContext } from '../../AdminContext';
import Confirmation from 'admin/components/CustomDialogs/Confirmation';
import moment from 'moment';
import {
    prefix
} from '../../../Environment.js';

const useStyles = theme => ({
    ...styles(theme),
    buttonTable: {
        minWidth: '104px'
    },
    img: {
        width: '100px',
        height: '50px',
    },
});

export class Voucher extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props)

        this.state = {
            available_vouchers: [],
            not_available_vouchers: [],

            openDialog: false,
            tempDeleted: null,
        }
    }

    numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    componentDidMount = () => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        this.getVouchers();
    }

    getVouchers = () => {
        axios.get(`${baseUrl}/vouchers`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                console.log(res.data.data)
                this.setState({
                    available_vouchers: res.data.data.available_vouchers,
                    not_available_vouchers: res.data.data.not_available_vouchers
                }, () => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    handleDeleteClick = (data) => {
        this.setState({
            tempDeleted: data,
            openDialog: true,
        })
    }

    handleCloseDialog = (action) => {
        if (action) {
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
            axios.delete(`${baseUrl}/vouchers/${this.state.tempDeleted.id}`,
                requestConfigAdmin
            ).then((res) => {
                if (res.data.success) {
                    this.getVouchers();
                    this.setState({
                        tempDeleted: null,
                        openDialog: false,
                    }, () => {
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "success",
                            message: "Voucher Deleted"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    })
                } else {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: res.data.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: e.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log("Error : ", e);
            })
        } else {
            this.setState({
                tempDeleted: null,
                openDialog: false,
            })
        }
    }

    render() {
        const { classes } = this.props;
        const columns = [
            {
                title: 'Code',
                field: 'code',
            },
            {
                title: 'Name',
                field: 'name',
            },
            {
                title: 'Valid From',
                field: 'valid_from',
                render: (rowData) => {
                    return (
                        <React.Fragment>
                            {moment(rowData.valid_from).format('DD-MM-YYYY (HH:mm:ss)')}
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Valid To',
                field: 'valid_to',
                render: (rowData) => {
                    return (
                        <React.Fragment>
                            {moment(rowData.valid_to).format('DD-MM-YYYY (HH:mm:ss)')}
                        </React.Fragment>
                    )
                }
            },
            {
                title: 'Quantity',
                field: 'qty',
                render: (rowData) => {
                    return (
                        <React.Fragment>
                            {rowData.qty ? this.numberWithCommas(rowData.qty) : 'No Limit'}
                        </React.Fragment>
                    )
                }
            },
            {
                title: '',
                render: (rowData) => {
                    return (
                        <Grid container>
                            <Grid item xs={12} container justify="center">
                                <ButtonAdmin className={classes.buttonTable} color="info"
                                    onClick={
                                        (e) => this.props.history.push(prefix + '/admin/vouchers/' + rowData.id)
                                    }
                                >
                                    EDIT
                                </ButtonAdmin>
                            </Grid>
                            <Grid item xs={12} container justify="center">
                                <ButtonAdmin className={classes.buttonTable}
                                    color="danger"
                                    onClick={() => this.handleDeleteClick(rowData)}
                                >
                                    DELETE
                                </ButtonAdmin>
                            </Grid>
                        </Grid>
                    )
                }
            },
        ]
        return (
            <div style={{ maxWidth: '100%' }}>
                <Confirmation
                    openDialog={this.state.openDialog}
                    message={`You are about to delete voucher <b>${this.state.tempDeleted ? this.state.tempDeleted.name : ""}</b>, 
                    are you sure you want to delete it?`}
                    handleCloseDialog={this.handleCloseDialog}
                />
                <h4 style={{ background: 'white', padding: '10px 25px' }}><b>Available</b></h4>
                <MaterialTable
                    columns={columns}
                    data={this.state.available_vouchers}
                    title={
                        <React.Fragment>
                            <ButtonAdmin color="info" onClick={() => this.props.history.push(prefix + '/admin/vouchers/add')}>
                                ADD NEW VOUCHER
                            </ButtonAdmin>
                        </React.Fragment>
                    }
                    options={{
                        search: true,
                        exportButton: false,
                        pageSize: 5
                    }}
                />
                <br />
                <br />
                <h4 style={{ background: 'white', padding: '10px 25px' }}><b>Not Available</b></h4>
                <MaterialTable
                    columns={columns}
                    data={this.state.not_available_vouchers}
                    title=""
                    options={{
                        search: true,
                        exportButton: false,
                        pageSize: 5
                    }}
                />
            </div>
        )
    }
}

export default withStyles(useStyles)(Voucher)
