import React, {useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {isMobile} from "react-device-detect";
import Flickity from "react-flickity-component";
import "assets/css/flickity.css";

// import Article1 from "assets/img/havilla/article1.png";
// import Article2 from "assets/img/havilla/article2.png";
// import Article3 from "assets/img/havilla/article3.png";

import Arrow from "assets/img/havilla/arrow.svg";
import PostLarge from '../Post/PostLarge';
import PostSmall from '../Post/PostSmall';

import {
    prefix
} from '../../../Environment.js';


const useStyles = makeStyles((theme) => ({
    itemTab : {
        fontFamily : 'Martel Sans',
        fontSize : '24px',
        textTransform: 'none',
    },
    rightFade : {
        position : 'absolute',
        // background: 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
        width: '65px',
        height : '451px',
        top : '140rem',
        right : '0rem',
        pointerEvents: 'none',
        [theme.breakpoints.down('sm')]: {
            display : 'none'
        },
    },
    customNextButton :{
        position : 'absolute',
        top : '160rem',
        overflow : 'hidden',
        right : '-3rem',
        width : '0px',
        transition : 'all 0.7s cubic-bezier(0.19, 1, 0.22, 1)',
        '&.active' :{
            right : '3rem',
            width : '44px',
        },
        [theme.breakpoints.down('sm')]: {
            display : 'none'
        },
    },
    customPrevButton : {
        position : 'absolute',
        top : '160rem',
        overflow : 'hidden',
        left : '-3rem',
        width : '0px',
        transition : 'all 0.7s cubic-bezier(0.19, 1, 0.22, 1)',
        '&.active' :{
            left : '3rem',
            width : '44px',
        },
        [theme.breakpoints.down('sm')]: {
            display : 'none'
        },
    },
    emptyLastItem : {
        marginLeft : '0rem',
        maxWidth : '292px',
        // minHeight : '451px',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            display : 'none'
        },
    }
}));

export default function PostsContainer(props) {
    const classes = useStyles();
    const [flkty, setflkty] = useState(null);

    useEffect(() => {
        if(flkty !== null){
            flkty.on('change', () => {
                if(!isMobile){
                    let elBtnPrev = document.getElementById('customPrevButton');
                    let elBtnNext = document.getElementById('customNextButton');
                    let selectedIndex = flkty.selectedIndex;
                    if(selectedIndex > 0){
                        elBtnPrev.classList.add('active');
                    }else{
                        elBtnPrev.classList.remove('active');
                    }
    
                    if(selectedIndex !== (flkty.slides.length-1)){
                        elBtnNext.classList.add('active');
                    }else{
                        elBtnNext.classList.remove('active');
                    }
                }

            })
        }
    });

    const handlePrevPost = () => {
        flkty.previous()
    }
    const handleNextPost = () => {
        flkty.next()
    }
    
    const nextButton = (
        <div id="customNextButton" onClick={handleNextPost} className={`active noselect flickity-button flickity-prev-next-button ${classes.customNextButton}`} type="button" aria-label="Previous">
            <img style={{width:'30px', height:'44px'}} src={Arrow} alt='arrow right'></img>
        </div>
    )
    const prevButton = (
        <div id="customPrevButton" onClick={handlePrevPost} className={`noselect flickity-button flickity-prev-next-button ${classes.customPrevButton}`} type="button" aria-label="Previous">
            <img style={{width:'30px', height:'44px', transform: 'rotate(180deg)'}} src={Arrow} alt='arrow left'></img>
        </div>
    )

    const flickityOptions = {
        initialIndex: 0,
        groupCells: '100%',
        pageDots: false,
        cellAlign: 'left',
        contain : true,
        prevNextButtons: false,
        freeScroll: true
    }

    return (
        <React.Fragment>
            <Flickity flickityRef={c => setflkty(c)} options={flickityOptions}>
                {
                    props.articles.map(item => {
                        return (
                            <PostLarge
                                key={item.id}
                                img={item.thumbnail_url}
                                title={item.title}
                                desc={item.caption}
                                imgAlt={`article thumbnail ${item.url_slug}`}
                                linkText='Read Now'
                                linkTo={`${prefix}/tea_talks/${item.url_slug}`}
                            />
                        )
                    })
                }
                {/* <PostLarge
                    img={Article1}
                    title='Stay at Home with Our Golden Remedy'
                    desc='Come visit our latest tea blend and enjoy it at home. Golden Remedy will warm-up your body and help to support your immune system....'
                    linkText='Check the new blend'
                /> */}
                {/* <PostSmall 
                    img={Article2}
                    title='Work From Home with our New Tea Blend'
                    linkText='Explore the Blend'
                />

                <PostSmall 
                    img={Article3}
                    title='Our first Collaboration with Toko Kopi Djawa'
                    linkText='Read Article'
                />                

                <PostSmall 
                    img={Article3}
                    title='Our first Collaboration with Toko Kopi Djawa'
                    linkText='Read Article'
                />

                <PostSmall 
                    img={Article3}
                    title='Our first Collaboration with Toko Kopi Djawa'
                    linkText='Read Article'
                /> */}

                <PostSmall additionalClass={classes.emptyLastItem} />
                
            </Flickity>
            <div className={classes.rightFade}>
            </div>
            {props.articles.length > 2 ? prevButton : null}
            {props.articles.length > 2 ? nextButton : null}
            
        </React.Fragment>
    );
}
