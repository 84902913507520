import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import { Container, Grid, Button } from '@material-ui/core';
import Floral from "assets/img/havilla/floral.svg";
import { Link } from 'react-router-dom';
import { dictionary } from 'Dictionary';
import {ClientSideContext} from '../../ClientSideContext';

const useStyles = makeStyles((theme) => ({
    ...styles(theme),
    containerTextContent: {
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            padding: "50px 40px 100px",
        },
        [theme.breakpoints.up('md')]: {
            padding: "7% 20% 0",
        },
    },
    floral: {
        marginLeft: "20%",
        marginTop: "-80px",
        [theme.breakpoints.down('sm')]: {
            width: "100px",
            position: "absolute",
            right: "-100px"
        },
    },
    smallerTitle: {
        fontFamily: 'BlaakLight',
        fontSize: '36px',
        marginBottom : '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
        },
    },
    pText :{
        fontFamily: 'Martel Sans',
        fontSize: '16px',
    },
    activeOnSm : {
        display : 'none',
        [theme.breakpoints.down('sm')]: {
            display : 'block',
        },
    },
    activeOnMd : {
        display : 'block',
        [theme.breakpoints.down('sm')]: {
            display : 'none',
        },
    },
    buttonLetsStartOnSm : {
        marginBottom: '2rem',
    },
    buttonLetsStartOnMd : {
        marginTop: '32px',
    },
  }));

function PageIntro(props) {
    const classes = useStyles();
    const {language} = useContext(ClientSideContext)

    return (
        <React.Fragment>
            <Container maxWidth="xl" className={`${classes.container} ${classes.containerTextContent}`}>
                <Grid container className={classes.formContainer}>
                    <Grid item md={language === 'en' ? 5 : 6} xs={12}>
                        <h3 className={classes.smallerTitle}>{dictionary['Own Blend Intro Headline'][language]}</h3>
                        <p className={classes.pText}>
                        {dictionary['Own Blend Intro Sub Headline Part 1'][language]} <br/><br className={classes.activeOnSm}/>{dictionary['Own Blend Intro Sub Headline Part 2'][language]}  
                        </p>
                        <Link to={`${props.prefix}/create_your_own/how`}>
                            <Button variant="outlined" className={`${classes.buttonDark} ${classes.buttonLetsStartOnMd} ${classes.activeOnMd}`}>
                                {dictionary['Own Blend Intro Button'][language]}
                            </Button>
                        </Link>
                    </Grid>
                    {
                        language === 'en' ?
                            <Grid item md={1} xs={12}>
                            </Grid>
                        :
                            null
                    }
                    <Grid item md={6} xs={12}>
                        <img className={classes.floral} src={Floral} alt="floral"></img>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="xl" className={`${classes.container} ${classes.activeOnSm}`}>
                <Grid container className={classes.formContainer} justify="center">
                    <Grid item xs={11}>
                        <Link to={`${props.prefix}/create_your_own/how`}>
                            <Button variant="outlined" className={`${classes.buttonDark} ${classes.buttonLetsStartOnSm}`}>
                                {dictionary['Own Blend Intro Button'][language]}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default PageIntro
