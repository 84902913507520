import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    // IconButton, 
    Container
} from '@material-ui/core';
import HavillaLogo from "assets/img/havilla/logo.svg";
import CssBaseline from '@material-ui/core/CssBaseline';
import Floral from "assets/img/havilla/floral.svg";
import FacebookIcon from "assets/img/havilla/facebook.svg";
import InstagramIcon from "assets/img/havilla/instagram.svg";
import WhatsappIcon from "assets/img/havilla/whatsapp.svg";
import LineIcon from "assets/img/havilla/line.svg";
// import { Link } from 'react-router-dom';
// Styles
import styles from "assets/jss/client-side/App.js";
import { Link } from 'react-router-dom';
import {
    prefix
} from '../../../Environment.js';
import { ClientSideContext } from '../../ClientSideContext';

const useStyles = makeStyles((theme) => ({
    ...styles(theme),
    logo: {
        width: "184px",
        height: '130px'
    },
    logoContainer: {
        alignItems: 'center',
        textAlign: 'center'
    },
    containerFooter: {
        paddingTop: '130px',
        backgroundImage: `url(${Floral})`,
        backgroundPosition: 'bottom 100px right 100px',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down('sm')]: {
            backgroundPosition: 'top 50px center',
            backgroundSize: '100px',
            backgroundSize: '20%'
        },
    },
    itemsContainer: {
        margin: "20px 84px 20px 84px",
        [theme.breakpoints.down('sm')]: {
            margin: "20px 10px 20px 10px",
        },
        // padding : "40px 84px 40px 84px !important"
    },
    copyrightContainer: {
        marginTop: '60px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
        },
        textAlign: 'center',
    },
    footerTitle: {
        fontSize: '24px',
        marginBottom: '24px',
        marginTop: '10px',
    },
    footerListItem: {
        display: 'block',
        listStyleType: 'none',
        '& li': {
            marginTop: '16px',
        }
    },
    footerListItemIcon: {
        listStyleType: 'none',
        '& li': {
            marginTop: '16px',
            display: 'inline',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '2rem',
        },
    },
    icon: {
        width: "24px",
        height: '24px',
        marginRight: '32px'
    },
    gridFooterItemContainer: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        },
    },
    lineSeparator: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
        color: 'black',
        backgroundColor: 'black',
        height: "3px",
        marginBottom: "24px",
        marginLeft: '27px',
        marginRight: '27px'
    },
    linkFooter: {
        textDecoration: 'none',
        color: 'white',
        '&:hover': {
            textDecoration: 'none',
            color: '#AF986D',
        },
        '&:focus': {
            color: '#AF986D',
        }
    }
}));


function Footer() {
    const { language } = useContext(ClientSideContext)

    const classes = useStyles();
    return (
        <Fragment>
            <CssBaseline />
            <Container maxWidth="xl" className={`${classes.container} ${classes.containerFooter}`}>
                <Grid container spacing={2} alignItems="center" >
                    <Grid item xs={12} className={classes.logoContainer}>
                        <img className={classes.logo} src={HavillaLogo} alt="havilla logo"></img>
                    </Grid>
                    <Grid className={classes.itemsContainer} item xs={12} spacing={3} container alignItems="flex-start" justify="center">
                        <Grid item md={3} xs={12} className={classes.gridFooterItemContainer}>
                            <div className={classes.footerTitle}>
                                <Link
                                    className={classes.linkFooter}
                                    to={`${prefix}/about_us`}
                                >
                                    { language === 'id' ? 'Tentang Havilla' : 'About Havilla'}
                                </Link>
                            </div>
                            <ul className={classes.footerListItem}>
                                <li>
                                    <Link
                                        className={classes.linkFooter}
                                        to={`${prefix}/about_us/detail`}
                                    >
                                        { language === 'id' ? 'Cerita Kami' : 'Our Story'}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={classes.linkFooter}
                                        to={`${prefix}/about_us/our_partners`}
                                    >
                                        { language === 'id' ? 'Partner Kami' : 'Our Partners'}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={classes.linkFooter}
                                        to={`${prefix}/about_us/social_acts`}
                                    >
                                        { language === 'id' ? 'Aksi Sosial' : 'Social Acts'}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={classes.linkFooter}
                                        to={`${prefix}/about_us/our_partners`}
                                    >
                                        { language === 'id' ? 'Lokasi Toko' : 'Store Locator'}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={classes.linkFooter}
                                        to={`${prefix}/about_us/faq`}
                                    >
                                        FAQ
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item md={3} xs={12} className={classes.gridFooterItemContainer}>
                            <div className={classes.footerTitle}>
                                <Link
                                    className={classes.linkFooter}
                                    to={`${prefix}/tea_shop`}
                                >
                                    { language === 'id' ? 'Toko Teh' : 'Tea Shop'}
                                </Link>
                            </div>
                            <ul className={classes.footerListItem}>
                                <li>
                                    <Link
                                        className={classes.linkFooter}
                                        to={{
                                            pathname: `${prefix}/tea_shop/tea`,
                                            state: {
                                                category_id: 1
                                            }
                                        }}
                                    >
                                        { language === 'id' ? 'Belanja Teh' : 'Shop Tea'}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={classes.linkFooter}
                                        to={{
                                            pathname: `${prefix}/tea_shop/tisane`,
                                            state: {
                                                category_id: 2
                                            }
                                        }}
                                    >
                                        { language === 'id' ? 'Belanja Tisane' : 'Shop Tisane'}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={classes.linkFooter}
                                        to={{
                                            pathname: `${prefix}/tea_shop/gift`,
                                            state: {
                                                category_id: 3
                                            }
                                        }}
                                    >
                                        { language === 'id' ? 'Belanja Hadiah' : 'Shop Gifts'}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={classes.linkFooter}
                                        to={{
                                            pathname: `${prefix}/tea_shop/accessories`,
                                            state: {
                                                category_id: 4
                                            }
                                        }}
                                    >
                                        { language === 'id' ? 'Belanja Aksesoris' : 'Shop Accessories'}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={classes.linkFooter}
                                        to={`${prefix}/havilla_partnership`}
                                    >
                                        { language === 'id' ? 'Pembelian Grosir' : 'Wholesale'}
                                    </Link>
                                    {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSdwFkXNfPn4CcXSiAV5qRzGUAJp9LFxpcWUhNCpWcP879HO2Q/viewform?usp=sf_link"
                                        target="_blank">
                                        { language === 'id' ? 'Pembelian Grosir' : 'Wholesale'}
                                    </a> */}
                                </li>
                                <li>
                                    <Link
                                        className={classes.linkFooter}
                                        to={`${prefix}/havilla_gifts`}
                                    >
                                        { language === 'id' ? 'Hadiah Custom' : 'Custom Gifts'}
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item md={3} xs={12} className={classes.gridFooterItemContainer}>
                            <div className={classes.footerTitle}>{ language === 'id' ? 'Bantuan' : 'Support'}</div>
                            <ul className={classes.footerListItem}>
                                <li>{ language === 'id' ? 'Untuk informasi lebih lanjut' : 'For Further Information'} </li>
                                <li><a href="tel:+6281221689133">{ language === 'id' ? 'Kontak': 'Contact'} : (+62) 812 2168 9133</a></li>
                                <li><a href="mailto:someone@example.com">Email : say.hi@havillatea.com</a></li>
                            </ul>
                        </Grid>
                        <Grid item md={3} xs={12} className={classes.gridFooterItemContainer}>
                            <div className={classes.footerTitle}>{ language === 'id' ? 'Kontak Kami' : 'Contact Us'}</div>
                            <ul className={classes.footerListItemIcon}>
                                <a href="https://www.instagram.com/havilla_tea" target="_blank">
                                    <li><img className={classes.icon} src={InstagramIcon} alt="instagram icon"></img></li>
                                </a>
                                <a href="https://www.facebook.com/havillagourmettea/" target="_blank">
                                    <li><img className={classes.icon} src={FacebookIcon} alt="facebook icon"></img></li>
                                </a>
                                <a href="https://wa.me/6281221689133" target="_blank">
                                    <li><img className={classes.icon} src={WhatsappIcon} alt="whatsapp icon"></img></li>
                                </a>
                                <a href="http://line.me/ti/p/~@havilla_tea#~">
                                    <li><img className={classes.icon} src={LineIcon} alt="line icon"></img></li>
                                </a>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.copyrightContainer}>
                        <hr className={classes.lineSeparator} />
                        (c) 2020. PT CITRA KENCANA CEMERLANG. All Rights Reserved. Powered by <a href="https://www.anthem.co.id" target="_blank">Anthem</a>.
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    )
}

export default Footer