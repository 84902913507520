import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { baseUrl, requestConfigAdmin } from 'Environment'
import { Grid, withStyles } from '@material-ui/core';
import styles from "assets/jss/client-side/App.js";
import ButtonAdmin from "admin/components/CustomButtons/Button.js";
import { AdminContext } from '../../AdminContext';
import Confirmation from 'admin/components/CustomDialogs/Confirmation';
import IOSSwitch from 'admin/components/Switch/IOSSwitch';
import {
    prefix
} from '../../../Environment.js';

const useStyles = theme => ({
    ...styles(theme),
    buttonTable: {
        minWidth: '104px'
    }
});

export class ArticleCategory extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props)

        this.state = {
            activeArticleCategories: [],
            nonActiveArticleCategories: [],

            openDialog: false,
            tempDeleted: null,
        }
    }

    componentDidMount = () => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        this.getArticleCategories();
    }

    getArticleCategories = () => {
        axios.get(`${baseUrl}/article_categories`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    activeArticleCategories: res.data.data.active,
                    nonActiveArticleCategories: res.data.data.non_active
                }, () => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    handleActiveToggle = (e) => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        let id = (e.target.name).split('-')[1];
        let data = {
            id: id,
        }
        axios.post(`${baseUrl}/article_categories/toggle_active`,
            data,
            requestConfigAdmin
        ).then((res) => {
            if (res.data.success) {
                this.getArticleCategories();
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "success",
                    message: "Update Article Category Success!"
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            } else {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            console.log("Error : ", e);
        })
    }

    handleDeleteClick = (data) => {
        this.setState({
            tempDeleted: data,
            openDialog: true,
        })
    }

    handleCloseDialog = (action) => {
        if (action) {
            axios.delete(`${baseUrl}/article_categories/${this.state.tempDeleted.id}`,
                requestConfigAdmin
            ).then((res) => {
                if (res.data.success) {
                    this.getArticleCategories();
                    this.setState({
                        tempDeleted: null,
                        openDialog: false,
                    }, () => {
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "success",
                            message: "Article Category Deleted"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    })
                } else {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: res.data.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: e.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log("Error : ", e);
            })
        } else {
            this.setState({
                tempDeleted: null,
                openDialog: false,
            })
        }
    }

    render() {
        const { classes } = this.props;
        const columns = [
            {
                title: 'Name English',
                field: 'name_en',
            },
            {
                title: 'URL English',
                field: 'url_slug_en'
            },
            {
                title: 'Name Indonesia',
                field: 'name_id',
            },
            {
                title: 'URL Indonesia',
                field: 'url_slug_id'
            },
            {
                title: 'Active',
                render: (rowData) => {
                    return (
                        <Grid container>
                            <Grid item container xs={3} alignItems="center">
                                <IOSSwitch
                                    checked={rowData.is_active === 1 ? true : false}
                                    onChange={this.handleActiveToggle}
                                    name={`is_active-${rowData.id}`}
                                />
                            </Grid>
                            <Grid item container xs={9} justify="center">
                                <Grid item xs={12} container justify="center">
                                    <ButtonAdmin className={classes.buttonTable} color="info"
                                        onClick={
                                            (e) => this.props.history.push(prefix + '/admin/article_categories/' + rowData.id)
                                        }
                                    >
                                        EDIT
                                    </ButtonAdmin>
                                </Grid>
                                <Grid item xs={12} container justify="center">
                                    <ButtonAdmin className={classes.buttonTable}
                                        color="danger"
                                        onClick={() => this.handleDeleteClick(rowData)}
                                    >
                                        DELETE
                                    </ButtonAdmin>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            },
        ]
        return (
            <div style={{ maxWidth: '100%' }}>
                <Confirmation
                    openDialog={this.state.openDialog}
                    message={`You are about to delete article category <b>${this.state.tempDeleted ? this.state.tempDeleted.name_en : ""}</b>, 
                    are you sure you want to delete it?`}
                    handleCloseDialog={this.handleCloseDialog}
                />
                
                <MaterialTable
                    columns={columns}
                    data={this.state.activeArticleCategories}
                    title={
                        <ButtonAdmin color="info" onClick={() => this.props.history.push(prefix + '/admin/article_categories/add')}>
                            ADD NEW ARTICLE CATEGORY
                        </ButtonAdmin>
                    }
                    options={{
                        search: true,
                        exportButton: false,
                        pageSize: 10
                    }}
                />
                <br />
                <br />
                <h4 style={{ background: 'white', padding: '10px 25px' }}><b>Not Active</b></h4>
                <MaterialTable
                    columns={columns}
                    data={this.state.nonActiveArticleCategories}
                    title=""
                    options={{
                        search: true,
                        exportButton: false,
                        pageSize: 5
                    }}
                />
            </div>
        )
    }
}

export default withStyles(useStyles)(ArticleCategory)
