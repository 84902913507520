import React, { Component } from 'react'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import { baseUrl, requestConfigAdmin } from 'Environment';
import { withStyles, TextField, MenuItem } from '@material-ui/core';
import globalStyles from "assets/jss/client-side/App.js";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import GridItem from 'admin/components/Grid/GridItem';
import Card from "admin/components/Card/Card.js";
import CardHeader from "admin/components/Card/CardHeader.js";
import CardBody from "admin/components/Card/CardBody.js";
import GridContainer from 'admin/components/Grid/GridContainer';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField';
import CardFooter from 'admin/components/Card/CardFooter';
import Button from "admin/components/CustomButtons/Button.js";
import OwnProductIngredient from './OwnProductIngredient';
import {
    prefix
} from '../../../Environment.js';

const CustomTextfieldRaw = withStyles({
    root: {
        width: '100%',
        '& label.Mui-focused': {
            color: 'black',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);

const useStyles = theme => ({
    ...globalStyles(theme),
    ...dashboardStyle,
    // USED IN PRODUCT DETAIL VARIANT
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'auto'
    },
    paperModal: {
        [theme.breakpoints.down('sm')]: {
            padding: '1rem',
            width: '100%',
        },
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: '0',
        width: '672px',
        height: '389px',
    },
    closeIconModal: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer'
    },
    contentContainerModal: {
        padding: '10px 30px',
    },
    contentTitleModal: {
        padding: '20px 30px',
        fontSize: '20px',
        fontFamily: 'Martel Sans',
        fontWeight: 'bold',
        borderBottom: '1px solid rgba(0,0,0, 0.3)'
    },
    imgPreview: {
        width: '250px',
        cursor: 'pointer',
        '&:hover + .delete-button': {
            marginTop: '-17px',
        }
    },
    deletePict: {
        transition: 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)',
        float: 'right',
        marginTop: '-37px',
        display: 'block',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            marginTop: '-17px',
        },
    },
    tempPictContainer: {
        paddingTop: '20px',
        overflow: 'hidden',
        '&:hover .delete-button': {
            marginTop: '-17px',
        }
    },
    img: {
        width: '50px',
        height: '50px',
    },
    buttonTable: {
        minWidth: '104px'
    }
    // END USED IN PRODUCT DETAIL VARIANT

});


export class OwnProductStepDetail extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props)

        this.state = {
            type: "update",

            id: null,
            name_id: "",
            name_en: "",
            page_title_id: "",
            page_title_en: "",
            page_desc_id: "",
            page_desc_en: "",
            min_pick: "",
            max_pick: "",
            price: "",
            own_product_ingredient_type_id: '',
            is_active: false,

            ownProductIngredientTypes: [],
            own_product_ingredients: []
        }
    }

    getOwnProductIngredientTypes = () => {
        axios.get(`${baseUrl}/own_product_ingredient_types`,
            requestConfigAdmin
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState({
                    ownProductIngredientTypes: data.active,
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    getOwnProductStep = () => {
        const id = this.props.match.params.id;
        axios.get(`${baseUrl}/own_product_steps/${id}`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    ...res.data.data,
                }, () => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        this.getOwnProductIngredientTypes();
        if (id !== "add") {
            this.getOwnProductStep();
            this.setState({
                type: "update"
            })
        } else {
            this.setState({
                type: "add"
            })
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
        }
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    handleSubmitOwnProductStep = () => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        if (this.state.type === "update") {
            let data = {
                id: this.state.id,
                name_id: this.state.name_id,
                name_en: this.state.name_en,
                page_title_id: this.state.page_title_id,
                page_title_en: this.state.page_title_en,
                page_desc_id: this.state.page_desc_id,
                page_desc_en: this.state.page_desc_en,
                min_pick: this.state.min_pick,
                max_pick: this.state.max_pick,
                price: this.state.price,
                own_product_ingredient_type_id: this.state.own_product_ingredient_type_id,
            }
            axios.put(`${baseUrl}/own_product_steps/${data.id}`,
                data,
                requestConfigAdmin
            ).then((res) => {
                if (res.data.success) {
                    this.getOwnProductStep();
                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "success",
                        message: "Update Product Info Success!"
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                } else {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: res.data.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: "Error : "+e.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log("Error : ", e);
            })
        } else {
            let add_header = {
                ...requestConfigAdmin.headers,
                'Content-Type': 'multipart/form-data'
            }

            let formData = new FormData();
            formData.append('name_id', this.state.name_id);
            formData.append('name_en', this.state.name_en);
            formData.append('page_title_id', this.state.page_title_id);
            formData.append('page_title_en', this.state.page_title_en);
            formData.append('page_desc_id', this.state.page_desc_id);
            formData.append('page_desc_en', this.state.page_desc_en);
            formData.append('min_pick', this.state.min_pick);
            formData.append('max_pick', this.state.max_pick);
            formData.append('price', this.state.price);
            formData.append('own_product_ingredient_type_id', this.state.own_product_ingredient_type_id);
            formData.append('own_product_ingredients', JSON.stringify(this.state.own_product_ingredients));

            for (let i = 0; i < this.state.own_product_ingredients.length; i++) {
                const own_product_ingredient = this.state.own_product_ingredients[i];

                const img = own_product_ingredient.image_url;
                formData.append('own_product_ingredient_' + i + '_image', img);
            }

            axios.post(`${baseUrl}/own_product_steps`,
                formData,
                { headers: add_header },
            ).then((res) => {
                if (res.data.success) {
                    let path = prefix + `/admin/own_product_steps`;
                    this.props.history.push(path);
                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "success",
                        message: "Add New Product Success!"
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})

                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                } else {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: res.data.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: "Error : "+e.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log("Error : ", e);
            })
        }

    }

    setOwnProductIngredients = (newValue) => {
        this.setState({
            own_product_ingredients: newValue
        }, () => {
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="gray">
                            <h4 className={classes.cardTitleWhite}>Own Product Step</h4>
                            <p>Own Product Step General Information</p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem container item sm={12} md={12}>
                                    <GridItem md={6} sm={12} xs={12}>
                                        <CustomTextField
                                            value={this.state.name_en}
                                            // error={this.state.name_error !== ""}
                                            // helperText={this.state.name_error}
                                            type="text" onChange={this.onChange} name="name_en" label="Name English" />
                                    </GridItem>
                                    <GridItem md={6} sm={12} xs={12}>
                                        <CustomTextField
                                            value={this.state.name_id}
                                            // error={this.state.name_error !== ""}
                                            // helperText={this.state.name_error}
                                            type="text" onChange={this.onChange} name="name_id" label="Name Indonesia" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={6}>
                                        <CustomTextField
                                            value={this.state.page_title_en}
                                            // error={this.state.page_title_error !== ""}
                                            // helperText={this.state.page_title_error}
                                            type="text" onChange={this.onChange} name="page_title_en" label="Page Step Title English" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={6}>
                                        <CustomTextField
                                            value={this.state.page_title_id}
                                            // error={this.state.page_title_error !== ""}
                                            // helperText={this.state.page_title_error}
                                            type="text" onChange={this.onChange} name="page_title_id" label="Page Step Title Indonesia" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={12}>
                                        <CustomTextField
                                            value={this.state.page_desc_en}
                                            // error={this.state.page_desc_en !== ""}
                                            // helperText={this.state.page_desc_en}
                                            type="text" onChange={this.onChange} name="page_desc_en" label="Page Step Desc English (Leave it blank will make the page description into own product combination)" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={12}>
                                        <CustomTextField
                                            value={this.state.page_desc_id}
                                            // error={this.state.page_desc_id !== ""}
                                            // helperText={this.state.page_desc_id}
                                            type="text" onChange={this.onChange} name="page_desc_id" label="Page Step Desc Indonesia (Leave it blank will make the page description into own product combination)" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={6}>
                                        <CustomTextfieldRaw
                                            select
                                            label="Own Product Ingredient Type"
                                            name="own_product_ingredient_type_id"
                                            value={this.state.own_product_ingredient_type_id}
                                            onChange={this.onChange}
                                        >
                                            {
                                                this.state.ownProductIngredientTypes.map((type, i) => {
                                                    return <MenuItem value={type.id} key={type.id}>{`${type.name_en} | ${type.name_id}`}</MenuItem>
                                                })
                                            }
                                        </CustomTextfieldRaw>
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={6}>
                                        <CustomTextField
                                            value={this.state.price}
                                            // error={this.state.price_error !== ""}
                                            // helperText={this.state.price_error}
                                            type="number" onChange={this.onChange} name="price" label="Price (in Rp, Price charged if maximum free pick exceeded)" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={6}>
                                        <CustomTextField
                                            value={this.state.min_pick}
                                            // error={this.state.min_pick_error !== ""}
                                            // helperText={this.state.min_pick_error}
                                            type="number" onChange={this.onChange} name="min_pick" label="Minimum Pick (0 for no minimum)" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={6}>
                                        <CustomTextField
                                            value={this.state.max_pick}
                                            // error={this.state.max_pick_error !== ""}
                                            // helperText={this.state.max_pick_error}
                                            type="number" onChange={this.onChange} name="max_pick" label="Maximum Pick (Leave it blank if there is no limit in picking)" />
                                    </GridItem>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {this.state.type !== "add" ?
                                <Button color="info" onClick={this.handleSubmitOwnProductStep}>Update Own Product Step Info</Button>
                                :
                                null
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
                <OwnProductIngredient {...this.props} setOwnProductIngredients={this.setOwnProductIngredients} />
                {this.state.type === "add" ?
                    <GridItem item container xs={12} >
                        <Button color="info" onClick={this.handleSubmitOwnProductStep}>Store New Own Product Step</Button>
                    </GridItem>
                    :
                    null
                }
            </GridContainer>
        )
    }
}

export default withStyles(useStyles)(OwnProductStepDetail)
