import React, { useContext } from 'react'
import { makeStyles, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';
import BcaIcon from "assets/img/havilla/bca.svg";
import TransferIcon from "assets/img/havilla/transfer_bank.png";
import { dictionary } from 'Dictionary';
import { ClientSideContext } from '../../ClientSideContext';

const useStyles = makeStyles((theme) => ({
    radioRoot: {
        '& + $label': {
            color: 'white',
        },
        '&': {
            color: 'white',
        },
    },
    radioChecked: {
        '& + $label': {
            color: '#AF986D',
        },
        '&': {
            color: '#AF986D',
        },
    },
    radioDisabled: {
        '&, & + $label': {
            color: 'rgba(0, 0, 0, 0.38)',
        },
    },
    label: {
    },
    buttonRegister: {
        marginTop: '68px'
    },
    formControlRadio: {
        [theme.breakpoints.down('sm')]: {
            marginRight: '12px',
            width: '100%'
        },
        width: '100%'
    },
    radioItemContainer: {
        width: '100%', border: '1px solid lightgrey',
        marginBottom: 15,
        marginLeft: 3,
        padding: '5px 15px'
    },
    radioItem: {
        width: '85%',
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
    },
    bcaIcon: {
        float: 'right',
        marginTop: 8,
        width: 60,
        [theme.breakpoints.down('xs')]: {
            width: '20%',
        }
    },
    transferIcon: {
        float: 'right',
        marginTop: 8,
        width: 30
    },
    detailContainer: {
        marginBottom: 15,
        paddingLeft: 45,
        width: '100%'
    }
}));

function PaymentRadioButton(props) {
    const classes = useStyles();
    const [showDetail, setShowDetail] = React.useState('bca')
    const { language } = useContext(ClientSideContext)

    const handleClickRadioItem = (key) => setShowDetail(key)
    return (
        <FormControl component="fieldset" className={classes.formControlRadio}>
            <RadioGroup row aria-label="paymentMethod" name="paymentMethod" value={props.value} onChange={props.onChange}>
                <div className={classes.radioItemContainer} onClick={() => { handleClickRadioItem('bca') }}>
                    <img className={classes.bcaIcon} src={BcaIcon} alt="close icon"></img>
                    <FormControlLabel
                        className={classes.radioItem}
                        classes={{ label: classes.label }}
                        value="bca" control={
                            <Radio classes={{
                                checked: classes.radioChecked,
                                disabled: classes.radioDisabled,
                            }}
                                color='default' />
                        }
                        label="BCA Virtual Account"
                    />
                </div>
                {showDetail === 'bca' ?
                    <div className={classes.detailContainer}>
                        <b>{dictionary['How to pay using BCA Virtual Account'][language]}</b>
                        <p>
                            1. {dictionary['How to pay Point 1'][language]}<br />
                            2. {dictionary['How to pay Point 2'][language]}<br />
                            3. {dictionary['How to pay Point 3'][language]}<br />
                            4. {dictionary['How to pay Point 4'][language]}<br />
                            5. {dictionary['How to pay Point 5'][language]}<br />
                        </p>
                        <br />
                        <b>{dictionary['Pay to BCA Virtual Account - Other bank User'][language]}</b>
                        <p>
                            {dictionary['Pay to BCA Virtual Account Desc'][language]}
                        </p>
                    </div>
                    :
                    ''
                }

                {/* <div className={classes.radioItemContainer}>
                    <img className={classes.transferIcon} src={TransferIcon} alt="close icon"></img>
                    <FormControlLabel
                        className={classes.radioItem}
                        classes={{ label: classes.label }}
                        value="transfer" control={
                            <Radio classes={{
                                checked: classes.radioChecked,
                                disabled: classes.radioDisabled,
                            }}
                                color='default' />
                        }
                        label="Bank Transfer"
                    />
                </div> */}
            </RadioGroup>
            <FormHelperText style={{
                position: "absolute",
                bottom: '-1.2rem',
                textTransform: 'capitalize'
            }}>{props.gender_error}</FormHelperText>
        </FormControl>
    )
}

export default PaymentRadioButton
