import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom';
import styles from "assets/jss/client-side/App.js";

const useStyles = makeStyles((theme) => ({
    ...styles(theme),
    img : {
        width : '100%',
        // maxHeight : '524px',
        [theme.breakpoints.down('sm')]: {
            width : '1264px',
            height : '512px',
            position : 'absolute',
            left: '60%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
        },
    },
    imgContainer : {
        [theme.breakpoints.down('sm')]: {
            height : '512px',
            overflow:'hidden',
            position: 'relative',
        },
    },
    sliderTitle :{
        color : 'white',
        position : 'absolute',
        top : '25%',
        left : '8%',
        fontFamily: 'BlaakLight',
        fontSize: '28px',
        textAlign : 'left',
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
            left : '32px',
        },
    },
    sliderDesc :{
        color : 'white',
        position : 'absolute',
        top : '37%',
        left : '8%',
        fontFamily: 'BlaakLight',
        fontSize: '16px',
        textAlign : 'left',
        [theme.breakpoints.down('sm')]: {
            left : '32px',
        },
    },
    sliderLinkContainer :{
        position : 'absolute',
        top : '70%',
        left : '8%',
        textAlign : 'left',
        [theme.breakpoints.down('sm')]: {
            left : '32px',
        },
    },
    sliderLink :{
        color : 'white',
        fontFamily: 'BlaakLight',
        fontSize: '14px !important',
        '&:hover': {
            textDecoration: 'none',
            color : 'white',
        },
        '&:focus' :{
            color : 'white',
        }
    },
    sliderFade : {
        position : 'absolute',
        top : '0px',
        left : '0px',
        width : '100%',
        height : '99%',
        background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 78.76%)',
        [theme.breakpoints.down('sm')]: {
            background: 'gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 78.76%)',
            height : '100%',
            // transform: 'rotate(-90deg)',
        },
        [theme.breakpoints.down('xs')]: {
            background: 'rgba(0, 0, 0, 0.2)',
            width : '100%',
            height : '100%',
            // transform: 'rotate(-90deg)',
        },
    }
}));
function TeaShopCarouseItem(props) {
    const classes = useStyles();
    return (
        <Grid 
            container 
            justify='center' 
            alignItems='center' 
        >
            <Grid item xs={12} className={classes.imgContainer}>
                <img className={classes.img} src={props.img} alt={props.img}/>
            </Grid>
            <div className={classes.sliderFade}>

            </div>
            <Grid className={classes.sliderTitle} item xs={11} sm={5} md={4}>
                {props.title}
            </Grid>
            <Grid className={classes.sliderDesc} item xs={11} sm={5} md={3}>
                {props.desc}
            </Grid>
            <Grid className={classes.sliderLinkContainer} item xs={12} sm={5} md={4}>
                <a href={props.linkTo} className={`${classes.link} ${classes.sliderLink} ${classes.linkPointerWUnderline}`}>
                    {props.linkText}
                </a>
                {/* <Link to={props.linkTo} className={`${classes.link} ${classes.sliderLink} ${classes.linkPointerWUnderline}`}>
                </Link> */}
            </Grid>
        </Grid>
    )
}

export default TeaShopCarouseItem
