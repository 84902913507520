import React from 'react'
import { makeStyles, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';
import JneIcon from "assets/img/havilla/jne_logo.svg";
import TikiIcon from "assets/img/havilla/tiki.png";
import GosendIcon from "assets/img/havilla/gosend_icon.svg";


const useStyles = makeStyles((theme) => ({
    radioRoot: {
        '& + $label': {
            color: 'white',
        },
        '&': {
            color: 'white',
        },
    },
    radioChecked: {
        '& + $label': {
            color: '#AF986D',
        },
        '&': {
            color: '#AF986D',
        },
    },
    radioDisabled: {
        '&, & + $label': {
            color: 'rgba(0, 0, 0, 0.38)',
        },
    },
    label: {
    },
    buttonRegister: {
        marginTop: '68px'
    },
    formControlRadio: {
        [theme.breakpoints.down('sm')]: {
            marginRight: '12px',
            width: '100%'
        },
        width: '100%'
    },
    radioItemContainer: {
        width: '100%', border: '1px solid lightgrey',
        marginBottom: 15,
        marginLeft: 3,
        padding: '5px 15px'
    },
    selectedRadioItemContainer: {
        width: '100%', border: '1px solid #AF986D',
        marginBottom: 15,
        marginLeft: 3,
        padding: '5px 15px'
    },
    radioItem: {
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
        width: '80%',
    },
    shippingIcon: {
        float: 'right',
        marginTop: 8,
        width: 60,
        [theme.breakpoints.down('xs')]: {
            width: '20%',
        },
    }
}));

function ShippingRadioButton(props) {
    const classes = useStyles();
    return (
        <FormControl component="fieldset" className={classes.formControlRadio}>
            <RadioGroup row aria-label="shipping_service" name="shipping_service" value={props.value} onChange={props.onChange}>
                {
                    props.city == '22' || props.city == '23' || props.city == '107' ?
                        <div className={props.value === 'gosend' ? classes.selectedRadioItemContainer : classes.radioItemContainer}>
                            <img className={classes.shippingIcon} src={GosendIcon} alt="close icon" style={{ width: 85 }}></img>
                            <FormControlLabel
                                className={classes.radioItem}
                                classes={{ label: classes.label }}
                                value="gosend" control={
                                    <Radio classes={{
                                        root: classes.radioRoot,
                                        checked: classes.radioChecked,
                                        disabled: classes.radioDisabled,
                                    }}
                                        color='default' />
                                }
                                label={props.gosendCost > 0 ? `Go-Send (Rp. ${props.gosendCost.toLocaleString('ID')})` : `Go-Send`}
                            />
                        </div>
                        :
                        ''
                }
                {
                    props.list.length > 0 ?
                        props.list.map((item, i) => {
                            return (
                                <div className={props.value === `${item.courier}-${item.service}` ? classes.selectedRadioItemContainer : classes.radioItemContainer} key={`${item.courier}-${item.service}`}>
                                    <img className={classes.shippingIcon} src={item.courier === 'jne' ? JneIcon : TikiIcon} alt="close icon"></img>
                                    <FormControlLabel
                                        className={classes.radioItem}
                                        classes={{ label: classes.label }}
                                        value={`${item.courier}-${item.service}`} control={
                                            <Radio classes={{
                                                root: classes.radioRoot,
                                                checked: classes.radioChecked,
                                                disabled: classes.radioDisabled,
                                            }}
                                                color='default' />
                                        }
                                        label={`${item.label} ${item.service} (Rp. ${item.value.toLocaleString('ID')})`}
                                    />
                                </div>
                            )
                        })
                        :
                        'Please input recipient address first'
                }

                {/* <div className={props.value === 'jne-REG' ? classes.selectedRadioItemContainer : classes.radioItemContainer}>
                    <img className={classes.shippingIcon} src={JneIcon} alt="close icon"></img>
                    <FormControlLabel
                        className={classes.radioItem}
                        classes={{ label: classes.label }}
                        value="jne-REG" control={
                            <Radio classes={{
                                checked: classes.radioChecked,
                                disabled: classes.radioDisabled,
                            }}
                                color='default' />
                        }
                        label="JNE Reguler"
                    />
                </div>
                <div className={props.value === 'jne-YES' ? classes.selectedRadioItemContainer : classes.radioItemContainer}>
                    <img className={classes.shippingIcon} src={JneIcon} alt="close icon"></img>
                    <FormControlLabel
                        className={classes.radioItem}
                        classes={{ label: classes.label }}
                        value="jne-YES" control={
                            <Radio classes={{
                                checked: classes.radioChecked,
                                disabled: classes.radioDisabled,
                            }}
                                color='default' />
                        }
                        label="JNE YES"
                    />
                </div>
                <div className={props.value === 'tiki' ? classes.selectedRadioItemContainer : classes.radioItemContainer}>
                    <img className={classes.shippingIcon} src={TikiIcon} alt="close icon" style={{ width: 85 }}></img>
                    <FormControlLabel
                        className={classes.radioItem}
                        classes={{ label: classes.label }}
                        value="tiki" control={
                            <Radio classes={{
                                checked: classes.radioChecked,
                                disabled: classes.radioDisabled,
                            }}
                                color='default' />
                        }
                        label="TIKI"
                    />
                </div> */}
            </RadioGroup>
            <FormHelperText style={{
                position: "absolute",
                bottom: '-1.2rem',
                textTransform: 'capitalize'
            }}>{props.gender_error}</FormHelperText>
        </FormControl>
    )
}

export default ShippingRadioButton
