import React, { Component } from 'react'
import MaterialTable from 'material-table'
import axios from 'axios'
import { baseUrl, requestConfigAdmin } from 'Environment'
import { Grid, withStyles } from '@material-ui/core';
import styles from "assets/jss/client-side/App.js";
import ButtonAdmin from "admin/components/CustomButtons/Button.js";
import { AdminContext } from '../../AdminContext';
import Confirmation from 'admin/components/CustomDialogs/Confirmation';
import IOSSwitch from 'admin/components/Switch/IOSSwitch';
import {
    prefix
} from '../../../Environment.js';

const useStyles = theme => ({
    ...styles(theme),
    buttonTable: {
        minWidth: '104px'
    },
    img: {
        width: '100px',
        height: '50px',
    },
});

export class Article extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props)

        this.state = {
            tea_talks_articles: [],
            social_acts_articles: [],

            openDialog: false,
            tempDeleted: null,
            count_for_update : 0
        }
    }

    componentDidMount = () => {
        this.getArticles();
    }

    getArticles = () => {
        this.setState((prevState => ({
            count_for_update: prevState.count_for_update + 1
        })))
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        axios.get(`${baseUrl}/articles`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    tea_talks_articles: res.data.data.tea_talks,
                    social_acts_articles: res.data.data.social_acts,
                }, () => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    handleDeleteClick = (data) => {
        this.setState({
            tempDeleted: data,
            openDialog: true,
        })
    }

    handleCloseDialog = (action) => {
        if (action) {
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
            axios.delete(`${baseUrl}/articles/${this.state.tempDeleted.id}`,
                requestConfigAdmin
            ).then((res) => {
                if (res.data.success) {
                    this.getArticles();
                    this.setState({
                        tempDeleted: null,
                        openDialog: false,
                    }, () => {
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "success",
                            message: "Article Category Deleted"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    })
                } else {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: res.data.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: e.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log("Error : ", e);
            })
        } else {
            this.setState({
                tempDeleted: null,
                openDialog: false,
            })
        }
    }

    handleActiveToggle = (e) => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        let id = (e.target.name).split('-')[1];
        let data = {
            id: id,
        }
        axios.post(`${baseUrl}/articles/toggle_active`,
            data,
            requestConfigAdmin
        ).then((res) => {
            if (res.data.success) {
                this.getArticles();
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "success",
                    message: "Update Article Success!"
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            } else {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            console.log("Error : ", e);
        })
    }

    render() {
        const { classes } = this.props;
        const columns = [
            {
                title: 'Title',
                field: 'title',
            },
            {
                title: 'Written By',
                field: 'written_by',
            },
            {
                title: 'Thumbnail',
                field: 'photo_url',
                render: (rowData) => {
                    return (
                        <img className={classes.img} src={`${rowData.thumbnail_url}?v=${this.state.count_for_update}`} alt={`${rowData.id} img ${this.state.count_for_update}`}></img>
                    )
                }
            },
            {
                title: 'Active',
                render: (rowData) => {
                    return (
                        <Grid container>
                            <Grid item container xs={2} alignItems="center">
                                <IOSSwitch
                                    checked={rowData.is_active == 1 ? true : false}
                                    onChange={this.handleActiveToggle}
                                    name={`is_active-${rowData.id}`}
                                />
                            </Grid>
                        </Grid>
                    )
                }
            },
            {
                title: '',
                render: (rowData) => {
                    return (
                        <Grid container>
                            <Grid item container xs={12} justify="center">
                                <Grid item xs={6} container justify="center">
                                    <ButtonAdmin className={classes.buttonTable} color="info"
                                        onClick={
                                            (e) => this.props.history.push(prefix + '/admin/articles/' + rowData.id)
                                        }
                                    >
                                        EDIT
                                    </ButtonAdmin>
                                </Grid>
                                <Grid item xs={6} container justify="center">
                                    <ButtonAdmin className={classes.buttonTable}
                                        color="danger"
                                        onClick={() => this.handleDeleteClick(rowData)}
                                    >
                                        DELETE
                                    </ButtonAdmin>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            },
        ]
        return (
            <div style={{ maxWidth: '100%' }}>
                <Confirmation
                    openDialog={this.state.openDialog}
                    message={`You are about to delete article <b>${this.state.tempDeleted ? this.state.tempDeleted.title : ""}</b>, 
                    are you sure you want to delete it?`}
                    handleCloseDialog={this.handleCloseDialog}
                />
                <h4 style={{ background: 'white', padding: '10px 25px' }}><b>Articles Tea Talks</b></h4>

                <MaterialTable
                    columns={columns}
                    data={this.state.tea_talks_articles}
                    title={<ButtonAdmin color="info" onClick={() => this.props.history.push(prefix + '/admin/articles/add')}>ADD NEW TEA TALKS ARTICLE</ButtonAdmin>}
                    options={{
                        search: true,
                        exportButton: false,
                        pageSize: 10
                    }}
                />
                <br />
                <br />
                <h4 style={{ background: 'white', padding: '10px 25px' }}><b>Article Social Acts</b></h4>
                <MaterialTable
                    columns={columns}
                    data={this.state.social_acts_articles}
                    title={<ButtonAdmin color="info" onClick={() => this.props.history.push(prefix + '/admin/articles/add_social_act')}>ADD NEW SOCIAL ACTS ARTICLE</ButtonAdmin>}
                    options={{
                        search: true,
                        exportButton: false,
                        pageSize: 10
                    }}
                />
            </div>
        )
    }
}

export default withStyles(useStyles)(Article)
