const white_color = '#FFFAF4';
const gold_color = '#AF986D';

const App = theme => ({
    container: {
        padding: '10px',
        backgroundColor: "#000000",
        color: "white"
    },
    containerHeadline: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: "50px",
        },
        paddingTop: "85px",
        textAlign: 'center',
        alignItems: 'center',
        backgroundColor: "#000000",
    },
    title: {
        fontFamily: 'BlaakLight',
        fontSize: '40px'
    },
    smallerTitle: {
        fontFamily: 'BlaakLight',
        fontSize: '36px',
        color: 'white',
        '& p': {
            fontSize: '36px',
            color: 'white',
            fontFamily: 'Blaaklight'
        },
        '& span': {
            fontSize: '36px',
            color: 'white',
            fontFamily: 'Blaaklight'
        }
    },
    smallTitle: {
        fontFamily: 'BlaakLight',
        fontSize: '22px',
        '& p': {
            fontSize: '36px',
            color: 'white',
            fontFamily: 'Blaaklight'
        }
    },
    desc: {
        fontFamily: 'Martel Sans',
        fontSize: '24px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px'
            // paddingLeft: '25px !important',
            // paddingRight: '25px !important',
        }
    },
    buttonOutline: {
        [theme.breakpoints.down('lg')]: {
            width: "100%",
        },
        [theme.breakpoints.up('lg')]: {
            width: '245px',
        },
        borderRadius: "0px",
        backgroundColor: "none",
        border:'1px solid #AF986D',
        color:'#AF986D',
        height: '56px',
        fontSize: "15px",
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                border:'1px solid white',
                color: "white"
            },
        },
        textTransform: 'none'
    },
    buttonLight: {
        [theme.breakpoints.down('lg')]: {
            width: "100%",
        },
        [theme.breakpoints.up('lg')]: {
            width: '245px',
        },
        borderRadius: "0px",
        backgroundColor: "#222222",
        color:"white",
        height: '56px',
        fontSize: "15px",
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                backgroundColor: "#333333",
                color: "white"
            },
        },
        textTransform: 'none'
    },
    buttonDark: {
        [theme.breakpoints.down('lg')]: {
            width: "100%",
        },
        [theme.breakpoints.up('lg')]: {
            width: '245px',
        },
        borderRadius: "0px",
        color: 'white',
        backgroundColor: "#AF986D",
        height: '56px',
        fontSize: "15px",
        textTransform: 'none',
        '&:hover': {
            backgroundColor: "#AF9860",
            color: "white"
        },
        '&:focus': {
            backgroundColor: "#AF986D",
            color: "white"
        },
        '&:disabled': {
            backgroundColor: "#333333",
            color: "black"
        },
        [theme.breakpoints.down('sm')]: {
            '&:hover': {
                backgroundColor: "white",
                color: "black"
            },
            '&:focus': {
                backgroundColor: "#AF986D",
                color: "white"
            },
            '&:disabled': {
                backgroundColor: "#333333",
                color: "black"
            },
        },
    },
    sliderItem: {
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            height: "auto",
        },
        [theme.breakpoints.down('xl')]: {
            width: "300px",
            height: "551px",
        },
        [theme.breakpoints.up('xl')]: {
            width: "316px",
            height: "551px",
        },
        margin: '10px',
        alignItems: 'center',
        '& img': {
            [theme.breakpoints.down('sm')]: {
                width: "auto",
                height: "100%",
                margin:'0 -90px'
            },
            [theme.breakpoints.up('md')]: {
                width: "551px",
                height: "551px",
            },
        }
    },
    sliderContainer: {
        width: '100%',
        padding: '0px !important',
        // [theme.breakpoints.down('xl')]: {
        //     marginLeft: '-270px',
        // },
        // [theme.breakpoints.up('xl')]: {
        //     marginLeft: '-200px',
        // },
    },
    link: {
        fontFamily: 'Martel Sans',
        fontSize: "16px !important",
        cursor:'pointer',
        color : white_color,
        '&:hover': { 
            textDecoration: 'none',
            color : gold_color,
        },
        '&:focus' :{
            color : gold_color,
        }
    },
    linkHoverBold : {
        '&:hover': {
            fontWeight : 'bold',
            color : gold_color,
        }
    },
    linkGold: {
        fontFamily: 'Martel Sans',
        textDecoration: 'underline',
        // fontSize: "16px !important",
        cursor:'pointer',
        color : gold_color,
        '&:hover': { 
            color : white_color,
        },
        '&:focus' :{
            color : white_color,
        }
    },
    goldColor :{
        color : gold_color,
    },
    linkHoverUnderline : {
        '&:hover': {
            borderBottom: '1px solid black',
            paddingBottom: '5px',
        }
    },
    linkPointer: {
        cursor:'pointer',
    },
    linkPointerWUnderline: {
        cursor:'pointer',
        textDecoration:'underline'
    },
    titleMediumSize: {
        fontWeight: `bold`,
        fontStyle: `normal`,
        fontSize: `1.5rem`
    },
    contentContainer: {
        [theme.breakpoints.down('sm')]: {
            padding: `1rem 2rem`
        },
        padding: `3rem 5rem`
    },
    linkStyledButton : {
        fontSize : '14px',
        fontFamily : 'Martel Sans',
        border : 'none',
        backgroundColor : 'none',
        background : 'none',
        cursor : 'pointer',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 10000,
      color: 'black',
      background:'rgba(255,255,255,0)',
      pointerEvents: 'none',
    },
    fontBold : {
        fontWeight : 700
    },
    sectionTitle : {
        fontSize: '28px',
        fontFamily : 'BlaakLight',
        marginTop : '60px',
        padding : '12px'
    },
    noResult : {
        textAlign : 'center',
        fontSize: '20px',
        opacity : 0.5
    },
    buttonSmall: {
        fontSize : '12px',
        borderRadius: 0,
        background: 'none',
        border: '1px solid #AF986D',
        marginTop: 4,
        color: '#AF986D',
        '&:hover': {
          backgroundColor: "white",
          color: "black"
        },
        '&:focus': {
          backgroundColor: "#AF986D",
          color: "white"
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: '10px',
            '&:hover': {
                backgroundColor: "white",
                color: "black"
            },
            '&:focus': {
                backgroundColor: "#AF986D",
                color: "white"
            },
        },
    },
    selectedButtonSmall: {
        fontSize : '12px',
        borderRadius: 0,
        backgroundColor: "#AF986D",
        border: '1px solid #AF986D',
        color: "white",
        '&:hover': {
            backgroundColor: "white",
            color: "black"
        },
        '&:focus': {
            backgroundColor: "#AF986D",
            color: "white"
        },
        marginTop: 4,
        [theme.breakpoints.down('sm')]: {
            marginRight: '10px',
            '&:hover': {
                backgroundColor: "white",
                color: "black"
            },
            '&:focus': {
                backgroundColor: "#AF986D",
                color: "white"
            },
        },
    },
});

export default App