import React, { Component } from 'react'
import {
    baseUrl,
    requestConfig
} from '../../../../Environment.js';
import {
    Grid,
    // IconButton 
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Backdrop, CircularProgress } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import ProductCard from 'client-side/components/ProductCard/ProductCard';
import axios from 'axios';
const useStyles = theme => ({
    ...styles(theme),
    textField: {
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        [theme.breakpoints.up('md')]: {
        },
        width: "80%"
    },
    iconEdit: {
        marginTop: `-8px`
    },
    addNewContainer: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: 0,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '55%'
        },
    },
    boxOutline: {
        padding: '1.2rem',
        width: '100%',
        height: '100%'
    },
});

// const borderDotted = {
//     border: '2px dotted lightgrey',
// }
class MyWishlist extends Component {
    state = {
        openBackdrop: false,
        wishlists: []
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.wishlists !== nextState.wishlists) return true
        if (this.state.openBackdrop !== nextState.openBackdrop) return true
        return false;
    }

    handleToggleWishlist = (id) => {
        // console.log(newValue);
        this.setState({
            // addresses: [...this.state.addresses, res.data.data]
            wishlists: this.state.wishlists.map(wishlist => {
                if (wishlist.id === id) {
                    wishlist.wishlisted = !wishlist.wishlisted
                }
                return wishlist;
            })
        }, () => { 
        })
    }

    componentDidMount = async () => {
        this.setState({ openBackdrop: true });
        await axios.get(`${baseUrl}/profile/get_all_wishlist`, requestConfig).then((res) => {
            if (res.data.success) {
                this.setState({
                    wishlists: res.data.data
                }, () => {
                    this.setState({ openBackdrop: false });
                    // console.log(this.state)
                })
            } else {
                this.setState({ openBackdrop: false });
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            console.log("Error : ", e);
        })
    }

    render() {
        const { classes } = this.props;
        // const {
        //     startDate
        // } = this.state;
        return (
            <Container maxWidth="xl" className={`${classes.container} ${classes.contentContainer}`}>
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{'color': '#AF986D'}} />
                </Backdrop>
                <Grid container
                    spacing={3}>
                    {
                        this.state.wishlists.map(item => {
                            // alert(window.location.pathname.split("/")[2]);
                            return (
                                <Grid item lg={3} sm={6} xs={12} key={item.id}>
                                    <ProductCard thumbnail={item.thumbnail_url} detail={item} key={item.id} handleToggleWishlist={this.handleToggleWishlist} openCart={this.props.openCart}></ProductCard>
                                </Grid>
                            )
                        })
                    }
                    {this.state.wishlists.length === 0 ?

                        <Grid item xs={12}>
                            <p style={{ textAlign: 'center' }}>You have no wishlist item at the moment.</p>
                        </Grid>
                        :
                        ""
                    }
                </Grid>
            </Container>
        )
    }
}

export default withStyles(useStyles)(MyWishlist) 
