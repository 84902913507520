import React, { Component } from 'react'
import { Container, withStyles, Grid, Backdrop, CircularProgress, Button } from '@material-ui/core'
import styles from "assets/jss/client-side/App.js";
import TeaShopCarousel from 'client-side/components/TeaShopCarousel/TeaShopCarousel';
import TabNav from 'client-side/components/TabNav/TabNav';
import TabNavSmall from 'client-side/components/TabNav/TabNavSmall';
import SearchField from 'client-side/components/SearchField/SearchField';
import axios from 'axios';
import {
    baseUrl,
    requestConfig,
    prefix
} from '../../../Environment.js';
import ProductCardGeneral from 'client-side/components/ProductCard/ProductCardGeneral';
import {isMobile} from "react-device-detect";
import ImgContent1 from "assets/img/havilla/content_tea_shop_1.png";
import ImgContent2 from "assets/img/havilla/content_tea_shop_2.png";
import PostLargeGeneral from 'client-side/components/Post/PostLargeGeneral';
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp.js';
import Fade from '@material-ui/core/Fade';
import { ClientSideContext } from '../../ClientSideContext';

const useStyles = theme => ({
    ...styles(theme),
    carouselContainer: {
        // marginTop : '20px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0px',
            marginLeft: '0px',
            marginRight: '0px',
        },
    },
    postsContainer: {
        marginTop: '12px',
    },
    searchFieldContainer: {
        paddingTop: '2.7rem',
        paddingLeft: '1.3rem',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '3rem'
        },
    },
    tabNavContainer: {
        marginTop: '32px',
        [theme.breakpoints.up('xl')]: {
            marginRight: '4.3rem'
        },
    },
    categoriesTitle:{
        [theme.breakpoints.up('lg')]: {
            marginLeft: '-70px'
        },
    }
});
const TeaShopCarouselMemo = React.memo(props => {
    return <TeaShopCarousel {...props}></TeaShopCarousel>
});
export class TeaShop extends Component {
    static contextType = ClientSideContext;
    constructor(props) {
        super(props)

        this.state = {
            openBackdrop: false,

            productCategoryId: 1,
            subProductCategoryId: null,
            categories: [],
            subCategories: [],
            sliders: [],

            searchText: '',
            all: [{
                id: null,
                name: "All"
            }],

            products: [],
            nextPageLink: null,

            snackbarType: 'info',
            snackbarMessage: '',
            snackbarCounter: 0,
            loadProducts: false,
            noResultFound: "",
            loadSliders: false,
            position_y : 0,
        }
    }

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (this.state.products !== nextState.products) return true
    //     if (this.state.openBackdrop !== nextState.openBackdrop) return true
    //     return false;
    // }

    componentDidMount() {
        this.getCategories()
        this.getSliders()
    }

    componentDidUpdate() {
    }

    getSliders = () => {
        this.setState({ openBackdrop: false });
        let data = {
            type: 'tea_shop'
        }
        axios.post(`${baseUrl}/sliders/get_by_type`,
            data,
            requestConfig
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState({
                    sliders: data,
                    loadSliders: true
                },()=>{
                    window.scrollTo(0, 130)
                })
                this.setState({ openBackdrop: false });
            } else {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${res.data.message}`
                })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            console.log("Error : ", e);
        })
    }

    getCategories = () => {
        this.setState({ openBackdrop: true });
        axios.get(`${baseUrl}/productcategory`,
            requestConfig
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                // let category_id = parseInt(this.props.location.pathname.replace(`/tea_shop/`, ""))
                // let obj = data.find(o => o.id === category_id);
                if (this.props.location.state !== undefined) {
                    this.setState({
                        productCategoryId: this.props.location.state.category_id
                    })
                } else {
                    const category_name = (this.props.location.pathname.split("/").pop()).toLowerCase();
                    let category = data.find(o => (o.name).toLowerCase() === category_name);
                    if (category) {
                        this.setState({
                            productCategoryId: category.id
                        })
                    } else {
                        if (category_name !== 'tea_shop') {
                            let path = prefix + `/tea_shop`;
                            this.props.history.push(path);
                        }
                    }
                }
                this.setState({
                    categories: data,
                })
                // FETCH SUB CATEGORIES
                this.getSubCategories(data[0].id);
                // this.setState({ openBackdrop:false});
            } else {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${res.data.message}`
                })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            console.log("Error : ", e);
        })
    }

    getSubCategories = (category_id) => {
        this.setState({ openBackdrop: true });
        axios.get(`${baseUrl}/productcategory/get_sub_category/${category_id}`,
            requestConfig
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState({
                    subCategories: [...this.state.all, ...data],
                })
                // FETCH PRODUCTS
                this.getProducts()
                // this.setState({ openBackdrop:false});
            } else {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${res.data.message}`
                })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            console.log("Error : ", e);
        })
    }

    getProducts() {
        this.setState({ openBackdrop: true });
        let data = {
            category_id: this.state.productCategoryId,
            sub_category_id: this.state.subProductCategoryId,
            keyword: this.state.searchText,
            limit: 9
        }

        axios.post(`${baseUrl}/products/get_products_by_category`,
            data,
            requestConfig
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState({
                    products: data.data,
                    nextPageLink: data.next_page_url,
                    loadProducts: true
                })
                if(data.data.length === 0){
                    this.setState({
                        noResultFound : "No Result Found"
                    })
                }
                this.setState({ openBackdrop: false });
            } else {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${res.data.message}`
                })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            console.log("Error : ", e);
        })
    }

    handleProductCategoryChange = (e, newValue) => {
        e.preventDefault()
        this.setState({
            productCategoryId: newValue
        }, () => {
            if (this.state.category_id !== 1) {
                this.setState({
                    subProductCategoryId: null
                }, () => {
                    this.getProducts();
                })
            } else {
                this.getProducts();
            }
            // console.log(this.state);
        });
    }
    handleSubProductCategoryChange = (e, newValue) => {
        e.preventDefault()
        this.setState({
            subProductCategoryId: newValue
        }, () => {
            this.getProducts();
            // console.log(this.state);
        });
    }

    handleSearchChange = (e, newValue) => {
        e.preventDefault()
        this.setState({
            searchText: newValue
        }, () => {
            this.setState({
                productCategoryId: false,
                subProductCategoryId: null
            }, () => {
                this.getProducts();
            })
            // console.log(this.state);
        });
    }

    handleNextPage = () => {
        var top  = window.pageYOffset || document.documentElement.scrollTop
        this.setState({ openBackdrop: true });
        let data = {
            category_id: this.state.productCategoryId,
            sub_category_id: this.state.subProductCategoryId,
            keyword: this.state.searchText,
            limit: 9
        }
        axios.post(`${this.state.nextPageLink}`,
            data,
            requestConfig
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState((prevState, props) => ({
                    products: [...prevState.products, ...data.data],
                    nextPageLink: data.next_page_url
                }),()=>{
                    window.scrollTo({ top: top });
                    this.setState({ openBackdrop: false });
                })
            } else {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${res.data.message}`
                })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            console.log("Error : ", e);
        })
    }
    render() {
        const { classes } = this.props

        return (
            <Container maxWidth="xl" className={classes.container}>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.counter}
                />
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{'color': '#AF986D'}} />
                </Backdrop>
                <Grid container alignItems='center' justify="center">
                    {/* <Fade in={this.state.loadSliders} timeout={700}> */}
                        <Grid item xs={12} md={11} className={`${classes.carouselContainer}`}>
                            <TeaShopCarouselMemo sliders={this.state.sliders} />
                        </Grid>
                    {/* </Fade> */}
                    <Grid item xs={12} container direction="row-reverse" justify={isMobile ? "center" : "flex-end"}>
                        <Grid item xs={12} md={2} className={classes.searchFieldContainer}>
                            <SearchField value={this.state.searchText} onChange={this.handleSearchChange}></SearchField>
                        </Grid>
                        {/* <Grid item md={1} /> */}
                        <Grid item xs={12} sm={10} md={9} lg={9} container justify="flex-end"
                        className={classes.categoriesTitle}>
                            <TabNav
                                className={classes.tabNavContainer}
                                tabStyles={{ fontSize: '28px' }}
                                variant="scrollable"
                                value={this.state.productCategoryId}
                                handleNavChange={this.handleProductCategoryChange}
                                navItems={this.state.categories}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container justify="center" style={{ minHeight: '72px' }}>
                        {
                            this.state.productCategoryId === 1 ?
                                <TabNavSmall
                                    styles={{ marginTop: '24px' }}
                                    variant="scrollable"
                                    value={this.state.subProductCategoryId}
                                    handleNavChange={this.handleSubProductCategoryChange}
                                    navItems={this.state.subCategories}
                                />
                                :
                                null
                        }
                    </Grid>
                    {/* <Fade in={this.state.loadProducts} timeout={700}> */}
                        <React.Fragment>
                            <Grid style={{ marginTop: '32px', minHeight: '600px' }} item xs={12} md={11} container
                                spacing={3}>
                                {
                                    this.state.products.length > 0 ?
                                        this.state.products.map(item => {
                                            // alert(window.location.pathname.split("/")[2]);
                                            return (
                                                <Grid item lg={4} md={6} sm={6} xs={12} key={item.id}>
                                                    <ProductCardGeneral thumbnail={item.thumbnail_url} openCart={this.props.openCart} detail={item} key={item.id} />
                                                </Grid>
                                            )
                                        })
                                        :
                                    <Grid item xs={12} className={classes.noResult}>{this.state.noResultFound}</Grid>
                                }
                            </Grid>
                            {
                                this.state.products.length > 0 && this.state.nextPageLink !== null ?
                                    <Grid item xs={8} md={2}>
                                        <Button onClick={this.handleNextPage} variant="outlined" className={classes.buttonOutline} style={{ marginTop: '48px', width: '100%' }}>
                                            See More Products
                                        </Button>
                                    </Grid>
                                    :
                                    null
                            }
                        </React.Fragment>
                    {/* </Fade> */}


                    <Grid item xs={12} md={11} className={classes.sectionTitle}>
                        {this.context.language === 'id' ? 'Kontak kami untuk informasi lebih lanjut' : 'Other way to have Us'}
                    </Grid>
                    <Grid container item xs={12} md={11} spacing={3} className={classes.postsContainer} justify="center">
                        <PostLargeGeneral
                            xs={12}
                            md={6}
                            img={ImgContent1}
                            title={this.context.language === 'id' ? 'Havilla di Tempat Kamu' : 'Havilla at Your Place'}
                            desc={this.context.language === 'id' ? 
                                'Kami menyediakan pilihan teh berkualitas yang dapat disajikan di tempatmu. Mulai dari restoran, hotel sampai kafe, kami siap menyediakan kebutuhanmu!'
                                :
                                'We can provide you range of tea selections that can be served and catered all of your tea needs. From Restaurants, Hotels to Coffee Shops, we got you covered!'
                            }
                            linkText={this.context.language === 'id' ? 'Jadilah Mitra Havilla Tea!' : 'Become Our Partner!'}
                            linkTo={`${prefix}/havilla_partnership`}
                        />

                        <PostLargeGeneral
                            xs={12}
                            md={6}
                            img={ImgContent2}
                            title={this.context.language === 'id' ? 'Milikilah Teh yang Khusus Diracik untukmu!' : 'Have your Own Company\'s Specialitea!'}
                            desc={this.context.language === 'id' ? 
                                'Apakah kamu ingin membuat racikan atau merek teh sendiri untuk perusahaanmu? Kami dapat memfasilitasinya dengan penawaran grosir dan label pribadi yang menarik.'
                                :
                                'Do you want to make your own customized tea blend/brand? We can also cater big batch orders and do private label for you!'
                            }
                            linkText={this.context.language === 'id' ? 'Informasi Pembelian Grosir' : 'Wholesale Buying Information'}
                            linkTo={`${prefix}/havilla_partnership`}
                        />
                    </Grid>

                </Grid>
            </Container>
        )
    }
}

export default withStyles(useStyles)(TeaShop)

TeaShop.defaultProps = {
    category_id: false
}