import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Floral from "assets/img/havilla/floral_big.svg";
import PasswordField from "client-side/components/PasswordField/PasswordField";
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";

import {
    baseUrl,
    requestConfig
} from '../../../Environment.js';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField.js';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { ClientSideContext } from '../../ClientSideContext';
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp.js';
import { dictionary } from 'Dictionary';

const useStyles = theme => ({
    ...styles(theme),
    containerLogin: {
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            padding: "50px 40px 100px",
        },
        [theme.breakpoints.up('md')]: {
            padding: "7% 20% 0",
        },
    },
    floral: {
        marginLeft: "20%",
        marginTop: "-80px",
        [theme.breakpoints.down('sm')]: {
            width: "100px",
            position: "absolute",
            right: "-50px"
        },
    },
    textField: {
        width: "80%",
    }
});

class Login extends Component {
    static contextType = ClientSideContext;

    constructor(props) {
        super(props);

        this.state = {
            openBackdrop: false,
            language : 'en',

            email: '',
            password: '',

            snackbarType: 'info',
            snackbarMessage: '',
            snackbarCounter: 0,
        }

        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        this.setState({
            language: this.context.language
        })
    }

    componentDidUpdate(){
        

        return true;
    }

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    handlePasswordChange = (newValue) => {
        this.setState({
            password: newValue,
        })
    }

    handleAtcGuest = () => {
        const { cartItems } = this.context;
        var cartItemsContext;
        try {
            cartItemsContext = JSON.parse(cartItems)
        } catch (e) {
            cartItemsContext = []
        }

        axios.post(`${baseUrl}/atc_guest`, { cart: cartItemsContext }, requestConfig).then((res) => {
            if (res.data.success) {
                // console.log(res.data.data)
            } else {
                // this.setState({ openBackdrop: false });
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            // this.setState({ openBackdrop: false });
            console.log("Error : ", e);
        })
    }

    handleUserOwnProduct = () => {
        const { dispatchCartItems } = this.context;

        let own_product_ids = JSON.parse(localStorage.getItem('own_product_ids'));
        if (own_product_ids === null) {
            localStorage.setItem('own_product_ids', JSON.stringify([]));
        } else {
            if (own_product_ids.length > 0) {
                let data = {
                    own_product_ids: JSON.stringify(own_product_ids)
                }

                axios.post(`${baseUrl}/add_to_cart_own`,
                    { own_product_id: own_product_ids[0] },
                    requestConfig)
                    .then((res) => {
                        if (res.data.success) {
                            dispatchCartItems({
                                type: "ADD_TO_CART",
                                newItem: res.data.data,
                            })
                            axios.post(`${baseUrl}/ownproducts/update_own_product_user`, data, requestConfig).then((res) => {
                                if (res.data.success) {
                                    console.log(res.data.data)
                                    localStorage.setItem('own_product_ids', JSON.stringify([]));
                                } else {
                                    console.log(res.data.message, res.data.data)
                                }
                            }, (e) => {
                                console.log("Error : ", e);
                            })
                        } else {
                            console.log(res.data.message, res.data.data)
                            this.setState({
                                snackbarType: 'error',
                                snackbarMessage: 'Failed Added To Cart',
                            });
                        }
                    }, (e) => {
                        console.log("Error : ", e);
                        this.setState({
                            snackbarType: 'error',
                            snackbarMessage: 'Failed Added To Cart',
                        });
                    })



            }
        }

    }

    handleSubmit = (e) => {
        this.setState({ openBackdrop: true });
        e.preventDefault();
        const state = this.state;
        axios.post(`${baseUrl}/login`,
            {
                email: this.state.email,
                password: this.state.password,
                type: 'customer'
            },
            requestConfig
        ).then((res) => {
            if (res.data.success) {
                localStorage.setItem('auth', JSON.stringify(res.data.data));
                requestConfig.headers.Authorization = "Bearer " + res.data.data.access_token
                let path = this.props.prefix + `/`;

                this.handleAtcGuest();
                this.handleUserOwnProduct();
                this.setState({ openBackdrop: false });
                this.props.history.push(path);
            } else {
                this.handleOpenSnackbar({
                    type: 'error',
                    message: res.data.message
                });
                this.setState({ openBackdrop: false });
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "error",
                message: `${e.message}`
            })
            console.log("Error : ", e);
        })
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value })
    render() {
        const { classes } = this.props;
        return (
            <Container maxWidth="xl" className={`${classes.container} ${classes.containerLogin}`}>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.counter}
                />
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{'color': '#AF986D'}} />
                </Backdrop>
                <Grid container className={classes.formContainer} onSubmit={this.handleSubmit}>
                    <Grid item md={5} xs={12}>
                        <h3 className={classes.smallerTitle}>{dictionary['Welcome Back'][this.context.language]}!</h3>
                        <p>{dictionary['Login Sub Title'][this.context.language]} <Link to={`${this.props.prefix}/register`} style={{ textDecoration: 'underline' }}>{dictionary['Click here'][this.context.language]}</Link></p>
                        <form className={classes.root} autoComplete="off">
                            <br />
                            <CustomTextField
                                width="80%"
                                autoFocus={true}
                                value={this.state.email}
                                onChange={this.onChange} name="email" type="email" label="Email *" />
                            <br /><br />

                            <PasswordField
                                value={this.state.password}
                                onChange={this.onChange}
                                name="password"
                                label="Password"
                                hideIcon={false}></PasswordField>

                            <br /><br />
                            {dictionary['Forgot Password'][this.context.language]}? <Link to={`${this.props.prefix}/send_request_forgot_password`} style={{ textDecoration: 'underline' }}>{dictionary['Click here'][this.context.language]}</Link><br /><br /><br />

                            {
                                dictionary['Login'][this.context.language] ?
                                    <Button type='submit' variant="outlined" className={classes.buttonDark}>{dictionary['Login'][this.context.language]}</Button>
                                :
                                    null
                            }
                        </form>
                    </Grid>
                    <Grid item md={1} xs={12}>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <img className={classes.floral} src={Floral} alt="floral"></img>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default withStyles(useStyles)(Login) 
