import React, { useContext } from 'react'
import { makeStyles, Backdrop, CircularProgress } from '@material-ui/core'
import globalStyles from "assets/jss/client-side/App.js";
import { AdminContext } from 'admin/AdminContext';

const useStyles = makeStyles((theme) => ({
    ...globalStyles(theme),
}))

function BackdropComp() {
    const classes = useStyles();
    const { toggleBackdrop } = useContext(AdminContext)
    return (
        <Backdrop className={classes.backdrop} open={toggleBackdrop}>
            <CircularProgress style={{'color': '#AF986D'}} />
        </Backdrop>
    )
}

export default BackdropComp
