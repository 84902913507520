import React from 'react'
import { Grid, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from "assets/jss/client-side/App.js";

const useStyles = makeStyles((theme) =>  ({
    ...styles(theme),
    shopTitle :{
        // marginTop : '10px',
        fontSize: '28px',
        fontFamily: 'BlaakLight',
    },
    shopPhotoContainer : {
        overflow : 'hidden',
        height: 'auto',
        // maxHeight: 586,
    },
    shopPhoto : {
        [theme.breakpoints.down('xs')]: {
            width: '80vw',
            marginBottom: '15px'
        },
        width:'75%',
        marginBottom: '15px'
        // height:'auto'
    },
    shopLink : {
        fontFamily: 'Martel Sans',
        fontSize: "16px",
        textDecorationLine: 'underline',
        color: "#AF986D",
        '&:hover' : {
            color : 'white'
            // textDecorationLine: 'underline',
        }
    },
    bestProductContainer: {
        [theme.breakpoints.down('xs')]: {
            marginBottom : '25px'
        },
    }
}));
function BestProduct(props) {
    const classes = useStyles();

    return (
        <Grid item container xs={12} md={6} lg={4} className={classes.bestProductContainer}>
            <Grid item xs={12} className={classes.shopPhotoContainer}>
                <img src={props.image} className={classes.shopPhoto} alt={props.imageAlt}></img>
            </Grid>
            <Grid item xs={12} className={classes.shopTitle}>
                {props.description}
            </Grid>
            <Grid item xs={12} className={classes.shopTitle}>
                <Link to={props.linkTo}
                    className={`${classes.shopLink}`}
                >
                    {props.linkText}
                </Link>
            </Grid>
        </Grid>
    )
}

export default BestProduct
