export const clientSideReducer = (state, action) => {
    var stateObject;
    try {
        stateObject = JSON.parse(state)
    } catch (e) {
        stateObject = []
    }
    var resultState = []

    switch (action.type) {
        case 'CHANGE_LANGUAGE':
            return action.language
        // CART
        case 'REFRESH_CART':
            resultState = action.state
            for (let i = 0; i < resultState.length; i++) {
                const cart = resultState[i];
                if(!cart.is_own){
                    if(!cart.sub_product.product.is_active){
                        resultState = stateObject.filter(cartItem => cart.id !== cartItem.id)
                    }
                }
            }
            return JSON.stringify(resultState);
        case 'ADD_TO_CART':
            var existInCart = false;
            resultState = stateObject.map(cartItem => {
                // console.log(cartItem);
                if(action.newItem[0].is_guest === 1){
                    if(cartItem.product_id === action.newItem[0].product_id){
                        cartItem.qty = cartItem.qty + action.newItem[0].qty
                        existInCart = true
                    }
                }
                else{
                    if(!action.newItem[0].is_own){
                        if(action.newItem[0].sub_product.product.is_active){
                            if (
                                // (cartItem.is_own === 0 && action.newItem[0].is_own === 0 && cartItem.product_id === action.newItem[0].product_id)
                                // || 
                                cartItem.id
                                 === action.newItem[0].id) {
                                // if (action.newItem[0].qty > 1) {
                                cartItem.qty = action.newItem[0].qty
                                // } else {
                                //     cartItem.qty += 1
                                // }
                                existInCart = true
                            }
                        }else{
                            resultState = stateObject.filter(cartItem => action.newItem[0].id !== cartItem.id)
                        }
                    }else{
                        if (
                            // (cartItem.is_own === 0 && action.newItem[0].is_own === 0 && cartItem.product_id === action.newItem[0].product_id)
                            // || 
                            cartItem.id
                             === action.newItem[0].id) {
                            // if (action.newItem[0].qty > 1) {
                            cartItem.qty = action.newItem[0].qty
                            // } else {
                            //     cartItem.qty += 1
                            // }
                            existInCart = true
                        }
                    }
                }
                return cartItem;
            })
            if (!existInCart){
                if(!action.newItem[0].is_own){
                    if(action.newItem[0].sub_product.product.is_active){
                        resultState = [...stateObject, action.newItem[0]]
                    }else{
                        resultState = stateObject.filter(cartItem => action.newItem[0].id !== cartItem.id)
                    }
                }else{
                    resultState = [...stateObject, action.newItem[0]]
                }
            }
            return JSON.stringify(resultState);
        case 'DELETE_FROM_CART':
            resultState = stateObject.filter(cartItem => action.cartItemId !== cartItem.id)
            return JSON.stringify(resultState);
        case 'CHANGE_QTY_CART':
            if (action.newValue > 0) {
                resultState = stateObject.map(cartItem => {
                    if (cartItem.id === action.cartItemId) {
                        cartItem.qty = action.newValue
                    }
                    return cartItem;
                })
                return JSON.stringify(resultState);
            }
            else {
                return JSON.stringify(stateObject);
            }
        // OWN PRODUCT
        case 'UPDATE_OWN_PRODUCT_INGREDIENTS' : 
            return JSON.stringify(action.new_own_product_ingredients)
        default:
            return state
    }
}