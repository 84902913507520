import React from 'react'
import TimerIcon from "assets/img/havilla/timer-icon.svg";
import { Grid, makeStyles, Grow } from '@material-ui/core';
import { dictionary } from 'Dictionary';

const useStyles = makeStyles((theme) => ({
    loadingScreenContainer : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '70vh',
    },
    loadingText : {
        fontFamily: 'Martel Sans',
        top : '25rem',
        left : '0',
        right : '0',
    },
    imgContainer : {
        top : '21rem',
        left : '0',
        right : '0',
    }
}));

function LoadingScreenTimer(props) {
    const classes = useStyles();

    return (
        <Grid container className={classes.loadingScreenContainer}>
            <Grow in={true}
                style={{ transformOrigin: '0 0 0' }}
                {...(true ? { timeout: 800 } : {})}
            >
                <Grid item xs={12} className={classes.imgContainer}>
                    <img src={TimerIcon} alt="timer icon"></img>
                </Grid>
            </Grow>
            <Grow in={true}
                style={{ transformOrigin: '0 0 0' }}
                {...(true ? { timeout: 1200 } : {})}
            >
                <Grid item xs={12} className={classes.loadingText}>
                    {dictionary['Loading'][props.language]}...
                </Grid>
            </Grow>
        </Grid>
    )
}

export default LoadingScreenTimer
