
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import HavillaLogo from "assets/img/havilla/logo.svg";
import Photo from "assets/img/havilla/slider1.png";
import axios from 'axios';
import { requestConfigAdmin } from 'Environment';
import { baseUrl } from 'Environment';
import Snackbar from "admin/components/Snackbar/Snackbar.js";
import { CircularProgress } from '@material-ui/core';
import { refreshHeader } from 'Environment';
import {
  prefix
} from '../../../Environment.js';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="">
        Matcha
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'hidden'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    minHeight: '60px'
  },
}));

export default function LoginAdmin(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [snackbarColor, setSnackbarColor] = useState('info')
  const [snackbarmessage, setSnackbarmessage] = useState('')
  const [toggleSnackbar, setToggleSnackbar] = useState(false)
  const [timer, setTimer] = useState(null);

  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('auth_admin') ? true : false)

  useEffect(() => {
    if (isLoggedIn) {
      let path = prefix + `/admin`;
      props.history.push(path);
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (toggleSnackbar) {
      setTimer(setTimeout(function () {
        setToggleSnackbar(false);
      }, 5000));
    }
  }, [toggleSnackbar])

  const handleCloseSnackbar = () => {
    clearTimeout(timer);
  }

  const handleOpenSnackbar = (color, message) => {
    setToggleSnackbar(true)
    setSnackbarColor(color)
    setSnackbarmessage(message)
  }

  const login = (e) => {
    e.preventDefault();
    setLoading(true);
    axios.post(`${baseUrl}/login`,
      {
        email: email,
        password: password,
        type: 'admin'
      },
      requestConfigAdmin
    ).then((res) => {
      if (res.data.success) {
        localStorage.setItem('auth_admin', JSON.stringify(res.data.data));
        requestConfigAdmin.headers.Authorization = "Bearer " + res.data.data.access_token
        let path = prefix + `/admin`;
        props.history.push(path);
      } else {
        handleOpenSnackbar('danger', res.data.message);
        console.log(res.data.message, res.data.data)
        setLoading(false);
      }
    }, (e) => {
      handleOpenSnackbar('danger', e.message);
      console.log("Error : ", e.message);
      setLoading(false);
    })
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Snackbar
        place="tr"
        color={snackbarColor}
        message={snackbarmessage}
        open={toggleSnackbar}
        closeNotification={handleCloseSnackbar}
        close
      />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <img src={Photo} alt='side img' style={{
          height: '100%',
          left: '-10px',
          top: '0',
          transform: 'translate(-20%,0%)',
        }}></img>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={HavillaLogo} alt='logo'></img>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={login}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {loading ? <CircularProgress style={{'color': '#AF986D'}} /> : "Sign In"}
            </Button>
            <Box mt={5}>
              {/* <Copyright /> */}
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}