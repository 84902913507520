import React, { useState, useEffect } from 'react'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

function SnackbarComp(props) {
    
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState(props.snackbarMessage)
    const [snackbarType, setSnackbarType] = useState(props.snackbarType)

    useEffect(() => {
        if(props.snackbarMessage !== ''){
            setOpenSnackbar(true);
            setSnackbarMessage(props.snackbarMessage);
            setSnackbarType(props.snackbarType);
        }
    },[props.counter,props.snackbarMessage,props.snackbarType])
    
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarComp
