import React, { Component } from 'react'
import { Container, withStyles, Grid, Backdrop, CircularProgress, Button } from '@material-ui/core'
import styles from "assets/jss/client-side/App.js";
import TabNav from 'client-side/components/TabNav/TabNav';
import axios from 'axios';
import {
    baseUrl,
    requestConfig,
    prefix
} from '../../../Environment.js';
import { dictionary } from '../../../Dictionary';
import { ClientSideContext } from '../../ClientSideContext';
import PostLargeGeneral from 'client-side/components/Post/PostLargeGeneral.js';
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp.js';
import Fade from '@material-ui/core/Fade';

const useStyles = theme => ({
    ...styles(theme),
    teaTalksTitle: {
        marginTop: '48px',
        marginBottom: '48px',
        fontSize: '40px',
        textAlign: 'center'
    },
    postsContainer: {
        marginTop: '12px',
    },
});

export class TeaTalks extends Component {
    static contextType = ClientSideContext;
    constructor(props) {
        super(props)

        this.state = {
            openBackdrop: false,
            language: 'en',

            articles: [],
            articleCategories: [],
            articleCategoryId: null,
            nextPageLink: null,

            snackbarType: 'info',
            snackbarMessage: '',
            snackbarCounter: 0,
            loadArticles:false,
            count_for_update : 0
        }
    }

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    componentDidUpdate() {
        
    }

    componentDidMount() {
        this.setState({ openBackdrop: true });
        this.getArticleCategories();
        this.setState({
            language: this.context.language
        })
    }

    getArticleCategories = () => {
        axios.get(`${baseUrl}/article_categories`,
            requestConfig
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState((prevState, props) => ({
                    articleCategories: data.active,
                    articleCategoryId: data.active[0].id
                }), () => {
                    this.getArticles();
                })
                // this.setState({ openBackdrop:false});
            } else {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${res.data.message}`
                })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            console.log("Error : ", e);
        })
    }

    getArticles = () => {
        this.setState((prevState => ({
            count_for_update: prevState.count_for_update + 1
        })))
        this.setState({ openBackdrop: true });
        let data = {
            category_id: this.state.articleCategoryId,
            limit: 6
        }
        axios.post(`${baseUrl}/articles/get_articles_by_category`,
            data,
            requestConfig
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState({
                    articles: data.data,
                    nextPageLink: data.next_page_url,
                    loadArticles: true
                })
                this.setState({ openBackdrop: false });
            } else {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${res.data.message}`
                })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            console.log("Error : ", e);
        })
    }

    handleArticleCategoryChange = (e, newValue) => {
        e.preventDefault()
        this.setState({
            articleCategoryId: newValue,
            loadArticles: false
        }, () => {
            this.getArticles();
        });
    }

    handleNextPage = () => {
        this.setState({ openBackdrop: true });
        let data = {
            category_id: this.state.articleCategoryId,
            limit: 6
        }
        axios.post(`${this.state.nextPageLink}`,
            data,
            requestConfig
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState((prevState, props) => ({
                    articles: [...prevState.articles, ...data.data],
                    nextPageLink: data.next_page_url
                }))
                this.setState({ openBackdrop: false });
            } else {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${res.data.message}`
                })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            console.log("Error : ", e);
        })
    }

    render() {
        const { classes } = this.props

        return (
            <Container maxWidth="xl" className={classes.container}>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.counter}
                />
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{'color': '#AF986D'}} />
                </Backdrop>
                <Grid container alignItems='center' justify="center">
                    <Grid item xs={12} className={`${classes.teaTalksTitle} ${classes.title}`}>
                        {dictionary['Tea Talks'][this.context.language]}
                    </Grid>

                    {
                        this.state.articleCategories.length > 0 ?
                            <TabNav
                                value={this.state.articleCategoryId}
                                handleNavChange={this.handleArticleCategoryChange}
                                navItems={this.state.articleCategories}
                                tabStyles={{ fontSize: '28px' }}
                            />
                            :
                            null
                    }
                    <Fade in={this.state.loadArticles} timeout={700}>
                        <Grid container item xs={12} md={11} spacing={5} className={classes.postsContainer} justify="flex-start">
                            {
                                this.state.articles.length > 0 ?
                                    this.state.articles.map(item => {
                                        return (
                                            <PostLargeGeneral
                                                key={item.id}
                                                xs={12}
                                                md={6}
                                                lg={4}
                                                img={`${item.thumbnail_url}?v=${this.state.count_for_update}`}
                                                title={item.title}
                                                desc={item.caption}
                                                imgAlt={`article thumbnail ${item.url_slug} ${this.state.count_for_update}`}
                                                linkText='Read Now'
                                                linkTo={`${prefix}/tea_talks/${item.url_slug}`}
                                            />
                                        )
                                    })
                                    :
                                    <Grid item xs={12} className={classes.noResult}>No articles at the moment...</Grid>
                            }
                        </Grid>
                    </Fade>
                    {
                        this.state.articles.length > 0 && this.state.nextPageLink !== null ?
                            <Grid item xs={8} md={2}>
                                <Button onClick={this.handleNextPage} variant="outlined" className={classes.buttonLight} style={{ marginTop: '48px', width: '100%' }}>
                                    See More Articles
                                </Button>
                            </Grid>
                            :
                            null
                    }

                </Grid>
            </Container>
        )
    }
}

export default withStyles(useStyles)(TeaTalks)