
import React, { useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
    Link,
    // BrowserRouter, withRouter
} from 'react-router-dom';
import { ClientSideContext } from '../../ClientSideContext';
import { dictionary } from '../../../Dictionary';


const useStyles = makeStyles((theme) => ({
    tabNav: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '-9px'
        },
    },
    itemTab: {
        fontFamily: 'Martel Sans',
        fontSize: '1rem',
        textTransform: 'none',
    }
}));

const CustomTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        opacity: 1,
        color: 'white',
        '&:hover': {
            fontWeight: 'bold',
            opacity: 1,
            color: '#AF986D'
        }, '&:focus': {
            fontWeight: 'bold',
            opacity: 1,
            color: '#AF986D'
        },
    },
}
))((props) => <Tab disableRipple {...props} />);

const CustomTabs = withStyles({
    indicator: {
        display: 'none'
    },
})(Tabs);

const selected = {
    fontWeight: 'bold',
    opacity: 1,
}
export default function TabNavProfile(props) {
    const context = useContext(ClientSideContext)

    const classes = useStyles();

    const handleChange = (event, newValue) => {
        props.handleNavChange(newValue)
    };
    return (
        <CustomTabs
            value={props.value}
            className={classes.tabNav}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
        >
            {
                props.navItems.map(item => {
                    // alert(window.location.pathname.split("/")[2]);
                    return (
                        <CustomTab key={item.value}
                            value={item.value}
                            className={classes.itemTab}
                            style={window.location.pathname.split("/")[3] === item.link ? selected : {}}
                            label={dictionary[item.title][context.language]}
                            component={Link} to={props.prefix + '/profile/' + item.link} />
                    )
                })
            }
        </CustomTabs>
    );
}