import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
// import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { Grid, IconButton } from '@material-ui/core';
import HavillaLogo from "assets/img/havilla/logo.svg";
import ProfileIcon from "assets/img/havilla/profile.png";
import CartIcon from "assets/img/havilla/cart.png";
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link } from 'react-router-dom';
import MenuIconCustom from '../Icons/MenuIconCustom';
import styles from "assets/jss/client-side/App.js";
import ProfilePicture from "assets/img/havilla/default_pp.png";
import { dictionary } from '../../../Dictionary';
import { ClientSideContext } from '../../ClientSideContext';
import CustomModalCart from '../CustomModals/CustomModalCart.js';

import axios from 'axios';
import {
    baseUrl,
    requestConfig,
    prefix
} from '../../../Environment.js';

const useStyles = (theme) => ({
    ...styles(theme),
    title: {
        flexGrow: 1,
        minHeight: 128,
    },
    logo: {
        width: '100%',
        height: '100%',
        cursor: "pointer",
        transition: 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)',
        [theme.breakpoints.down('sm')]: {
            width: '120px',
        },
        [theme.breakpoints.up('md')]: {
            height: '60%',
            marginTop: 15
        },
    },
    icon: {
        width: 17,
        height: 17
    },
    grid: {
        textAlign: 'center',
        transition: 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)',
    },
    buttonProfile: {
        fontSize: '16px',
        marginRight: '30px'
    },
    buttonCart: {
        marginRight: '40px'
    },
    appBarDesktop: {
        backgroundColor: "#000000",
        color: '#AF986D',
        top: '0rem',
        padding: '1.2em',
        borderBottom: '0.25px solid #AF986D',
        // transition: 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'block'
        },
        // borderBottom: "2px solid #f4f4f4"
    },
    appBarMobile: {
        color: '#AF986D',
        padding: '1.2em',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        // borderBottom: "2px solid #f4f4f4",
        backgroundColor: "#121212"
    },
    mobileMenuLanguage: {
        marginTop: '0.5rem',
    },
    mobileMenuLogin: {
        marginBottom: '0.5rem'
    },
    menuMobile: {
        overflow: 'hidden',
        height: '0rem'
    },
    menuMobileWithHeight: {
        overflow: 'hidden',
        height: '16rem'
    },
    linkLeft: {
        marginRight: '40px',
        '&.active': {
            borderBottom: '1px solid black',
            paddingBottom: '5px',
        },
        color: '#FFFAF4',
    },
    linkRight: {
        marginLeft: '40px',
        '&.active': {
            borderBottom: '1px solid black',
            paddingBottom: '5px',
        },
        color: '#FFFAF4',
    },
    name: {
        maxWidth: '70px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    profilePic: {
        objectFit: 'cover',
        width: '35px',
        height: '35px',
        borderRadius: '100%'
    },
    logoContainer: {
        height: '82px',
        transition: 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)',
    },
    logoContainerReduced: {
        height: '72px',
        transition: 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)',

    },
    appBarOnScroll: {
        top: '-1rem',
        paddingBottom: '0px'
    },
    linkContainer: {
        minHeight: '28.4px'
    }
});

class Header extends Component {
    static contextType = ClientSideContext;
    constructor(props) {
        super(props)

        this.state = {
            toggle: false,
            isLogin: false,
            user: null,
            language: '',

            tea_shop_active: false,
            create_your_own_active: false,
            tea_talks_active: false,
            about_us_active: false,

            yOffset: 0,

            cartModalOpen: false,
        }
    }

    handleCloseModal = (newValue) => {
        if (this.props.openCart)
            this.props.closeCart()

        this.setState({
            cartModalOpen: newValue
        })
    }

    handleOpenCart = () => {
        this.setState({
            cartModalOpen: true
        })
    }

    handleLoginCheck = () => {
        let auth = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null;
        this.setState({
            isLogin: auth ? true : false,
            user: auth ? auth.user : null,
        })
    }

    handleCheckActive = () => {
        let location = (this.props.location.pathname).replace('/', '');
        this.setState({
            tea_shop_active: location.includes('tea_shop'),
            create_your_own_active: location.includes('create_your_own'),
            tea_talks_active: location.includes('tea_talks'),
            about_us_active: location.includes('about_us'),
        })
    }

    handleOnScrollDocument = (e) => {
        const { classes } = this.props;
        let elContainer = document.getElementById('appbar');
        let elLogoContainer = document.getElementById('logoContainer');
        this.setState({
            yOffset: window.pageYOffset
        }, () => {
            if (this.state.yOffset > 0) {
                elContainer.classList.add(classes.appBarOnScroll);
                elLogoContainer.classList.add(classes.logoContainerReduced);
                elLogoContainer.classList.remove(classes.logoContainer);
            } else {
                elContainer.classList.remove(classes.appBarOnScroll);
                elLogoContainer.classList.remove(classes.logoContainerReduced);
                elLogoContainer.classList.add(classes.logoContainer);
            }
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        let auth = localStorage.getItem('auth') ? true : false;

        if (this.state.cartModalOpen !== nextState.cartModalOpen) return true

        if (this.state.isLogin !== auth) return true

        if (this.state.language !== nextState.language) return true

        if (this.state.tea_shop_active !== nextState.tea_shop_active) return true
        if (this.state.create_your_own_active !== nextState.create_your_own_active) return true
        if (this.state.tea_talks_active !== nextState.tea_talks_active) return true
        if (this.state.about_us_active !== nextState.about_us_active) return true
        if (this.state.yOffset !== nextState.yOffset) return true

        // if (this.props.openCart === true) return true

        return false;
    }

    componentWillUnmount() {
        window.onscroll = null;
    }

    componentDidUpdate() {
        this.handleCheckActive();
        this.handleLoginCheck();
    }

    componentDidMount = async () => {
        const { language } = this.context;
        this.setState({
            language: language
        })

        document.addEventListener("scroll", (e) => this.handleOnScrollDocument(e), true);
        this.handleLoginCheck();
    }

    changeLanguage = (e) => {
        const { dispatchLanguage } = this.context;

        this.setState({ [e.target.name]: e.target.value }, () => {
            dispatchLanguage({ type: "CHANGE_LANGUAGE", language: this.state.language })
        })
    }

    render() {
        const { classes } = this.props;
        const handleEnlargeMenu = (event) => {
            if (this.state.toggle) {
                let el = document.getElementById('menu_mobile');
                el.classList.remove(classes.menuMobile);
                el.classList.add(classes.menuMobileWithHeight);

                // Element from PostContainer
                let nav_icon = document.getElementById('menu_icon');
                nav_icon.classList.add('active');
                nav_icon.classList.remove('not-active');
            } else {
                let el = document.getElementById('menu_mobile');
                el.classList.remove(classes.menuMobileWithHeight);
                el.classList.add(classes.menuMobile);

                // Element from PostContainer
                let nav_icon = document.getElementById('menu_icon');
                nav_icon.classList.remove('active');
                nav_icon.classList.add('not-active');
            }
            this.setState({
                toggle: !this.state.toggle
            })
        }

        let AppBarDesktop = (
            <AppBar id='appbar' className={classes.appBarDesktop} elevation={0}>
                <CssBaseline />
                <Grid container spacing={2} className={classes.grid} alignItems="center">
                    <Grid item xs={2}>
                        <button onClick={this.changeLanguage} value="id" name="language"
                            className={`${classes.link} ${classes.linkHoverBold} ${classes.linkStyledButton} 
                            ${this.state.language === 'id' ? classes.goldColor : ''}`}
                        >
                            ID
                        </button>
                        &nbsp;|&nbsp;
                        <button onClick={this.changeLanguage} value="en" name="language"
                            className={`${classes.link} ${classes.linkHoverBold} ${classes.linkStyledButton} 
                            ${this.state.language === 'en' ? classes.goldColor : ''}`}
                        >
                            EN
                        </button>
                    </Grid>
                    <Grid item container xs={8} justify='flex-end'>
                        <Grid item md={12} spacing={0} container alignItems="center">
                            <Grid item md={4} container justify='flex-end' className={classes.linkContainer}>
                                <Link to={{
                                    pathname: `${prefix}/tea_shop`,
                                    state: {
                                        category_id: 1
                                    }
                                }}

                                    className={`${classes.link} ${classes.linkHoverUnderline} ${classes.linkLeft}
                                    ${this.state.tea_shop_active ? 'active' : ''}`}
                                >
                                    {dictionary['Tea Shop'][this.state.language]}
                                </Link>
                                {/* <Link to={`${prefix}/create_your_own`}
                                    className={`${classes.link} ${classes.linkHoverUnderline} ${classes.linkLeft}
                                    ${this.state.create_your_own_active ? 'active' : ''}`}
                                >
                                    {dictionary['Create Your Own'][this.state.language]}
                                </Link> */}
                                <Link to={`${prefix}/tea_talks`}
                                    className={`${classes.link} ${classes.linkHoverUnderline} ${classes.linkRight}
                                    ${this.state.tea_talks_active ? 'active' : ''}`}
                                >
                                    {dictionary['Tea Talks'][this.state.language]}
                                </Link>
                            </Grid>
                            <Grid item md={4} id='logoContainer' className={classes.logoContainer}>
                                <Link to={`${prefix}`}
                                    className={`${classes.link}`}>
                                    <img id='havilla_logo' className={classes.logo} src={HavillaLogo} alt="havilla logo"></img>
                                </Link>
                            </Grid>

                            <Grid item md={4} container justify='flex-start' className={classes.linkContainer}>
                                <Link to={`${prefix}/about_us`}
                                    className={`${classes.link} ${classes.linkHoverUnderline} ${classes.linkRight}
                                    ${this.state.about_us_active ? 'active' : ''}`}
                                >
                                    {dictionary['About Us'][this.state.language]}
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems='center' justify='flex-end' item xs={2}>
                        {!this.state.isLogin ?
                            <Fragment>
                                <Grid item md={2}>
                                    <Link to={`${prefix}/login`}>
                                        <IconButton edge="start" className={classes.buttonProfile} color="inherit" aria-label="profile">
                                            <img className={classes.icon} src={ProfileIcon} alt="profile icon"></img>
                                        </IconButton>
                                    </Link>
                                </Grid>

                                <Grid item md={2}>
                                    <IconButton
                                        onClick={this.handleOpenCart}
                                        edge="start" className={classes.buttonCart} color="inherit" aria-label="cart">
                                        <img className={classes.icon} src={CartIcon} alt="cart icon"></img>
                                    </IconButton>
                                </Grid>
                                <Grid item md={1} />
                                <CustomModalCart
                                    cartItems={this.state.cartItems}
                                    open={this.props.openCart ? this.props.openCart : this.state.cartModalOpen}
                                    handleCloseModal={this.handleCloseModal}
                                    prefix={this.props.prefix}
                                ></CustomModalCart>
                            </Fragment>
                            :
                            <Fragment>
                                <Grid item md={9}>
                                    <Grid alignItems='center' style={{ fontSize: '16px' }} container>
                                        Hello,&nbsp;
                                        <Link className={`${classes.linkGold} ${classes.name}`} to={`${prefix}/profile/order`}>
                                            <u>{this.state.user.first_name}</u>!
                                        </Link>
                                        &nbsp;&nbsp;
                                        <img src={localStorage.getItem('auth') ?
                                            !JSON.parse(localStorage.getItem('auth')).user.photo_url ?
                                                ProfilePicture : JSON.parse(localStorage.getItem('auth')).user.photo_url
                                            : ProfilePicture}
                                            className={classes.profilePic} alt="profile pic" />
                                    </Grid>
                                </Grid>

                                <Grid item md={3}>
                                    <IconButton
                                        onClick={this.handleOpenCart}
                                        edge="start" className={classes.buttonCart} color="inherit" aria-label="cart">
                                        <img className={classes.icon} src={CartIcon} alt="cart icon"></img>
                                    </IconButton>
                                </Grid>

                                <CustomModalCart
                                    open={this.props.openCart ? this.props.openCart : this.state.cartModalOpen}
                                    handleCloseModal={this.handleCloseModal}
                                    prefix={this.props.prefix}
                                ></CustomModalCart>
                            </Fragment>
                        }

                    </Grid>
                </Grid>
            </AppBar>
        );

        let AppBarMobile = (
            <AppBar position="sticky" className={classes.appBarMobile} elevation={0}>
                <CssBaseline />
                <Grid container spacing={2} className={classes.grid} alignItems="center">
                    <Grid item xs={2}>
                        {!this.state.isLogin ?
                            <React.Fragment>
                                <Grid item md={2}>
                                    <IconButton
                                        onClick={this.handleOpenCart}
                                        edge="start" className={classes.buttonCart} color="inherit" aria-label="cart">
                                        <img className={classes.icon} src={CartIcon} alt="cart icon"></img>
                                    </IconButton>
                                </Grid>
                                <Grid item md={1} />
                                <CustomModalCart
                                    cartItems={this.state.cartItems}
                                    open={this.props.openCart ? this.props.openCart : this.state.cartModalOpen}
                                    handleCloseModal={this.handleCloseModal}
                                    prefix={this.props.prefix}
                                ></CustomModalCart>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Grid item md={2}>
                                    <IconButton
                                        onClick={this.handleOpenCart}
                                        edge="start" className={classes.buttonCart} color="inherit" aria-label="cart">
                                        <img className={classes.icon} src={CartIcon} alt="cart icon"></img>
                                    </IconButton>
                                </Grid>
                                <Grid item md={1} />
                                <CustomModalCart
                                    open={this.props.openCart ? this.props.openCart : this.state.cartModalOpen}
                                    handleCloseModal={this.handleCloseModal}
                                    prefix={this.props.prefix}
                                ></CustomModalCart>
                            </React.Fragment>
                        }
                        {/* <IconButton edge="start" color="inherit" aria-label="cart">
                            <img className={classes.icon} src={CartIcon} alt="cart icon"></img>
                        </IconButton> */}
                    </Grid>
                    <Grid item xs={8}>
                        <Link to={prefix}
                            className={classes.link}>
                            <img className={classes.logo} src={HavillaLogo} alt="havilla logo"></img>
                        </Link>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleEnlargeMenu}>
                            <MenuIconCustom></MenuIconCustom>
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid id="menu_mobile" container spacing={2} className={`${classes.grid} ${classes.menuMobile}`} alignItems="center">
                    <Grid item xs={12} className={classes.mobileMenuLogin}>
                        {!this.state.isLogin ?
                            <Fragment>
                                <Link
                                    className={classes.link}
                                    to={`${prefix}/login`}
                                >
                                    <u>Login</u>
                                </Link> or <Link
                                    className={classes.link}
                                    to={`${prefix}/register`}
                                >
                                    <u>Create Havilla Account</u>
                                </Link>
                            </Fragment>
                            :
                            <Fragment>
                                <Grid container justify='center' alignItems='center' style={{ fontSize: '16px' }}>
                                    Hello,&nbsp;
                                    <Link
                                        className={classes.link}
                                        to={`${prefix}/profile/order`}
                                    >
                                        <u>{this.state.user.first_name}</u>
                                    </Link>
                                    &nbsp;&nbsp;
                                    <img src={localStorage.getItem('auth') ?
                                        !JSON.parse(localStorage.getItem('auth')).user.photo_url ?
                                            ProfilePicture : JSON.parse(localStorage.getItem('auth')).user.photo_url
                                        : ProfilePicture}
                                        className={classes.profilePic} alt="profile pic" />
                                </Grid>
                            </Fragment>

                        }

                    </Grid>
                    <Grid item xs={12}>
                        <Link
                            className={classes.link}
                            to={{
                                pathname: `${prefix}/tea_shop/tea`,
                                state: {
                                    category_id: 1
                                }
                            }}
                            onClick={handleEnlargeMenu}
                        >
                            {dictionary['Tea Shop'][this.state.language]}
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <Link
                            className={classes.link}
                            to={`${prefix}/tea_talks`}
                            onClick={handleEnlargeMenu}
                        >
                            {dictionary['Tea Talks'][this.state.language]}
                        </Link>
                        {/* <Link
                            className={classes.link}
                            to={`${prefix}/create_your_own`}
                            onClick={handleEnlargeMenu}
                        >
                            {dictionary['Create Your Own'][this.state.language]}
                        </Link> */}
                    </Grid>
                    <Grid item xs={12}>
                        <Link
                            className={classes.link}
                            to={`${prefix}/about_us`}
                            onClick={handleEnlargeMenu}
                        >
                            {dictionary['About Us'][this.state.language]}
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={12} className={classes.mobileMenuLanguage}>
                        <button onClick={this.changeLanguage} value="id" name="language"
                            className={`${classes.link} ${classes.linkHoverBold} ${classes.linkStyledButton} 
                            ${this.state.language === 'id' ? classes.goldColor : ''}`}
                        >
                            ID
                        </button>
                        &nbsp;|&nbsp;
                        <button onClick={this.changeLanguage} value="en" name="language"
                            className={`${classes.link} ${classes.linkHoverBold} ${classes.linkStyledButton} 
                            ${this.state.language === 'en' ? classes.goldColor : ''}`}
                        >
                            EN
                        </button>
                    </Grid>
                </Grid>
            </AppBar>
        );

        return (
            <Fragment>
                {AppBarMobile}
                {AppBarDesktop}
            </Fragment>
        )
    }
}

export default withStyles(useStyles)(Header) 