import React, { Component } from 'react'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import { baseUrl, baseUrlNonApi, requestConfigAdmin } from 'Environment';
import { withStyles, Grid, TextField, MenuItem } from '@material-ui/core';
import globalStyles from "assets/jss/client-side/App.js";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import GridItem from 'admin/components/Grid/GridItem';
import Card from "admin/components/Card/Card.js";
import CardHeader from "admin/components/Card/CardHeader.js";
import CardBody from "admin/components/Card/CardBody.js";
import GridContainer from 'admin/components/Grid/GridContainer';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField';
import CardFooter from 'admin/components/Card/CardFooter';
import Button from "admin/components/CustomButtons/Button.js";
import CloseIcon from "assets/img/havilla/close_icon_black.svg";
import FormHelperText from '@material-ui/core/FormHelperText';
import { Editor } from '@tinymce/tinymce-react';
import {
    prefix
} from '../../../Environment.js';

const CustomTextfieldRaw = withStyles({
    root: {
        width: '100%',
        '& label.Mui-focused': {
            color: 'black',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);

const useStyles = theme => ({
    ...globalStyles(theme),
    ...dashboardStyle,
    imgPreview: {
        cursor: 'pointer',
        height: '220px',
        maxWidth : '540px',
        '&:hover + .delete-button': {
            marginTop: '-17px',
        }
    },
    deletePict: {
        transition: 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)',
        marginTop: '-47px',
        display: 'block',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            marginTop: '-17px',
        },
    },
    tempPictContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: '30px',
        overflow: 'hidden',
        '&:hover .delete-button': {
            marginTop: '-17px',
        }
    },
});

const required_field = [
    'type',
    'photo_url',
]

export class SliderDetail extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props)

        this.state = {
            page_type: "update",

            id: null,
            title: "",
            title_error: "",

            type: '',
            type_error: "",

            caption: "",
            caption_error: "",
            
            button_text : "",
            button_text_error: "",
            
            link_href : "",
            link_href_error : "",
            
            photo_url : '',
            photo_url_error : "",

            types: [
                {
                    id : 'tea_shop',
                    name : 'Tea Shop'
                },
                {
                    id : 'homepage',
                    name : 'Homepage'
                }
            ],
            count_for_update: 0,
        }

        this.title_ref = React.createRef();
        this.type_ref = React.createRef();
        this.caption_ref = React.createRef();
        this.button_text_ref = React.createRef();
        this.link_href_ref = React.createRef();
        this.photo_url_ref = React.createRef();
    }

    scrolToNonInput = (ref) => {
        const container = document.querySelector('#perfect-scrollbar-container');
        container.scrollTop = ref.current.offsetTop;
    }

    getSlider = () => {
        this.setState((prevState => ({
            count_for_update: prevState.count_for_update + 1
        })))
        const id = this.props.match.params.id;
        axios.get(`${baseUrl}/sliders/${id}`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    ...res.data.data,
                }, () => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        if (id === "add") {
            this.setState({
                page_type: "add"
            })
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
        } else {
            this.setState({
                page_type: "update"
            }, () => {
                this.getSlider();
            })
        }
    }

    componentDidUpdate(nextProps, nextState) {
        for (let i = 0; i < required_field.length; i++) {
            let field_name = required_field[i];
            if (this.state[field_name + "_error"] !== "") {
                if (this.state[field_name] !== nextState[field_name]) {
                    this.setState({ [field_name + "_error"]: "" });
                }
            }
        }

        return true;
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeImages = (e) => {
        if (e.target.files.length > 0) {
            let file = e.target.files[0];
            this.setState({
                [e.target.name]: file
            }, () => {
            })
        }
    }

    handleDeleteTempImg = (ref) => {
        this.setState({
            [ref]: null
        }, () => {
            if (this.state[ref] === null) {
                document.getElementById(ref).value = "";
            }
        });
    }

    handleValidation = () => {
        let is_ok = true;

        for (let i = 0; i < required_field.length; i++) {
            let field_name = required_field[i];
            let field_name_text = required_field[i].replace('_', ' ');
            if (this.state[field_name] === "" || this.state[field_name] === null) {
                is_ok = false;
                if (field_name === 'photo_url') {
                    this.scrolToNonInput(this[field_name + "_ref"]);
                } else {
                    this[field_name + "_ref"].current.focus();
                }
                this.setState({ [field_name + "_error"]: field_name_text + " is required" });
                
            }
        }

        return is_ok
    }

    handleSubmitSlider = () => {
        let is_ok = this.handleValidation();
        
        if (is_ok) {
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
            let requestConfigAdd = {
                ...requestConfigAdmin.headers,
                'Content-Type': 'multipart/form-data'
            }

            let formData = new FormData();
            formData.append('title', this.state.title);
            formData.append('caption', this.state.caption);
            formData.append('photo_url', this.state.photo_url);
            formData.append('button_text', this.state.button_text);
            formData.append('link_href', this.state.link_href);
            formData.append('type', this.state.type);

            if (this.state.page_type === "update") {
                formData.append('_method', "PUT");
                axios.post(`${baseUrl}/sliders/${this.state.id}`,
                    formData,
                    requestConfigAdd
                ).then((res) => {
                    if (res.data.success) {
                        this.getSlider();
                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "success",
                            message: "Update Slider Success!"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    } else {
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "danger",
                            message: res.data.message
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log(res.data.message, res.data.data)
                    }
                }, (e) => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: "Error : "+e.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log("Error : ", e);
                })
            } else {
                axios.post(`${baseUrl}/sliders`,
                    formData,
                    requestConfigAdd,
                ).then((res) => {
                    if (res.data.success) {
                        let path = prefix + `/admin/sliders`;
                        this.props.history.push(path);
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "success",
                            message: "Add New Slider Success!"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    } else {
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "danger",
                            message: res.data.message
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log(res.data.message, res.data.data)
                    }
                }, (e) => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: "Error : "+e.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log("Error : ", e);
                })
            }
        }

    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="gray">
                            <h4 className={classes.cardTitleWhite}>Slider</h4>
                            <p>Manage Sliders</p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem container item sm={12} md={6}>
                                    <GridItem sm={12} xs={12} md={12}>    
                                        <CustomTextfieldRaw
                                            select
                                            label="Slider Type"
                                            name="type"
                                            error={this.state.type_error !== ""}
                                            helperText={this.state.type_error}
                                            value={this.state.type}
                                            inputRef={this.type_ref}
                                            onChange={this.onChange}
                                        >
                                            {
                                                this.state.types.map((type, i) => {
                                                    return <MenuItem value={type.id} key={type.id}>{type.name}</MenuItem>
                                                })
                                            }
                                        </CustomTextfieldRaw>
                                    </GridItem>
                                    <Grid item xs={12} style={{paddingLeft : '15px'}}>
                                        <FormHelperText>Slider Image (Recommended Size : { this.state.type == 'tea_shop' ? '1380 x 570' : '550 x 550' })</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} container alignItems='center' style={{paddingLeft : '15px'}}>
                                        <input
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="photo_url"
                                            name='photo_url'
                                            type="file"
                                            onChange={this.onChangeImages}
                                        />
                                        <label htmlFor="photo_url" ref={this.photo_url_ref} style={{ marginRight: '15px' }}>
                                            <Button color="info" component="span">
                                                Upload Photo
                                            </Button>
                                        </label>
                                        <FormHelperText error style={{ textTransform: 'capitalize' }}>{this.state.photo_url_error}</FormHelperText>
                                    </Grid>
                                    <Grid item container md={12} xs={12} style={{ height: '260px',paddingLeft: '15px' }}>
                                        {
                                            this.state.photo_url != null && this.state.photo_url !== '' ?
                                                <div className={classes.tempPictContainer}>
                                                    {
                                                        typeof this.state.photo_url === 'object' && this.state.photo_url !== null ?
                                                            <img className={classes.imgPreview} alt={`preview ${this.state.photo_url.name}`} src={URL.createObjectURL(this.state.photo_url)} />
                                                            :
                                                            <img className={classes.imgPreview} alt={`preview thumbnail`} src={`${this.state.photo_url}?v=${this.state.count_for_update}`} />
                                                    }
                                                    <div className={`${classes.deletePict} delete-button`} >
                                                        <img src={CloseIcon} alt="close icon" onClick={() => this.handleDeleteTempImg('photo_url')}></img>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </Grid>
                                </GridItem>
                                {
                                    this.state.type == 'tea_shop' ? 
                                        <GridItem container item sm={12} md={6}>
                                            <GridItem sm={12} xs={12}>
                                                <CustomTextField
                                                    value={this.state.title}
                                                    error={this.state.title_error !== ""}
                                                    helperText={this.state.title_error}
                                                    inputRef={this.title_ref}
                                                    type="text" onChange={this.onChange} name="title" label="Title" />
                                            </GridItem>
                                            <GridItem sm={12} xs={12}>
                                                <CustomTextField
                                                    label="Caption"
                                                    name="caption"
                                                    otherOptions={{
                                                        multiline: true,
                                                        rows: 3
                                                    }}
                                                    error={this.state.caption_error !== ""}
                                                    helperText={this.state.caption_error}
                                                    onChange={this.onChange}
                                                    value={this.state.caption === null ? '' : this.state.caption}
                                                    inputRef={this.caption_ref}
                                                />
                                            </GridItem>
                                            <GridItem sm={12} xs={12}>
                                                <CustomTextField
                                                    value={this.state.button_text}
                                                    error={this.state.button_text_error !== ""}
                                                    helperText={this.state.button_text_error}
                                                    inputRef={this.button_text_ref}
                                                    type="text" onChange={this.onChange} name="button_text" label="Button Text" />
                                            </GridItem>
                                            <GridItem sm={12} xs={12}>
                                                <CustomTextField
                                                    value={this.state.link_href}
                                                    error={this.state.link_href_error !== ""}
                                                    helperText={this.state.link_href_error}
                                                    inputRef={this.link_href_ref}
                                                    type="text" onChange={this.onChange} name="link_href" label="Link To"/>
                                            </GridItem>
                                        </GridItem>
                                    :
                                        null
                                }
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {this.state.page_type !== "add" ?
                                <Button color="info" onClick={this.handleSubmitSlider}>Update Slider</Button>
                                :
                                <Button color="info" onClick={this.handleSubmitSlider}>Store New Slider</Button>
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

export default withStyles(useStyles)(SliderDetail)
