import React, { PureComponent } from 'react'
import ButtonAdmin from "admin/components/CustomButtons/Button.js";
import CloseIcon from "assets/img/havilla/close_icon_black.svg";
import { Grid, withStyles, Switch, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField';
import Card from 'admin/components/Card/Card';
import CardHeader from 'admin/components/Card/CardHeader';
import CardBody from 'admin/components/Card/CardBody';
import Button from "admin/components/CustomButtons/Button.js";
import MaterialTable from 'material-table';
import { AdminContext } from '../../AdminContext';
import Confirmation from 'admin/components/CustomDialogs/Confirmation';
import axios from 'axios';
import { baseUrl ,requestConfigAdmin } from 'Environment';

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[400],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
    }))(({ classes, ...props }) => {
    return (
        <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
        }}
        {...props}
        />
    );
});

const required_fields = [
    'form_varian',
    'form_price',
    'form_weight',
]

export class ProductDetailVariant extends PureComponent {
    static contextType = AdminContext;
    constructor(props) {
        super(props)
    
        this.state = {
            type : 'update',
            modalType : 'add',
            modalOpen : false,
            tempDeleted : null,
            openDialog : false,

            product_id : '',

            sub_products: [],
            form_varian : "",
            form_varian_error : "",

            form_price : "",
            form_price_error : "",
            
            form_weight : "",
            form_weight_error : "",
            
            form_discount : "",
            form_discount_error : "",

            form_images : [],
            deleted_images : [],

            main_sub_product_index : null,
            oldData : null,
            count_for_update : 0,
        }

        this.form_varian_ref = React.createRef();
        this.form_price_ref = React.createRef();
        this.form_weight_ref = React.createRef();
        this.form_discount_ref = React.createRef();
    }

    cleanForm = () =>{
        this.setState({
            form_varian : "",
            form_price : "",
            form_weight : "",
            form_discount : "",
            form_images : [],
            deleted_images : []
        })
    }

    getSubProducts = () =>{
        this.setState((prevState=> ({
            count_for_update : prevState.count_for_update+1
        })))
        let data = {
            product_id: this.state.product_id,
        }
        axios.post(`${baseUrl}/subproducts/getProductSub`,
            data,
            requestConfigAdmin
        ).then((res)=>{
            if(res.data.success){
                let data = res.data.data;
                let main_index = data.map(function(e) { return e.is_main; }).indexOf(1);
                for (let i = 0; i < data.length; i++) {
                    const sub_product = data[i];
                    // let temp_images = JSON.parse(data[i].photo_url)
                    
                    sub_product['images'] = JSON.parse(data[i].photo_url)
                }
                this.setState({
                    sub_products : data,
                    main_sub_product_index : main_index
                },()=>{
                })
            }else{
                this.context.dispatchSnackbarContent({ 
                    type: "SET_SNACKBAR_CONTENT", 
                    color : "danger",
                    message : res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log(res.data.message,res.data.data)
            }
        },(e)=>{
            this.context.dispatchSnackbarContent({ 
                type: "SET_SNACKBAR_CONTENT", 
                color : "danger",
                message : "Error"
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            console.log("Error : ",e);
        })
    }

    componentDidMount(){
        const id = this.props.match.params.id;

        if(id !== "add"){
            this.setState({
                type : "update",
                product_id : id
            },()=>{
                this.getSubProducts()
            }) 
        }else{
            this.setState({
                type : "add"
            }) 
        }
    }

    componentDidUpdate(nextProps, nextState){
        for (let i = 0; i < required_fields.length; i++) {
            let field_name = required_fields[i];
            if(this.state[field_name+"_error"] !== ""){
                if(this.state[field_name] !== nextState[field_name]){
                    this.setState({ [field_name+"_error"]: "" });
                }
            }
        }

        return true;
    }
    
    handleToggleModal = (type, data) => {
        if(type === 'update'){
            this.setState({
                form_varian : data.varian,
                form_price : data.price,
                form_weight : data.weight,
                form_discount : data.discount,
                form_images : data.images,
                modalType : 'update',
                oldData : data
            })
        }else{
            this.setState({
                modalType : 'add',
            })
        }
        this.setState((prevState) => ({
            modalOpen: !prevState.modalOpen
        }),()=>{
        })
    }

    handleDeleteTempImg = (i) =>{
        this.setState(prevState => {
            const form_images = [...prevState.form_images];

            const deleted_images = [...prevState.deleted_images];
            deleted_images.push(form_images[i])
            
            form_images.splice(i, 1);
            if(form_images.length === 0){
                document.getElementById("upload-image").value = "";
            }
            return { ...prevState, form_images, deleted_images };
        },()=>{
        });
    }

    handleValidation = () =>{
        let is_ok = true;
        for (let i = 0; i < required_fields.length; i++) {
            let field_name = required_fields[i];
            let field_name_text = required_fields[i].replace('_',' ');
            field_name_text = field_name_text.replace('form','');
            field_name_text = field_name_text.replace('varian','name');

            if(this.state[field_name] === "" || this.state[field_name] === null || this.state[field_name].length === 0){
                is_ok = false;
                this[field_name+"_ref"].current.focus();
                this.setState({ [field_name+"_error"]: field_name_text+" is required" });
            }
        }
        return is_ok;
    }
    
    handleSubmitVariant = () => {
        let is_ok = this.handleValidation();

        if(is_ok){
            if(this.state.type === 'add'){
                if(this.state.modalType === "add"){
                    let is_main = this.state.sub_products.length > 0 ? false : true
                    
                    let newData = {
                        varian : this.state.form_varian,
                        weight : this.state.form_weight,
                        discount : this.state.form_discount,
                        price : this.state.form_price,
                        images : this.state.form_images,
                        is_main : is_main
                    }
                    this.setState(prevState => {
                        const sub_products = [...prevState.sub_products];
                        sub_products.push(newData);
                        return { 
                            ...prevState, 
                            sub_products,
                            main_sub_product_index : is_main ? 0 : prevState.main_sub_product_index
                        };
                    },()=>{
                        this.handleToggleModal();
                        this.cleanForm();
                        this.props.setSubProducts(this.state.sub_products);
                    })
                }else{
                    let newData = {
                        ...this.state.oldData,
                        varian : this.state.form_varian,
                        weight : this.state.form_weight,
                        discount : this.state.form_discount,
                        price : this.state.form_price,
                        images : this.state.form_images,
                    }
    
                    this.setState(prevState => {
                        const sub_products = [...prevState.sub_products];
                        sub_products[sub_products.indexOf(this.state.oldData)] = newData;
                        return { ...prevState, sub_products };
                    },()=>{
                        this.handleToggleModal();
                        this.props.setSubProducts(this.state.sub_products);
                        this.cleanForm();
                    });
                }
            }else{
                if(this.state.modalType === "add"){
                    let is_main = this.state.sub_products.length > 0 ? 0 : 1
    
                    let add_header = {
                        ...requestConfigAdmin.headers,
                        'Content-Type': 'multipart/form-data'
                    }
    
                    let formData = new FormData();
                    formData.append('product_id', this.state.product_id);
                    formData.append('varian', this.state.form_varian);
                    formData.append('weight', this.state.form_weight); 
                    formData.append('discount', this.state.form_discount); 
                    formData.append('price', this.state.form_price);
                    formData.append('is_main', is_main);
                    for (let i = 0; i < this.state.form_images.length; i++) {
                        const img = this.state.form_images[i];
                        formData.append('sub_product_'+this.state.product_id+'_images['+i+']', img);
                    }
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
    
                    axios.post(`${baseUrl}/subproducts`,
                        formData,
                        {headers : add_header},
                    ).then((res)=>{
                        if(res.data.success){
                            this.getSubProducts();
                            this.context.dispatchSnackbarContent({ 
                                type: "SET_SNACKBAR_CONTENT", 
                                color : "success",
                                message : "Add New Sub Product Success!"
                            })
                            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                            this.handleToggleModal();
                            this.cleanForm();
                            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                            
                        }else{
                            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            
                            this.context.dispatchSnackbarContent({ 
                                type: "SET_SNACKBAR_CONTENT", 
                                color : "danger",
                                message : res.data.message
                            })
                            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                            console.log(res.data.message,res.data.data)
                        }
                    },(e)=>{
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            
                        this.context.dispatchSnackbarContent({ 
                            type: "SET_SNACKBAR_CONTENT", 
                            color : "danger",
                            message : "Error"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log("Error : ",e);
                    })
                }else{
                    let add_header = {
                        ...requestConfigAdmin.headers,
                        'Content-Type': 'multipart/form-data'
                    }
    
                    let formData = new FormData();
                    formData.append('_method', "PUT");
                    formData.append('varian', this.state.form_varian);
                    formData.append('weight', this.state.form_weight); 
                    formData.append('discount', this.state.form_discount); 
                    formData.append('price', this.state.form_price);
                    formData.append('deleted_images', JSON.stringify(this.state.deleted_images));
                    for (let i = 0; i < this.state.form_images.length; i++) {
                        const img = this.state.form_images[i];
                        if(typeof img === 'object' && img !== null){
                            formData.append('sub_product_images['+i+']', img);
                        }
                    }
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
    
                    axios.post(`${baseUrl}/subproducts/${this.state.oldData.id}`,
                        formData,
                        {headers : add_header},
                    ).then((res)=>{
                        if(res.data.success){
                            this.getSubProducts();
                            this.context.dispatchSnackbarContent({ 
                                type: "SET_SNACKBAR_CONTENT", 
                                color : "success",
                                message : "Update Sub Product Success!"
                            })
                            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                            this.handleToggleModal();
                            this.cleanForm();
                            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                            
                        }else{
                            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            
                            this.context.dispatchSnackbarContent({ 
                                type: "SET_SNACKBAR_CONTENT", 
                                color : "danger",
                                message : res.data.message
                            })
                            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                            console.log(res.data.message,res.data.data)
                        }
                    },(e)=>{
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            
                        this.context.dispatchSnackbarContent({ 
                            type: "SET_SNACKBAR_CONTENT", 
                            color : "danger",
                            message : "Error"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log("Error : ",e);
                    })
                }
            }
        }
    }

    handleDeleteSubProduct = (data) => {
        if(data.is_main){
            this.context.dispatchSnackbarContent({ 
                type: "SET_SNACKBAR_CONTENT", 
                color : "danger",
                message : "Cannot delete main variant, please change it first"
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
        }else{
            this.setState({
                tempDeleted : data,
                openDialog : true,
            })
        }
    }

    handleCloseConfirmationDialog = (action) =>{
        if(action){
            if(this.state.type === 'add'){
                this.setState(prevState => {
                    const sub_products = [...prevState.sub_products];
                    sub_products.splice(sub_products.indexOf(this.state.tempDeleted), 1);
                    return { ...prevState, sub_products };
                },() =>{
                    this.setState({
                        tempDeleted : null,
                        openDialog : false,
                    })
                    this.props.setSubProducts(this.state.sub_products);
                });
            }else{
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
                axios.delete(`${baseUrl}/subproducts/${this.state.tempDeleted.id}`,
                    requestConfigAdmin
                ).then((res)=>{
                    if(res.data.success){
                        this.getSubProducts();
                        this.setState({
                            tempDeleted : null,
                            openDialog : false,
                        },()=>{
                            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                            this.context.dispatchSnackbarContent({ 
                                type: "SET_SNACKBAR_CONTENT", 
                                color : "success",
                                message : "Product Deleted"
                            })
                            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        })
                    }else{
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                        this.context.dispatchSnackbarContent({ 
                            type: "SET_SNACKBAR_CONTENT", 
                            color : "danger",
                            message : res.data.message
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log(res.data.message,res.data.data)
                    }
                },(e)=>{
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({ 
                        type: "SET_SNACKBAR_CONTENT", 
                        color : "danger",
                        message : e
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log("Error : ",e);
                })
            }
        }else{
            this.setState({
                tempDeleted : null,
                openDialog : false,
            })
        }
    }

    handleIsMainToggle = (rowData) =>{
        if(this.state.sub_products.length === 1){
            this.context.dispatchSnackbarContent({ 
                type: "SET_SNACKBAR_CONTENT", 
                color : "danger",
                message : "Product need at least 1 main product"
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
        }else{
            if(this.state.type === 'add'){
                let newData = {
                    ...rowData,
                    is_main : !rowData.is_main
                }
                this.setState(prevState => {
                    const sub_products = [...prevState.sub_products];
                    sub_products[sub_products.indexOf(rowData)] = newData;
                    return { 
                        ...prevState, 
                        sub_products,
                        main_sub_product_index : rowData.tableData.id
                    };
                },()=>{
                    this.props.setSubProducts(this.state.sub_products);
                });
            }else{
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
                let data = {
                    id : rowData.id
                }
                axios.post(`${baseUrl}/subproducts/toggle_is_main`,
                    data,
                    requestConfigAdmin,
                ).then((res)=>{
                    if(res.data.success){
                        this.getSubProducts();
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                        
                        this.context.dispatchSnackbarContent({ 
                            type: "SET_SNACKBAR_CONTENT", 
                            color : "success",
                            message : "Change Main Sub Product Success!"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        
                    }else{
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
        
                        this.context.dispatchSnackbarContent({ 
                            type: "SET_SNACKBAR_CONTENT", 
                            color : "danger",
                            message : res.data.message
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log(res.data.message,res.data.data)
                    }
                },(e)=>{
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
        
                    this.context.dispatchSnackbarContent({ 
                        type: "SET_SNACKBAR_CONTENT", 
                        color : "danger",
                        message : "Error"
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log("Error : ",e);
                })
            }
        }
    }

    onChange = (e) => {
        if(e.target.name === 'form_discount'){
            if(e.target.value > 100){
                this.context.dispatchSnackbarContent({ 
                    type: "SET_SNACKBAR_CONTENT", 
                    color : "danger",
                    message : "Max discount is 100%"
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.setState({ [e.target.name]: '' });
            }else{
                this.setState({ [e.target.name]: e.target.value });
            }
        }else{
            this.setState({ [e.target.name]: e.target.value });
        }
    }
        
    
    onChangeImages = (e) =>{
        let files = e.target.files;

        this.setState(prevState => {
            const form_images = [...prevState.form_images,...files];
            return { ...prevState, form_images };
        },()=>{
        });
    }

    render() {
        const {classes} = this.props
        const columns_product_variants = [
            { 
                title: 'Name', 
                field: 'varian',
            },
            { 
                title: 'Price', 
                field: 'price',
            },
            { 
                title: 'Discount', 
                width : 200,
                render: (rowData) => {
                    return (
                        rowData.discount != null && rowData.discount > 0 ?
                            `${rowData.discount}% (${rowData.price - (rowData.price * (rowData.discount/100))})`
                        :
                            '-'
                    )
                }
            },
            { 
                title: 'Weight', 
                field: 'weight',
            },
            { 
                title: 'Pics', 
                render: (rowData) => {
                    return (
                        <Grid 
                            container
                        >
                            {
                                rowData.images != null ?
                                    rowData.images.map((image,i) => {
                                        return (
                                            <Grid item xs={4} key={i}>
                                                {
                                                    typeof image === 'object' && image !== null ?
                                                        <img className={classes.img} alt={`${rowData.varian} ${image.name}`} src={URL.createObjectURL(image)}></img>
                                                    : 
                                                        <img className={classes.img} alt={`${rowData.varian} ${i}`} src={`${image}?v=${this.state.count_for_update}`}></img>
                                                }
                                            </Grid>
                                        )
                                    })
                                :
                                    null
                            }
                            
                        </Grid>
                    )
                }
            },
            { 
                title: 'Is Main?', 
                render: (rowData) => {
                    return (
                        <Grid container>
                            <Grid item container xs={3} alignItems="center">
                                <IOSSwitch 
                                    checked={rowData.tableData.id === this.state.main_sub_product_index} 
                                    onChange={() => this.handleIsMainToggle(rowData)} 
                                />
                            </Grid>
                            <Grid item container xs={9} justify="center">
                                <Grid item xs={12} container justify="center">
                                    <ButtonAdmin className={classes.buttonTable} color="info" 
                                        onClick={()=>this.handleToggleModal('update',rowData)}
                                    >
                                        EDIT
                                    </ButtonAdmin>
                                </Grid>
                                <Grid item xs={12} container justify="center">
                                    <ButtonAdmin className={classes.buttonTable} color="danger" onClick={() => this.handleDeleteSubProduct(rowData)}>DELETE</ButtonAdmin>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            },
        ]
        
        return (
            <React.Fragment>
                <Confirmation
                    openDialog={this.state.openDialog}
                    message={`You are about to delete variant <b>${this.state.tempDeleted ? this.state.tempDeleted.varian : ""}</b>, 
                    are you sure you want to delete it?`}
                    handleCloseDialog={this.handleCloseConfirmationDialog}
                />
                <Dialog 
                    fullWidth={true}
                    maxWidth="md"
                    open={this.state.modalOpen} 
                    onClose={this.handleToggleModal}>
                    <DialogTitle>Add New Variant</DialogTitle>
                    <DialogContent>
                            <img className={classes.closeIconModal} src={CloseIcon} alt="close icon" onClick={this.handleToggleModal}></img>
                            <Grid container>
                                <Grid item container xs={12} className={classes.contentContainerModal}>
                                    <Grid container className={classes.formContainer} spacing={4}>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                otherOptions={{
                                                    autoFocus : true
                                                }}
                                                width="100%" 
                                                type="text" 
                                                value={this.state.form_varian}
                                                inputRef={this.form_varian_ref}
                                                error={this.state.form_varian_error !== ""}
                                                helperText={this.state.form_varian_error}
                                                onChange={this.onChange} name="form_varian" label="Name" />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                width="100%" 
                                                value={this.state.form_price}
                                                inputRef={this.form_price_ref}
                                                error={this.state.form_price_error !== ""}
                                                helperText={this.state.form_price_error}
                                                type="number" onChange={this.onChange} name="form_price" label="Price (Rp)" />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                width="100%" 
                                                value={this.state.form_weight}
                                                inputRef={this.form_weight_ref}
                                                error={this.state.form_weight_error !== ""}
                                                helperText={this.state.form_weight_error}
                                                type="number" onChange={this.onChange} name="form_weight" label="Weight (grams)" />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                width="100%" 
                                                value={this.state.form_discount}
                                                inputRef={this.form_discount_ref}
                                                error={this.state.form_discount_error !== ""}
                                                helperText={this.state.form_discount_error}
                                                type="number" onChange={this.onChange} name="form_discount" label="Discount (%)" />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                disabled
                                                width="100%" 
                                                value={
                                                    this.state.form_price > 0 && this.state.form_price != null && this.state.form_price != '' ? 
                                                        this.state.form_discount > 0 && this.state.form_discount != null && this.state.form_discount != '' ?
                                                            this.state.form_price - (this.state.form_price * (this.state.form_discount/100))
                                                        :
                                                            this.state.form_price
                                                    : 
                                                        0
                                                }
                                                type="number" name="form_final_price" label="Final Price (Rp)" />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <input
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                id="upload-image"
                                                type="file"
                                                multiple
                                                onChange={this.onChangeImages}
                                            />
                                            <label htmlFor="upload-image">
                                                <Button color="info" component="span" >
                                                    Upload Image
                                                </Button>
                                            </label> 
                                        </Grid>
                                        <Grid item container md={12} xs={12}>
                                            {
                                                this.state.form_images != null ?
                                                    this.state.form_images.map((image,i) => {
                                                        return (
                                                            <Grid className={classes.tempPictContainer} key={i} item container md={4} xs={6}>
                                                                {
                                                                    typeof image === 'object' && image !== null ? 
                                                                        <img className={classes.imgPreview} key={i} alt={`preview ${image.name}`} src={URL.createObjectURL(image)}/>
                                                                    :
                                                                        <img className={classes.imgPreview} key={i} alt={`preview ${i}`} src={`${image}?v=${this.state.count_for_update}`}/>
                                                                }
                                                                <div className={`${classes.deletePict} delete-button`} >
                                                                    <img src={CloseIcon} alt="close icon" onClick={() => this.handleDeleteTempImg(i)}></img>
                                                                </div>
                                                            </Grid>
                                                        )
                                                    })
                                                :
                                                    null
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="success" style={{marginRight: '12px'}} onClick={this.handleSubmitVariant}>
                            SAVE
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid item xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="gray">
                            <h4 className={classes.cardTitleWhite}>Product Variants</h4>
                            <p>Handle Product Variants (Every Product Needs to Have Minumum One Variant)</p>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <Grid item sm={12} md={12}>
                                    <ButtonAdmin color="info" onClick={()=>this.handleToggleModal('add')}>ADD NEW VARIANT</ButtonAdmin>    
                                </Grid> 
                                <Grid item sm={12} md={12}>
                                    <MaterialTable
                                        title={null}
                                        components={{
                                            Toolbar: props => (
                                                null
                                            )                                    
                                        }}
                                        options={{
                                            search: false,
                                            exportButton: false,
                                            pageSize: 5,
                                            actionsColumnIndex : 6
                                        }}
                                        columns={columns_product_variants}
                                        data={this.state.sub_products}
                                    />
                                </Grid>
                            </Grid>
                        </CardBody>
                        {/* <CardFooter>
                            {this.state.type !=="add" ? 
                                <Button color="info" onClick={this.handleSubmitProductInfo}>Update Product Variants</Button>
                            :
                                null
                            }
                        </CardFooter> */}
                    </Card>
                </Grid>
            </React.Fragment>
        )
    }
}

export default (ProductDetailVariant)
