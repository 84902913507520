import React, { Component } from 'react'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import { baseUrl, requestConfigAdmin } from 'Environment';
import { withStyles } from '@material-ui/core';
import globalStyles from "assets/jss/client-side/App.js";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import GridItem from 'admin/components/Grid/GridItem';
import Card from "admin/components/Card/Card.js";
import CardHeader from "admin/components/Card/CardHeader.js";
import CardBody from "admin/components/Card/CardBody.js";
import GridContainer from 'admin/components/Grid/GridContainer';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField';
import CardFooter from 'admin/components/Card/CardFooter';
import Button from "admin/components/CustomButtons/Button.js";
import {
    prefix
} from '../../../Environment.js';

const useStyles = theme => ({
    ...globalStyles(theme),
    ...dashboardStyle,
});


export class ArticleCategoryDetail extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props)

        this.state = {
            type: "update",

            id: null,
            name_en: "",
            url_slug_en: "",
            name_id: "",
            url_slug_id: "",

        }
    }

    convertToSlug = (Text) => {
        return Text.toLowerCase()
            .replace(/ /g, '-')
            .replace(/[-]+/g, '-')
            .replace(/[^\w-]+/g, '')
    }

    getArticleCategory = () => {
        const id = this.props.match.params.id;
        axios.get(`${baseUrl}/article_categories/${id}`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    ...res.data.data,
                }, () => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    componentDidMount() {
        const id = this.props.match.params.id;

        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        if (id !== "add") {
            this.getArticleCategory();
            this.setState({
                type: "update"
            })
        } else {
            this.setState({
                type: "add"
            })
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
        }
    }

    onChange = (e) => {
        if (e.target.name === 'name_en') {
            this.setState({ url_slug_en: this.convertToSlug(e.target.value) })
        }

        if (e.target.name === 'name_id') {
            this.setState({ url_slug_id: this.convertToSlug(e.target.value) })
        }

        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmitArticleCategory = () => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        let data = {
            id: this.state.id,
            name_en: this.state.name_en,
            name_id: this.state.name_id
        }
        if (this.state.type === "update") {
            axios.put(`${baseUrl}/article_categories/${data.id}`,
                data,
                requestConfigAdmin
            ).then((res) => {
                if (res.data.success) {
                    this.getArticleCategory();
                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "success",
                        message: "Update Article Category Info Success!"
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                } else {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: res.data.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: "Error : "+e.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log("Error : ", e);
            })
        } else {
            axios.post(`${baseUrl}/article_categories`,
                data,
                requestConfigAdmin,
            ).then((res) => {
                if (res.data.success) {
                    let path = prefix + `/admin/article_categories`;
                    this.props.history.push(path);
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "success",
                        message: "Add New Article Category Success!"
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                } else {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: res.data.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: "Error : "+e.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log("Error : ", e);
            })
        }

    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="gray">
                            <h4 className={classes.cardTitleWhite}>Article Category</h4>
                            <p>Manage Category Information used in Article</p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem container item sm={12} md={6}>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            value={this.state.name_en}
                                            // error={this.state.name_en_error !== ""}
                                            // helperText={this.state.name_en_error}
                                            type="text" onChange={this.onChange} name="name_en" label="Name English" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            disabled
                                            value={this.state.url_slug_en}
                                            // error={this.state.url_slug_en_error !== ""}
                                            // helperText={this.state.url_slug_en_error}
                                            type="text" name="url_slug_en" label="URL English" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            value={this.state.name_id}
                                            // error={this.state.name_id_error !== ""}
                                            // helperText={this.state.name_id_error}
                                            type="text" onChange={this.onChange} name="name_id" label="Name Indonesia" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            disabled
                                            value={this.state.url_slug_id}
                                            // error={this.state.url_slug_id_error !== ""}
                                            // helperText={this.state.url_slug_id_error}
                                            type="text" name="url_slug_id" label="URL Indonesia" />
                                    </GridItem>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {this.state.type !== "add" ?
                                <Button color="info" onClick={this.handleSubmitArticleCategory}>Update Article Category</Button>
                                :
                                <Button color="info" onClick={this.handleSubmitArticleCategory}>Store New Article Category</Button>
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

export default withStyles(useStyles)(ArticleCategoryDetail)
