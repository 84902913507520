import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import axios from 'axios'
import { baseUrl, requestConfigAdmin } from 'Environment'
import { Grid, withStyles, MenuItem, TextField } from '@material-ui/core';
import styles from "assets/jss/client-side/App.js";
import ButtonAdmin from "admin/components/CustomButtons/Button.js";
import { AdminContext } from '../../AdminContext';
import Confirmation from 'admin/components/CustomDialogs/Confirmation';
import ArrowUpward from "assets/img/havilla/arrow-upward.svg";
import ArrowDownward from "assets/img/havilla/arrow-downward.svg";
import {
    prefix
} from '../../../Environment.js';
import Skeleton from '@material-ui/lab/Skeleton';
import slider1 from "assets/img/havilla/slider1.png";

const useStyles = theme => ({
    ...styles(theme),
    buttonTable: {
        minWidth: '104px'
    },
    orderText: {
        fontWeight: 'bold',
        fontSize: '28px'
    },
    img: {
        cursor: 'pointer'
    },
    sliderPreview: {
        height : '150px'
    }
});

const CustomTextfieldRaw = withStyles({
    root: {
        width: '100%',
        '& label.Mui-focused': {
            color: 'black',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);

export class Slider extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props)

        this.state = {
            sliderTypes : [
                'tea_shop',
                'homepage'
            ],
            sliders : [],
            openDialog: false,
            tempDeleted: null,
            type : 'tea_shop'
        }
    }

    processString = (string) => {
        return string.replace('_', ' ');
    }

      
    componentDidMount = () => {
        // this.getSliderTypes();
        this.getSliders();
    }

    getSliderTypes = () =>{
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        axios.get(`${baseUrl}/sliders/get_types`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    sliderTypes: res.data.data,
                }, () => {
                    this.getSliders();
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    getSliders = () =>{
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        let data = {
            type : this.state.type
        }
        axios.post(`${baseUrl}/sliders/get_by_type`,data, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    sliders : res.data.data
                }, () => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    handleDeleteClick = (data) => {
        this.setState({
            tempDeleted: data,
            openDialog: true,
        })
    }

    handleCloseDialog = (action) => {
        if (action) {
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
            axios.delete(`${baseUrl}/sliders/${this.state.tempDeleted.id}`,
                requestConfigAdmin
            ).then((res) => {
                if (res.data.success) {
                    this.getSliders();
                    this.setState({
                        tempDeleted: null,
                        openDialog: false,
                    }, () => {
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "success",
                            message: "Slider Deleted"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    })
                } else {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: res.data.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: e.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log("Error : ", e);
            })
        } else {
            this.setState({
                tempDeleted: null,
                openDialog: false,
            })
        }
    }

    handleArrowClick = (rowData, direction) => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        let data = {
            id: rowData.id,
            direction: direction,
        }

        axios.post(`${baseUrl}/sliders/change_order`,
            data,
            requestConfigAdmin
        ).then((res) => {
            if (res.data.success) {
                this.getSliders();
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "success",
                    message: "Update Slider Success!"
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            } else {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            console.log("Error : ", e);
        })
    }
    
    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.getSliders();
        })
    };

    render() {
        const { classes } = this.props;
        const columns = [
            {
                title: 'Photo',
                render: (rowData) => {
                    return (
                        <Grid container>
                            <Grid item xs={12}>
                                {/* <img className={classes.sliderPreview} src={slider1} alt={`${rowData.id} img`}></img> */}
                                <img className={classes.sliderPreview} src={rowData.photo_url} alt={`${rowData.id} img`}></img>
                            </Grid>
                        </Grid>
                    )
                }
            },
            {
                hidden: this.state.type === 'homepage',
                searchable: this.state.type !== 'homepage',
                title: 'Title',
                field: 'title',
            },
            {
                title: 'Order',
                field: 'order',
                width : this.state.type === 'homepage' ? 300 : 180,
                render: (rowData) => {
                    return (
                        <Grid
                            container
                            alignItems="center"
                        >
                            <Grid item container xs={2} alignItems="center" className={classes.orderText}>
                                {rowData.order}
                            </Grid>
                            <Grid item container xs={10} alignItems='center'>
                                <Grid item xs={12}>
                                    <img className={classes.img} src={ArrowUpward} alt={`${rowData.id} arrow upward`}
                                        onClick={() => this.handleArrowClick(rowData, 'up')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <img className={classes.img} src={ArrowDownward} alt={`${rowData.id} arrow downward`}
                                        onClick={() => this.handleArrowClick(rowData, 'down')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            },
            {
                title: '',
                width: 300,
                render: (rowData) => {
                    return (
                        <Grid container>
                            <Grid item container xs={12} justify="center">
                                <Grid item xs={6} container justify="center">
                                    <ButtonAdmin className={classes.buttonTable} color="info"
                                        onClick={
                                            (e) => this.props.history.push(prefix + '/admin/sliders/' + rowData.id)
                                        }
                                    >
                                        EDIT
                                    </ButtonAdmin>
                                </Grid>
                                <Grid item xs={6} container justify="center">
                                    <ButtonAdmin className={classes.buttonTable}
                                        color="danger"
                                        onClick={() => this.handleDeleteClick(rowData)}
                                    >
                                        DELETE
                                    </ButtonAdmin>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            },
        ]
        return (
            <div style={{ maxWidth: '100%' }}>
                <Confirmation
                    openDialog={this.state.openDialog}
                    message={`You are about to delete this slider, 
                    this action will delete all items related to it (Own Product Ingredients),are you sure you want to delete it?`}
                    handleCloseDialog={this.handleCloseDialog}
                />
                {
                    this.state.sliderTypes.length > 0 ? 
                        <MaterialTable
                            columns={columns}
                            data={this.state.sliders}
                            title={<ButtonAdmin color="info" onClick={() => this.props.history.push(prefix + '/admin/sliders/add')}>ADD NEW SLIDER</ButtonAdmin>}
                            options={{
                                search: true,
                                exportButton: false,
                                pageSize: 10
                            }}
                            components={{
                                Toolbar: props => (
                                    <div>
                                        <MTableToolbar {...props} />
                                        <Grid style={{ paddingLeft: '18px' }} container justify="flex-start" spacing={3}>
                                            {
                                                this.state.sliderTypes.length > 0 ?
                                                    <Grid item xs={12} md={3}>
                                                        <CustomTextfieldRaw
                                                            select
                                                            label="Type"
                                                            name="type"
                                                            value={this.state.type}
                                                            helperText="Filter by Type"
                                                            onChange={this.onChange}
                                                        >
                                                            {/* <MenuItem value={"all"}>All</MenuItem> */}
                                                            {
                                                                this.state.sliderTypes.map((type, i) => {
                                                                    return <MenuItem value={type} key={i}>
                                                                        <p style={{textTransform: 'capitalize'}}>
                                                                            {this.processString(type)}
                                                                        </p>
                                                                    </MenuItem>
                                                                })
                                                            }
                                                        </CustomTextfieldRaw>
                                                    </Grid>
                                                    :
                                                    <Grid item xs={12} md={3}>
                                                        <Skeleton variant="rect" width={281.5} height={48} />
                                                    </Grid>
                                            }
                                        </Grid>
                                    </div>
                                ),
                            }}
                        />
                    :
                        null
                }
            </div>
        )
    }
}

export default withStyles(useStyles)(Slider)
