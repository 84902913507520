import React, { Component } from 'react'
import MaterialTable from 'material-table'

import ButtonAdmin from "admin/components/CustomButtons/Button.js";
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import {
    baseUrl,
    requestConfigAdmin
} from '../../../Environment.js';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import {
    prefix
} from '../../../Environment.js';
const useStyles = theme => ({
    ...styles(theme),
    img: {
        width: 100
    }
});


export class AboutUs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cms: [],
        }
    }

    componentDidMount = async () => {
        this.setState({ openBackdrop: true });
        await axios.get(`${baseUrl}/cms_get_table`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    cms: res.data.data
                }, () => {
                })
            } else {
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            console.log("Error : ", e);
        })
    }

    render() {
        const { classes } = this.props;

        const columns = [
            { title: 'Key', field: 'key' },
            {
                title: 'Content (ENG)', field: 'content_eng',
                render: (rowData) => {
                    return (
                        rowData.content_eng != null ?
                            <Grid item xs={12}
                                dangerouslySetInnerHTML={{ __html: rowData.content_eng }}
                            >
                            </Grid>
                            :
                            '-'
                    )
                }
            },
            {
                title: 'Content (ID)', field: 'content_id',
                render: (rowData) => {
                    return (
                        rowData.content_id != null ?
                            <Grid item xs={12}
                                dangerouslySetInnerHTML={{ __html: rowData.content_id }}
                            >
                            </Grid>
                            :
                            '-'
                    )
                }
            },
            {
                title: 'Images', field: 'photo_urls',
                render: (rowData) => {
                    return (
                        rowData.photo_urls != null ?
                            (
                                JSON.parse(rowData.photo_urls).length > 1 ?
                                    <Grid container>
                                        {
                                            JSON.parse(rowData.photo_urls).map((image, i) => {
                                                return (
                                                    <Grid item xs={4} key={i}>
                                                        <img className={classes.img} alt={`${rowData.photo_urls}`} src={JSON.parse(rowData.photo_urls)[i]}></img>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                    :
                                    <Grid item xs={4}>
                                        <img className={classes.img} alt={`${rowData.photo_urls}`} src={JSON.parse(rowData.photo_urls)[0]}></img>
                                    </Grid>
                            )
                            :
                            '-'
                    )
                }
            },
        ]

        return (
            <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    columns={columns}
                    // data={[{ name: 'Mehmet', surname: 'Baran', birthYear: 1987, birthCity: 63 }]}
                    data={this.state.cms}
                    // title={<ButtonAdmin color="info" onClick={() => this.props.history.push(prefix + '/admin/storelocator/detail/add')}>Add New Store Location</ButtonAdmin>}
                    title={`About Us CMS`}
                    options={{
                        search: true,
                        exportButton: true,
                        pageSize: 10
                    }}
                    onRowClick={(event, rowData) => this.props.history.push(prefix + '/admin/about_us/detail/' + rowData.id)}
                // detailPanel={rowData => {
                //     console.log(rowData);
                //     return (
                //         <Container maxWidth="xl" className={`${classes.container} ${classes.contentContainer}`}>
                //             <Grid container>
                //                 <Grid item md={6} xs={12}>
                //                     <CustomTextField
                //                         value={rowData.name}
                //                         // error={this.state.first_name_error !== ""}
                //                         // disabled={this.state.disabled}
                //                         // helperText={this.state.first_name_error}
                //                         type="text" name="name" label="Store Name" />
                //                 </Grid>
                //                 <Grid item md={6} xs={12}>
                //                     <CustomTextField
                //                         value={this.state.first_name}
                //                         error={this.state.first_name_error !== ""}
                //                         disabled={this.state.disabled}
                //                         helperText={this.state.first_name_error}
                //                         type="text" onChange={this.onChange} name="first_name" label="First Name" />
                //                 </Grid>
                //             </Grid>
                //         </Container>
                //     )
                // }}
                />
            </div>
        )
    }
}

export default withStyles(useStyles)(AboutUs) 