import Login from 'client-side/views/Login/Login';
import Register from 'client-side/views/Register/Register';
import Profile from 'client-side/views/Profile/Profile';
import MyOrder from 'client-side/views/Profile/Sub Pages/MyOrder';
import AccountDetails from 'client-side/views/Profile/Sub Pages/AccountDetails';
import MyOwnBlend from 'client-side/views/Profile/Sub Pages/MyOwnBlend';
import MyWishlist from 'client-side/views/Profile/Sub Pages/MyWishlist';
import Logout from 'client-side/views/Profile/Sub Pages/Logout';
import ForgotPassword from './views/ForgotPassword/ForgotPassword';
import TeaShop from './views/TeaShop/TeaShop';
import ProductDetail from './views/ProductDetail/ProductDetail';
import ProductBuying from './views/ProductBuying/ProductBuying';
import PageIntro from './views/CreateYourOwn/PageIntro';
import PageHow from './views/CreateYourOwn/PageHow';
import TeaTalks from './views/TeaTalks/TeaTalks';
import TeaTalksDetail from './views/TeaTalks/TeaTalksDetail';
import PageStep from './views/CreateYourOwn/PageStep';
import PageComplete from './views/CreateYourOwn/PageComplete';
import AboutUs from './views/AboutUs/AboutUs';
import AboutUsDetail from './views/AboutUs/AboutUsDetail';
import PageSummary from './views/CreateYourOwn/PageSummary';
import HavillaGift from './views/HavillaGift/HavillaGift';
import HavillaPartnership from './views/HavillaPartnership/HavillaPartnership';
import SocialActs from './views/SocialActs/SocialActs';
import FAQ from './views/AboutUs/FAQ';
import GoogleMaps from './views/OurPartners/GoogleMaps';
import OurPartners from './views/OurPartners/OurPartners';
import Homepage from './views/Homepage/Homepage';
import ForgotPasswordSendEmail from './views/ForgotPassword/ForgotPasswordSendEmail';
const clientSideRoutes = [
  {
    path: "/",
    component: Homepage,
    layout : null,
    is_exact : true
  },
  {
    path: "/login",
    component: Login,
    layout : null,
    is_exact : true
  },
  {
    path: "/register",
    component: Register,
    layout : null,
    is_exact : true
  },
  {
    path: "/forgot_password",
    component: ForgotPassword,
    layout : null,
    is_exact : false
  },
  {
    path: "/profile",
    component: MyOrder,
    layout : Profile,
    is_exact : true
  },
  {
    path: "/profile/order",
    component: MyOrder,
    layout : Profile,
    is_exact : true
  },
  {
    path: "/profile/account",
    component: AccountDetails,
    layout : Profile,
    is_exact : true
  },
  {
    path: "/profile/own_blend",
    component: MyOwnBlend,
    layout : Profile,
    is_exact : true
  },
  {
    path: "/profile/wishlist",
    component: MyWishlist,
    layout : Profile,
    is_exact : true
  },
  {
    path: "/profile/logout",
    component: Logout,
    layout : Profile,
    is_exact : true
  },
  {
    path: "/tea_shop",
    component: TeaShop,
    layout : null,
    is_exact : false
  },
  {
    path: "/product_buying",
    component: ProductBuying,
    layout : null,
    is_exact : true
  },
  {
    path: "/product/:id",
    component: ProductDetail,
    layout : null,
    is_exact : true
  },
  // {
  //   path: "/create_your_own",
  //   component: PageIntro,
  //   layout : null,
  //   is_exact : true
  // },
  // {
  //   path: "/create_your_own/how",
  //   component: PageHow,
  //   layout : null,
  //   is_exact : true
  // },
  // {
  //   path: "/create_your_own/create",
  //   component: PageStep,
  //   layout : null,
  //   is_exact : true
  // },
  // {
  //   path: "/create_your_own/summary",
  //   component: PageSummary,
  //   layout : null,
  //   is_exact : true
  // },
  // {
  //   path: "/create_your_own/complete",
  //   component: PageComplete,
  //   layout : null,
  //   is_exact : true
  // },
  {
    path: "/tea_talks",
    component: TeaTalks,
    layout : null,
    is_exact : true
  },
  {
    path: "/tea_talks/:slug",
    component: TeaTalksDetail,
    layout : null,
    is_exact : true
  },
  {
    
    path: "/about_us",
    component: AboutUs,
    layout : null,
    is_exact : true
  },
  {
    path: "/about_us/detail",
    component: AboutUsDetail,
    layout : null,
    is_exact : true
  },
  {
    path: "/about_us/social_acts/:slug",
    component: TeaTalksDetail,
    layout : null,
    is_exact : true
  },
  { 
    path: "/about_us/social_acts",
    component: SocialActs,
    layout : null,
    is_exact : true
  },
  {
    path: "/about_us/our_partners",
    component: OurPartners,
    layout : null,
    is_exact : true
  },
  {
    path: "/about_us/faq",
    component: FAQ,
    layout : null,
    is_exact : true
  },
  { 
    path: "/havilla_gifts",
    component: HavillaGift,
    layout : null,
    is_exact : true
  },
  { 
    path: "/havilla_partnership",
    component: HavillaPartnership,
    layout : null,
    is_exact : true
  },
  {
    path: "/send_request_forgot_password",
    component: ForgotPasswordSendEmail,
    layout : null,
    is_exact : false
  },
];

export default clientSideRoutes;
