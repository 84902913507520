import React, { Component } from 'react'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import { baseUrl, baseUrlNonApi, requestConfigAdmin } from 'Environment';
import { withStyles, Grid, TextField, MenuItem } from '@material-ui/core';
import globalStyles from "assets/jss/client-side/App.js";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import GridItem from 'admin/components/Grid/GridItem';
import Card from "admin/components/Card/Card.js";
import CardHeader from "admin/components/Card/CardHeader.js";
import CardBody from "admin/components/Card/CardBody.js";
import GridContainer from 'admin/components/Grid/GridContainer';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField';
import CardFooter from 'admin/components/Card/CardFooter';
import Button from "admin/components/CustomButtons/Button.js";
import CloseIcon from "assets/img/havilla/close_icon_black.svg";
import FormHelperText from '@material-ui/core/FormHelperText';
import { Editor } from '@tinymce/tinymce-react';
import {
    prefix
} from '../../../Environment.js';

const CustomTextfieldRaw = withStyles({
    root: {
        width: '100%',
        '& label.Mui-focused': {
            color: 'black',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);

const useStyles = theme => ({
    ...globalStyles(theme),
    ...dashboardStyle,
    imgPreview: {
        cursor: 'pointer',
        height: '250px',
        maxWidth : '530px',
        '&:hover + .delete-button': {
            marginTop: '-17px',
        }
    },
    deletePict: {
        transition: 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)',
        marginTop: '-47px',
        display: 'block',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            marginTop: '-17px',
        },
    },
    tempPictContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: '30px',
        overflow: 'hidden',
        '&:hover .delete-button': {
            marginTop: '-17px',
        }
    },
});

const field_names = [
    'title',
    'caption',
    'written_by',
    'content',
    'category_id',
    'thumbnail_url',
    'header_image_url',
    'is_active',
]

export class ArticleDetail extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props)

        this.state = {
            type: "update",

            id: null,
            title: "",
            title_error: "",
            category_id: "",
            category_id_error: "",
            is_active: 1,
            is_active_error: "",
            caption: "",
            caption_error: "",
            written_by: "",
            written_by_error: "",
            thumbnail_url: null,
            thumbnail_url_error: "",
            header_image_url: null,
            header_image_url_error: "",
            content: "",

            url_slug: "",
            article_categories: [],
            count_for_update: 0,

        }

        this.title_ref = React.createRef();
        this.category_id_ref = React.createRef();
        this.is_active_ref = React.createRef();
        this.caption_ref = React.createRef();
        this.written_by_ref = React.createRef();
        this.thumbnail_url_ref = React.createRef();
        this.header_image_url_ref = React.createRef();
    }

    convertToSlug = (Text) => {
        return Text.toLowerCase()
            .replace(/ /g, '-')
            .replace(/[-]+/g, '-')
            .replace(/[^\w-]+/g, '')
    }

    scrolToNonInput = (ref) => {
        const container = document.querySelector('#perfect-scrollbar-container');
        container.scrollTop = ref.current.offsetTop;
    }

    getArticle = () => {
        this.setState((prevState => ({
            count_for_update: prevState.count_for_update + 1
        })))
        const id = this.props.match.params.id;
        axios.get(`${baseUrl}/articles/${id}`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    ...res.data.data,
                }, () => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    getArticleCategories = () => {
        axios.get(`${baseUrl}/article_categories`,
            requestConfigAdmin
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data.active;
                this.setState({
                    article_categories: data,
                }, () => {
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        if (id === "add") {
            this.setState({
                type: "add"
            }, () => {
                this.getArticleCategories();
            })
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false})
        } else if (id === "add_social_act") {
            this.setState({
                type: "add",
                article_categories: [{
                    id: 1,
                    name_en: 'Social Acts',
                    name_id: 'Aksi Sosial'
                }],
                category_id: 1
            })
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
        } else {
            this.setState({
                type: "update"
            }, () => {
                this.getArticleCategories();
                this.getArticle();
            })
        }
    }

    componentDidUpdate(nextProps, nextState) {
        for (let i = 0; i < field_names.length; i++) {
            let field_name = field_names[i];
            if (this.state[field_name + "_error"] !== "") {
                if (this.state[field_name] !== nextState[field_name]) {
                    this.setState({ [field_name + "_error"]: "" });
                }
            }
        }

        return true;
    }

    onChange = (e) => {
        if (e.target.name === 'title') {
            this.setState({ url_slug: this.convertToSlug(e.target.value) })
        }

        this.setState({ [e.target.name]: e.target.value })
    }

    onChangeImages = (e) => {
        if (e.target.files.length > 0) {
            let file = e.target.files[0];
            this.setState({
                [e.target.name]: file
            }, () => {
            })
        }
    }

    handleEditorChange = (content, editor) => {
        this.setState({
            content: content
        })
    }

    handleDeleteTempImg = (ref) => {
        this.setState({
            [ref]: null
        }, () => {
            if (this.state[ref] === null) {
                document.getElementById(ref).value = "";
            }
        });
    }

    getAttrFromString = (str, node, attr) => {
        var regex = new RegExp('<' + node + ' .*?' + attr + '="(.*?)"', "gi"), result, res = [];
        while ((result = regex.exec(str))) {
            res.push(result[1]);
        }
        return res;
    }

    handleSubmitArticle = () => {
        let is_ok = true;
        for (let i = 0; i < field_names.length; i++) {
            let field_name = field_names[i];
            let field_name_text = field_names[i].replace('_', ' ');
            if (this.state[field_name] === "" || this.state[field_name] === null) {
                is_ok = false;
                if (field_name !== 'content') {
                    if (field_name === 'thumbnail_url') {
                        this.scrolToNonInput(this[field_name + "_ref"]);
                    } else if (field_name === 'header_image_url') {
                        this.scrolToNonInput(this[field_name + "_ref"]);
                    } else {
                        this[field_name + "_ref"].current.focus();
                    }
                    this.setState({ [field_name + "_error"]: field_name_text + " is required" });
                } else {
                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: "Content are required"
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                }
            }
        }
        if (is_ok) {
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
            let requestConfigAdd = {
                ...requestConfigAdmin.headers,
                'Content-Type': 'multipart/form-data'
            }

            let formData = new FormData();
            formData.append('title', this.state.title);
            formData.append('category_id', this.state.category_id);
            formData.append('is_active', this.state.is_active);
            formData.append('caption', this.state.caption);
            formData.append('written_by', this.state.written_by);
            formData.append('thumbnail_url', this.state.thumbnail_url);
            formData.append('header_image_url', this.state.header_image_url);
            formData.append('content', this.state.content);
            formData.append('photo_urls', JSON.stringify(this.getAttrFromString(this.state.content, 'img', 'src')));

            if (this.state.type === "update") {
                formData.append('_method', "PUT");
                axios.post(`${baseUrl}/articles/${this.state.id}`,
                    formData,
                    requestConfigAdd
                ).then((res) => {
                    if (res.data.success) {
                        this.getArticle();
                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "success",
                            message: "Update Article Success!"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    } else {
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "danger",
                            message: res.data.message
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log(res.data.message, res.data.data)
                    }
                }, (e) => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: "Error : "+ e.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log("Error : ", e);
                })
            } else {
                axios.post(`${baseUrl}/articles`,
                    formData,
                    requestConfigAdd,
                ).then((res) => {
                    if (res.data.success) {
                        let path = prefix + `/admin/articles`;
                        this.props.history.push(path);
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "success",
                            message: "Add New Article Success!"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    } else {
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "danger",
                            message: res.data.message
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log(res.data.message, res.data.data)
                    }
                }, (e) => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: "Error : "+e.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log("Error : ", e);
                })
            }
        }

    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="gray">
                            <h4 className={classes.cardTitleWhite}>Article</h4>
                            <p>Manage Article Content</p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem container item sm={12} md={6}>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            value={this.state.title}
                                            error={this.state.title_error !== ""}
                                            helperText={this.state.title_error}
                                            inputRef={this.title_ref}
                                            type="text" onChange={this.onChange} name="title" label="Title" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            disabled
                                            value={this.state.url_slug}
                                            // error={this.state.url_slug_error !== ""}
                                            // helperText={this.state.url_slug_error}
                                            type="text" name="url_slug" label="URL Article" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={12}>
                                        {
                                            this.state.category_id === 1 ?
                                                <CustomTextField
                                                    disabled
                                                    value={`${this.state.article_categories[0].name_en} | ${this.state.article_categories[0].name_id}`}
                                                    width="100%" label="Article Category" />
                                                :
                                                <CustomTextfieldRaw
                                                    select
                                                    label="Article Category"
                                                    name="category_id"
                                                    error={this.state.category_id_error !== ""}
                                                    helperText={this.state.category_id_error}
                                                    value={this.state.category_id}
                                                    inputRef={this.category_id_ref}
                                                    onChange={this.onChange}
                                                >
                                                    {
                                                        this.state.article_categories.map((category, i) => {
                                                            return <MenuItem value={category.id} key={category.id}>{category.name_en} | {category.name_id}</MenuItem>
                                                        })
                                                    }
                                                </CustomTextfieldRaw>
                                        }
                                    </GridItem>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            value={this.state.written_by}
                                            error={this.state.written_by_error !== ""}
                                            helperText={this.state.written_by_error}
                                            inputRef={this.written_by_ref}
                                            type="text" onChange={this.onChange} name="written_by" label="Written By" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            label="Caption"
                                            name="caption"
                                            otherOptions={{
                                                multiline: true,
                                                rows: 3
                                            }}
                                            error={this.state.caption_error !== ""}
                                            helperText={this.state.caption_error}
                                            onChange={this.onChange}
                                            value={this.state.caption === null ? '' : this.state.caption}
                                            inputRef={this.caption_ref}
                                        />
                                    </GridItem>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextfieldRaw
                                            select
                                            label="Article Status"
                                            name="is_active"
                                            error={this.state.is_active_error !== ""}
                                            helperText={this.state.is_active_error}
                                            value={this.state.is_active}
                                            inputRef={this.is_active_ref}
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value='1' key='1'>Active</MenuItem>
                                            <MenuItem value='0' key='0'>Not Active</MenuItem>
                                        </CustomTextfieldRaw>
                                    </GridItem>
                                </GridItem>
                                <GridItem container item sm={12} md={6}>
                                    <Grid item xs={12}>
                                        <FormHelperText>Thumbnail Image (Recommended Size : 650 x 300)</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} container alignItems='center'>
                                        <input
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="thumbnail_url"
                                            name='thumbnail_url'
                                            type="file"
                                            onChange={this.onChangeImages}
                                        />
                                        <label htmlFor="thumbnail_url" ref={this.thumbnail_url_ref} style={{ marginRight: '15px' }}>
                                            <Button color="info" component="span">
                                                Upload Thumbnail
                                            </Button>
                                        </label>
                                        <FormHelperText error style={{ textTransform: 'capitalize' }}>{this.state.thumbnail_url_error}</FormHelperText>
                                    </Grid>
                                    <Grid item container md={12} xs={12} style={{ height: '310px' }}>
                                        {
                                            this.state.thumbnail_url != null ?
                                                <div className={classes.tempPictContainer}>
                                                    {
                                                        typeof this.state.thumbnail_url === 'object' && this.state.thumbnail_url !== null ?
                                                            <img className={classes.imgPreview} alt={`preview ${this.state.thumbnail_url.name}`} src={URL.createObjectURL(this.state.thumbnail_url)} />
                                                            :
                                                            <img className={classes.imgPreview} alt={`preview thumbnail`} src={`${this.state.thumbnail_url}?v=${this.state.count_for_update}`} />
                                                    }
                                                    <div className={`${classes.deletePict} delete-button`} >
                                                        <img src={CloseIcon} alt="close icon" onClick={() => this.handleDeleteTempImg('thumbnail_url')}></img>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </Grid>
                                </GridItem>

                                <GridItem container item xs={12}>
                                    <Grid item xs={12} style={{ padding: '0px 15px' }}>
                                        <FormHelperText>Header Image (Recommended Size : 1450 x 450)</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} container alignItems='center' style={{ padding: '0px 15px' }}>
                                        <input
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="header_image_url"
                                            name='header_image_url'
                                            type="file"
                                            onChange={this.onChangeImages}
                                        />
                                        <label htmlFor="header_image_url" ref={this.header_image_url_ref} style={{ marginRight: '15px' }}>
                                            <Button color="info" component="span">
                                                Upload Header Image
                                            </Button>
                                        </label>
                                        <FormHelperText error style={{ textTransform: 'capitalize' }}>{this.state.header_image_url_error}</FormHelperText>
                                    </Grid>
                                    <Grid item container md={12} xs={12} style={{ height: '310px', padding: '0px 15px' }}>
                                        {
                                            this.state.header_image_url != null ?
                                                <div className={classes.tempPictContainer}>
                                                    {
                                                        typeof this.state.header_image_url === 'object' && this.state.header_image_url !== null ?
                                                            <img className={classes.imgPreview} alt={`preview ${this.state.header_image_url.name}`} src={URL.createObjectURL(this.state.header_image_url)} />
                                                            :
                                                            <img className={classes.imgPreview} alt={`preview header_image`} src={`${this.state.header_image_url}?v=${this.state.count_for_update}`} />
                                                    }
                                                    <div className={`${classes.deletePict} delete-button`} >
                                                        <img src={CloseIcon} alt="close icon" onClick={() => this.handleDeleteTempImg('header_image_url')}></img>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </Grid>
                                </GridItem>

                                <GridItem container item sm={12} md={12}>
                                    <GridItem xs={12} container justify="center">
                                        {/* <TinyEditorComponent
                                            id="myCoolEditor"
                                            onEditorChange={content => console.log(content)}
                                        /> */}
                                        <Editor
                                            apiKey="4xcq0qf05flqal1nd0n9broiga7bph32v2axralmppkw1nad"
                                            value={this.state.content}
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount imagetools '
                                                ],
                                                toolbar: [
                                                    'undo redo | \
                                                    bold italic underline strikethrough | \
                                                    fontselect fontsizeselect formatselect | \
                                                    alignleft aligncenter alignright alignjustify | ',
                                                    'outdent indent | \
                                                    numlist bullist | \
                                                    forecolor backcolor removeformat | \
                                                    pagebreak | \
                                                    charmap emoticons | \
                                                    fullscreen preview | \
                                                    insertfile image media template link anchor codesample code',
                                                ],
                                                width: 932,
                                                relative_urls: false,
                                                remove_script_host: false,
                                                convert_urls: true,
                                                image_advtab: true,
                                                image_caption: true,
                                                file_picker_types: 'image',
                                                document_base_url: baseUrlNonApi,
                                                images_upload_handler: function (blobInfo, success, failure) {
                                                    let data = new FormData();
                                                    data.append('file', blobInfo.blob(), blobInfo.filename());

                                                    axios.post(`${baseUrl}/articles/temp_file_upload`, data, requestConfigAdmin)
                                                        .then(function (res) {
                                                            success(`${baseUrlNonApi}${res.data.location}`);
                                                        })
                                                        .catch(function (err) {
                                                            failure('HTTP Error: ' + err.message);
                                                        });
                                                },
                                            }}
                                            onEditorChange={this.handleEditorChange}
                                        />
                                    </GridItem>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            {this.state.type !== "add" ?
                                <Button color="info" onClick={this.handleSubmitArticle}>Update Article</Button>
                                :
                                <Button color="info" onClick={this.handleSubmitArticle}>Store New Article</Button>
                            }
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

export default withStyles(useStyles)(ArticleDetail)
