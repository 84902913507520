import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
// import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';

// import Datepicker from 'client-side/components/Datepicker/Datepicker';
import HomeIcon from "assets/img/havilla/home_icon.svg";
import HomeIcon2 from "assets/img/havilla/home_icon2.svg";
import OfficeIcon from "assets/img/havilla/office_icon.svg";
import OfficeIcon2 from "assets/img/havilla/office_icon2.svg";
import CloseIcon from "assets/img/havilla/close_icon.svg";
import { Backdrop, CircularProgress } from '@material-ui/core';

import Fade from '@material-ui/core/Fade';
import AddIcon from "assets/img/havilla/add_icon.svg";
import Modal from '@material-ui/core/Modal';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import {
    baseUrl,
    requestConfig
} from '../../../Environment.js';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import { dictionary } from '../../../Dictionary';
import { ClientSideContext } from '../../ClientSideContext';

const CustomTextfieldRaw = withStyles({
    root: {
        width: '100%',
        '& label': {
            color: 'white',
        },
        '& label.Mui-disabled': {
            color: 'grey',
        },
        '& .MuiInput-root': {
            color: '#AF986D',
            '&:hover fieldset': {
                borderBottomColor: 'red',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#cfcfcf',
        },
        '& .MuiInput-root:hover': {
            '.MuiInput-underline:before': {
                borderBottomColor: '#cfcfcf',
            },
        },
        '& .MuiInput-underline-hover:before': {
            borderBottomColor: 'white',
        },
        '& label.Mui-focused': {
            color: '#AF986D',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#AF986D',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);

const useStyles = theme => ({
    ...styles(theme),
    textField: {
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        [theme.breakpoints.up('md')]: {
        },
        width: "100%",
    },
    iconEdit: {
        marginTop: `-8px`
    },
    boxOutline: {
        backgroundColor: '#121212',
        padding: '1.2rem',
        height: '100%',
        position: 'relative'
    },
    paper: {
        [theme.breakpoints.down('sm')]: {
            padding: '1rem',
            width: '100%',
        },
        position: 'absolute',
        width: '50%',
        backgroundColor: '#121212',
        boxShadow: theme.shadows[5],
        padding: '3rem 4rem'
    },
    closeIcon: {
        position: 'absolute',
        right: 20,
        top: 20,
        cursor: 'pointer'
    },
    selectedIconType: {
        border: '1px solid #AF986D',
        padding: '5px'
    },
    unselectedIconType: {
        padding: '6px'
    },
    addNewContainer: {
        [theme.breakpoints.down('md')]: {
            paddingTop: 0,
        },
        [theme.breakpoints.down('lg')]: {
            paddingTop: '5%'
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '10%'
        },
        height: '100%'
    },
    modalStyle: {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        overflowY: 'auto',
        height: '80%',
        // height: 'auto',
        display: 'block',
        [theme.breakpoints.up('xl')]: {
            height: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            height: '100%'
        }
    },
    defaultInfo: {
        position: 'absolute',
        bottom: 10
    },
    autoCompleteRoot: {
        '& label': {
            color: 'white',
        },
        '& label.Mui-disabled': {
            color: 'grey',
        },
        '& .MuiInput-root': {
            color: '#AF986D',
            '&:hover fieldset': {
                borderBottomColor: 'red',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#cfcfcf',
        },
        '& .MuiInput-root:hover': {
            '.MuiInput-underline:before': {
                borderBottomColor: '#cfcfcf',
            },
        },
        '& .MuiInput-underline-hover:before': {
            borderBottomColor: 'white',
        },
        '& label.Mui-focused': {
            color: '#AF986D',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#AF986D',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        },
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
    },
    autoCompletePaper: {
        backgroundColor: '#222222',
        borderRadius: 0,
        '& .MuiMenuItem-root': {
            background: '#222222'
        },
        '& .MuiMenuItem-root:hover': {
            backgroundColor: 'white',
            color: 'black',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#121212',
            borderRadius: 0,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#AF986D',
            borderRadius: 0,
            '&:hover': {
                backgroundColor: 'white',
            },
        }
    },
    autoCompleteOption: {
        color: 'white',
        '&:hover': {
            backgroundColor: 'white',
            color: 'black',
        },
    },
});
const borderStandart = {
    border: '1px solid #121212',
}
const borderBold = {
    border: '1px solid #AF986D',
}
const borderDotted = {
    border: '1px dotted grey',
}

const field_names = [
    'icon_type',
    'title',
    'province_id',
    'city_id',
    'postal_code',
    'street_address',
    'phone_no',
]


export class AddressCard extends Component {
    static contextType = ClientSideContext;
    constructor(props) {
        super(props);

        this.state = {
            openBackdrop: false,

            email: '',
            password: '',
            open: false,
            listProvinces: [],
            listCities: [{ value: '', label: '' }],
            city_disabled: false,

            addresses: [],
            selectedModal: null,
            selectedAddress: null,

            id: '',
            icon_type: '',
            title: '',
            province_id: '',
            city_id: '',
            province_name: '',
            city_name: '',

            postal_code: '',
            street_address: '',
            phone_no: '',
            notes: '',

            icon_type_error: '',
            title_error: '',
            province_id_error: '',
            city_id_error: '',
            postal_code_error: '',
            street_address_error: '',
            phone_no_error: '',
            notes_error: '',
        }

        // this.handlePasswordChange = this.handlePasswordChange.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(nextProps, nextState) {
        for (let i = 0; i < field_names.length; i++) {
            let field_name = field_names[i];
            if (this.state[field_name + "_error"] !== "") {
                if (this.state[field_name] !== nextState[field_name]) {
                    this.setState({ [field_name + "_error"]: "" });
                }
            }
        }

        return true;
    }

    componentDidMount = async () => {
        this.setState({ openBackdrop: true });
        await axios.get(`${baseUrl}/addresses`, requestConfig).then((res) => {
            if (res.data.success) {
                this.setState({
                    addresses: res.data.data
                }, () => {
                    // console.log(this.state)
                })
            } else {
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            console.log("Error : ", e);
        })

        if (this.props.editMode === false) {
            await axios.get(`${baseUrl}/address/get_default`,
                requestConfig)
                .then((res) => {
                    if (res.data.success) {
                        this.setState({
                            selectedAddress: res.data.data.id
                        })
                        this.props.handleSelectAddress(res.data.data)
                    } else {
                        console.log(res.data.message, res.data.data)
                    }
                }, (e) => {
                    console.log("Error : ", e);
                })
        }

        this.getProvinces()

        this.getCities()
    }

    getProvinces = async () => {
        await axios.get(`${baseUrl}/rajaongkir/get_provinces`).then((res) => {
            if (res.data.rajaongkir) {
                if (res.data.rajaongkir.status.code === 200) {
                    this.setState({
                        listProvinces: res.data.rajaongkir.results.map(item => {
                            // alert(window.location.pathname.split("/")[2]);
                            return (
                                {
                                    value: item.province_id,
                                    label: item.province
                                }
                            )
                        })
                    }, () => {
                    })
                } else {
                    this.setState({ openBackdrop: false });
                    console.log(res.data.message, res.data.data)
                }
            } else {
                this.setState({ openBackdrop: false });
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            console.log("Error : ", e);
        })
    }

    getCities = async () => {
        await axios.get(`${baseUrl}/rajaongkir/get_cities`).then((res) => {
            if (res.data.rajaongkir) {
                if (res.data.rajaongkir.status.code === 200) {
                    this.setState({
                        listCities: res.data.rajaongkir.results.map(item => {
                            // alert(window.location.pathname.split("/")[2]);
                            return (
                                {
                                    value: item.city_id,
                                    label: item.type + ' ' + item.city_name
                                }
                            )
                        })
                    }, () => {
                        this.setState({ openBackdrop: false });
                    })
                } else {
                    this.setState({ openBackdrop: false });
                    console.log(res.data.message, res.data.data)
                }
            } else {
                this.setState({ openBackdrop: false });
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            console.log("Error : ", e);
        })
    }

    getCitiesByProvince = (province_id, type) => {
        axios.get(`${baseUrl}/rajaongkir/get_city_by_province/${province_id}`).then((res) => {
            if (res.data.rajaongkir) {
                if (res.data.rajaongkir.status.code === 200) {
                    this.setState({
                        listCities: res.data.rajaongkir.results.map(item => {
                            // alert(window.location.pathname.split("/")[2]);
                            return (
                                {
                                    value: item.city_id,
                                    label: item.type + ' ' + item.city_name
                                }
                            )
                        })
                    }, () => {
                        if (type === "onChange") {
                            this.setState({
                                city_id: this.state.listCities[0].value,
                                city_name: this.state.listCities[0].label,
                            });
                        }
                        this.setState({
                            openBackdrop: false
                        });
                    })
                } else {
                    this.setState({ openBackdrop: false });
                    console.log(res.data.message, res.data.data)
                }
            } else {
                this.setState({ openBackdrop: false });
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            console.log("Error : ", e);
        })
    }

    handleSetDefault = async (i) => {
        const state = this.state;
        this.setState({ openBackdrop: true });
        await axios.get(`${baseUrl}/addresses/set_default/${i}`,
            requestConfig)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        // addresses: [...this.state.addresses, res.data.data]
                        addresses: this.state.addresses.map(address => {
                            if (address.id === res.data.data.id) {
                                address = res.data.data
                            }
                            return address;
                        })
                    }, () => {
                        window.location.reload();
                    })
                } else {
                    console.log(res.data.message, res.data.data)
                    this.setState({ openBackdrop: false });
                }
            }, (e) => {
                console.log("Error : ", e);
                this.setState({ openBackdrop: false });
            })
    }

    handleSelectIcon = (i) => {
        this.setState({
            icon_type: i
        }, () => {
        })
    }

    handleClickAddress = id => {
        this.setState({ openBackdrop: true });
        if (this.props.editMode === true) {
            this.setState({
                open: true,
                selectedModal: id
            }, () => {
            })

            const address = this.state.addresses.find(x => x.id === id);

            // const address = this.state.addresses[i];
            this.setState({
                id: id,
                icon_type: address.icon_type,
                title: address.title,
                province_id: address.province_id,
                city_id: address.city_id,
                province_name: address.province_name,
                city_name: address.city_name,
                postal_code: address.postal_code,
                street_address: address.street_address,
                phone_no: address.phone_no,
                notes: address.notes,
            }, () => {
            })

            this.getCitiesByProvince(address.province_id)
        }
        else {
            this.setState({
                selectedAddress: id
            }, () => {
            })
            this.props.handleSelectAddress(this.state.addresses.find(x => x.id === id));
            this.setState({ openBackdrop: false });
        }

    };

    handleOpenEditModal = (id) => {
        this.setState({ openBackdrop: true });
        this.setState({
            open: true,
            selectedModal: id
        }, () => {
        })

        const address = this.state.addresses.find(x => x.id === id);
        this.getCitiesByProvince(address.province_id)
        // const address = this.state.addresses[i];
        this.setState({
            id: id,
            icon_type: address.icon_type,
            title: address.title,
            province_id: address.province_id,
            city_id: address.city_id,
            province_name: address.province_name,
            city_name: address.city_name,
            postal_code: address.postal_code,
            street_address: address.street_address,
            phone_no: address.phone_no,
            notes: address.notes,
        }, () => {
        })
    }

    handleOpenCreateModal = () => {
        this.setState({
            open: true,
            selectedModal: null,
            icon_type: '1'
        }, () => {
        })
    }

    handleClose = () => {
        this.setState({
            open: false,
            id: '',
            // city_id: "",
            // province_id: "",
            icon_type: '',
            title: '',
            postal_code: '',
            street_address: '',
            phone_no: '',
            notes: '',
            // selectedModal: null
        })
    };

    onChange = (e, newValue) => {
        // if (e.target.value.match(phoneRegex)) {
        //     this.setState({ errorText: '' })
        // } else {
        //     this.setState({ errorText: 'Invalid format: ###-###-####' })
        // }
        this.setState({ [e.target.name]: e.target.value });
    }

    onProvinceChange = (e, newValue) => {
        this.setState({ openBackdrop: true });
        if (newValue) {
            this.setState({
                province_id: newValue.value,
                province_name: newValue.label
            }, () => {
                this.getCitiesByProvince(newValue.value, 'onChange')
            }
            );

            // this.setState({
            //     city_disabled: false,
            // })

        }
        else {
            this.setState({ openBackdrop: false });
        }
        // getDistrictsByRegency/{province_id}
    }

    onCityChange = (e, newValue) => {
        if (newValue != null)
            this.setState({
                city_id: newValue.value,
                city_name: newValue.label
            }, () => {
            });
    }


    handleDeleteAddress = async () => {
        this.setState({ openBackdrop: true });
        const state = this.state;
        await axios.delete(`${baseUrl}/addresses/${this.state.id}`,
            state,
            requestConfig)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        // addresses: [...this.state.addresses, res.data.data]
                        addresses: [...this.state.addresses.filter(address => address.id !== this.state.id)]
                    }, () => {
                        this.handleClose();
                        this.setState({ openBackdrop: false });
                    })
                } else {
                    console.log(res.data.message, res.data.data)
                    this.setState({ openBackdrop: false });
                }
            }, (e) => {
                console.log("Error : ", e);
                this.setState({ openBackdrop: false });
            })

    }

    handleUpdateAddress = async () => {
        this.setState({ openBackdrop: true });

        let is_ok = true;
        for (let i = 0; i < field_names.length; i++) {
            let field_name = field_names[i];
            let field_name_text = field_names[i].replace('_', ' ');
            if (this.state[field_name] === "" || this.state[field_name] === null) {
                this.setState({ [field_name + "_error"]: field_name_text + " is required" });
                is_ok = false;
            }
        }

        if (is_ok) {
            const state = this.state;
            await axios.put(`${baseUrl}/addresses/${this.state.id}`,
                state,
                requestConfig)
                .then((res) => {
                    if (res.data.success) {
                        this.setState({
                            // addresses: [...this.state.addresses, res.data.data]
                            addresses: this.state.addresses.map(address => {
                                if (address.id === res.data.data.id) {
                                    address = res.data.data
                                }
                                return address;
                            })
                        }, () => {
                            this.handleClose();
                            if (this.props.editMode === false) {
                                this.props.handleSelectAddress(res.data.data)
                            }
                            this.setState({ openBackdrop: false });
                        })
                    } else {
                        console.log(res.data.message, res.data.data)
                        this.setState({ openBackdrop: false });
                    }
                }, (e) => {
                    console.log("Error : ", e);
                    this.setState({ openBackdrop: false });
                })

        }
        else
            this.setState({ openBackdrop: false });
    }

    handleStoreAddress = async () => {
        this.setState({ openBackdrop: true });

        let is_ok = true;
        for (let i = 0; i < field_names.length; i++) {
            let field_name = field_names[i];
            let field_name_text = field_names[i].replace('_', ' ');
            if (this.state[field_name] === "" || this.state[field_name] === null) {
                this.setState({ [field_name + "_error"]: field_name_text + " is required" });
                is_ok = false;
            }
        }

        if (is_ok) {
            const state = this.state;
            await axios.post(`${baseUrl}/addresses`,
                state,
                requestConfig)
                .then((res) => {
                    if (res.data.success) {
                        this.setState({
                            addresses: [...this.state.addresses, res.data.data]
                        }, () => {
                            this.handleClose();
                            this.setState({ openBackdrop: false });
                        })
                    } else {
                        console.log(res.data.message, res.data.data)
                        this.setState({ openBackdrop: false });
                    }
                }, (e) => {
                    console.log("Error : ", e);
                    this.setState({ openBackdrop: false });
                })

        }
        else
            this.setState({ openBackdrop: false });
    }

    renderAddresses = () => {
        const { classes } = this.props;
        // return this.state.posts.map((post, i) => {
        //     return (
        //         <div
        //             key={post.id}
        //             style={{ width: 400, height: 400, backgroundColor: "orange" }}
        //             onClick={() => this.onOpenModal(i)} // Pass the id of the clicked post
        //         >
        //             <h1>{post.title}</h1>
        //         </div>
        //     );
        // });

        return this.state.addresses.map((item, i) => {
            // alert(window.location.pathname.split("/")[2]);
            return (
                <Grid item lg={4} md={6} sm={6} xs={12} className={`${classes.linkPointer}`}
                    key={item.id} >
                    <div style={this.state.selectedAddress !== item.id ? borderStandart : borderBold} className={classes.boxOutline}>
                        <div onClick={() => this.handleClickAddress(item.id)} >
                            {this.props.editMode === false ?
                                <div className={classes.linkPointerWUnderline} style={{ float: 'right' }} onClick={() => this.handleOpenEditModal(item.id)}>edit</div>
                                : ''}

                            {
                                item.icon_type === '1' ? <img src={HomeIcon} alt="home icon"></img> :
                                    item.icon_type === '2' ? <img src={HomeIcon2} alt="home icon"></img> :
                                        item.icon_type === '3' ? <img src={OfficeIcon} alt="home icon"></img> :
                                            item.icon_type === '4' ? <img src={OfficeIcon2} alt="home icon"></img> : ''
                            }
                            <h5><b>{item.title}</b></h5>
                            <p><b>{dictionary["Address Details"][this.context.language]} :</b></p>
                            <p>{item.street_address}</p>
                            {this.props.editMode === true ?
                                <React.Fragment>
                                    <br />
                                    <p>{dictionary["Special Notes"][this.context.language]} :</p>
                                    <p>{item.notes}</p>
                                    <br />
                                    <br />
                                </React.Fragment>
                                :
                                ''
                            }
                        </div>
                        <div>
                            {this.props.editMode === true ?
                                item.is_default === 1 ?
                                    <b className={`${classes.defaultInfo}`}>{dictionary["Default Address"][this.context.language]}</b>
                                    :
                                    <button className={`${classes.linkGold} ${classes.linkStyledButton} ${classes.defaultInfo}`}
                                        onClick={this.handleSetDefault.bind(this, item.id)}>
                                        Set {dictionary["Default Address"][this.context.language]}</button>
                                :
                                ''
                            }
                        </div>
                    </div>
                </Grid>
            )
        })
    }

    renderModal = () => {
        const { classes } = this.props;
        // Check to see if there's a selected post. If so, render it.
        if (this.state.selectedModal !== null) {
            return (
                <div className={`${classes.modalStyle} ${classes.paper}`}>
                    <img className={classes.closeIcon} src={CloseIcon} alt="close icon" onClick={this.handleClose}></img>
                    <div style={{
                        textAlign: 'center'
                    }}>
                        <h2 className={classes.smallerTitle}>{this.state.title}</h2>
                    </div>
                    <Grid container className={classes.formContainer} spacing={3} style={{ marginTop: 25 }}>
                        <Grid item md={6} xs={12}>
                            <label>{dictionary['Choose Address Icon'][this.context.language]}</label><br />
                            <img className={this.state.icon_type === '1' ? classes.selectedIconType : classes.unselectedIconType} onClick={this.handleSelectIcon.bind(this, '1')} src={HomeIcon} alt="home icon"></img>
                            <img className={this.state.icon_type === '2' ? classes.selectedIconType : classes.unselectedIconType} onClick={this.handleSelectIcon.bind(this, '2')} src={HomeIcon2} alt="home icon"></img>
                            <img className={this.state.icon_type === '3' ? classes.selectedIconType : classes.unselectedIconType} onClick={this.handleSelectIcon.bind(this, '3')} src={OfficeIcon} alt="home icon"></img>
                            <img className={this.state.icon_type === '4' ? classes.selectedIconType : classes.unselectedIconType} onClick={this.handleSelectIcon.bind(this, '4')} src={OfficeIcon2} alt="home icon"></img>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextfieldRaw onChange={this.onChange}
                                error={this.state.title_error !== ""}
                                helperText={this.state.title_error}
                                value={this.state.title}
                                name="title" type="text"
                                label={dictionary['Name this Address'][this.context.language]}
                                className={classes.textField} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                classes={{ root: classes.autoCompleteRoot, listbox: classes.autoCompletePaper, paper: classes.autoCompletePaper, option: classes.autoCompleteOption }}
                                value={this.state.listProvinces[this.state.listProvinces.findIndex(city => city.value.toString() === this.state.province_id.toString())]}
                                name="province_id"
                                onChange={this.onProvinceChange}
                                options={this.state.listProvinces}
                                getOptionLabel={(option) => option.label}
                                // getOptionLabel={(option) => option.title}
                                renderInput={(params) => <CustomTextfieldRaw {...params}
                                    label={dictionary['Province'][this.context.language]} />}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                classes={{ root: classes.autoCompleteRoot, listbox: classes.autoCompletePaper, paper: classes.autoCompletePaper, option: classes.autoCompleteOption }}

                                value={this.state.listCities[this.state.listCities.findIndex(district => district.value.toString() === this.state.city_id.toString())] || this.state.listCities[0]}
                                disabled={this.state.city_disabled}
                                name="city_id"
                                onChange={this.onCityChange}
                                options={this.state.listCities}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <CustomTextfieldRaw {...params} label={dictionary['City'][this.context.language]} />}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextfieldRaw
                                error={this.state.postal_code_error !== ""}
                                helperText={this.state.postal_code_error}
                                value={this.state.postal_code}
                                name="postal_code" type="text" label={dictionary['Zip Code (Wajib Harus Sesuai)'][this.context.language]} className={classes.textField} onChange={this.onChange} />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <CustomTextfieldRaw
                                error={this.state.street_address_error !== ""}
                                helperText={this.state.street_address_error}
                                value={this.state.street_address}
                                name="street_address" type="text" label={dictionary['Address Details'][this.context.language]} className={classes.textField} onChange={this.onChange} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextfieldRaw
                                error={this.state.phone_no_error !== ""}
                                helperText={this.state.phone_no_error}
                                value={this.state.phone_no}
                                name="phone_no" type="text" label={dictionary['Number to Contact'][this.context.language]} className={classes.textField} onChange={this.onChange} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextfieldRaw value={this.state.notes || ''} name="notes" type="text" label={dictionary["Special Notes"][this.context.language]} className={classes.textField} onChange={this.onChange} />
                        </Grid>

                        <Grid item xs={12} md={12} style={{ marginTop: "20px", textAlign: 'center' }}>
                            <Button variant="outlined" className={classes.buttonLight} onClick={this.handleDeleteAddress} style={{ marginRight: 15 }}>{dictionary["Delete Address"][this.context.language]}</Button>
                            <Button type="submit" variant="outlined" className={classes.buttonDark} onClick={this.handleUpdateAddress}>{dictionary["Save Address"][this.context.language]}</Button>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        else {
            return (
                <div className={`${classes.modalStyle} ${classes.paper}`}>
                    <img className={classes.closeIcon} src={CloseIcon} alt="close icon" onClick={this.handleClose}></img>
                    <div style={{
                        textAlign: 'center'
                    }}>
                        <h2 className={classes.smallerTitle}>
                            {dictionary["Let's Create New Address"][this.context.language]}
                        </h2>
                    </div>
                    <Grid container className={classes.formContainer} spacing={3} style={{ marginTop: 25 }}>
                        <Grid item md={6} xs={12}>
                            <label>{dictionary['Choose Address Icon'][this.context.language]}</label><br />
                            <img className={this.state.icon_type === '1' ? classes.selectedIconType : classes.unselectedIconType} onClick={this.handleSelectIcon.bind(this, '1')} src={HomeIcon} alt="home icon"></img>
                            <img className={this.state.icon_type === '2' ? classes.selectedIconType : classes.unselectedIconType} onClick={this.handleSelectIcon.bind(this, '2')} src={HomeIcon2} alt="home icon"></img>
                            <img className={this.state.icon_type === '3' ? classes.selectedIconType : classes.unselectedIconType} onClick={this.handleSelectIcon.bind(this, '3')} src={OfficeIcon} alt="home icon"></img>
                            <img className={this.state.icon_type === '4' ? classes.selectedIconType : classes.unselectedIconType} onClick={this.handleSelectIcon.bind(this, '4')} src={OfficeIcon2} alt="home icon"></img>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextfieldRaw onChange={this.onChange}
                                error={this.state.title_error !== ""}
                                helperText={this.state.title_error}
                                value={this.state.title}
                                name="title" type="text"
                                label={dictionary["Name this Address"][this.context.language]}
                                className={classes.textField} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                classes={{ root: classes.autoCompleteRoot, listbox: classes.autoCompletePaper, paper: classes.autoCompletePaper, option: classes.autoCompleteOption }}

                                // value={this.state.listProvinces[this.state.listProvinces.findIndex(city => city.value == this.state.province_id)]}
                                name="province_id"
                                onChange={this.onProvinceChange}
                                options={this.state.listProvinces}
                                getOptionLabel={(option) => option.label}
                                // getOptionLabel={(option) => option.title}
                                renderInput={(params) => <CustomTextfieldRaw {...params}
                                    error={this.state.province_id_error !== ""}
                                    helperText={this.state.province_id_error}
                                    label={dictionary["Province"][this.context.language]} />}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                classes={{ root: classes.autoCompleteRoot, listbox: classes.autoCompletePaper, paper: classes.autoCompletePaper, option: classes.autoCompleteOption }}

                                // value={this.state.listCities[0]}
                                disabled={this.state.city_disabled}
                                name="city_id"
                                onChange={this.onCityChange}
                                options={this.state.listCities}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => <CustomTextfieldRaw {...params}
                                    error={this.state.city_id_error !== ""}
                                    helperText={this.state.city_id_error}
                                    label={dictionary["City"][this.context.language]} />}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextfieldRaw
                                error={this.state.postal_code_error !== ""}
                                helperText={this.state.postal_code_error}
                                value={this.state.postal_code}
                                name="postal_code" type="text" label={dictionary["Zip Code (Wajib Harus Sesuai)"][this.context.language]} className={classes.textField} onChange={this.onChange} />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <CustomTextfieldRaw
                                error={this.state.street_address_error !== ""}
                                helperText={this.state.street_address_error}
                                value={this.state.street_address}
                                name="street_address" type="text" label={dictionary["Address Details"][this.context.language]} className={classes.textField} onChange={this.onChange} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextfieldRaw
                                error={this.state.phone_no_error !== ""}
                                helperText={this.state.phone_no_error}
                                value={this.state.phone_no}
                                name="phone_no" type="text" label={dictionary["Number to Contact"][this.context.language]} className={classes.textField} onChange={this.onChange} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomTextfieldRaw
                                // error={this.state.notes_error !== ""}
                                // helperText={this.state.notes_error}
                                value={this.state.notes || ''}
                                name="notes" type="text" label={dictionary["Special Notes"][this.context.language]} className={classes.textField} onChange={this.onChange} />
                        </Grid>

                        <Grid item xs={12} md={12} style={{ marginTop: "20px", textAlign: 'center' }}>
                            <Button type="submit" variant="outlined" className={classes.buttonDark} onClick={this.handleStoreAddress}>{dictionary["Save Address"][this.context.language]}</Button>
                        </Grid>
                    </Grid>
                </div >
            );
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{ 'color': '#AF986D' }} />
                </Backdrop>
                {this.renderAddresses()}
                {this.state.addresses.length >= 5 ?
                    null
                    :
                    <Grid item lg={4} md={6} xs={12} className={`${classes.linkPointer}`} onClick={this.handleOpenCreateModal}>
                        <Grid container
                            justify="center"
                            alignItems="center"
                            direction="column" style={borderDotted}
                            className={classes.addNewContainer}>
                            <img src={AddIcon} alt="home icon"></img>
                            <h5 style={{ color: 'lightgrey', marginTop: '10px', width: '100%', textAlign: 'center' }}>
                                {dictionary["Add New Address"][this.context.language]}</h5>
                        </Grid>
                    </Grid>

                }

                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    closeAfterTransition
                >
                    <Fade in={this.state.open}>
                        {/* {body} */}
                        <div>{this.renderModal()}</div>
                    </Fade>
                </Modal>
            </React.Fragment >
        )
    }
}

export default withStyles(useStyles)(AddressCard) 
