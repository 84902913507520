import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Banner from "assets/img/havilla/about_us_image_detail.jpg";
import axios from 'axios';
import Fade from '@material-ui/core/Fade';

import OwnerNeysa from "assets/img/havilla/about_us_owner_neysa.jpg";
import OwnerAresh from "assets/img/havilla/about_us_owner_aresh.jpg";
import AboutUsMore from 'client-side/views/AboutUs/AboutUsMore';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";

import {
    baseUrl,
    requestConfig
} from '../../../Environment.js';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { ClientSideContext } from '../../ClientSideContext';
import { dictionary } from '../../../Dictionary';


const useStyles = theme => ({
    ...styles(theme),
    containerLogin: {
    },
    banner: {
        width: '100%'
    },
    textField: {
        width: "80%",
    },
    menuIcon: {
        margin: 'auto',
        display: 'block'
    },
    questionBox: {
        border: '1px solid grey',
        padding: 25,
        margin: '45px 10px 25px 10px',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0
        }
    },
    moreContainer: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: 10
        },
        marginTop: 50
    },
    containerPadding: {
        // [theme.breakpoints.up('sm')]: {
        //     padding: '0 100px'
        // },
        textAlign: 'justify',
        marginTop: 35,
        fontSize: '16px',
        lineHeight: '25px',
        color: 'white',
        '& p': {
            color: 'white',
        },
        '& span': {
            color: 'white',
        }
    }
});

class AboutUs extends Component {
    static contextType = ClientSideContext;

    constructor(props) {
        super(props);

        this.state = {
            openBackdrop: false,

            email: '',
            password: '',
            cms: {},
            load_cms: false
        }
    }

    componentDidMount() {
        this.getCmsContent()
    }

    componentDidUpdate() {
    }

    getCmsContent = () => {
        this.setState({ openBackdrop: true });

        axios.get(`${baseUrl}/cms`,
            requestConfig
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState({
                    cms: data,
                    load_cms: true
                })
                this.setState({ openBackdrop: false });
            } else {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${res.data.message}`
                })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            console.log("Error : ", e);
        })
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value })
    render() {
        const { classes } = this.props;
        return (
            <Container maxWidth="xl" className={`${classes.container}`}>
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{ 'color': '#AF986D' }} />
                </Backdrop>
                <Fade in={this.state.load_cms} timeout={700}>
                    <Container maxWidth="md">
                        <Grid container spacing={4}>
                            <Grid item md={12} xs={12}>
                                <p style={{ textAlign: 'center', marginTop: 25 }}>
                                    {this.state.cms.about_us_detail_subheading ?
                                        this.context.language === 'id' ?
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_subheading.content_id }}>
                                            </span>
                                            :
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_subheading.content_eng }}>
                                            </span>
                                        :
                                        null
                                    }
                                </p>
                                <h3 className={classes.smallerTitle} style={{ textAlign: 'center' }}>
                                    {this.state.cms.about_us_detail_heading_1 ?
                                        this.context.language === 'id' ?
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_heading_1.content_id }}>
                                            </span>
                                            :
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_heading_1.content_eng }}>
                                            </span>
                                        :
                                        null
                                    }
                                </h3>
                                <p className={classes.containerPadding}>
                                    {this.state.cms.about_us_detail_paragraph_1 ?
                                        this.context.language === 'id' ?
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_paragraph_1.content_id }}>
                                            </span>
                                            :
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_paragraph_1.content_eng }}>
                                            </span>
                                        :
                                        null
                                    }
                                </p>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <img className={classes.banner} src={this.state.cms.about_us_detail_photo_1 ? JSON.parse(this.state.cms.about_us_detail_photo_1.photo_urls)[0] : ''} alt="banner"></img>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <h3 className={classes.smallerTitle} style={{ textAlign: 'center' }}>
                                    {this.state.cms.about_us_detail_heading_2 ?
                                        this.context.language === 'id' ?
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_heading_2.content_id }}>
                                            </span>
                                            :
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_heading_2.content_eng }}>
                                            </span>
                                        :
                                        null
                                    }
                                </h3>
                                <p className={classes.containerPadding}>
                                    {this.state.cms.about_us_detail_paragraph_2 ?
                                        this.context.language === 'id' ?
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_paragraph_2.content_id }}>
                                            </span>
                                            :
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_paragraph_2.content_eng }}>
                                            </span>
                                        :
                                        null
                                    }
                                </p>
                            </Grid>
                        </Grid>

                        <Grid container spacing={4} style={{ marginTop: 35 }}>
                            <Grid item md={5} xs={12}>
                                <img className={classes.banner} src={this.state.cms.about_us_detail_photo_2 ? JSON.parse(this.state.cms.about_us_detail_photo_2.photo_urls)[0] : ''} alt="banner"></img>
                            </Grid>
                            <Grid item md={7} xs={12}>
                                <h5 className={classes.smallTitle} style={{ fontSize: '29px' }}>
                                    {this.state.cms.about_us_detail_heading_3 ?
                                        this.context.language === 'id' ?
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_heading_3.content_id }}>
                                            </span>
                                            :
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_heading_3.content_eng }}>
                                            </span>
                                        :
                                        null
                                    }
                                </h5>
                                <p style={{ fontSize: '16px', lineHeight: '25px', textAlign: 'justify' }}>
                                    {this.state.cms.about_us_detail_paragraph_3 ?
                                        this.context.language === 'id' ?
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_paragraph_3.content_id }}>
                                            </span>
                                            :
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_paragraph_3.content_eng }}>
                                            </span>
                                        :
                                        null
                                    }
                                </p>
                            </Grid>
                        </Grid>

                        <Grid container spacing={4} style={{ marginTop: 35 }}>
                            <Grid item md={7} xs={12}>
                                <h5 className={classes.smallTitle} style={{ fontSize: '29px' }}>
                                    {this.state.cms.about_us_detail_heading_4 ?
                                        this.context.language === 'id' ?
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_heading_4.content_id }}>
                                            </span>
                                            :
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_heading_4.content_eng }}>
                                            </span>
                                        :
                                        null
                                    }
                                </h5>
                                <p style={{ fontSize: '16px', lineHeight: '25px', textAlign: 'justify' }}>
                                    {this.state.cms.about_us_detail_paragraph_4 ?
                                        this.context.language === 'id' ?
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_paragraph_4.content_id }}>
                                            </span>
                                            :
                                            <span
                                                dangerouslySetInnerHTML={{ __html: this.state.cms.about_us_detail_paragraph_4.content_eng }}>
                                            </span>
                                        :
                                        null
                                    }
                                </p>
                            </Grid>
                            <Grid item md={5} xs={12}>
                                <img className={classes.banner} src={this.state.cms.about_us_detail_photo_3 ? JSON.parse(this.state.cms.about_us_detail_photo_3.photo_urls)[0] : ''} alt="banner"></img>
                            </Grid>
                        </Grid>
                    </Container>
                </Fade>

                <Container maxWidth="lg">
                    <AboutUsMore>
                    </AboutUsMore>
                </Container>
            </Container>
        )
    }
}

export default withStyles(useStyles)(AboutUs) 
