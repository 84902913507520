export const prefix = "";
export const baseUrl = 'https://havillatea.com/production/api/public/api'
// export const baseUrl = "http://127.0.0.1:8000/api";
export const baseUrlNonApi = 'https://havillatea.com/production/api/public'
// export const baseUrlNonApi = "http://127.0.0.1:8000";
export let requestConfig = {
	headers: {
		Accept: "application/json",
		Authorization:
			"Bearer " +
			(localStorage.getItem("auth")
				? JSON.parse(localStorage.getItem("auth")).access_token
				: null),
	},
};
export let requestConfigAdmin = {
	headers: {
		Accept: "application/json",
		Authorization:
			"Bearer " +
			(localStorage.getItem("auth_admin")
				? JSON.parse(localStorage.getItem("auth_admin")).access_token
				: null),
	},
};

export const refreshHeader = () => {
	requestConfig = {
		headers: {
			Accept: "application/json",
			Authorization:
				"Bearer " +
				(localStorage.getItem("auth")
					? JSON.parse(localStorage.getItem("auth")).access_token
					: null),
		},
	};
};

export const refreshHeaderAdmin = () => {
	requestConfig = {
		headers: {
			Accept: "application/json",
			Authorization:
				"Bearer " +
				(localStorage.getItem("auth_admin")
					? JSON.parse(localStorage.getItem("auth_admin"))
							.access_token
					: null),
		},
	};
};
