import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import Eye from "client-side/components/PasswordField/Eye.png";
import EyeClose from "client-side/components/PasswordField/Eye Close.png";

import { withStyles } from '@material-ui/core/styles';

const passwordContainer = {
    position: "relative"
}
const eyeIcon = {
    position: "absolute",
    top: "20px",
    right: "12px"
}
const Custom = withStyles({
    root: {
        width: "80%",
        '& label': {
            color: 'white',
        },
        '& label.Mui-disabled': {
            color: 'grey',
        },
        '& .MuiInput-root': {
            color: '#AF986D',
            '&:hover fieldset': {
                borderBottomColor: 'red',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#cfcfcf',
        },
        '& .MuiInput-root:hover': {
            '.MuiInput-underline:before': {
                borderBottomColor: '#cfcfcf',
            },
        },
        '& .MuiInput-underline-hover:before': {
            borderBottomColor: 'white',
        },
        '& label.Mui-focused': {
            color: '#AF986D',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#AF986D',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);

class PasswordField extends Component {
    state = {
        type: 'password',
    }

    handleClick = () => this.setState(({ type }) => ({
        type: type === 'text' ? 'password' : 'text'
    }))

    render() {
        return (
            <label style={passwordContainer}>
                <Custom 
                    error={this.props.error}
                    helperText={this.props.helperText}
                    onChange={this.props.onChange} 
                    name={this.props.name} 
                    label={this.props.label}
                    type={this.state.type} 
                    disabled={this.props.disabled} 
                    value={this.props.value} 
                />
                {!this.props.disabled && !this.props.hideIcon ? <img src={this.state.type === 'text' ? Eye : EyeClose} onClick={this.handleClick} style={eyeIcon} alt="eye icon"></img>
                    : ""}
                {/* <span className="password__show" onClick={this.handleClick}>{this.state.type === 'text' ? 'Hide' : 'Show'}</span> */}
            </label>
        )
    }
}

export default(PasswordField) 