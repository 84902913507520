import React, { useContext, useEffect, useState } from 'react'
import Snackbar from "admin/components/Snackbar/Snackbar.js";
import { AdminContext } from 'admin/AdminContext';

function SnackbarComp() {
    const {dispatchToggleSnackbar} = useContext(AdminContext)
    const { toggleSnackbar, snackbarContent } = useContext(AdminContext)
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        if(toggleSnackbar){
            setTimer(setTimeout(function() {
                dispatchToggleSnackbar({type:'TOGGLE_STATE', value: false})
            }, 5000));        
        }
    }, [toggleSnackbar,dispatchToggleSnackbar])

    const handleClose = () =>{
        clearTimeout(timer);
        dispatchToggleSnackbar({type:'TOGGLE_STATE', value: false})
    }

    return (
        <Snackbar
            place="tr"
            color={snackbarContent.color ? snackbarContent.color : "info"}
            message={snackbarContent.message ? snackbarContent.message : ""}
            open={toggleSnackbar}
            closeNotification={handleClose}
            close
        />
    )
}

export default SnackbarComp
