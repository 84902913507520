import React, { Component } from 'react'
import { Container, withStyles, Grid, Backdrop, CircularProgress, Button } from '@material-ui/core'
import styles from "assets/jss/client-side/App.js";
import axios from 'axios';
import {
    baseUrl,
    requestConfig,
    prefix
} from '../../../Environment.js';
import { dictionary } from '../../../Dictionary';
import { ClientSideContext } from '../../ClientSideContext';
import LeftArrow from "assets/img/havilla/left-arrow-icon.svg";
import { Link } from 'react-router-dom';

import TwitterIcon from "assets/img/havilla/twitter.svg";
import WhatsappIcon from "assets/img/havilla/whatsapp.svg";
import FacebookIcon from "assets/img/havilla/facebook.svg";
import CopyPasteIcon from "assets/img/havilla/copy_paste.svg";
import PostLargeGeneral from 'client-side/components/Post/PostLargeGeneral.js';
import Fade from '@material-ui/core/Fade';

import { Helmet } from "react-helmet";
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
} from "react-share";

import ImgContent1 from "assets/img/havilla/content_tea_shop_1.png";
import ImgContent2 from "assets/img/havilla/content_tea_shop_2.png";
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp.js';
const useStyles = theme => ({
    ...styles(theme),
    img: {
        width: '100%',
    },
    articleContainer: {
        padding: '0px'
    },
    thumbnailContainer: {
        maxHeight: '26rem',
        overflow: 'hidden'
    },
    contentContainer: {
        marginTop: '32px'
    },
    backText: {
        fontSize: '12px !important',
        marginLeft: '8px'
    },
    articleTitle: {
        fontFamily: 'Martel Sans',
        fontSize: '40px',
        fontWeight: 'bold',
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '32px',
        },

    },
    articleWrittenBy: {
        fontFamily: 'Martel Sans',
        fontSize: '12px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'left',
        },
    },
    icon: {
        width: "20px",
        height: '20px',
        marginRight: '18px'
    },
    articleContent: {
        marginTop: '20px',
        '& p': {
            color:'white',
            // fontSize: '20px'
            lineHeight: '30px'
        },
        '& img': {
            [theme.breakpoints.down('lg')]: {
                width: '100%',
                height: 'auto'
            }
        }
    },
    relatedArticleContainer: {
        marginTop: '12px',
    },
    backContainer: {
        justifyContent: 'left',
    },
    shareIconContainer: {
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
            marginTop: '19px'
        },
    }
});

export class TeaTalksDetail extends Component {
    static contextType = ClientSideContext;
    constructor(props) {
        super(props)

        this.state = {
            openBackdrop: false,
            language: 'en',

            article: null,
            relatedArticles: [],

            snackbarType: 'info',
            snackbarMessage: '',
            snackbarCounter: 0,
            loadArticle: false
        }
    }

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    componentDidUpdate() {
        // if (this.context.language !== this.context.language) {
        //     this.setState({
        //         language: this.context.language
        //     })
        // }
    }

    componentDidMount() {
        this.getArticle();
        this.setState({
            language: this.context.language
        })
    }

    getArticle = () => {
        const slug = this.props.location.pathname.split("/").pop();
        if (slug) {
            this.setState({ openBackdrop: true });
            axios.get(`${baseUrl}/articles/${slug}`,
                requestConfig
            ).then((res) => {
                if (res.data.success) {
                    let data = res.data.data;
                    this.setState({
                        article: data,
                        loadArticle: true
                    }, () => {
                        this.getRelatedArticle();
                    })
                    this.setState({ openBackdrop: false });
                } else {
                    this.setState({ openBackdrop: false });
                    this.handleOpenSnackbar({
                        type: "error",
                        message: `${res.data.message}`
                    })
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${e.message}`
                })
                console.log("Error : ", e);
            })
        }
    }

    getRelatedArticle = () => {
        this.setState({ openBackdrop: true });
        let data = {
            article_id: this.state.article.id,
            limit: 2
        }
        axios.post(`${baseUrl}/articles/get_related_article`,
            data,
            requestConfig
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState({
                    relatedArticles: data,
                })
                this.setState({ openBackdrop: false });
            } else {
                this.setState({ openBackdrop: false });
                this.handleOpenSnackbar({
                    type: "error",
                    message: `${res.data.message}`
                })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            this.handleOpenSnackbar({
                type: "error",
                message: `${e.message}`
            })
            console.log("Error : ", e);
        })
    }


    render() {
        const { classes } = this.props

        return (
            <Container maxWidth="xl" className={`${classes.container} ${classes.articleContainer}`}>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.counter}
                />
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{ 'color': '#AF986D' }} />
                </Backdrop>
                {
                    this.state.article !== null ?
                        <React.Fragment>
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>{this.state.article.title}</title>
                            </Helmet>
                            <Fade in={this.state.loadArticle} timeout={700}>
                                <div className={classes.thumbnailContainer}>
                                    <img className={classes.img} src={this.state.article.header_image_url} alt={`article header ${this.state.article.url_slug}`} />
                                </div>
                            </Fade>
                            <Fade in={this.state.loadArticle} timeout={700}>
                                <Grid container justify='center' className={classes.contentContainer} spacing={3}>
                                    <Grid item xs={10} md={7} container alignItems="center" className={classes.backContainer}>
                                        {
                                            this.state.article.category_id === 1 ?
                                                <Link to={`${prefix}/about_us/social_acts`} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={LeftArrow} alt={`left arrow icon`} />
                                                    <Grid item xs={12} className={`${classes.link} ${classes.backText}`}>
                                                        Back to all social acts article
                                                    </Grid>
                                                </Link>
                                                :
                                                <Link to={`${prefix}/tea_talks`} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={LeftArrow} alt={`left arrow icon`} />
                                                    <Grid item xs={12} className={`${classes.link} ${classes.backText}`}>
                                                        Back to all articles
                                                    </Grid>
                                                </Link>
                                        }
                                    </Grid>

                                    <Grid item xs={10} md={7} className={classes.articleTitle}>
                                        {this.state.article.title}
                                    </Grid>

                                    <Grid item xs={10} md={7} container alignItems="center" className={classes.articleWrittenShare}>
                                        <Grid item xs={12} md={9} className={classes.articleWrittenBy}>
                                            Written By : {this.state.article.written_by}
                                        </Grid>
                                        <Grid item xs={12} md={3} container alignItems="center" className={classes.shareIconContainer}>
                                            <TwitterShareButton
                                                url={window.location.href}
                                                title={this.state.article.title}
                                                className="Demo__some-network__share-button">
                                                <img className={classes.icon} src={TwitterIcon} alt="instagram icon"></img>
                                            </TwitterShareButton>
                                            <FacebookShareButton
                                                url={window.location.href}
                                                title={this.state.article.title}
                                                className="Demo__some-network__share-button">
                                                <img className={classes.icon} src={FacebookIcon} alt="facebook icon"></img>
                                            </FacebookShareButton>
                                            <WhatsappShareButton
                                                url={window.location.href}
                                                title={this.state.article.title}
                                                className="Demo__some-network__share-button">
                                                <img className={classes.icon} src={WhatsappIcon} alt="whatsapp icon"></img>
                                            </WhatsappShareButton>
                                            <img className={`${classes.icon} ${classes.linkPointer}`} src={CopyPasteIcon} alt="line icon"
                                                onClick={() => { navigator.clipboard.writeText(window.location.href) }}
                                                style={{ marginTop: -8 }}>
                                            </img>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={10} md={7} className={classes.articleContent}
                                        dangerouslySetInnerHTML={{ __html: this.state.article.content }}
                                    >
                                    </Grid>

                                    <Grid item xs={11} md={11} className={classes.sectionTitle} style={{ paddingLeft: '20px' }}>
                                        Related Articles
                                    </Grid>
                                    <Grid container item xs={10} md={11} spacing={3} className={classes.relatedArticleContainer}>
                                        {
                                            this.state.relatedArticles.length > 0 ?
                                                this.state.relatedArticles.map(item => {
                                                    return (
                                                        <PostLargeGeneral
                                                            key={item.id}
                                                            xs={12}
                                                            md={6}
                                                            img={item.thumbnail_url}
                                                            title={item.title}
                                                            desc={item.caption}
                                                            imgAlt={`article thumbnail ${item.url_slug}`}
                                                            linkText={this.state.article.category_id === 1 ? 'See the stories' : 'Read Now'}
                                                            linkTo={`${prefix}/tea_talks/${item.url_slug}`}
                                                        />
                                                    )
                                                })
                                                :
                                                <Grid item xs={12} className={classes.noResult}>No related articles at the moment...</Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Fade>
                        </React.Fragment>
                        :
                        <Grid container>
                            <Grid style={{ marginTop: '5rem' }} item xs={12} className={classes.noResult}>Loading...</Grid>
                        </Grid>

                }
            </Container>
        )
    }
}

export default withStyles(useStyles)(TeaTalksDetail)