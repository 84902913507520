import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import { Link } from 'react-router-dom';
import axios from 'axios'
import { baseUrl, requestConfigAdmin } from 'Environment'
import Suddhabumi from "assets/img/havilla/Suddhabumi.png";
import { Grid, withStyles, TextField, MenuItem } from '@material-ui/core';
import styles from "assets/jss/client-side/App.js";
import ButtonAdmin from "admin/components/CustomButtons/Button.js";
import { AdminContext } from '../../AdminContext';
import Confirmation from 'admin/components/CustomDialogs/Confirmation';
import IOSSwitch from 'admin/components/Switch/IOSSwitch';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    prefix
} from '../../../Environment.js';

const CustomTextfieldRaw = withStyles({
    root: {
        width: '100%',
        '& label.Mui-focused': {
            color: 'black',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);
const useStyles = theme => ({
    ...styles(theme),
    productImage: {
        width: '90%'
    },
    productName: {
        fontSize: '20px',
        marginTop: 10,
        marginBottom: 5,
        fontWeight: 'bold'
    },
    productSku: {
        fontSize: '12px',
    },
    productStatistic: {
        fontSize: '14px',
    },
    productSubCategory: {
        fontSize: '0.7rem',
        border: '1px solid grey',
        borderRadius: '16px',
        padding: '2px 10px 0',
        textTransform: 'uppercase',
        width: 'auto',
    },
});

export class Product extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props)

        this.state = {
            categories: [],
            category_id: 'all',
            sub_categories: [],
            sub_category_id: 'all',
            products: [],

            openDialog: false,
            tempDeleted: null,
        }
    }

    componentDidMount = () => {
        this.getCategories();
    }

    onChangeCategory = (e) => {
        let category_id = e.target.value
        this.setState({
            category_id: category_id,
            sub_category_id: 'all',
            sub_categories: []
        }, () => {
            this.getProducts();
        })
        if (category_id !== 'all') {
            this.getSubCategories(category_id)
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.getProducts();
        })
    };

    getCategories = () => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        axios.get(`${baseUrl}/productcategory`,
            requestConfigAdmin
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState({
                    categories: data,
                }, () => {
                    this.getProducts();
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE", value: true })
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE", value: true })
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    getSubCategories = (category_id) => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        axios.get(`${baseUrl}/productcategory/get_sub_category/${category_id}`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    sub_categories: res.data.data
                }, () => {
                    this.getProducts();
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE", value: true })
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE", value: true })
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    getProducts = () => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        let data = {
            category_id: this.state.category_id,
            sub_category_id: this.state.sub_category_id
        }
        axios.post(`${baseUrl}/product_view`, data, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    products: res.data.data
                }, () => {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE", value: true })
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE", value: true })
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    handleActiveToggle = (e) => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        let id = (e.target.name).split('-')[1];
        let data = {
            id: id,
        }
        axios.post(`${baseUrl}/products/toggle_active`,
            data,
            requestConfigAdmin
        ).then((res) => {
            if (res.data.success) {
                this.getProducts();
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "success",
                    message: "Update Product Success!"
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE", value: true })
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            } else {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE", value: true })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE", value: true })
            console.log("Error : ", e);
        })
    }

    handleDeleteClick = (data) => {
        this.setState({
            tempDeleted: data,
            openDialog: true,
        })
    }

    handleCloseDialog = (action) => {
        if (action) {
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
            axios.delete(`${baseUrl}/products/${this.state.tempDeleted.id}`,
                requestConfigAdmin
            ).then((res) => {
                if (res.data.success) {
                    this.getProducts();
                    this.setState({
                        tempDeleted: null,
                        openDialog: false,
                    }, () => {
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                        this.context.dispatchSnackbarContent({
                            type: "SET_SNACKBAR_CONTENT",
                            color: "success",
                            message: "Product Deleted"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE", value: true })
                    })
                } else {
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({
                        type: "SET_SNACKBAR_CONTENT",
                        color: "danger",
                        message: res.data.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE", value: true })
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: e.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE", value: true })
                console.log("Error : ", e);
            })
        } else {
            this.setState({
                tempDeleted: null,
                openDialog: false,
            })
        }
    }

    render() {
        const { classes } = this.props;
        const columns = [
            {
                hidden: true,
                searchable: false,
                title: 'created_at',
                field: 'created_at',
                defaultSort: 'desc',
            },
            {
                hidden: true,
                searchable: true,
                title: 'sku',
                field: 'sku',
            },
            {
                hidden: true,
                searchable: true,
                title: 'Category',
                field: 'product_category.name',
            },
            {
                title: 'Product Info',
                field: 'name',
                render: (rowData) => {
                    return (
                        <Grid container>
                            <Grid item xs={3}>
                                <Link to={prefix + `/product/${rowData.id}`}>
                                    {
                                        rowData.thumbnail_url !== null ?
                                            <img src={rowData.thumbnail_url}
                                                className={classes.productImage}
                                                // style={{ maxWidth: '364', maxHeight: '364px' }} 
                                                alt='product img'></img>
                                            :
                                            // INSERT DEFAULT NO PHOTO HERE
                                            <img className={classes.productImage} src={Suddhabumi}></img>
                                    }
                                </Link>
                                {/* <img className={classes.productImage} src={Suddhabumi} alt={`${rowData.id} img`}></img> */}
                            </Grid>
                            <Grid item xs={9} style={{ paddingTop: '4%' }}>
                                <span className={classes.productSubCategory}>{rowData.product_category.name}</span><br />
                                <p className={classes.productName}>{rowData.name}</p>
                                <p className={classes.productSku}>SKU : {rowData.sku}</p>
                            </Grid>
                        </Grid>
                    )
                }
            },
            {
                title: 'Statistic',
                render: (rowData) => {
                    return (
                        <Grid
                            container
                            justify="center"
                        >
                            <Grid item container xs={12} alignItems='center' justify="center">
                                <Grid item xs={12} className={classes.productStatistic}>
                                    Orders : {rowData.sum_order ? rowData.sum_order : '-'}
                                </Grid>
                                <Grid item xs={12} className={classes.productStatistic}>
                                    Wishlists : {rowData.wishlist.length}
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            },
            {
                title: 'Active',
                render: (rowData) => {
                    return (
                        <Grid container>
                            <Grid item xs={3}>
                                <IOSSwitch checked={rowData.is_active == 1 ? true : false} onChange={this.handleActiveToggle} name={`is_active-${rowData.id}`} />
                            </Grid>
                            <Grid item xs={9}>
                                {/* <ButtonAdmin color="info"
                                    onClick={
                                        (e) => this.props.history.push(prefix + '/admin/products/' + rowData.id)
                                    }
                                > */}
                                <ButtonAdmin color="info"
                                    onClick={
                                        (e) => window.open(prefix + '/admin/products/' + rowData.id, '_blank')
                                    }
                                >
                                    EDIT
                                </ButtonAdmin>
                                <ButtonAdmin color="danger" onClick={() => this.handleDeleteClick(rowData)}>DELETE</ButtonAdmin>
                            </Grid>
                        </Grid>
                    )
                }
            },
        ]
        return (
            <div style={{ maxWidth: '100%' }}>
                <Confirmation
                    openDialog={this.state.openDialog}
                    message={`You are about to delete product <b>${this.state.tempDeleted ? this.state.tempDeleted.name : ""}</b>, 
                    are you sure you want to delete it?`}
                    handleCloseDialog={this.handleCloseDialog}
                />
                <MaterialTable
                    columns={columns}
                    data={this.state.products}
                    title={<ButtonAdmin color="info" onClick={() => this.props.history.push(prefix + '/admin/products/add')}>ADD NEW PRODUCT</ButtonAdmin>}
                    options={{
                        search: true,
                        exportButton: false,
                        pageSize: 10
                    }}
                    components={{
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                                <Grid style={{ paddingLeft: '18px' }} container justify="flex-start" spacing={3}>
                                    {
                                        this.state.categories.length > 0 ?
                                            <Grid item xs={12} md={3}>
                                                <CustomTextfieldRaw
                                                    select
                                                    label="Category"
                                                    name="category_id"
                                                    value={this.state.category_id}
                                                    helperText="Filter by Category"
                                                    onChange={this.onChangeCategory}
                                                >
                                                    <MenuItem value={"all"}>All</MenuItem>
                                                    {
                                                        this.state.categories.map((category, i) => {
                                                            return <MenuItem value={category.id} key={category.id}>{category.name}</MenuItem>
                                                        })
                                                    }
                                                </CustomTextfieldRaw>
                                            </Grid>
                                            :
                                            <Grid item xs={12} md={3}>
                                                <Skeleton variant="rect" width={281.5} height={48} />
                                            </Grid>
                                    }
                                    {
                                        this.state.sub_categories.length > 0 ?
                                            <Grid item xs={12} md={3}>
                                                <CustomTextfieldRaw
                                                    select
                                                    label="Sub Category"
                                                    name="sub_category_id"
                                                    value={
                                                        this.state.sub_category_id === null ? '' :
                                                            this.state.sub_categories.length <= 0 ? '' : this.state.sub_category_id
                                                    }
                                                    onChange={this.onChange}
                                                >
                                                    <MenuItem value={"all"}>All</MenuItem>
                                                    {
                                                        this.state.sub_categories.map((sub_category, i) => {
                                                            return <MenuItem value={sub_category.id} key={sub_category.id}>{sub_category.name}</MenuItem>
                                                        })
                                                    }
                                                </CustomTextfieldRaw>
                                            </Grid>
                                            :
                                            null
                                    }
                                </Grid>
                            </div>
                        ),
                    }}
                />
            </div>
        )
    }
}

export default withStyles(useStyles)(Product)
