import React, {
    createContext,
    useReducer,
    useEffect, 
} from 'react'
import { clientSideReducer } from './clientSideReducer';

export const ClientSideContext = createContext();

const ClientSideContextProvider = (props) => {
    const [language, dispatchLanguage] = useReducer(clientSideReducer, 'en', ()=>{
        const languageLS = localStorage.getItem('language')
        return languageLS ? languageLS : 'en'
    })
    useEffect(() => {
        localStorage.setItem('language', language);
    }, [language])

    
    const [cartItems, dispatchCartItems] = useReducer(clientSideReducer, "[]", ()=>{
        const cartLS = localStorage.getItem('cart')
        return cartLS ? cartLS : []
    })
    useEffect(() => {
        localStorage.setItem('cart', cartItems);
    }, [cartItems])

    // const handleCheckNotCreateYourOwnPage = () =>{
    //     const url = window.location.href;
    //     const create_your_own = "create_your_own";
    //     if(!url.includes(create_your_own)){
    //         localStorage.setItem('own_product_ingredients', JSON.stringify([]));
    //     }
    // }
    
    const [ownProductIngredients, dispatchOwnProductIngredients] = useReducer(clientSideReducer, "[]", ()=>{
        const ownProductIngredientLS = localStorage.getItem('own_product_ingredients')
        return ownProductIngredientLS ? ownProductIngredientLS : "[]"
    })
    useEffect(() => {
        localStorage.setItem('own_product_ingredients', ownProductIngredients);
    }, [ownProductIngredients])


    return (
        <ClientSideContext.Provider value={{
            language, cartItems, ownProductIngredients,
            dispatchLanguage, dispatchCartItems, dispatchOwnProductIngredients
        }}>
            {props.children}
        </ClientSideContext.Provider>
    )
}

export default ClientSideContextProvider
