import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField.js';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';

import { MenuItem } from '@material-ui/core';

import axios from 'axios';
import { baseUrl, baseUrlNonApi, requestConfigAdmin, prefix } from 'Environment';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import { Editor } from '@tinymce/tinymce-react';
import CloseIcon from "assets/img/havilla/close_icon.svg";
import { AdminContext } from '../../AdminContext';

const useStyles = theme => ({
    ...styles(theme),
    autoCompleteRoot: {
        '& label': {
            color: 'white',
        },
        '& label.Mui-disabled': {
            color: 'grey',
        },
        '& .MuiInput-root': {
            color: '#AF986D',
            '&:hover fieldset': {
                borderBottomColor: 'red',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#cfcfcf',
        },
        '& .MuiInput-root:hover': {
            '.MuiInput-underline:before': {
                borderBottomColor: '#cfcfcf',
            },
        },
        '& .MuiInput-underline-hover:before': {
            borderBottomColor: 'white',
        },
        '& label.Mui-focused': {
            color: '#AF986D',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#AF986D',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        },
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
    },
    autoCompletePaper: {
        backgroundColor: '#222222',
        borderRadius: 0,
        '& .MuiMenuItem-root': {
            background: '#222222'
        },
        '& .MuiMenuItem-root:hover': {
            backgroundColor: 'white',
            color: 'black',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#121212',
            borderRadius: 0,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#AF986D',
            borderRadius: 0,
            '&:hover': {
                backgroundColor: 'white',
            },
        }
    },
    autoCompleteOption: {
        color: 'white',
        '&:hover': {
            backgroundColor: 'white',
            color: 'black',
        },
    },
    imgPreview: {
        width: '100%',
        height: 'auto',
        cursor: 'pointer',
        '&:hover + .delete-button': {
            opacity: '100'
        }
    },
    deletePict: {
        transition: 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)',
        position: 'absolute',
        marginTop: '17px',
        top: '0',
        right: '0',
        opacity: '0',
        cursor: 'pointer',
        background: 'black'
    },
    tempPictContainer: {
        position: 'relative',
        paddingTop: '20px',
        overflow: 'hidden',
        '&:hover .delete-button': {
            opacity: '100'
        }
    },
    img: {
        width: '50px',
        height: '50px',
    },
});

const CustomTextfieldRaw = withStyles({
    root: {
        width: '100%',
        '& label': {
            color: 'white',
        },
        '& label.Mui-disabled': {
            color: 'grey',
        },
        '& .MuiInput-root': {
            color: '#AF986D',
            '&:hover fieldset': {
                borderBottomColor: 'red',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#cfcfcf',
        },
        '& .MuiInput-root:hover': {
            '.MuiInput-underline:before': {
                borderBottomColor: '#cfcfcf',
            },
        },
        '& .MuiInput-underline-hover:before': {
            borderBottomColor: 'white',
        },
        '& label.Mui-focused': {
            color: '#AF986D',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#AF986D',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);

const field_names = [
    'name',
    'lat',
    'lng',
    'type',
]

export class AboutUsDetail extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props);

        this.state = {
            listCities: [
                {
                    value: '',
                    label: ''
                }
            ],
            name_error: '',
            lat_error: '',
            lng_error: '',
            regency_id_error: '',
            type_error: '',
            address_error: '',

            id: '',
            content_eng: '',
            content_id: '',
            key: '',
            photo_urls: [],
        }
    }

    handleEditorChangeId = (content, editor) => {
        this.setState({
            content_id: content
        })
    }
    handleEditorChangeEng = (content, editor) => {
        this.setState({
            content_eng: content
        })
    }

    onChange = (e, newValue) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    addMoreImages = (e) => {
        let files = e.target.files;

        this.setState(prevState => {
            const photo_urls = [...prevState.photo_urls, ...files];
            return { ...prevState, photo_urls };
        }, () => {
        });
    }

    onChangeImage = (e) => {
        let files = e.target.files;

        this.setState({
            photo_urls: [...files]
        }, () => {
        });
    }

    handleDeleteTempImg = (i) => {
        this.setState(prevState => {
            const photo_urls = [...prevState.photo_urls];

            photo_urls.splice(i, 1);
            if (photo_urls.length === 0) {
                document.getElementById("upload-image").value = "";
            }
            return { ...prevState, photo_urls };
        }, () => {
        });
    }

    onCityChange = (e, newValue) => {
        if (newValue) {
            this.setState({
                regency_id: newValue.value
            }, () => {

            });
            // this.setState({
            //     district_disabled: false,
            // })

        }
        // getDistrictsByRegency/{regency_id}
    }

    handleUpdate = async () => {
        let add_header = {
            ...requestConfigAdmin.headers,
            'Content-Type': 'multipart/form-data'
        }

        let formData = new FormData();
        formData.append('_method', "PUT");
        if (this.state.content_id != null)
            formData.append('content_id', this.state.content_id);
        if (this.state.content_eng != null)
            formData.append('content_eng', this.state.content_eng);
        if (this.state.photo_urls != null) {
            for (let i = 0; i < this.state.photo_urls.length; i++) {
                const img = this.state.photo_urls[i];
                if (typeof img === 'object' && img !== null) {
                    formData.append('add_images[' + i + ']', img);
                }
            }
        }
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })

        axios.post(`${baseUrl}/cms/${this.state.id}`,
            formData,
            { headers: add_header },
        ).then((res) => {
            if (res.data.success) {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "success",
                    message: "Update CMS Success!"
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE", value: true })
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                let path = prefix + `/admin/about_us`;
                this.props.history.push(path);

            } else {
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE", value: true })
            }
        }, (e) => {
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: "Error"
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE", value: true })
            console.log("Error : ", e);
        })
    }

    componentDidUpdate(nextProps, nextState) {
        for (let i = 0; i < field_names.length; i++) {
            let field_name = field_names[i];
            if (this.state[field_name + "_error"] !== "") {
                if (this.state[field_name] !== nextState[field_name]) {
                    this.setState({ [field_name + "_error"]: "" });
                }
            }
        }

        return true;
    }

    componentDidMount = async () => {
        if (this.props.match.params.id !== 'add') {
            await axios.get(`${baseUrl}/cms/${this.props.match.params.id}`, requestConfigAdmin).then((res) => {
                if (res.data.success) {
                    this.setState({
                        id: res.data.data.id,
                        content_id: res.data.data.content_id,
                        content_eng: res.data.data.content_eng,
                        key: res.data.data.key,
                        photo_urls: JSON.parse(res.data.data.photo_urls)
                    }, () => { console.log(this.state) })
                } else {
                    console.log(res.data.message, res.data.data)
                }
            }, (e) => {
                console.log("Error : ", e);
            })
        }

    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <h3 style={{ color: 'white' }}>{this.state.key}</h3>
                <Container maxWidth="xl" className={`${classes.container} ${classes.contentContainer}`}>
                    {
                        this.state.content_eng != null ?
                            <Grid xs={12} item justify="center">
                                <p>Content (English)</p>
                                <Editor
                                    apiKey="4xcq0qf05flqal1nd0n9broiga7bph32v2axralmppkw1nad"
                                    value={this.state.content_eng}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount imagetools '
                                        ],
                                        toolbar: [
                                            'undo redo | \
                                                    bold italic underline strikethrough | \
                                                    fontselect fontsizeselect formatselect | \
                                                    alignleft aligncenter alignright alignjustify | ',
                                            'outdent indent | \
                                                    numlist bullist | \
                                                    forecolor backcolor removeformat | \
                                                    pagebreak | \
                                                    charmap emoticons | \
                                                    fullscreen preview | \
                                                    insertfile image media template link anchor codesample code',
                                        ],
                                        width: 932,
                                        relative_urls: false,
                                        remove_script_host: false,
                                        convert_urls: true,
                                        image_advtab: true,
                                        image_caption: true,
                                        file_picker_types: 'image',
                                        document_base_url: baseUrlNonApi,
                                        images_upload_handler: function (blobInfo, success, failure) {
                                            let data = new FormData();
                                            data.append('file', blobInfo.blob(), blobInfo.filename());

                                            axios.post(`${baseUrl}/articles/temp_file_upload`, data, requestConfigAdmin)
                                                .then(function (res) {
                                                    success(`${baseUrlNonApi}${res.data.location}`);
                                                })
                                                .catch(function (err) {
                                                    failure('HTTP Error: ' + err.message);
                                                });
                                        },
                                    }}
                                    onEditorChange={this.handleEditorChangeEng}
                                />
                            </Grid>
                            :
                            ``
                    }


                    <br></br>
                    {
                        this.state.content_id != null ?
                            <Grid xs={12} item justify="center">
                                <p>Content (Indonesia)</p>
                                <Editor
                                    apiKey="4xcq0qf05flqal1nd0n9broiga7bph32v2axralmppkw1nad"
                                    value={this.state.content_id}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount imagetools '
                                        ],
                                        toolbar: [
                                            'undo redo | \
                                                        bold italic underline strikethrough | \
                                                        fontselect fontsizeselect formatselect | \
                                                        alignleft aligncenter alignright alignjustify | ',
                                            'outdent indent | \
                                                        numlist bullist | \
                                                        forecolor backcolor removeformat | \
                                                        pagebreak | \
                                                        charmap emoticons | \
                                                        fullscreen preview | \
                                                        insertfile image media template link anchor codesample code',
                                        ],
                                        width: 932,
                                        relative_urls: false,
                                        remove_script_host: false,
                                        convert_urls: true,
                                        image_advtab: true,
                                        image_caption: true,
                                        file_picker_types: 'image',
                                        document_base_url: baseUrlNonApi,
                                        images_upload_handler: function (blobInfo, success, failure) {
                                            let data = new FormData();
                                            data.append('file', blobInfo.blob(), blobInfo.filename());

                                            axios.post(`${baseUrl}/articles/temp_file_upload`, data, requestConfigAdmin)
                                                .then(function (res) {
                                                    success(`${baseUrlNonApi}${res.data.location}`);
                                                })
                                                .catch(function (err) {
                                                    failure('HTTP Error: ' + err.message);
                                                });
                                        },
                                    }}
                                    onEditorChange={this.handleEditorChangeId}
                                />
                            </Grid>
                            :
                            ``
                    }

                    <br></br>
                    {
                        this.state.photo_urls != null ?
                            <p>Images</p>
                            :
                            ``
                    }
                    {
                        this.state.photo_urls != null ?
                            <Grid container>
                                {
                                    this.state.photo_urls != null ?
                                        this.state.photo_urls.map((image, i) => {
                                            return (
                                                <Grid className={classes.tempPictContainer} key={i} item md={4} xs={6}>
                                                    {
                                                        typeof image === 'object' && image !== null ?
                                                            <img className={classes.imgPreview} key={i} alt={`preview ${image.name}`} src={URL.createObjectURL(image)} />
                                                            :
                                                            <img className={classes.imgPreview} key={i} alt={`preview ${i}`} src={`${image}?v=${this.state.count_for_update}`} />
                                                    }
                                                    {/* <div className={`${classes.deletePict} delete-button`} >
                                                        <img src={CloseIcon} alt="close icon" onClick={() => this.handleDeleteTempImg(i)}></img>
                                                    </div> */}
                                                </Grid>
                                            )
                                        })
                                        :
                                        null
                                }
                            </Grid>
                            :
                            ``
                    }
                    <Grid item md={12} xs={12}>
                        {this.state.photo_urls != null ? this.state.photo_urls.length > 1 ?
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="upload-image"
                                type="file"
                                multiple
                                onChange={this.onChangeImage}
                            />
                            :
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="upload-image"
                                type="file"
                                onChange={this.onChangeImage}
                            />
                            :
                            ""
                        }
                        {this.state.photo_urls != null ? this.state.photo_urls.length > 1 ?
                            <label htmlFor="upload-image">
                                <Button component="span" className={classes.buttonLight}>
                                    Change Images (Multiple Selection)
                                </Button>
                            </label>
                            :
                            <label htmlFor="upload-image">
                                <Button component="span" className={classes.buttonLight}>
                                    Change Image
                                </Button>
                            </label>
                            :
                            ""
                        }
                    </Grid>
                    <hr></hr>
                    <Grid item xs={12} md={12} style={{ marginTop: "20px", textAlign: 'center' }}>
                        <Button type="submit" variant="outlined" className={classes.buttonDark} onClick={this.handleUpdate}>Save</Button>
                    </Grid>
                </Container>
            </div>
        )
    }
}

export default withStyles(useStyles)(AboutUsDetail) 
