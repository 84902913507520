import React, { Component } from 'react'
import {
    Grid,
    //  IconButton 
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import OwnBlendIcon from "assets/img/havilla/own_blend_icon.svg";
import {
    baseUrl,
    requestConfig,
    prefix
} from '../../../../Environment.js';
import axios from 'axios';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import ProductCard from 'client-side/components/ProductCard/ProductCard';
import { ClientSideContext } from '../../../ClientSideContext';
import { dictionary } from '../../../../Dictionary';
const useStyles = theme => ({
    ...styles(theme),
    textField: {
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        [theme.breakpoints.up('md')]: {
        },
        width: "80%"
    },
    iconEdit: {
        marginTop: `-8px`
    },
    addNewContainer: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: 0,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '55%',
            paddingBottom: '55%'
        },
    },
    boxOutline: {
        padding: '1.2rem',
        width: '100%',
        height: '100%'
    },
});

const borderDotted = {
    border: '2px dotted lightgrey',

}
class MyOwnBlend extends Component {
    static contextType = ClientSideContext;
    state = {
        openBackdrop: false,
        startDate: null,
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        birth_date: "",
        password: "",
        disabled: true,
        myOwnBlend: []
    };

    handleStartDate = (date) => {
        this.setState({
            startDate: date
        });
    };

    handleEdit = () => {
        this.setState({
            disabled: !this.state.disabled
        });
    }

    handlePasswordChange = (newValue) => {
        this.setState({
            password: newValue,
        })
    }

    handleSubmit = () => {
        const state = this.state;
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     console.log(this.state.myOwnBlend)
    //     console.log(nextState.myOwnBlend)
    //     if(this.state.myOwnBlend !== nextState.myOwnBlend) return true
    //     return false
    // }

    componentDidMount = async () => {
        this.setState({ openBackdrop: true });
        await axios.get(`${baseUrl}/ownproducts`, requestConfig).then((res) => {
            if (res.data.success) {
                this.setState({
                    myOwnBlend: res.data.data
                }, () => {
                    this.setState({ openBackdrop: false });
                    // console.log(this.state)
                })
            } else {
                this.setState({ openBackdrop: false });
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.setState({ openBackdrop: false });
            console.log("Error : ", e);
        })
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    render() {
        const { classes } = this.props;
        // const {
        //     startDate
        // } = this.state;
        return (
            <Container maxWidth="xl" className={`${classes.container} ${classes.contentContainer}`}>
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{'color': '#AF986D'}} />
                </Backdrop>
                <Grid container
                    spacing={3}>
                    {
                        this.state.myOwnBlend.map(item => {
                            // alert(window.location.pathname.split("/")[2]);
                            return (
                                <Grid item lg={3} sm={6} xs={12} key={item.id}>
                                    <ProductCard detail={item} key={item.id} type='own' openCart={this.props.openCart}></ProductCard>
                                </Grid>
                            )
                        })
                    }
                    <Grid item lg={3} sm={6} xs={12}>
                        <div className={classes.boxOutline} style={borderDotted}>
                            <div className={classes.linkPointer} onClick={this.handleOpen}>
                                <Grid container spacing={2}
                                    justify="center"
                                    alignItems="center"
                                    direction="column"
                                    className={classes.addNewContainer}>
                                    <Grid item xs={12}>
                                        <img src={OwnBlendIcon} alt="home icon"></img><br />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p style={{ textAlign: 'center' }}>{dictionary['Never Stop Creating!'][this.context.language]} <br /> 
                                        {dictionary['Let’s Create Your Own Blend'][this.context.language]}
                                        </p>
                                    </Grid>
                                    <Link to={`${prefix}/create_your_own`}>
                                        <Grid item xs={12}>
                                            <Button style={{ textAlign: 'center' }} variant="outlined" className={classes.buttonDark} >
                                            {dictionary["Let's Go!"][this.context.language]}
                                            </Button>
                                        </Grid>
                                    </Link>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default withStyles(useStyles)(MyOwnBlend) 
