import React, { Component } from 'react'
import Header from 'client-side/components/Header/Header';
import { Route, Switch } from "react-router-dom";
import routes from "client-side/routes.js";
import { CssBaseline } from '@material-ui/core';
import Footer from 'client-side/components/Footer/Footer';
import NotFound from 'client-side/components/NotFound/NotFound';
import Homepage from 'client-side/views/Homepage/Homepage';
import ClientSideContextProvider from 'client-side/ClientSideContext';
import {
    prefix, baseUrl, requestConfigAdmin
} from '../../Environment.js';
// import ProductBuying from 'client-side/views/ProductBuying/ProductBuying';
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import CustomModalWImage from 'client-side/components/CustomModals/CustomModalWImage.js';
require('../App.css');

let bannerLS = null;
class ClientSide extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cartModalOpen: false,
            modalOpen: false,

            title: "",
            content: "",
            link_to: "",
            link_text: "",
            image_url: "",
            count: ""
        }

    }

    // handleCheckNotCreateYourOwnPage = () =>{
    //     const url = this.props.location.pathname;
    //     const create_your_own = "create_your_own";
    //     if(!url.includes(create_your_own)){
    //         localStorage.setItem('own_product_ingredients', JSON.stringify([]));
    //     }
    // }

    getLatestBanner = () => {
        axios.get(`${baseUrl}/banner_pop_up/get_latest`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState({
                    ...res.data.data,
                }, () => {
                    bannerLS = localStorage.getItem('banner');
                    if (bannerLS === null) {
                        localStorage.setItem('banner', this.state.count);
                        bannerLS = localStorage.getItem('banner');
                        this.setState({
                            modalOpen: true
                        })
                    } else {
                        if (bannerLS != this.state.count) {
                            this.setState({
                                modalOpen: true
                            })
                        }
                    }
                })
            } else {
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            console.log("Error : ", e);
        })
    }

    componentDidMount() {
        this.getLatestBanner();
        // this.handleCheckNotCreateYourOwnPage();
        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };
        ReactPixel.init('997680367397186');
        ReactPixel.pageView();
    }

    handleCloseModal = (newValue) => {
        this.setState({
            modalOpen: newValue
        }, () => {
            localStorage.setItem('banner', this.state.count);
        })
    }

    // componentDidUpdate(){
    //     // this.handleCheckNotCreateYourOwnPage();
    // }

    openCart = () => {
        this.setState({
            cartModalOpen: true
        }, () => {
            // console.log(this.state.cartModalOpen)
        })
    }
    closeCart = () => {
        this.setState({
            cartModalOpen: false
        }, () => {
            // console.log(this.state.cartModalOpen)
        })
    }

    render() {
        const switchRoutes = (
            <Switch>
                {routes.map((prop, key) => {
                    if (prop.layout == null) {
                        return (
                            <Route
                                exact={prop.is_exact}
                                path={prefix + prop.path}
                                component={() => (
                                    React.createElement(prop.component, {
                                        ...this.props,
                                        handleOpenSnackbar: this.handleOpenSnackbar,
                                        openCart: this.openCart,
                                        prefix: prefix
                                    }))}
                                key={key}
                            />
                        );
                    } else {
                        return (
                            <Route exact
                                path={prefix + prop.path}
                                component={() => (<React.Fragment>
                                    {React.createElement(prop.layout, { ...this.props, handleOpenSnackbar: this.handleOpenSnackbar, openCart: this.openCart, prefix: prefix })}
                                    {React.createElement(prop.component, { ...this.props, handleOpenSnackbar: this.handleOpenSnackbar, openCart: this.openCart, prefix: prefix })}
                                </React.Fragment>)}
                                key={key}
                            />
                        );
                    }
                })}
                {/* <Route path="/product_buying" component={() => (
                    <React.Fragment>
                        <ProductBuying openCart={this.openCart} handleOpenSnackbar={this.handleOpenSnackbar} {...this.props}></ProductBuying>
                    </React.Fragment>
                )}></Route> */}
                {/* <Route exact path='/' 
                    render={(props) => <Homepage {...props} handleOpenSnackbar={this.handleOpenSnackbar} openCart={this.openCart}/>}
                /> */}
                <Route component={NotFound} />
            </Switch>
        );
        let path = this.props.location.pathname;
        let withFooter =
            path === prefix + '/login' ||
            path === prefix + '/register' ||
            path === prefix + '/forgot_password' ||
            path.includes(prefix + '/create_your_own')

        let title_tags = "Havilla Gourmet Tea";
        if (path.includes(prefix + '/tea_shop') || path.includes(prefix + '/product')) {
            title_tags = "Tea Shop - Havilla Gourmet Tea"
        } else if (path.includes(prefix + '/create_your_own')) {
            title_tags = "Create Your Own Blend - Havilla Gourmet Tea"
        } else if (path.includes(prefix + '/tea_talks')) {
            title_tags = "Tea Talks - Havilla Gourmet Tea"
        } else if (path.includes(prefix + '/about_us')) {
            title_tags = "About Us - Havilla Gourmet Tea"
        }

        return (
            <ClientSideContextProvider>
                <Header openCart={this.state.cartModalOpen} closeCart={this.closeCart} {...this.props} prefix={prefix}></Header>
                <Helmet>
                    <title>{title_tags}</title>
                </Helmet>
                <CustomModalWImage
                    open={this.state.modalOpen}
                    handleCloseModal={this.handleCloseModal}
                    img={typeof this.state.image_url === 'object' && this.state.image_url !== null ? URL.createObjectURL(this.state.image_url) : `${this.state.image_url}?v=${this.state.count_for_update}`}
                    content={this.state.content}
                    link_to={this.state.link_to}
                    link_text={this.state.link_text}
                    title={this.state.title}
                />
                <div className='content-container'>
                    <CssBaseline />
                    {switchRoutes}
                </div>
                {!withFooter ? <Footer></Footer> : null}
            </ClientSideContextProvider>
        )
    }
}

export default (ClientSide)
