/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// import Person from "@material-ui/icons/Person";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
// import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// core components/views for Admin layout
import DashboardPage from "admin/views/Dashboard/Dashboard.js";
import StoreLocator from "admin/views/StoreLocator/StoreLocator";
import StoreLocatorDetail from "admin/views/StoreLocator/StoreLocatorDetail";
import Product from "admin/views/Product/Product";
import ProductDetail from "admin/views/Product/ProductDetail";
import ArticleCategory from "./views/ArticleCategory/ArticleCategory";
import ArticleCategoryDetail from "./views/ArticleCategory/ArticleCategoryDetail";
import Article from "./views/Article/Article";
import ArticleDetail from "./views/Article/ArticleDetail";
import OwnProductIngredientType from "./views/OwnProductIngredientType/OwnProductIngredientType";
import OwnProductIngredientTypeDetail from "./views/OwnProductIngredientType/OwnProductIngredientTypeDetail";
import OwnProductStep from "./views/OwnProductStep/OwnProductStep";
import OwnProductStepDetail from "./views/OwnProductStep/OwnProductStepDetail";
import Order from "admin/views/Order/Order";
import Voucher from "./views/Voucher/Voucher";
import VoucherDetail from "./views/Voucher/VoucherDetail";
import ProfileAdmin from "./views/ProfileAdmin/ProfileAdmin";
import Slider from "./views/Slider/Slider";
import SliderDetail from "./views/Slider/SliderDetail";

import {
  prefix
} from '../Environment.js';
import BannerPopUp from "./views/BannerPopUp/BannerPopUp";
import AboutUs from "./views/AboutUs/AboutUs";
import AboutUsDetail from "./views/AboutUs/AboutUsDetail";
const dashboardRoutes = [
  {
    path: prefix + "/admin/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    is_menu : true
  },
  {
    path: prefix + "/admin/orders",
    name: "Orders",
    icon: Dashboard,
    component: Order,
    layout: "/admin",
    is_menu : true
  },
  {
    path: prefix + "/admin/products",
    name: "Products",
    icon: Dashboard,
    component: Product,
    layout: "/admin",
    is_menu : true
  },
  {
    path: prefix + "/admin/products/:id",
    name: "Product",
    icon: Dashboard,
    component: ProductDetail,
    layout: "/admin",
    is_menu : false
  },
  {
    path: prefix + "/admin/storelocator",
    name: "Store Locations",
    icon: Dashboard,
    component: StoreLocator,
    layout: "/admin",
    is_menu : true
  },
  {
    path: prefix + "/admin/storelocator/detail/:id",
    name: "Detail Store Locations",
    icon: Dashboard,
    component: StoreLocatorDetail,
    layout: "/admin",
    is_menu : false
  },
  {
    path: prefix + "/admin/article_categories",
    name: "Article Categories",
    icon: Dashboard,
    component: ArticleCategory,
    layout: "/admin",
    is_menu : true
  },
  {
    path: prefix + "/admin/article_categories/:id",
    name: "Article Category",
    icon: Dashboard,
    component: ArticleCategoryDetail,
    layout: "/admin",
    is_menu : false
  },
  {
    path: prefix + "/admin/articles",
    name: "Articles",
    icon: Dashboard,
    component: Article,
    layout: "/admin",
    is_menu : true
  },
  {
    path: prefix + "/admin/articles/:id",
    name: "Article",
    icon: Dashboard,
    component: ArticleDetail,
    layout: "/admin",
    is_menu : false
  },
  {
    path: prefix + "/admin/own_product_ingredient_types",
    name: "Own Product Ingredient Types",
    icon: Dashboard,
    component: OwnProductIngredientType,
    layout: "/admin",
    is_menu : true
  },
  {
    path: prefix + "/admin/own_product_ingredient_types/:id",
    name: "Own Product Ingredient Types",
    icon: Dashboard,
    component: OwnProductIngredientTypeDetail,
    layout: "/admin",
    is_menu : false
  },
  {
    path: prefix + "/admin/own_product_steps",
    name: "Create Your Own Steps",
    icon: Dashboard,
    component: OwnProductStep,
    layout: "/admin",
    is_menu : true
  },
  {
    path: prefix + "/admin/own_product_steps/:id",
    name: "Create Your Own Step",
    icon: Dashboard,
    component: OwnProductStepDetail,
    layout: "/admin",
    is_menu : false
  },
  {
    path: prefix + "/admin/vouchers",
    name: "Vouchers",
    icon: Dashboard,
    component: Voucher,
    layout: "/admin",
    is_menu : true
  },
  {
    path: prefix + "/admin/vouchers/:id",
    name: "Vouchers",
    icon: Dashboard,
    component: VoucherDetail,
    layout: "/admin",
    is_menu : false
  },
  {
    path: prefix + "/admin/profile",
    name: "Profile",
    icon: Dashboard,
    component: ProfileAdmin,
    layout: "/admin",
    is_menu : false
  },
  {
    path: prefix + "/admin/sliders",
    name: "Sliders",
    icon: Dashboard,
    component: Slider,
    layout: "/admin",
    is_menu : true
  },
  {
    path: prefix + "/admin/sliders/:id",
    name: "Slider",
    icon: Dashboard,
    component: SliderDetail,
    layout: "/admin",
    is_menu : false
  },
  {
    path: prefix + "/admin/banner_pop_up",
    name: "Banner Pop Up",
    icon: Dashboard,
    component: BannerPopUp,
    layout: "/admin",
    is_menu : true
  },
  {
    path: prefix + "/admin/about_us",
    name: "About Us",
    icon: Dashboard,
    component: AboutUs,
    layout: "/admin",
    is_menu : true
  },
  {
    path: prefix + "/admin/about_us/detail/:id",
    name: "Detail About Us",
    icon: Dashboard,
    component: AboutUsDetail,
    layout: "/admin",
    is_menu : false
  },
];

export default dashboardRoutes;
