import React, { Component } from 'react'
import { AdminContext } from '../../AdminContext';
import axios from 'axios';
import { baseUrl, requestConfigAdmin } from 'Environment';
import { withStyles} from '@material-ui/core';
import globalStyles from "assets/jss/client-side/App.js";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import GridItem from 'admin/components/Grid/GridItem';
import Card from "admin/components/Card/Card.js";
import CardHeader from "admin/components/Card/CardHeader.js";
import CardBody from "admin/components/Card/CardBody.js";
import GridContainer from 'admin/components/Grid/GridContainer';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField';
import CardFooter from 'admin/components/Card/CardFooter';
import Button from "admin/components/CustomButtons/Button.js";
import PasswordField from 'client-side/components/PasswordField/PasswordField';

const useStyles = theme => ({
    ...globalStyles(theme),
    ...dashboardStyle,
});

const required_fields = [
    'email'
]
const fields = [
    'email',
    'password',
    'password_confirmation'
]

export class ProfileAdmin extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props)
    
        this.state = {
            email : '',
            email_error : '',
            password : '',
            password_error : '',
            password_confirmation : '',
            password_confirmation_error : ''
        }
    }

    
    getAdminProfile = () =>{
        axios.get(`${baseUrl}/getCurrentUserInfo`,
            requestConfigAdmin
        ).then((res) => {
            if (res.data.success) {
                let data = res.data.data;
                this.setState({
                    ...data
                },()=>{
                })
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    componentDidMount(){
       this.getAdminProfile()
    }

    componentDidUpdate(nextProps, nextState){
        for (let i = 0; i < required_fields.length; i++) {
            let field_name = required_fields[i];
            if(this.state[field_name+"_error"] !== ""){
                if(this.state[field_name] !== nextState[field_name]){
                    this.setState({ [field_name+"_error"]: "" });
                }
            }
        }

        return true;
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleValidation = () =>{
        let is_ok = true;
        for (let i = 0; i < required_fields.length; i++) {
            let field_name = required_fields[i];
            let field_name_text = required_fields[i].replace('_',' ');

            if(this.state[field_name] === "" || this.state[field_name] === null || this.state[field_name].length === 0){
                is_ok = false;
                this.setState({ [field_name+"_error"]: field_name_text+" is required" });
            }
        }
        return is_ok;
    }
    
    handleSubmitAdminInfo = () =>{
        let is_ok = this.handleValidation();
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })

        if(is_ok){
            let data = {
                email : this.state.email,
                password : this.state.password,
                password_confirmation : this.state.password_confirmation
            }
            axios.put(`${baseUrl}/updateUserAdmin`,
                data,
                requestConfigAdmin
            ).then((res)=>{
                if(res.data.success){
                    this.context.dispatchSnackbarContent({ 
                        type: "SET_SNACKBAR_CONTENT", 
                        color : "success",
                        message : "Update Admin Info Success!"
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    let path = `/admin`;
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                    this.props.history.push(path);
                }else{
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
    
                    this.context.dispatchSnackbarContent({ 
                        type: "SET_SNACKBAR_CONTENT", 
                        color : "danger",
                        message : res.data.message
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    if(res.data.data){
                        this.handleErrorFromRequest(res.data.data);
                    }
                    console.log(res.data.message,res.data.data)
                }
            },(e)=>{
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
    
                this.context.dispatchSnackbarContent({ 
                    type: "SET_SNACKBAR_CONTENT", 
                    color : "danger",
                    message : "Error"
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log("Error : ",e);
            })
        }
    }

    handleErrorFromRequest = (errors) =>{
        for (let i = 0; i < fields.length; i++) {
            let field_name = fields[i];
            let field_name_text = fields[i].replace('_',' ');
            if(errors[field_name] !== undefined){
                this.setState({ [field_name+"_error"]: errors[field_name][0] });
                
            }
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="gray">
                            <h4 className={classes.cardTitleWhite}>Admin Profile</h4>
                            <p>Manage Admin Profile</p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem container item sm={12} md={6}>
                                    <GridItem sm={12} xs={12}>
                                        <CustomTextField
                                            width="80%"
                                            value={this.state.email}
                                            error={this.state.email_error !== ""}
                                            helperText={this.state.email_error}
                                            type="text" onChange={this.onChange} name="email" label="Email" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12}>
                                        <PasswordField 
                                            error={this.state.password_error !== ""}
                                            helperText={this.state.password_error}
                                            hideIcon={true}
                                            type="password" onChange={this.onChange} name="password" label="New Password" />
                                    </GridItem>
                                    <GridItem sm={12} xs={12}>
                                        <PasswordField 
                                            error={this.state.password_confirmation_error !== ""}
                                            helperText={this.state.password_confirmation_error}
                                            hideIcon={true}
                                            type="password" onChange={this.onChange} name="password_confirmation" label="Re-Type Your New Password" />
                                    </GridItem>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button color="info" onClick={this.handleSubmitAdminInfo}>Update Admin Info</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }
}

export default withStyles(useStyles)(ProfileAdmin)
