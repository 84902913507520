import React, { Component } from 'react'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import CloseIcon from "assets/img/havilla/close_icon.svg";
import { Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Chamomile from "assets/img/havilla/own_blend_square.jpg";

import Suddhabumi from "assets/img/havilla/Suddhabumi.png";
import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp';
import { ClientSideContext } from '../../ClientSideContext';
import { dictionary } from '../../../Dictionary';

import ReactPixel from 'react-facebook-pixel';

import axios from 'axios';
import {
  baseUrl,
  requestConfig,
  prefix
} from '../../../Environment.js';

export class CustomModalAddToCart extends Component {
  static contextType = ClientSideContext;

  constructor(props) {
    super(props);

    this.state = {
      price: 0,
      discount: 0,
      selectedVarian: 0,
      sub_product_id: '',
      product: null,

      snackbarType: 'success',
      snackbarMessage: '',
    }
  }

  componentDidMount = async () => {
    if (this.props.products.sub_products) {
      this.setState({
        price: this.props.products.sub_products[0].price,
        discount: this.props.products.sub_products[0].discount,
        sub_product_id: this.props.products.sub_products[0].id
      })
    }
    else {
      this.setState({
        price: this.props.products.price
      })
    }
  }

  handleClose = () => {
    this.props.handleCloseModal(false);
  };

  handleSelectVarian = (item, i) => {
    this.setState({
      price: item.price,
      selectedVarian: i,
      sub_product_id: item.id
    })
  }

  handleAddToCart = async () => {
    const { dispatchCartItems } = this.context;
    const state = this.state;
    let auth = localStorage.getItem('auth') ? true : false;
    if (auth) {
      if (this.props.is_own === 0) {
        await axios.post(`${baseUrl}/add_to_cart`,
          state,
          requestConfig)
          .then((res) => {
            if (res.data.success) {
              dispatchCartItems({
                type: "ADD_TO_CART",
                newItem: res.data.data,
              })
              var data = res.data.data[0].sub_product;
              data['product_name'] = res.data.data[0].sub_product.product.name;
              
              ReactPixel.trackCustom("AddToCart", data);
              if (this.props.type === 'atc') {
                this.handleClose();
                this.props.openCart();
              }
            } else {
              console.log(res.data.message, res.data.data)
              this.setState({
                snackbarType: 'error',
                snackbarMessage: 'Failed Added To Cart',
              });
            }
          }, (e) => {
            console.log("Error : ", e);
            this.setState({
              snackbarType: 'error',
              snackbarMessage: 'Failed Added To Cart',
            });
          })
      }
      else {
        await axios.post(`${baseUrl}/add_to_cart_own`,
          { own_product_id: this.props.products.id },
          requestConfig)
          .then((res) => {
            if (res.data.success) {
              dispatchCartItems({
                type: "ADD_TO_CART",
                newItem: res.data.data,
              })
              if (this.props.type === 'atc') {
                this.handleClose();
                this.props.openCart();
              }
              var data = res.data.data[0];
              data['sub_product'] = "";
              
              ReactPixel.trackCustom("AddToCart", data);
            } else {
              console.log(res.data.message, res.data.data)
              this.setState({
                snackbarType: 'error',
                snackbarMessage: 'Failed Added To Cart',
              });
            }
          }, (e) => {
            console.log("Error : ", e);
            this.setState({
              snackbarType: 'error',
              snackbarMessage: 'Failed Added To Cart',
            });
          })
      }
    } else {
      let currentCart = JSON.parse(localStorage.getItem('cart'));
      let format = [{
        id: currentCart ? currentCart.length + 1 : 1,
        is_own: 0,
        product_id: null,
        own_product: null,
        qty: 0,
        sub_product: null,
      }]

      axios.get(`${baseUrl}/subproducts/${state.sub_product_id}`,
        state,
        requestConfig)
        .then((res) => {
          if (res.data.success) {
            let result = res.data.data;
            format[0].product_id = result.id;
            format[0].sub_product = result;
            format[0].qty = 1;
            format[0].thumbnail_url = result.photo_url === "[]" ? null : result.photo_url;
            format[0].is_guest = 1;
            dispatchCartItems({
              type: "ADD_TO_CART",
              newItem: format,
            })
            if (this.props.type === 'atc') {
              this.handleClose();
              this.props.openCart();
            }
          } else {
            console.log(res.data.message, res.data.data)
            this.setState({
              snackbarType: 'error',
              snackbarMessage: 'Failed Added To Cart',
            });
          }
        }, (e) => {
          console.log("Error : ", e);
          this.setState({
            snackbarType: 'error',
            snackbarMessage: 'Failed Added To Cart',
          });
        })
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <SnackbarComp
          snackbarType={this.state.snackbarType}
          snackbarMessage={this.state.snackbarMessage}
          counter={this.state.counter}
        />
        <Modal
          className={classes.modal}
          open={this.props.open}
          onClose={this.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Slide direction="up" in={this.props.open} mountOnEnter unmountOnExit>
            <div className={classes.paper}>
              <Grid container>
                <Grid item lg={1} xs={1}>
                </Grid>
                <Grid item lg={3} md={4} xs={12} style={{ position: 'relative' }}>

                  <Grid container className={classes.productBackground} alignItems="center" justify="center">
                    <Link to={prefix + `/product/${this.props.products.url_slug}`}>
                      {
                        this.props.is_own === 0 ?
                          this.props.thumbnail !== null ?
                            <img src={this.props.thumbnail}
                              className={classes.productImage}
                            ></img>
                            :
                            // INSERT DEFAULT NO PHOTO HERE
                            <img src={Suddhabumi} className={classes.productImage}></img>
                          :
                          <img src={Chamomile} className={classes.productImage}></img>
                      }
                    </Link>
                    {/* <img src={Suddhabumi} className={classes.productImage} alt='product img'></img> */}
                  </Grid>
                  {/* <p>
                    <span>
                      asdlklaksdla asda ksadaksk as
                    </span>
                  </p> */}
                  {
                    this.props.is_own === 0 ?
                      this.props.products.product_sub_category ?
                        <span className={classes.productSubCategory}>{this.props.products.product_sub_category.name}<br /></span>
                        :
                        <span className={classes.productSubCategory}>{this.props.products.product_category.name}<br /></span>
                      :
                      <span className={classes.productSubCategory}>Unique Blend<br /></span>
                  }
                  <p style={{ marginTop: 12 }}>{this.props.products.name ? this.props.products.name : ''}</p>
                  {
                    this.state.discount !== null && this.state.discount > 0 ?
                      <span className={classes.price}>
                        Rp. {(this.state.price - (this.state.price * (this.state.discount / 100))).toLocaleString('ID')}
                        <p className={classes.originalPrice}>
                          {/* {`${this.state.variant_selected.discount}%`}&nbsp; */}
                          <del>
                            {`Rp. ${this.state.price.toLocaleString('ID')}`}
                          </del>
                        </p>
                      </span>
                      :
                      <span className={classes.price}>Rp. {this.state.price.toLocaleString('ID')}</span>
                  }
                </Grid>
                <Grid item lg={4} md={6} xs={12} className={classes.atcContainer}>
                  <Grid container>
                    {
                      this.props.is_own === 0 ?
                        this.props.products.sub_products.length > 1 ?
                          <Grid item lg={4} md={4} xs={12}>
                            <p className={classes.chooseVariant}>{dictionary['Choose Variant'][this.context.language]}: </p>
                          </Grid>
                          :
                          ''
                        :
                        ''
                    }
                    <Grid item lg={8} md={8} xs={12} className={classes.varianContainer}>
                      {
                        this.props.is_own === 0 ?
                          this.props.products.sub_products.length > 1 ?
                            this.props.products.sub_products.map((item, i) => {
                              return (
                                <Button style={{ marginRight: 10 }} className={this.state.selectedVarian === i ? classes.selectedButtonSmall : classes.buttonSmall}
                                  onClick={() => this.handleSelectVarian(item, i)}
                                  key={item.id}>
                                  {item.varian}
                                </Button>
                              )
                            })
                            :
                            ''
                          :
                          ''
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={3} xs={12} className={classes.btnAtcContainer}>
                  {this.props.type === 'atc' ?
                    <Button style={{ float: 'right' }} variant="outlined" className={classes.buttonDark} onClick={this.handleAddToCart}>
                      {dictionary['Add to Cart'][this.context.language]}
                    </Button>
                    :
                    <Link to={
                      {
                        pathname: prefix + "/product_buying",
                        state: {
                          recipient: 'gift',
                        }
                      }
                    }>
                      <Button style={{ float: 'right' }} variant="outlined" className={classes.buttonDark} onClick={this.handleAddToCart}>
                        {dictionary['Send as Gift'][this.context.language]}
                      </Button>
                    </Link>
                  }
                </Grid>
                <Grid item xs={1}>
                  <img className={classes.closeIcon} src={CloseIcon} alt="close icon" onClick={this.handleClose}></img>
                </Grid>
              </Grid>
            </div>
          </Slide>
        </Modal>
      </React.Fragment>
    );
  }
}


const useStyles = theme => ({
  ...styles(theme),
  modal: {
    // position:'absolute',
    // bottom: '0',
    // width: '100%'
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  paper: {
    [theme.breakpoints.down('sm')]: {
      padding: '2rem',
      width: '100%',
    },
    backgroundColor: '#222222',
    color: 'white',
    boxShadow: theme.shadows[5],
    position: 'absolute',
    bottom: '0',
    width: '100%',
    padding: '2rem'
  },
  closeIcon: {
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      right: 20,
      top: 10
    },
    // position: 'absolute',
    // right: 20,
    // bottom: '3.1rem',
    marginTop: 18,
    cursor: 'pointer'
  },
  productSubCategory: {
    fontSize: '0.7rem',
    border: '1px solid #AF986D',
    color: '#AF986D',
    borderRadius: '16px',
    padding: '2px 10px 0',
    textTransform: 'uppercase',
    width: 'auto'
  },
  buttonVarian: {
    borderRadius: 0,
    background: 'white',
    border: '1px solid black',
    color: 'black',
    '&:hover': {
      backgroundColor: "black",
      color: "white"
    },
    '&:focus': {
      backgroundColor: "black",
      color: "white"
    },
    marginTop: 4
  },
  selectedButtonVarian: {
    borderRadius: 0,
    backgroundColor: "black",
    border: '1px solid black',
    color: "white",
    marginTop: 4,
    '&:hover': {
      backgroundColor: "black",
      color: "white"
    },
    '&:focus': {
      backgroundColor: "black",
      color: "white"
    },
  },
  price: {
    position: 'absolute',
    right: 30,
    top: 19,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0,
      right: 0,
    },
  },
  productBackground: {
    background: '#000000',
    height: 70,
    width: 70,
    // padding: 10,
    float: 'left',
    marginTop: '-10px',
    marginRight: 20
  },
  productImage: {
    width: '100%',
    height: '100%'
  },
  atcContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      marginBottom: 20
    },
    marginTop: 5
  },
  btnAtcContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: 20,
      paddingRight: 0
    },
    paddingRight: 20
  },
  chooseVariant: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'left'
    },
    marginTop: 12, textAlign: 'right', paddingRight: 15
  },
  // varianContainer: {
  //   border:'1px solid black'
  // }

  originalPrice: {
    fontFamilty: 'Martel Sans',
    fontSize: '12px',
    color: '#CFCFCF',
  }
});

export default withStyles(useStyles)(CustomModalAddToCart)