import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import { Container, Grid, Button } from '@material-ui/core';
import HeaderImage from "assets/img/havilla/havilla_gift_header_image.png";
import ContentImage from "assets/img/havilla/havilla_gift_content_image.png";
import AwardIcon from "assets/img/havilla/award-icon.svg";
import CustomizeIcon from "assets/img/havilla/customize-icon.svg";
import DetailIcon from "assets/img/havilla/detail-icon.svg";
import Gift1 from "assets/img/havilla/havilla_gifts/gift_1.png";
import Gift2 from "assets/img/havilla/havilla_gifts/gift_2.png";
import Gift3 from "assets/img/havilla/havilla_gifts/gift_3.png";
import Gift4 from "assets/img/havilla/havilla_gifts/gift_4.png";
import Gift5 from "assets/img/havilla/havilla_gifts/gift_5.png";
import Gift6 from "assets/img/havilla/havilla_gifts/gift_6.png";
import { dictionary } from 'Dictionary';
import { ClientSideContext } from '../../ClientSideContext';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
    ...styles(theme),
    img: {
        width: '100%',
    },
    pageContainer: {
        padding: '0px'
    },
    thumbnailContainer: {
        maxHeight: '26rem',
        overflow: 'hidden'
    },
    contentContainer: {
        marginTop: '32px'
    },
    title :{
        marginLeft: '10px',
        marginRight: '10px',
        fontSize : '40px',
        textAlign : 'center',
        fontFamily: 'BlaakLight',
    },
    subTitle : {
        marginLeft: '10px',
        marginRight: '10px',
        fontSize : '16px',
        textAlign : 'center',
        fontFamily: 'Martel Sans',
        maxWidth : '1038px',
    },
    headlineTitle: {
        marginLeft: '10px',
        marginRight: '10px',
        marginTop : '80px',
        textAlign : 'center',
        fontFamily: 'BlaakLight',
        fontSize: '28px',
    },
    secondHeadlineTitle: {
        marginLeft: '10px',
        marginRight: '10px',
        marginTop : '50px',
        textAlign : 'center',
        fontFamily: 'BlaakLight',
        fontSize: '28px',
    },
    containerHeadlineHow: {
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '2rem',
        textAlign: 'center'
    },
    howImgContainer: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'right',
        },
    },
    titleIcon: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            marginTop: '0px',
        },
        marginTop: '20px',
        fontWeight: 'bold',
        fontSize: '16px',
        textAlign: 'center'
    },
    titleIconPoint1: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            marginTop: '0px',
            minHeight: '0px',
        },
        minHeight: '44.8px',
        marginTop: '20px',
        fontWeight: 'bold',
        fontSize: '16px',
        textAlign: 'center'
    },
    descIcon: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
        fontSize: '16px',
        marginTop: '16px',
    },
    howContentContainer: {
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '30px',
    },
    containerHowIconContent: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '30px'
        },
    },
    noteText: {
        marginTop: '48px',
        fontFamily: 'Martel Sans',
        fontSize: '12px',
    },
    buttonOkContainer: {
        marginTop: '40px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '15px',
            marginRight: '10px',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft : '40px',
        },
    },
    contentTitle : {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            marginRight: '10px',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft : '40px',
        },
        fontSize : '28px',
        fontFamily: 'BlaakLight',
    },
    contentText : {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            marginRight: '10px',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft : '40px',
        },
        marginTop : '24px',
        fontSize : '16px',
        fontFamily: 'Martel Sans',
        maxWidth : '500px'
    },
    photoContent : {
        marginLeft : '88px',
        marginRight : '88px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            marginRight: '10px',
        },
        marginBottom : '50px',
    },
    giftPhotoContainer : {
        padding : '25px'
    }
}));

function HavillaGift(props) {
    const classes = useStyles();
    const { language } = useContext(ClientSideContext)

    return (
        <Container maxWidth="xl" className={`${classes.container} ${classes.pageContainer}`}>
            <Fade in={true} timeout={700}>
                <div className={classes.thumbnailContainer}>
                    <img className={classes.img} src={HeaderImage} alt={`page header`} />
                </div>
            </Fade>
            <Fade in={true} timeout={700}>
                <Grid container justify='center' className={classes.contentContainer} spacing={3}>
                    <Grid item xs={12} className={classes.title}>
                        {dictionary['Havilla Gifts Title'][language]}
                    </Grid>
                    <Grid item xs={12} className={classes.subTitle}>
                        {dictionary['Havilla Gifts Sub Title'][language]}
                    </Grid>

                    <Grid item sm={12} className={classes.headlineTitle}>
                        {language === 'en' ? 'Why Havilla Gifts?' : 'Why Havilla Gifts?'}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}
                        container
                        justify='center'
                        alignItems='flex-start'
                        spacing={2}
                        className={classes.howContentContainer}
                    >
                        <Grid item xs={12} md={3} container justify="center" alignItems='flex-start' >
                            <Grid container item xs={4} md={12} justify="center" >
                                <img src={CustomizeIcon} alt="customize icon"></img>
                            </Grid>
                            <Grid container item xs={8} md={12} justify="center">
                                <Grid item md={9} xs={12} className={classes.titleIcon}>
                                    {dictionary['Havilla Gifts Point 1 Title'][language]}
                                </Grid>
                                <Grid item md={10} xs={12} className={classes.descIcon}>
                                    {dictionary['Havilla Gifts Point 1 Desc'][language]}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} container justify="center" alignItems='flex-start' className={classes.containerHowIconContent}>
                            <Grid container item xs={4} md={12} justify="center">
                                <img src={AwardIcon} alt="award icon"></img>
                            </Grid>
                            <Grid container item xs={8} md={12} justify="center">
                                <Grid item md={5} xs={12} className={classes.titleIcon}>
                                    {dictionary['Havilla Gifts Point 2 Title'][language]}
                                </Grid>
                                <Grid item md={10} xs={12} className={classes.descIcon}>
                                    {dictionary['Havilla Gifts Point 2 Desc'][language]}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} container justify="center" alignItems='flex-start' className={classes.containerHowIconContent}>
                            <Grid container item xs={4} md={12} justify="center">
                                <img src={DetailIcon} alt="detail icon"></img>
                            </Grid>
                            <Grid container item xs={8} md={12} justify="center">
                                <Grid item md={6} xs={12} className={classes.titleIcon}>
                                    {dictionary['Havilla Gifts Point 3 Title'][language]}
                                </Grid>
                                <Grid item md={10} xs={12} className={classes.descIcon}>
                                    {dictionary['Havilla Gifts Point 3 Desc'][language]}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sm={12} className={classes.secondHeadlineTitle}>
                        {language === 'en' ? 'Our Latest Handcrafted Gifts' : 'Our Latest Handcrafted Gifts'}
                    </Grid>
                    <Grid item container sm={12} className={classes.photoContent}>
                        <Grid item md={4} sm={12} className={classes.giftPhotoContainer}>
                            <img className={classes.img} src={Gift1} alt={`gift 1`} />
                        </Grid>
                        <Grid item md={4} sm={12} className={classes.giftPhotoContainer}>
                            <img className={classes.img} src={Gift2} alt={`gift 2`} />
                        </Grid>
                        <Grid item md={4} sm={12} className={classes.giftPhotoContainer}>
                            <img className={classes.img} src={Gift3} alt={`gift 3`} />
                        </Grid>
                        <Grid item md={4} sm={12} className={classes.giftPhotoContainer}>
                            <img className={classes.img} src={Gift4} alt={`gift 4`} />
                        </Grid>
                        <Grid item md={4} sm={12} className={classes.giftPhotoContainer}>
                            <img className={classes.img} src={Gift5} alt={`gift 5`} />
                        </Grid>
                        <Grid item md={4} sm={12} className={classes.giftPhotoContainer}>
                            <img className={classes.img} src={Gift6} alt={`gift 6`} />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <img className={classes.img} src={ContentImage} alt={`page content`} />
                    </Grid>
                    <Grid item xs={12} md={6} style={{
                        display : 'flex',
                        alignItems : 'center'
                    }}>
                        <div>
                            <Grid item xs={12} className={classes.contentTitle}>
                                {dictionary['Havilla Gifts Content Title'][language]}
                            </Grid>
                            <Grid item xs={12} className={classes.contentText}>
                                {dictionary['Havilla Gifts Content Desc'][language]}
                            </Grid>
                            <Grid item xs={11} className={classes.buttonOkContainer}>
                                <a target="_blank" href={`https://docs.google.com/forms/d/e/1FAIpQLSdYNTadMexhUfwwwQpfsIU6Tca0ebGbGmPB32T-z11B3Bcb-A/viewform`}>
                                    <Button variant="outlined" className={`${classes.buttonDark}`}>
                                        Send Inquiry
                                    </Button>
                                </a>
                            </Grid>
                        </div>

                    </Grid>
                </Grid>
            </Fade>
        </Container>
    )
}

export default HavillaGift