export const adminReducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_STATE':
            return action.value
        case 'SET_SNACKBAR_CONTENT':
            return {
                color : action.color,
                message : action.message,
            }
        default:
            return state
    }
}