import React, { PureComponent } from 'react'
import ButtonAdmin from "admin/components/CustomButtons/Button.js";
import CloseIcon from "assets/img/havilla/close_icon.svg";
import { Grid, withStyles, Dialog, DialogTitle, DialogContent, DialogActions, TextField, MenuItem } from '@material-ui/core';
import CustomTextField from 'client-side/components/CustomTextField/CustomTextField';
import Card from 'admin/components/Card/Card';
import CardHeader from 'admin/components/Card/CardHeader';
import CardBody from 'admin/components/Card/CardBody';
import Button from "admin/components/CustomButtons/Button.js";
import MaterialTable from 'material-table';
import { AdminContext } from '../../AdminContext';
import Confirmation from 'admin/components/CustomDialogs/Confirmation';
import axios from 'axios';
import { baseUrl ,requestConfigAdmin } from 'Environment';
import Autocomplete from '@material-ui/lab/Autocomplete';

const CustomTextfieldRaw = withStyles({
    root: {
        width: '100%',
        '& label.Mui-focused': {
            color: 'black',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);

const required_fields = [
    'form_type',
    'form_value',
]

export class VoucherRequirement extends PureComponent {
    static contextType = AdminContext;
    constructor(props) {
        super(props)
    
        this.state = {
            type : 'update',
            modalType : 'add',
            modalOpen : false,
            tempDeleted : null,
            openDialog : false,

            voucher_id : '',

            voucher_requirements: [],
            form_type : "",
            form_type_error : "",

            form_value : "",
            form_value_error : "",
            
            oldData : null,

            requirement_types : [
                {
                    id : 'total_amount',
                    name : 'Total Amount'
                },
                {
                    id : 'city',
                    name : 'City'
                },
                {
                    id : 'total_quantity',
                    name : 'Total Quantity'
                },
                {
                    id : 'user_id',
                    name : 'User'
                },
                {
                    id : 'max_per_user',
                    name : 'Max Usage per User'
                },
            ],

            customer_users : [{ value: '', label: '' }],

            province_id : '',
            list_provinces : [{ value: '', label: '' }],
            list_cities : [{ value: '', label: '', province_id : '' }],
            list_all_cities : [],
            disable_city : true,
        }

        this.form_type_ref = React.createRef();
        this.form_value_ref = React.createRef();
    }

    disableCity = () =>{
        this.setState({
            disable_city : true
        })
    }

    enableCity = () =>{
        this.setState({
            disable_city : false
        })
    }

    cleanForm = () =>{
        this.setState({
            form_type : "",
            form_value : "",
        })
    }

    getCities = () =>{
        axios.get(`${baseUrl}/rajaongkir/get_cities`, requestConfigAdmin).then((res) => {
            if (res.data.rajaongkir.status.code === 200) {
                this.setState({
                    list_all_cities: res.data.rajaongkir.results.map(item => {
                        return (
                            {
                                value: item.city_id,
                                label: item.type + ' ' + item.city_name,
                                province_id : item.province_id
                            }
                        )
                    })
                }, ()=>{
                });
            } else {
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            console.log("Error : ", e);
        })
    }

    getProvinces = () =>{
        axios.get(`${baseUrl}/rajaongkir/get_provinces`,requestConfigAdmin).then((res) => {
            if (res.data.rajaongkir.status.code === 200) {
                this.setState(previousState => ({
                    list_provinces: [...previousState.list_provinces,...res.data.rajaongkir.results.map(item => {
                        return (
                            {
                                value: item.province_id,
                                label: item.province
                            }
                        )
                    })]
                }), ()=>{
                });
            } else {
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            console.log("Error : ", e);
        })
    }
    
    onProvinceChange = (e, newValue) => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        this.disableCity();

        if (newValue) {
            this.setState({
                province_id: newValue.value,
                form_value : ''
            },()=>{
                axios.get(`${baseUrl}/rajaongkir/get_city_by_province/${newValue.value}`,requestConfigAdmin).then((res) => {
                    if (res.data.rajaongkir.status.code === 200) {
                        this.setState({
                            list_cities: [{ value: '', label: '' },...res.data.rajaongkir.results.map(item => {
                                return (
                                    {
                                        value: item.city_id,
                                        label: item.type + ' ' + item.city_name,
                                        province_id : item.province_id
                                    }
                                )
                            })]
                        }, ()=>{
                            this.enableCity();
                            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                        });
                    } else {
                        console.log(res.data.message, res.data.data)
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                    }
                }, (e) => {
                    console.log("Error : ", e);
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                })

            })

        }
        else {
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
        }
    }

    setUpCityOnUpdate = (province_id, city_id) => {
        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
        this.disableCity();

        this.setState({
            province_id: province_id,
            form_value : city_id
        },()=>{
            axios.get(`${baseUrl}/rajaongkir/get_city_by_province/${province_id}`,requestConfigAdmin).then((res) => {
                if (res.data.rajaongkir.status.code === 200) {
                    this.setState({
                        list_cities: [{ value: '', label: '' },...res.data.rajaongkir.results.map(item => {
                            return (
                                {
                                    value: item.city_id,
                                    label: item.type + ' ' + item.city_name,
                                    province_id : item.province_id
                                }
                            )
                        })]
                    }, ()=>{
                        this.enableCity();
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                    });
                } else {
                    console.log(res.data.message, res.data.data)
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                }
            }, (e) => {
                console.log("Error : ", e);
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            })

        })
    }

    onAutocompleteChange = (e, newValue) => {
        if (newValue != null)
            this.setState({ form_value: newValue.value }, () => {
            });
    }

    getCustomerUsers = () =>{
        axios.get(`${baseUrl}/users/get_customer_users`, requestConfigAdmin).then((res) => {
            if (res.data.success) {
                this.setState(previousState => ({
                    customer_users: [{ value: '', label: '' },...res.data.data.map(item => {
                        return (
                            {
                                value: item.id,
                                label: `${item.first_name} ${item.last_name} (${item.email} - ${item.phone_number})`
                            }
                        )
                    })]
                }), ()=>{
                });
            } else {
                this.context.dispatchSnackbarContent({
                    type: "SET_SNACKBAR_CONTENT",
                    color: "danger",
                    message: res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.context.dispatchSnackbarContent({
                type: "SET_SNACKBAR_CONTENT",
                color: "danger",
                message: e.message
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            console.log("Error : ", e);
        })
    }

    getVoucherRequirements = () =>{
        let data = {
            voucher_id: this.state.voucher_id,
        }
        axios.post(`${baseUrl}/voucher_requirements/get_requirement_by_voucher`,
            data,
            requestConfigAdmin
        ).then((res)=>{
            if(res.data.success){
                let data = res.data.data;
                this.setState({
                    voucher_requirements : data,
                },()=>{
                })
            }else{
                this.context.dispatchSnackbarContent({ 
                    type: "SET_SNACKBAR_CONTENT", 
                    color : "danger",
                    message : res.data.message
                })
                this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                console.log(res.data.message,res.data.data)
            }
        },(e)=>{
            this.context.dispatchSnackbarContent({ 
                type: "SET_SNACKBAR_CONTENT", 
                color : "danger",
                message : "Error"
            })
            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
            console.log("Error : ",e);
        })
    }

    componentDidMount(){
        const id = this.props.match.params.id;
        this.getCustomerUsers()
        this.getProvinces()
        this.getCities()
        if(id !== "add"){
            this.setState({
                type : "update",
                voucher_id : id
            },()=>{
                this.getVoucherRequirements()
            }) 
        }else{
            this.setState({
                type : "add"
            }) 
        }
    }

    componentDidUpdate(nextProps, nextState){
        for (let i = 0; i < required_fields.length; i++) {
            let field_name = required_fields[i];
            if(this.state[field_name+"_error"] !== ""){
                if(this.state[field_name] !== nextState[field_name]){
                    this.setState({ [field_name+"_error"]: "" });
                }
            }
        }

        return true;
    }
    
    handleToggleModal = (type, data) => {
        if(type === 'update'){
            let city = null
            if(data.type === 'city'){
                city = this.state.list_all_cities[this.state.list_all_cities.findIndex(city => city.value.toString() === data.value.toString())]
            }
            this.setState({
                form_type : data.type,
                form_value : data.value,
                province_id : city ? city.province_id : '',
                modalType : 'update',
                oldData : data
            },()=>{
                if(data.type === 'city'){
                    this.setUpCityOnUpdate(city.province_id,data.value);
                }
            })
        }else{
            this.cleanForm();
            this.setState({
                modalType : 'add',
            })
        }
        this.setState((prevState) => ({
            modalOpen: !prevState.modalOpen
        }),()=>{
        })
    }

    handleValidation = () =>{
        let is_ok = true;
        for (let i = 0; i < required_fields.length; i++) {
            let field_name = required_fields[i];
            let field_name_text = required_fields[i].replace('_',' ');
            field_name_text = field_name_text.replace('form','');

            if(this.state[field_name] === "" || this.state[field_name] === null || this.state[field_name].length === 0){
                is_ok = false;
                this[field_name+"_ref"].current.focus();
                this.setState({ [field_name+"_error"]: field_name_text+" is required" });
            }
        }
        return is_ok;
    }
    
    handleSubmitVoucherRequirements = () => {
        let is_ok = this.handleValidation();

        if(is_ok){
            if(this.state.type === 'add'){
                if(this.state.modalType === "add"){
                    let newData = {
                        type : this.state.form_type,
                        value : this.state.form_value,
                    }
                    this.setState(prevState => {
                        const voucher_requirements = [...prevState.voucher_requirements];
                        voucher_requirements.push(newData);
                        return { 
                            ...prevState, 
                            voucher_requirements,
                        };
                    },()=>{
                        this.handleToggleModal();
                        this.cleanForm();
                        this.props.setVoucherRequirements(this.state.voucher_requirements);
                    })
                }else{
                    let newData = {
                        ...this.state.oldData,
                        type : this.state.form_type,
                        value : this.state.form_value,
                    }
    
                    this.setState(prevState => {
                        const voucher_requirements = [...prevState.voucher_requirements];
                        voucher_requirements[voucher_requirements.indexOf(this.state.oldData)] = newData;
                        return { ...prevState, voucher_requirements };
                    },()=>{
                        this.handleToggleModal();
                        this.props.setVoucherRequirements(this.state.voucher_requirements);
                        this.cleanForm();
                    });
                }
            }else{
                if(this.state.modalType === "add"){
                    let data = {
                        voucher_id : this.state.voucher_id,
                        type : this.state.form_type,
                        value : this.state.form_value
                    }

                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
    
                    axios.post(`${baseUrl}/voucher_requirements`,
                        data,
                        requestConfigAdmin,
                    ).then((res)=>{
                        if(res.data.success){
                            this.getVoucherRequirements();
                            this.context.dispatchSnackbarContent({ 
                                type: "SET_SNACKBAR_CONTENT", 
                                color : "success",
                                message : "Add New Voucher Requirement Success!"
                            })
                            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                            this.handleToggleModal();
                            this.cleanForm();
                            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                            
                        }else{
                            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            
                            this.context.dispatchSnackbarContent({ 
                                type: "SET_SNACKBAR_CONTENT", 
                                color : "danger",
                                message : res.data.message
                            })
                            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                            console.log(res.data.message,res.data.data)
                        }
                    },(e)=>{
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            
                        this.context.dispatchSnackbarContent({ 
                            type: "SET_SNACKBAR_CONTENT", 
                            color : "danger",
                            message : "Error"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log("Error : ",e);
                    })
                }else{
                    let data = {
                        type : this.state.form_type,
                        value : this.state.form_value
                    }
                    
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
    
                    axios.put(`${baseUrl}/voucher_requirements/${this.state.oldData.id}`,
                        data,
                        requestConfigAdmin,
                    ).then((res)=>{
                        if(res.data.success){
                            this.getVoucherRequirements();
                            this.context.dispatchSnackbarContent({ 
                                type: "SET_SNACKBAR_CONTENT", 
                                color : "success",
                                message : "Update Voucher Requirement Success!"
                            })
                            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                            this.handleToggleModal();
                            this.cleanForm();
                            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                            
                        }else{
                            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            
                            this.context.dispatchSnackbarContent({ 
                                type: "SET_SNACKBAR_CONTENT", 
                                color : "danger",
                                message : res.data.message
                            })
                            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                            console.log(res.data.message,res.data.data)
                        }
                    },(e)=>{
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
            
                        this.context.dispatchSnackbarContent({ 
                            type: "SET_SNACKBAR_CONTENT", 
                            color : "danger",
                            message : "Error"
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log("Error : ",e);
                    })
                }
            }
        }
    }

    handleDeleteVoucherRequirement = (data) => {
        this.setState({
            tempDeleted : data,
            openDialog : true,
        })
    }

    handleCloseConfirmationDialog = (action) =>{
        if(action){
            if(this.state.type === 'add'){
                this.setState(prevState => {
                    const voucher_requirements = [...prevState.voucher_requirements];
                    voucher_requirements.splice(voucher_requirements.indexOf(this.state.tempDeleted), 1);
                    return { ...prevState, voucher_requirements };
                },() =>{
                    this.setState({
                        tempDeleted : null,
                        openDialog : false,
                    })
                    this.props.setVoucherRequirements(this.state.voucher_requirements);
                });
            }else{
                this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: true })
                axios.delete(`${baseUrl}/voucher_requirements/${this.state.tempDeleted.id}`,
                    requestConfigAdmin
                ).then((res)=>{
                    if(res.data.success){
                        this.getVoucherRequirements();
                        this.setState({
                            tempDeleted : null,
                            openDialog : false,
                        },()=>{
                            this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })
                            this.context.dispatchSnackbarContent({ 
                                type: "SET_SNACKBAR_CONTENT", 
                                color : "success",
                                message : "Voucher Requirement Deleted"
                            })
                            this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        })
                    }else{
                        this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                        this.context.dispatchSnackbarContent({ 
                            type: "SET_SNACKBAR_CONTENT", 
                            color : "danger",
                            message : res.data.message
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                        console.log(res.data.message,res.data.data)
                    }
                },(e)=>{
                    this.context.dispatchToggleBackdrop({ type: "TOGGLE_STATE", value: false })

                    this.context.dispatchSnackbarContent({ 
                        type: "SET_SNACKBAR_CONTENT", 
                        color : "danger",
                        message : e
                    })
                    this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    console.log("Error : ",e);
                })
            }
        }else{
            this.setState({
                tempDeleted : null,
                openDialog : false,
            })
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        if(e.target.name === 'form_type'){
            if(e.target.value == 'total_amount' || e.target.value == 'total_quantity' || e.target.value == 'max_per_user'){
                for (let i = 0; i < this.state.voucher_requirements.length; i++) {
                    const vr = this.state.voucher_requirements[i];
                    if(vr.type == e.target.value){
                        this.setState({
                            form_type : ''
                        })
                        this.context.dispatchSnackbarContent({ 
                            type: "SET_SNACKBAR_CONTENT", 
                            color : "danger",
                            message : 'Failed to store voucher requirement, there are already requirement with this type on this voucher'
                        })
                        this.context.dispatchToggleSnackbar({ type: "TOGGLE_STATE" , value : true})
                    }
                }
            }
            this.setState({
                form_value : '',
                province_id : '',
            })
        }

    };

    renderFormValueInput = () =>{
        let display_input_number = false;
        let display_input_city = false;
        let display_input_user = false;

        if(this.state.form_type !== ''){
            if(this.state.form_type === 'total_amount' || this.state.form_type === 'total_quantity' || this.state.form_type === 'max_per_user'){
                display_input_number = true;
            }else if(this.state.form_type === 'city'){
                display_input_city = true;
            }else if(this.state.form_type === 'user_id'){
                display_input_user = true;
            }
        }

        if(display_input_number || display_input_city || display_input_user){
            return <React.Fragment>
                <Grid item md={12} xs={12} 
                style={{
                    display : display_input_number ? 'block' : 'none'
                }}>
                    <CustomTextField
                        value={this.state.form_value}
                        inputRef={this.form_value_ref}
                        error={this.state.form_value_error !== ""}
                        helperText={this.state.form_value_error}
                        type="number" onChange={this.onChange} name="form_value" label="Value" />
                </Grid>
                <Grid item md={12} xs={12} 
                style={{
                    display : display_input_city ? 'block' : 'none'
                }}>
                    <Autocomplete
                        value={this.state.list_provinces[this.state.list_provinces.findIndex(province => province.value.toString() === this.state.province_id.toString())] || this.state.list_provinces[0]}
                        name="province_id"
                        onChange={this.onProvinceChange}
                        options={this.state.list_provinces}
                        getOptionLabel={(option) => option.label}
                        // getOptionLabel={(option) => option.title}
                        renderInput={(params) => <CustomTextfieldRaw {...params}
                            label="Province" />}
                    />
                </Grid>
                <Grid item md={12} xs={12}
                style={{
                    display : display_input_city ? 'block' : 'none'
                }}>
                    {
                        this.state.province_id !== '' ?
                        <Autocomplete
                            value={this.state.list_cities[this.state.list_cities.findIndex(city => city.value.toString() === this.state.form_value.toString())] || this.state.list_cities[0]}
                            disabled={this.state.disable_city}
                            name="city_id"
                            onChange={this.onAutocompleteChange}
                            options={this.state.list_cities}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <CustomTextfieldRaw {...params} label="City" />}
                        />
                    :
                        <CustomTextField
                            disabled
                            width="100%" label="Please Select Province First" />
                    }
                </Grid>
                <Grid item md={12} xs={12} 
                style={{
                    display : display_input_user ? 'block' : 'none'
                }}>
                    <Autocomplete
                        value={this.state.customer_users[this.state.customer_users.findIndex(user => user.value.toString() === this.state.form_value.toString())] || this.state.customer_users[0]}
                        name="user_id"
                        onChange={this.onAutocompleteChange}
                        options={this.state.customer_users}
                        getOptionLabel={(option) => option.label}
                        // getOptionLabel={(option) => option.title}
                        renderInput={(params) => <CustomTextfieldRaw {...params}
                            label="User" />}
                    />
                </Grid>
            </React.Fragment>
        }else{
            return <Grid item md={12} xs={12}>
                <CustomTextField
                    disabled
                    width="100%" 
                    type="number" label="Please Select Requirement Type First" />
            </Grid>
        }
    }
    render() {
        const {classes} = this.props
        const columns_voucher_requirements = [
            { 
                title: 'Type', 
                field: 'type',
                render: (rowData) => {
                    let type = this.state.requirement_types[this.state.requirement_types.findIndex(type => type.id.toString() === rowData.type.toString())]
                    return (
                        type.name
                    )
                }
            },
            { 
                title: 'Value', 
                field: 'value',
                render: (rowData) => {
                    let value_table = '';
                    if(rowData.type === 'total_amount' || rowData.type === 'total_quantity' || rowData.type === 'max_per_user'){
                        value_table = rowData.value
                    }else if(rowData.type === 'city'){
                        let city = this.state.list_all_cities[this.state.list_all_cities.findIndex(city => city.value.toString() === rowData.value.toString())]
                        value_table = city.label
                    }else if(rowData.type === 'user_id'){
                        let user = this.state.customer_users[this.state.customer_users.findIndex(user => user.value.toString() === rowData.value.toString())]
                        value_table = user.label
                    }
                    return (
                        value_table
                    )
                }
            },
            { 
                title: '', 
                render: (rowData) => {
                    return (
                        <Grid container>
                            <Grid item container xs={9} justify="center">
                                <Grid item xs={6} container justify="center">
                                    <ButtonAdmin className={classes.buttonTable} color="info" 
                                        onClick={()=>this.handleToggleModal('update',rowData)}
                                    >
                                        EDIT
                                    </ButtonAdmin>
                                </Grid>
                                <Grid item xs={6} container justify="center">
                                    <ButtonAdmin className={classes.buttonTable} color="danger" onClick={() => this.handleDeleteVoucherRequirement(rowData)}>DELETE</ButtonAdmin>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            },
        ]
        
        return (
            <React.Fragment>
                <Confirmation
                    openDialog={this.state.openDialog}
                    message={`You are about to delete voucher requirement <b>${this.state.tempDeleted ? this.state.requirement_types[this.state.requirement_types.findIndex(type => type.id.toString() === this.state.tempDeleted.type.toString())].name : ""}</b>, 
                    are you sure you want to delete it?`}
                    handleCloseDialog={this.handleCloseConfirmationDialog}
                />
                <Dialog 
                    fullWidth={true}
                    maxWidth="md"
                    open={this.state.modalOpen} 
                    onClose={this.handleToggleModal}>
                    <DialogTitle>Add New Voucher Requirements</DialogTitle>
                    <DialogContent>
                            <img className={classes.closeIconModal} src={CloseIcon} alt="close icon" onClick={this.handleToggleModal}></img>
                            <Grid container>
                                <Grid item container xs={12} className={classes.contentContainerModal}>
                                    <Grid container className={classes.formContainer} spacing={4}>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextfieldRaw
                                                select
                                                label="Requirement Type"
                                                name="form_type"
                                                value={this.state.form_type}
                                                inputRef={this.form_type_ref}
                                                error={this.state.form_type_error !== ""}
                                                helperText={this.state.form_type_error}
                                                onChange={this.onChange}
                                            >
                                                {
                                                    this.state.requirement_types.map((requirement_type, i) => {
                                                        return <MenuItem value={requirement_type.id} key={requirement_type.id}>{requirement_type.name}</MenuItem>
                                                    })
                                                }
                                            </CustomTextfieldRaw>
                                        </Grid>
                                        {this.renderFormValueInput()}
                                    </Grid>
                                </Grid>
                            </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="success" style={{marginRight: '12px'}} onClick={this.handleSubmitVoucherRequirements}>
                            SAVE
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid item xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="gray">
                            <h4 className={classes.cardTitleWhite}>Voucher Requirements</h4>
                            <p>Handle Voucher Requirement</p>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <Grid item sm={12} md={12}>
                                    <ButtonAdmin color="info" onClick={()=>this.handleToggleModal('add')}>ADD NEW VOUCHER REQUIREMENT</ButtonAdmin>    
                                </Grid> 
                                <Grid item sm={12} md={12}>
                                    {
                                        this.state.list_all_cities.length > 0 ?
                                            <MaterialTable
                                                title={null}
                                                components={{
                                                    Toolbar: props => (
                                                        null
                                                    )                                    
                                                }}
                                                options={{
                                                    search: false,
                                                    exportButton: false,
                                                    pageSize: 5,
                                                    actionsColumnIndex : 6
                                                }}
                                                columns={columns_voucher_requirements}
                                                data={this.state.voucher_requirements}
                                            />
                                        :
                                            null
                                    }
                                </Grid>
                            </Grid>
                        </CardBody>
                    </Card>
                </Grid>
            </React.Fragment>
        )
    }
}

export default (VoucherRequirement)
