import React, { useContext } from 'react'
import TextField from '@material-ui/core/TextField';
import SearchIcon from "client-side/components/SearchField/search_icon.svg";

import { withStyles } from '@material-ui/core/styles';

import { ClientSideContext } from '../../ClientSideContext';
import { dictionary } from '../../../Dictionary';
const searchContainer = {
    position: "relative",
    width : '15%',
}
const searchIcon = {
    cursor : "pointer",
    position: "absolute",
    top: "5px",
    right: "-24px"
}
const Custom = withStyles({
    root: {
        transition: 'all 0.5s cubic-bezier(0.19, 1, 0.22, 1)',
        width: "88%",
        '& label': {
            color: 'white',
        },
        '& label.Mui-disabled': {
            color: 'grey',
        },
        '& .MuiInput-root': {
            color: 'white',
            '&:hover fieldset': {
                borderBottomColor: 'red',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#cfcfcf',
        },
        '& .MuiInput-root:hover': {
            '.MuiInput-underline:before': {
                borderBottomColor: '#cfcfcf',
            },
        },
        '& .MuiInput-underline-hover:before': {
            borderBottomColor: 'white',
        },
        '& label.Mui-focused': {
            color: '#AF986D',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#AF986D',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);

export default function TabNav(props) {
    const context = useContext(ClientSideContext)
    return (
        <label style={searchContainer}>
            <Custom 
                onChange={(e) => props.onChange(e,e.target.value)} 
                name='search'
                type='text'
                value={props.value} 
                placeholder={dictionary["Search here"][context.language]}
            />
            { <img src={SearchIcon} style={searchIcon} alt="search icon"></img>}
        </label>
    )
}