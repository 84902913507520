import React, { useContext } from 'react'
import { makeStyles, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';

import { dictionary } from 'Dictionary';
import {ClientSideContext} from '../../ClientSideContext';

const useStyles = makeStyles((theme) => ({
    radioRoot: {
        '& + $label': {
            color: 'white',
        },
        '&': {
            color: 'white',
        },
    },
    radioChecked: {
        '&, & + $label': {
            color: '#AF986D'
        },
        '&': {
            color: '#AF986D',
        }
    },
    radioDisabled: {
        '&, & + $label': {
            color: 'grey',
        },
    },
    label: {},
    buttonRegister :{
        marginTop : '68px'
    },
    formControlRadio : {
        [theme.breakpoints.down('sm')]: {
            marginRight : '12px',
            width:'55%'
        },
    }
}));

function GenderRadioButton(props) {
    const classes = useStyles();
    const {language} = useContext(ClientSideContext)
    return (
        <FormControl component="fieldset" error={props.error} disabled={props.disabled} className={classes.formControlRadio}>
            <RadioGroup style={{paddingTop:'12px'}} row aria-label="gender" name="gender" value={props.value} onChange={props.onChange}>
                <FormControlLabel 
                    classes={{label : classes.label}} 
                    value="male" control={
                        <Radio classes={{
                            root: classes.radioRoot,
                            checked: classes.radioChecked,
                            disabled: classes.radioDisabled,
                        }} 
                        color='white'/>
                    } 
                    label={dictionary['Male'][language]} 
                />
                <FormControlLabel 
                    classes={{label : classes.label}} 
                    value="female" 
                    control={
                        <Radio classes={{
                            root: classes.radioRoot,
                            checked: classes.radioChecked,
                            disabled: classes.radioDisabled,
                        }} 
                        color='white' />
                    } 
                    label={dictionary['Female'][language]} 
                />
            </RadioGroup>
            <FormHelperText style={{
                position : "absolute",
                bottom : '-1.2rem',
                textTransform: 'capitalize'
            }}>{props.gender_error}</FormHelperText>
        </FormControl>
    )
}

export default GenderRadioButton
