import React, { Component, PureComponent } from 'react';
// import DateFnsUtils from "@date-io/date-fns"; // choose your libz
// import {
// //     DatePicker,
// //     TimePicker,
// //     DateTimePicker,
//     KeyboardDatePicker,
//     MuiPickersUtilsProvider,
// } from '@material-ui/pickers';
import { TextField, withStyles } from '@material-ui/core';
import { dictionary } from '../../../Dictionary';
import { ClientSideContext } from '../../ClientSideContext';


export class Datepicker extends PureComponent {
    static contextType = ClientSideContext;

    handleChange = (e) => {
        this.props.handleDateChange(e.target.value)
    }
    render() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = `${yyyy}-${mm}-${dd}`

        const CustomTextField = withStyles({
            root: {
                width: this.props.width ? this.props.width : "80%",
                '& label': {
                    color: 'white',
                },
                '& label.Mui-disabled': {
                    color: 'grey',
                },
                '& .MuiInput-root': {
                    color: '#AF986D',
                    '&:hover fieldset': {
                        borderBottomColor: 'red',
                    },
                },
                '& .MuiInput-underline:before': {
                    borderBottomColor: 'white',
                },
                '& .MuiInput-root:hover': {
                    '.MuiInput-underline:before': {
                        borderBottomColor: 'white',
                    },
                },
                '& .MuiInput-underline-hover:before': {
                    borderBottomColor: 'white',
                },
                '& label.Mui-focused': {
                    color: '#AF986D',
                },
                '& label.Mui-focused.Mui-error': {
                    color: 'red',
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: '#AF986D',
                },
                '& .MuiInput-underline.Mui-error:after': {
                    borderBottomColor: 'red',
                },
                '& .MuiInput-underline.Mui-error': {
                    color: 'red',
                },
                '& .MuiFormHelperText-root': {
                    position: "absolute",
                    bottom: '-1.5rem',
                    textTransform: 'capitalize'
                },
                '& .MuiSvgIcon-root': {
                    color: 'white',
                },
            },
        })((props) => <TextField {...props} />);

        return (
            <CustomTextField
                id="date"
                label="Birthday"
                type="date"
                InputLabelProps={{
                    shrink: true,
                }}
                error={this.props.error}
                disabled={this.props.disabled}
                helperText={this.props.helperText}
                name={this.props.name}
                inputRef={this.props.inputRef}
                id={this.props.id ? this.props.id : "date-picker-dialog"}
                label={this.props.label ? this.props.label : dictionary['Date of Birth'][this.context.language]}
                defaultValue={this.props.value ? this.props.value : ''}
                onBlur={this.handleChange}
                InputProps={this.props.name === 'delivery_date' ? { inputProps: { min: today } } : null}
                placeholder='dd/mm/yyyy'
            />
            // <MuiPickersUtilsProvider utils={DateFnsUtils}>
            //     <KeyboardDatePicker 
            //         error={this.props.error}   
            //         disabled={this.props.disabled}   
            //         helperText={this.props.helperText}
            //         name={this.props.name}
            //         inputRef={this.props.inputRef}
            //         TextFieldComponent={CustomTextField}
            //         id={this.props.id ? this.props.id : "date-picker-dialog"}
            //         label={this.props.label ? this.props.label : "Date of Birth"}
            //         format="d MMM yyyy"
            //         value={this.props.value}
            //         onChange={this.handleChange}
            //         KeyboardButtonProps={{
            //             'aria-label': 'change date',
            //         }}
            //     />
            // </MuiPickersUtilsProvider>
        )
    }
}

export default Datepicker
