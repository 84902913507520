import React from 'react'
import Flickity from "react-flickity-component";
import "assets/css/flickity.css";
import slider1 from "assets/img/havilla/slider1.png";
import slider2 from "assets/img/havilla/slider2.png";
import slider3 from "assets/img/havilla/slider3.png";
// import { makeStyles } from '@material-ui/core';
// import styles from "assets/jss/client-side/App.js";
import TeaShopCarouseItem from './TeaShopCarouseItem';
import {isMobile} from "react-device-detect";

// const useStyles = makeStyles((theme) => ({
//     ...styles(theme),
// }));

function TeaShopCarousel(props) {
    // const classes = useStyles();
    // const [flkty, setflkty] = useState(null);
    const flickityOptions = {
        initialIndex: 0,
        pageDots: true,
        cellAlign: 'left',
        prevNextButtons: isMobile ? false : true,
        draggable: true,
        wrapAround: true,
        autoPlay: 4500
    }
    return (
        <React.Fragment>
            {
                props.sliders.length > 0 ?
                    <Flickity 
                        // flickityRef={c => setflkty(c)} 
                        options={flickityOptions}>
                            {
                                props.sliders.map(item => {
                                    // alert(window.location.pathname.split("/")[2]);
                                    return (
                                        <TeaShopCarouseItem 
                                            key={item.id}
                                            img={item.photo_url}
                                            title={item.title}
                                            desc={item.caption}
                                            linkText={item.button_text}
                                            linkTo={item.link_href}
                                        />
                                    )
                                })
                            }
                    </Flickity>
                :
                    null
            }
        </React.Fragment>
    )
}

export default TeaShopCarousel
