import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import SpecialPrice from "assets/img/havilla/special_price.svg";
import HeartEmpty from "assets/img/havilla/heart_empty.svg";
import HeartFilled from "assets/img/havilla/heart_filled.svg";
import Suddhabumi from "assets/img/havilla/Suddhabumi.png";
import {
    baseUrl,
    requestConfig,
    prefix
} from '../../../Environment.js';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/client-side/App.js";
import CustomModalAddToCart from '../CustomModals/CustomModalAddToCart.js';
import SnackbarComp from '../Snackbar/SnackbarComp.js';
import { ClientSideContext } from '../../ClientSideContext';
import { dictionary } from '../../../Dictionary';

const useStyles = theme => ({
    ...styles(theme),
    textField: {
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        [theme.breakpoints.up('md')]: {
        },
        width: "80%"
    },
    iconEdit: {
        marginTop: `-8px`
    },
    boxOutline: {
        padding: '1.2rem',
        width: '100%',
        height: 'auto',
        minHeight: '210px',
        maxHeight: '250px',
        backgroundColor: '#121212',
    },
    productTitle: {
        fontSize: '1.5rem',
        cursor: 'pointer',
        color: 'white',
        '&:hover': {
            textDecoration: 'none',
            color: '#AF986D',
        },
        '&:focus': {
            color: '#AF986D',
        }
    },
    productSubCategory: {
        fontSize: '0.7rem',
        color: '#AF986D',
        border: '1px solid #AF986D',
        borderRadius: '16px',
        padding: '2px 10px 0',
        textTransform: 'uppercase',
        float: 'right',
        width: 'auto'
    },
    productBackground: {
        // background: '#f0f0f0',
        // [theme.breakpoints.up('sm')]: {
        //     minHeight: '451px',
        // },
        height: 'auto',
        // padding: '40px 0',
        position: 'relative'
    },
    opacitySoldOut : {
        opacity : '0.5'
    },
    heartIconFilled: {
        position: 'absolute',
        width: '2rem',
        height: '2rem',
        right: '7%',
        top: "7%",
    },
    heartIconEmpty: {
        position: 'absolute',
        width: '2rem',
        height: '2rem',
        right: '7%',
        top: "7%",
    },
    soldOutText: {
        color: '#AF986D',
        fontSize: "14px",
        fontFamilty : 'Martel Sans',
        fontWeight : 'bold',
    },
    specialPriceBanner : {
        width : '96px',
        height : '96px',
        position : 'absolute',
    }
});
const borderStandart = {
    // border: '1px solid #f0f0f0',
}

export class ProductCard extends Component {
    static contextType = ClientSideContext;
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            wishlisted: false,
            buttonType: '',

            snackbarType: 'info',
            snackbarMessage: '',
            snackbarCounter: 0,
        }
    }

    componentDidMount() {
        this.setState({
            wishlisted: this.props.detail.wishlisted
        })
    }

    handleToggleWishlist = async (product_id) => {
        let auth = localStorage.getItem('auth') ? true : false;
        if (auth) {
            await axios.get(`${baseUrl}/toggle_wishlist/${product_id}`,
                requestConfig)
                .then((res) => {
                    if (res.data.success) {
                        this.setState((prevState) => ({
                            wishlisted: !prevState.wishlisted
                        }), () => {
                        })
                    } else {
                        console.log(res.data.message, res.data.data)
                    }
                }, (e) => {
                    console.log("Error : ", e);
                })
        } else {
            this.handleOpenSnackbar({
                type: "error",
                message: `You Must Logged In to Add This Item to Your Wishlist`
            })
        }

    }

    handleCloseModal = (newValue) => {
        this.setState({
            modalOpen: newValue
        })
    }

    handleAddToCart = async (own_product_id, buttonType, is_active) => {
        if(is_active){
            const { type } = this.props;
    
            if (type !== 'own') {
                // add to cart for reguler product
                this.setState({
                    modalOpen: true,
                    buttonType: buttonType
                })
            }
            else {
                // add to cart for own blend product
                await axios.post(`${baseUrl}/add_to_cart_own`,
                    { own_product_id: own_product_id },
                    requestConfig)
                    .then((res) => {
                        if (res.data.success) {
                            this.handleOpenSnackbar({
                                type: "success",
                                message: "Success Added to Cart"
                            })
                        } else {
                            console.log(res.data.message, res.data.data)
                        }
                    }, (e) => {
                        console.log("Error : ", e);
                    })
            }
        }else{
            this.handleOpenSnackbar({
                type: "error",
                message: `${dictionary['Sold Out Message'][this.context.language]}`
            })
        }
    }

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    render() {
        const { classes } = this.props;
        const { type, detail } = this.props;

        return (
            <React.Fragment>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.snackbarCounter}
                />
                {
                    type !== 'own' ?
                        // for product that have sub products
                        <CustomModalAddToCart
                            type={this.state.buttonType}
                            openCart={this.props.openCart}
                            products={detail}
                            open={this.state.modalOpen}
                            handleCloseModal={this.handleCloseModal}
                            is_own={0}
                            thumbnail={this.props.thumbnail}
                        ></CustomModalAddToCart>
                        :
                        // for my own blend product
                        ''
                }
                <Grid container className={`${classes.productBackground} ${detail.is_active == 0 ? classes.opacitySoldOut : ''}`}
                // alignItems="center" justify="center"
                >

                    {type !== 'own' ?
                        (
                            this.state.wishlisted === true ?
                                <img onClick={() => this.handleToggleWishlist(detail.id)}
                                    src={HeartFilled} alt='wishlist icon filled'
                                    className={`${classes.heartIconFilled} 
                                    ${classes.linkPointer}`}></img>
                                :
                                <img onClick={() => this.handleToggleWishlist(detail.id)}
                                    src={HeartEmpty}
                                    alt='wishlist icon empty'
                                    className={`${classes.heartIconEmpty} ${classes.linkPointer}`}></img>
                        )
                        :
                        ''
                    }
                    <Link to={prefix + `/product/${detail.url_slug}`}>
                        {
                            this.props.thumbnail !== null ?
                                <React.Fragment>
                                    {
                                        detail.sub_product_have_discount ?
                                            <img src={SpecialPrice} className={classes.specialPriceBanner} alt='special price banner'></img>
                                        :
                                            null
                                    }
                                    <img src={this.props.thumbnail} style={{ width: '100%', height: '100%' }} alt='product img'></img>
                                </React.Fragment>
                            :
                                // INSERT DEFAULT NO PHOTO HERE
                                <React.Fragment>
                                    {
                                        detail.sub_product_have_discount ?
                                            <img src={SpecialPrice} className={classes.specialPriceBanner} alt='special price banner'></img>
                                        :
                                            null
                                    }
                                    <img src={Suddhabumi} style={{ width: '100%', height: '100%'}} alt='product img'></img>
                                </React.Fragment>
                        }
                    </Link>
                </Grid>
                <div className={`${classes.boxOutline} ${detail.is_active == 0 ? classes.opacitySoldOut : ''}`} style={borderStandart}>
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            {detail.is_new ?
                                <React.Fragment>
                                    <i style={{color:'gold'}}>{dictionary['NEW'][this.context.language]} 
                                    </i>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <i></i>
                                </React.Fragment>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            {
                                type !== 'own' ?
                                    detail.sub_category_id ?
                                        <span className={classes.productSubCategory}>
                                            {detail.product_sub_category.name}
                                        </span> :
                                        <span className={classes.productSubCategory}>
                                            {detail.product_category.name}
                                        </span>
                                    :
                                    <span className={classes.productSubCategory}>Unique Blend</span>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {type !== 'own' ?
                                <Link
                                    className={`${classes.productTitle}`}
                                    to={prefix + `/product/${detail.url_slug}`}>
                                    {
                                        detail.name.length > 25 ?
                                            detail.name.substring(0, 25) + ' ...'
                                            :
                                            detail.name
                                    }
                                </Link>
                                :
                                detail.name}
                        </Grid>
                        <Grid item xs={12}>
                            {
                                type !== 'own' ?
                                    detail ?
                                        <p>{
                                            this.context.language == 'en' ?
                                                detail.short_desc_eng.length > 80 ?
                                                    detail.short_desc_eng.substring(0, 80) + ' ...'
                                                    :
                                                    detail.short_desc_eng
                                                :
                                                detail.short_desc_id.length > 80 ?
                                                    detail.short_desc_id.substring(0, 80) + ' ...'
                                                    :
                                                    detail.short_desc_id
                                        }</p>
                                        : ''
                                    :
                                    `a Special Custom Blend By ${detail.maker_name}`
                            }
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '15px' }}>
                            {
                                detail.is_active == 0 ?
                                    <p className={`${classes.soldOutText}`}>{dictionary['SOLD OUT'][this.context.language]}</p>
                                :
                                    <React.Fragment>
                                        <button
                                            className={`${classes.linkPointerWUnderline} ${classes.linkGold} ${classes.linkStyledButton}`}
                                            onClick={() => this.handleAddToCart(detail.id, "atc", detail.is_active)}
                                        >
                                            {dictionary['Add to Cart'][this.context.language]}
                                            {/* Add to Cart */}
                                        </button>
                                        <button
                                            className={`${classes.linkPointerWUnderline} ${classes.linkGold} ${classes.linkStyledButton}`}
                                            onClick={() => this.handleAddToCart(detail.id, "gift",detail.is_active)}
                                            style={{ marginLeft: '15px' }}
                                        >
                                            {dictionary['Send as Gift'][this.context.language]}
                                            {/* Send as Gift */}
                                        </button>
                                    </React.Fragment>
                            }
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment >
        )
    }
}

export default withStyles(useStyles)(ProductCard) 
