import React from 'react'
import { withStyles, TextField } from '@material-ui/core';

const Custom = withStyles({
    root: {
        '& label': {
            color: 'white',
        },
        '& label.Mui-disabled': {
            color: 'grey',
        },
        '& .MuiInput-root': {
            color: '#AF986D',
            '&:hover fieldset': {
                borderBottomColor: 'red',
            },
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#cfcfcf',
        },
        '& .MuiInput-root:hover': {
            '.MuiInput-underline:before': {
                borderBottomColor: '#cfcfcf',
            },
        },
        '& .MuiInput-underline-hover:before': {
            borderBottomColor: 'white',
        },
        '& label.Mui-focused': {
            color: '#AF986D',
        },
        '& label.Mui-focused.Mui-error': {
            color: 'red',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#AF986D',
        },
        '& .MuiInput-underline.Mui-error:after': {
            borderBottomColor: 'red',
        },
        '& .MuiInput-underline.Mui-error': {
            color: 'red',
        },
        '& .MuiFormHelperText-root': {
            position: "absolute",
            bottom: '-1.5rem',
            textTransform: 'capitalize'
        }
    },
})(TextField);

function CustomTextField(props) {

    return (
        <Custom
            style={{
                width: props.width,
            }}
            {...props.otherOptions}
            value={props.value}
            error={props.error}
            helperText={props.helperText}
            type={props.type}
            onChange={props.onChange}
            name={props.name}
            label={props.label}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            inputRef={props.inputRef}
        />
    )
}

export default CustomTextField

CustomTextField.defaultProps = {
    width: "100%",
    type: "text",
    error: false,
    helperText: '',
    disabled: false,
    autoFocus: false,
}
