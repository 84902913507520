export const dictionary = {
    // Menu
    "Feeling": [{
        'en': "Energetic",
        'id': "Energik"
    }, {
        'en': "Relaxing",
        'id': "Menenangkan"
    }, {
        'en': "Fit",
        'id': "Fit"
    }, {
        'en': "Inspiring",
        'id': "Menginspirasi"
    }, {
        'en': "Delightful",
        'id': "Menyenangkan"
    }],
    // "Relaxing" : {
    //     'en' : "Relaxing",
    //     'id' : "Menenangkan"
    // },
    // "Fit" : {
    //     'en' : "Fit",
    //     'id' : "Fit"
    // },
    // "Inspiring" : {
    //     'en' : "Inspiring",
    //     'id' : "Menginspirasi"
    // },
    // "Delightful" : {
    //     'en' : "Delightful",
    //     'id' : "Menyenangkan"
    // },
    "Tea Shop": {
        'en': "Tea Shop",
        'id': "Toko Teh"
    },
    'Create Your Own': {
        'en': "Own Blend",
        'id': "Buat Racikan"
    },
    'Tea Talks': {
        'en': "Tea Talks",
        'id': "Obrolan Teh"
    },
    'About Us': {
        'en': "About Us",
        'id': "Tentang Kami"
    },
    // Homepage
    'First Section Title': {
        'en': 'Goodness and Happiness in A Cup of Tea',
        'id': 'Kebaikan dan Kebahagiaan melalui Secangkir Teh'
    },
    'First Section Desc': {
        'en': 'Delivering excellent tea experience, from the world to your place!',
        'id': 'Mengantarkan pengalaman teh yang istimewa ke tempatmu!'
    },
    'Button Create': {
        'en': 'Create Your Own Blend',
        'id': 'Buat Racikan Teh-Mu'
    },
    'Button Shop': {
        'en': 'Shop Selections',
        'id': 'Lihat Pilihan Produk'
    },
    "Quote": {
        'en': '“Because drinking tea isn’t merely a lifestyle, it’s a way of life.”',
        'id': '“Because drinking tea isn’t merely a lifestyle, it’s a way of life.”'
    },
    "Quote By": {
        'en': 'Neysa Valeria, Founder of Havilla Tea',
        'id': 'Neysa Valeria, Founder of Havilla Tea'
    },
    "Why": {
        'en': 'Why Havilla?',
        'id': 'Mengapa Havilla?'
    },
    "Why Point 1": {
        'en': 'Best Quality Ingredients Only',
        'id': 'Hanya Menyediakan Kualitas Terbaik'
    },
    "Why Point 2": {
        'en': 'International Food Safety Standard Certified',
        'id': 'Packing yang Bersih dan Aman'
    },
    "Why Point 3": {
        'en': 'Crafted & Curated by Tea Specialists',
        'id': 'Diracik oleh ahli Teh'
    },
    "Why Point 4": {
        'en': 'Supporting Local Producers',
        'id': 'Mendukung Produk dan Merk Lokal'
    },
    "Why Point 5": {
        'en': 'Unlimited Possibili-teas',
        'id': 'Racikan Teh tanpa batas'
    },
    "Best Tea": {
        'en': 'Our Best Tea Selection',
        'id': 'Pilihan Teh Terbaik Kami'
    },
    "Best Tea Link": {
        'en': 'Shop Tea',
        'id': 'Belanja Teh'
    },
    "Best Tisane": {
        'en': 'Curated Tisane for you',
        'id': 'Tisane Terbaik Untuk Teh-Mu'
    },
    "Best Tisane Link": {
        'en': 'Shop Tisane',
        'id': 'Belanja Tisane'
    },
    "TeaTalks": {
        'en': 'TeaTalks',
        'id': 'Obrolan Teh'
    },
    "PERSONAL DETAILS": {
        'en': 'PERSONAL DETAILS',
        'id': 'AKUN PERSONAL'
    },
    "SAVED ADDRESS": {
        'en': 'SAVED ADDRESS',
        'id': 'ALAMAT SAYA'
    },
    "Choose Address Icon": {
        'en': 'Choose Address Icon',
        'id': 'Pilih Icon'
    },
    "Let's Create New Address": {
        'en': "Let's Create New Address",
        'id': 'Ayo Buat Alamat Baru'
    },
    "Name this Address": {
        'en': 'Name this Address',
        'id': 'Beri Nama Alamat ini'
    },
    "Address Details": {
        'en': 'Address Details',
        'id': 'Detil Alamat'
    },
    "Add New Address": {
        'en': 'Add New Address',
        'id': 'Tambah Alamat'
    },
    "City": {
        'en': 'City',
        'id': 'Kota'
    },
    "Province": {
        'en': 'Province',
        'id': 'Provinsi'
    },
    "Zip Code (Wajib Harus Sesuai)": {
        'en': 'Zip Code (Wajib Harus Sesuai)',
        'id': 'Kode Pos (Wajib Harus Sesuai)'
    },
    "Number to Contact": {
        'en': 'Number to Contact',
        'id': 'Nomor Telepon'
    },
    "Phone Number": {
        'en': 'Phone Number',
        'id': 'Nomor Telepon'
    },
    "Special Notes": {
        'en': 'Special Notes',
        'id': 'Catatan Khusus'
    },
    "Default Address": {
        'en': 'Default Address',
        'id': 'Alamat Utama'
    },
    "Delete Address": {
        'en': 'Delete Address',
        'id': 'Hapus Alamat'
    },
    "Save Address": {
        'en': 'Save Address',
        'id': 'Simpan Alamat'
    },
    "Save Changes": {
        'en': 'Save Changes',
        'id': 'Simpan Perubahan'
    },
    "Change password": {
        'en': 'Change password',
        'id': 'Ubah Password'
    },
    "My Orders": {
        'en': 'My Orders',
        'id': 'Order Saya'
    },
    "My Account": {
        'en': 'My Account',
        'id': 'Akun Saya'
    },
    "My Own Blend": {
        'en': 'My Own Blend',
        'id': 'Racikan Saya'
    },
    "My Wishlist": {
        'en': 'My Wishlist',
        'id': 'Favorit Saya'
    },
    "Logout": {
        'en': 'Logout',
        'id': 'Keluar'
    },
    "See You Soon!": {
        'en': 'See You Soon!',
        'id': 'Sampai Bertemu Lagi!'
    },
    "Are You Sure You Want to Logout?": {
        'en': 'Are You Sure You Want to Logout?',
        'id': 'Apakah kamu yakin ingin keluar?'
    },
    "Are You Sure You Want to Logout?": {
        'en': 'Are You Sure You Want to Logout?',
        'id': 'Apakah kamu yakin ingin keluar?'
    },
    "Never Stop Creating!": {
        'en': 'Never Stop Creating!',
        'id': 'Jangan Berhenti Berkreasi!'
    },
    "Let’s Create Your Own Blend": {
        'en': "Let's Create Your Own Blend",
        'id': 'Ayo Buat Racikanmu Sendiri!'
    },
    "Let's Go!": {
        'en': "Let's Go!",
        'id': 'Ayo Mulai!'
    },
    "other items": {
        'en': 'other items',
        'id': 'produk lainnya'
    },
    "Deliver To": {
        'en': 'Deliver To',
        'id': 'Dikirim ke'
    },
    "On Delivery": {
        'en': 'On Delivery',
        'id': 'Dalam Pengiriman'
    },
    "On Process": {
        'en': 'On Process',
        'id': 'Dalam Proses'
    },
    "Add to Cart": {
        'en': 'Add to Cart',
        'id': 'Tambah ke Keranjang'
    },
    "Send as Gift": {
        'en': 'Send as Gift',
        'id': 'Kirim sebagai Kado'
    },
    "Social Acts": {
        'en': 'Social Acts',
        'id': 'Aksi Sosial'
    },

    //REGISTER PAGE
    "Register Headline": {
        'en': 'Let\'s Create Your Account!',
        'id': 'Buat Akun-mu Sekarang!'
    },
    "Register Sub Headline": {
        'en': 'Please fill in your details below.',
        'id': 'Lengkapi data Kamu.'
    },
    "Register Button": {
        'en': 'Create my account',
        'id': 'Buat akun'
    },
    "First Name": {
        'en': 'First Name',
        'id': 'Nama Depan'
    },
    "Last Name": {
        'en': 'Last Name',
        'id': 'Nama Belakang'
    },
    "Phone Number": {
        'en': 'Phone Number',
        'id': 'Nomor HP'
    },
    "Male": {
        'en': 'Male',
        'id': 'Pria'
    },
    "Female": {
        'en': 'Female',
        'id': 'Wanita'
    },
    "Date of Birth": {
        'en': 'Date of Birth',
        'id': 'Tanggal Lahir'
    },
    "Re-Type Your Password": {
        'en': 'Re-Type Your Password',
        'id': 'Ketik Ulang Password'
    },

    // Own Blend Intro
    "Own Blend Intro Headline": {
        'en': 'Hello! Welcome to The Tea Studio',
        'id': 'Halo! Selamat Datang Di Studio Teh'
    },
    "Own Blend Intro Sub Headline Part 1": {
        'en': "Our team at Havilla are ready to help you create a magical tea blend with your personal touch",
        'id': 'Tim kami siap membantu Kamu untuk menciptakan racikah teh dengan sentuhan personal Kamu'
    },
    "Own Blend Intro Sub Headline Part 2": {
        'en': "Are you ready to start?",
        'id': 'Apakah Kamu siap memulainya?'
    },
    "Own Blend Intro Button": {
        'en': 'Let\'s start',
        'id': 'Mari kita mulai'
    },

    // Own BLend How
    "Own Blend How Headline": {
        'en': 'How It Works',
        'id': 'Bagaimana Cara Kerjanya'
    },
    "Own Blend How Sub Headline": {
        'en': "This is how we will create the blend in 3 simple steps",
        'id': 'Beginilah bagaimana Kita akan membuat racikah teh dengan tiga langkah mudah'
    },
    "Own Blend How Button": {
        'en': 'Ok, Got It!',
        'id': 'Oke Mengerti!'
    },
    "Own Blend How Point 1 Title": {
        'en': 'Choose Your Tea Base',
        'id': 'Pilih bahan dasar Teh Kamu'
    },
    "Own Blend How Point 1 Desc": {
        'en': 'Please pick one tea base that you like',
        'id': 'Silahkan pilih satu teh dasar yang Kamu suka'
    },
    "Own Blend How Point 2 Title": {
        'en': 'Choose Up to 3 toppings',
        'id': 'Pilih Hingga 3 Jenis Bahan Tambahan'
    },
    "Own Blend How Point 2 Desc": {
        'en': 'Initial price is 115k consist of 1 tea base and 3 toppings *for additional toppings it will cost 5k per toppings',
        'id': 'Harga dasar adalah Rp 115.000 berisi satu jenis teh dasar dan 3 jenis bahan tambahan *Untuk Setiap penambahan bahan dikenakan biaya tambahan sebesar Rp 5.000'
    },
    "Own Blend How Point 3 Title": {
        'en': 'Customize Your Tea Name!',
        'id': 'Pilih Nama untuk Racikan Teh-Mu!'
    },
    "Own Blend How Point 3 Desc": {
        'en': 'Name your tea and choose a packaging color for your own tea',
        'id': 'Terakhir, jangan lupa untuk memberi nama untuk teh racikan kamu ya'
    },
    "Own Blend How Notes": {
        'en': '*You can choose less than 3 toppings, but the initial price will stay the same',
        'id': '*Kamu bisa memilih kurang dari 3 bahan tambahan, namun harga dasar akan tetap sama'
    },
    "Own Blend How Notes 2": {
        'en': '*Own Blend Feature needs more time to process, therefore every delivery will happen every ',
        'id': '*Fitur Own Blend membutuhkan lebih banyak waktu untuk diproses, sehingga pengiriman akan disatukan setiap hari '
    },
    "Own Blend How Notes 4": {
        'en': '*Maximum order at 12 PM to be processed on the same delivery day, or otherwise your order will be processed on the the next delivery day',
        'id': '*Maksimal pesanan pk 12.00 siang untuk dapat diproses di hari pengiriman yang sama, lebih dari waktu tersebut maka pesanan akan diproses di hari pengiriman selanjutnya'
    },
    "Days Own Blend": {
        'en': 'Tuesday and Friday',
        'id': 'Selasa dan Jumat'
    },
    "Own Blend How Notes 3": {
        'en': '*Please write down on “Delivery Notes“ before check out, which ingredient(s) that you want to highlight! Example : “I want to highlight strawberry & ginger”',
        'id': '*Tuliskan pada kolom “Catatan Pengiriman“ ketika akan check out bahan apa yang ingin kamu tonjolkan! Contohnya : “Saya ingin menonjolkan strawberry & jahe”'
    },

    // Page Step 
    "Back To": {
        'en': 'Back To',
        'id': 'Kembali Ke'
    },
    "Continue": {
        'en': 'Continue',
        'id': 'Lanjutkan'
    },
    "Pick this": {
        'en': 'Pick This',
        'id': 'Pilih'
    },
    "Skip this": {
        'en': 'Skip Add',
        'id': 'Lewati'
    },
    "Imbuhan Ini": {
        'en': '',
        'id': ' Ini'
    },
    "My Blend Is": {
        'en': 'My Blend is',
        'id': 'Racikan ku terdiri dari'
    },
    "1 Basic Blend": {
        'en': '1 Basic Blend',
        'id': '1 Racikan Dasar'
    },

    // Page Summary
    "Own Blend Summary Headline": {
        'en': 'Awesome! We are ready to mix it!',
        'id': 'Luar Biasa! Kami Siap Meramu Teh Kamu!'
    },
    "Own Blend Summary Button": {
        'en': 'Blend Now',
        'id': 'Ramu Sekarang'
    },
    "Your Price": {
        'en': 'Your Tea Price',
        'id': 'Harga Teh Kamu'
    },

    // Loading Screen
    "Loading": {
        'en': 'Loading',
        'id': 'Memuat'
    },
    "Loading V1": {
        'en': 'Picking all the fresh ingredients',
        'id': 'Memilih bahan-bahan segar berkualitas'
    },
    "Loading V2": {
        'en': 'Carefully measure the composition',
        'id': 'Menimbang komposisinya dengan hati-hati'
    },
    "Loading V3": {
        'en': 'Mixing the Blends',
        'id': 'Mencampur seluruh Bahannya!'
    },

    // Page Complete
    "Own Blend Complete Headline": {
        'en': 'Voila! Your Blend is Ready!',
        'id': 'Voila! Racikan Teh Kamu Sudah Siap!'
    },
    "Own Blend Complete Sub Headline": {
        'en': 'Don’t forget to put a name on it! Make it yours',
        'id': 'Jangan lupa beri nama teh Kamu!'
    },
    "Own Blend Complete Button": {
        'en': 'Proceed to Order',
        'id': 'Proses ke pemesanan'
    },
    "Own Blend Complete Choose Color": {
        'en': 'Choose Your Color Label',
        'id': 'Pilih warna label Kamu'
    },
    "Own Blend Complete Name Blend": {
        'en': 'Name Your Blend',
        'id': 'Beri Nama Kreasi Kamu'
    },
    "Own Blend Complete Tea Awards": {
        'en': 'I would like to Participate in Havilla #TEAwards',
        'id': 'Saya ingin ikut serta dalam Havilla #TEAwards'
    },

    // Product Buying
    "Contacts": {
        'en': 'Contacts',
        'id': 'Kontak'
    },
    "Delivery": {
        'en': 'Delivery',
        'id': 'Pengiriman'
    },
    "Payment": {
        'en': 'Payment',
        'id': 'Pembayaran'
    },
    "Order Summary": {
        'en': 'Order Summary',
        'id': 'Ringkasan Pesanan'
    },


    // Product Buying Step 1
    "Product Buying Step 1 Headline": {
        'en': 'Hello',
        'id': 'Halo'
    },
    "Product Buying Step 1 Sub Headline": {
        'en': 'This package is',
        'id': 'Paket ini'
    },
    "Product Buying Step 1 Button": {
        'en': 'Continue to Delivery',
        'id': 'Lanjutkan ke Pengiriman'
    },
    "For Me": {
        'en': 'For Me',
        'id': 'Untuk Saya'
    },
    "a Gift for someone else": {
        'en': 'a Gift for someone else',
        'id': 'Hadiah untuk orang lain'
    },
    "Sender Information": {
        'en': 'Sender Information',
        'id': 'Informasi Pengirim'
    },
    "Sender Identity": {
        'en': 'Sender Identity',
        'id': 'Identitas Pengirim'
    },
    "Recipient Information": {
        'en': 'Recipient Information',
        'id': 'Informasi Penerima'
    },
    "optional": {
        'en': 'optional',
        'id': 'tidak harus diisi'
    },
    "Choose Your Greeting Card": {
        'en': 'Choose Your Greeting Card',
        'id': 'Pilih Kartu Ucapan Kamu'
    },
    "Greeting Card Notes": {
        'en': 'Greeting Card Notes',
        'id': 'Pesan di Kartu Ucapan'
    },
    "Items": {
        'en': 'Items',
        'id': 'Barang'
    },
    "Edit": {
        'en': 'Edit',
        'id': 'Ubah'
    },
    "Total Weight": {
        'en': 'Total Weight',
        'id': 'Berat Total'
    },
    "Delivery Fee": {
        'en': 'Delivery Fee',
        'id': 'Biaya Pengiriman'
    },
    "Shipping Cost": {
        'en': 'Shipping Cost',
        'id': 'Biaya Pengiriman'
    },
    "Voucher Discount": {
        'en': 'Voucher Discount',
        'id': 'Diskon Voucher'
    },
    "Grand Total": {
        'en': 'Grand Total',
        'id': 'Total Keseluruhan'
    },
    "Delivery Information": {
        'en': 'Delivery Information',
        'id': 'Informasi Pengiriman'
    },
    "Name of Recipient": {
        'en': 'Name of Recipient',
        'id': 'Nama Penerima'
    },
    "Recipient Phone Number": {
        'en': 'Recipient Phone Number',
        'id': 'Nomor Telp Penerima'
    },
    "Delivery Using": {
        'en': 'Delivery Using',
        'id': 'Kurir Pengiriman'
    },
    "Delivery Address": {
        'en': 'Delivery Address',
        'id': 'Alamat Pengiriman'
    },
    "Tracking No": {
        'en': 'Tracking No',
        'id': 'Nomor Resi Pengiriman'
    },

    // Product Buying Step 2
    "Product Buying Step 2 Headline": {
        'en': 'Let’s Deliver The Goods',
        'id': 'Ayo Kirim Pesanan Kamu'
    },
    "Product Buying Step 2 Sub Headline": {
        'en': 'Address Details',
        'id': 'Alamat Lengkap'
    },
    "Recipient Address Details": {
        'en': 'Recipient Address Details',
        'id': 'Alamat Lengkap Penerima'
    },
    "Product Buying Step 2 Button": {
        'en': 'Continue to Payment',
        'id': 'Lanjutkan ke Pembayaran'
    },
    "Delivery Method": {
        'en': 'Delivery Method',
        'id': 'Metode Pengiriman'
    },
    "Delivery Notes": {
        'en': 'Delivery Notes',
        'id': 'Catatan Pengiriman'
    },
    "Delivery Time": {
        'en': 'Delivery Time',
        'id': 'Waktu Pengantaran'
    },

    // Product Buying Step 3
    "Product Buying Step 3 Headline": {
        'en': 'How would you like to pay',
        'id': 'Bagaimana Kamu ingin membayar'
    },
    "Havilla Voucher Code": {
        'en': 'Havilla Voucher Code',
        'id': 'Kode Voucher Havilla'
    },
    "Havilla Voucher Code Desc": {
        'en': 'If you have Havilla Voucher code, please enter it here.',
        'id': 'Jika Kamu memiliki kode Voucher Havilla, silakan masukkan disini'
    },
    "Check": {
        'en': 'Check',
        'id': 'Cek'
    },
    "Payment Method": {
        'en': 'Payment Method',
        'id': 'Metode Pembayaran'
    },
    "Pay Now": {
        'en': 'Pay Now',
        'id': 'Bayar Sekarang'
    },
    "How to pay using BCA Virtual Account": {
        'en': 'How to pay using BCA Virtual Account',
        'id': 'Cara pembayaran menggunakan BCA Virtual Account'
    },
    "How to pay Point 1": {
        'en': 'Please copy or save Havilla Virtual Account Number in the next page.',
        'id': 'Silakan salin atau simpan Nomor Virtual Account Havilla di halaman berikutnya.'
    },
    "How to pay Point 2": {
        'en': 'Access BCA Virtual Account via mBCA (app) or KlikBCA (web).',
        'id': 'Akses Virtual Account BCA melalui mBCA (aplikasi) atau KlikBCA (website).'
    },
    "How to pay Point 3": {
        'en': 'Click on Transfer, and choose BCA Virtual Account.',
        'id': 'Klik Transfer, dan pilih BCA Virtual Account.'
    },
    "How to pay Point 4": {
        'en': 'Type Your BCA Virtual Account Number.',
        'id': 'Ketikkan Nomor Virtual Akun BCA Kamu.'
    },
    "How to pay Point 5": {
        'en': 'Click Send and finish your payment.',
        'id': 'Klik Kirim dan selesaikan pembayaran Kamu.'
    },
    "Pay to BCA Virtual Account - Other bank User":{
        'en': 'Pay to BCA Virtual Account - Other bank User',
        'id': 'Bayar ke BCA Virtual Account - Pengguna Bank Lain'
    },
    "Pay to BCA Virtual Account Desc": {
        'en': 'Transfer to BCA Virtual Account can be done through other bank ATMs, Internet Banking, or mBanking. For Detailed step by step process please follow through guide from each of those channel.',
        'id': 'Transfer ke Virtual Account BCA bisa dilakukan melalui ATM dari bank lain, Internet Banking, atau mBanking. Untuk detil langkah demi langkah mohon mengikuti panduan dari masing-masing layanan tersebut.'
    },

    // Product Buying Finish
    "Product Buying Step Finish Headline": {
        'en': 'Thank You For Ordering',
        'id': 'Terima Kasih Telah Memesan'
    },
    "Please finish your payment in 1x24 hour": {
        'en': 'Please finish your payment in 1x24 hour',
        'id': 'Mohon selesaikan pembayaran Kamu dalam 1x24 jam'
    },
    "Otherwise, we are very sorry that we cannot continue to proceed your order": {
        'en': 'Otherwise, we are very sorry that we cannot continue to proceed your order',
        'id': 'Jika tidak, kami sangat menyesal kami tidak dapat melanjutkan proses pemesanan Kamu'
    },
    "Here is your Virtual Account Number": {
        'en': 'Here is your Virtual Account Number',
        'id': 'Ini Nomor Virtual Akun BCA Kamu'
    },
    "View my Order": {
        'en': 'View my Order',
        'id': 'Lihat Pesanan Saya'
    },
    "to KlikBCA website": {
        'en': 'to KlikBCA website',
        'id': 'ke website KlikBCA'
    },

    // Tea Shop
    "And": {
        'en': 'And',
        'id': 'Dan'
    },
    "other items": {
        'en': 'other items',
        'id': 'barang lainnya'
    },
    "Deliver To": {
        'en': 'Deliver To',
        'id': 'Dikirim ke'
    },
    "Gift For": {
        'en': 'Gift For',
        'id': 'Hadiah Untuk'
    },
    "Waiting For Payment": {
        'en': 'WAITING FOR PAYMENT',
        'id': 'MENUNGGU PEMBAYARAN'
    },
    "Canceled": {
        'en': 'CANCELED',
        'id': 'DIBATALKAN'
    },
    "On Process": {
        'en': 'ON PROCESS',
        'id': 'DALAM PROSES'
    },
    "On Delivery": {
        'en': 'ON DELIVERY',
        'id': 'DALAM PENGIRIMAN'
    },
    "Search here": {
        'en': 'Search here...',
        'id': 'Cari disini...'
    },
    "Add to Cart": {
        'en': 'Add to Cart',
        'id': 'Tambah ke Keranjang'
    },
    "Send as Gift": {
        'en': 'Send as Gift',
        'id': 'Kirim Hadiah'
    },
    "Choose Variant": {
        'en': 'Choose Variant',
        'id': 'Pilih Varian'
    },
    "NEW": {
        'en': 'NEW',
        'id': 'BARU'
    },
    "SOLD OUT": {
        'en': 'SOLD OUT',
        'id': 'HABIS'
    },
    "Sold Out Message": {
        'en': 'Sorry, this product is sold out...',
        'id': 'Maaf, barang ini habis...'
    },
    "My Cart": {
        'en': 'My Cart',
        'id': 'My Cart'
    },
    "Proceed to Checkout": {
        'en': 'Proceed to Checkout',
        'id': 'Lanjutkan Pembelian'
    },


    // About
    "Havilla Gifts": {
        'en': 'Havilla Gifts',
        'id': 'Havilla Gifts'
    },
    "Havilla Gifts Caption": {
        'en': 'Want to make Havilla Tea as a part of your personal or corporate-event gifts? We can provide you a fully customized gift package based on your needs.',
        'id': 'Apakah kamu ingin menjadikan teh Havilla sebagai hadiah acara personal atau korporat? Kami dapat menyediakan paket hadiah yang sepenuhnya dirancang sesuai dengan kebutuhan dan preferensimu.'
    },
    "Havilla Gifts CTA": {
        'en': "Customize your Havilla-Gift now!",
        'id': 'Tinggalkan pesanmu di sini!'
    },
    "Partnership & Wholesale": {
        'en': 'Partnership & Wholesale',
        'id': 'Kemitraan & Grosir'
    },
    "Partnership & Wholesale Caption": {
        'en': 'You can also serve Havilla Tea at your cafe, restaurant or shop!',
        'id': 'Apakah kamu ingin menjadikan teh Havilla sebagai hadiah acara personal atau korporat? Kami dapat menyediakan paket hadiah yang sepenuhnya dirancang sesuai dengan kebutuhan dan preferensimu.'
    },
    "Partnership & Wholesale CTA": {
        'en': "Let's be our partner!",
        'id': 'Tinggalkan pesanmu di sini!'
    },
    "More about Havilla": {
        'en': "More about Havilla",
        'id': 'Selebihnya tentang Havilla'
    },
    "Our Social Acts": {
        'en': 'Our Social Acts',
        'id': 'Aksi Sosial Kami'
    },
    "Our Social Acts Caption": {
        'en': 'In running a business we try to contribute in achieving its objectives sustainable development in indonesia.',
        'id': 'Dalam menjalankan bisnis kami berupaya berkontribusi dalam mencapai Tujuan Pembangunan Berkelanjutan di Indonesia.'
    },
    "Our Social Acts CTA": {
        'en': 'See Their Stories',
        'id': 'Lihat Ceritanya'
    },
    "Our Partners Store": {
        'en': 'Our Partners Store',
        'id': 'Toko Mitra Kami'
    },
    "Our Partners Store Caption": {
        'en': 'Get and enjoy our products at your favorite restaurant or coffee shop around you',
        'id': 'Dapatkan dan nikmati produk kami di restoran atau coffee shop di sekitar Kamu.'
    },
    "Our Partners Store CTA": {
        'en': 'See Their Location',
        'id': 'Lihat Lokasi'
    },
    "AboutTitle": {
        'en': 'It’s All about Havilla!',
        'id': 'Semua Tentang Havilla!'
    },
    "Got Questions": {
        'en': 'Got Questions?',
        'id': 'Ada Pertanyaan?'
    },
    "Got Questions 2": {
        'en': 'We got you covered. See our list of Frequently Asked Questions. Still can’t find your answers? Contact Us.',
        'id': 'Kami mengerti kebutuhanmu. Lihat daftar pertanyaan yang sering ditanyakan!'
    },
    "Got Questions Button": {
        'en': 'See FAQ',
        'id': 'Lihat FAQ'
    },
    "Build Partnership with Us": {
        'en': 'Build Partnership with Us',
        'id': 'Bangun Kerjasama dengan Kami'
    },
    "These brands put their trust on us": {
        'en': "We've built trust with these brands. Now it's your turn!",
        'id': 'Kami telah membangun kepercaayaan bersama brand-brand ini. Sekarang giliranmu!'
    },
    "Read their Story": {
        'en': 'Read their Story',
        'id': 'Baca Cerita Mereka'
    },
    "AboutDetail 1": {
        'en': 'Tea is the most consumed beverage in the world after water. Havilla believes that tea has so much potential to offer and we want to make it possible for every tea lover to experience it. Through teas, Havilla crews also aim to take our precious clientele to a new adventure in savouring the taste of the world.',
        'id': 'Tea is the most consumed beverage in the world after water. Havilla believes that tea has so much potential to offer and we want to make it possible for every tea lover to experience it. Through teas, Havilla crews also aim to take our precious clientele to a new adventure in savouring the taste of the world.'
    },
    "AboutDetail 2": {
        'en': 'Tea is an accessible, incredibly healthy and versatile beverage that has made many people in the world helplessly fall in love with it including our founder, Neysa Valeria.',
        'id': 'Tea is an accessible, incredibly healthy and versatile beverage that has made many people in the world helplessly fall in love with it including our founder, Neysa Valeria.'
    },
    "AboutDetail 3": {
        'en': 'She has fallen for tea since she was in High School, the good old days when staying up late night for exams and assignments were considered pretty fun. There was always and always a jug, yes a tall jug not merely a cup of tea as her company! Love grows and passion follows. Her devotion started back in 2012 when she was living abroad and witnessing how amazing the world of tea is! She then decided to learn and take tea seriously for her life since then! Founded in mid 2014, Havilla Tea has been serving hundreds of F&B clients all over Indonesia andt thousands of people from across the globe.',
        'id': 'She has fallen for tea since she was in High School, the good old days when staying up late night for exams and assignments were considered pretty fun. There was always and always a jug, yes a tall jug not merely a cup of tea as her company! Love grows and passion follows. Her devotion started back in 2012 when she was living abroad and witnessing how amazing the world of tea is! She then decided to learn and take tea seriously for her life since then! Founded in mid 2014, Havilla Tea has been serving hundreds of F&B clients all over Indonesia andt thousands of people from across the globe.'
    },
    "AboutDetail 4": {
        'en': 'Neysa is eager to spread the happiness, love and passion of experiencing tea to as many people as possible! As a pioneer of new wave Indonesian-based tea brands, Havilla Tea is offering a range of premium loose-leaf teas from all around the globe, both single origin and blended teas. Being a Certified Tea Master, Tea Taster and Tea Blender from Australian Tea Masters doesn’t make her tea-learning journey stopped there. As she believes learning is a lifetime process, she really enjoys learning and cross-path with new people. Her background in Psychology has helped her a lot in building the brand for managing relationships with customers and developing every approach the company takes. This also the foundation of her interest to initiate the project with KPAS (Komunitas Peduli Anak Spesial), a community of teenagers and young adults with disability-mostly autism. She believes everyone is able and wants to support the community to build a business entity for them to independently earn money at their productive ages.',
        'id': 'Neysa is eager to spread the happiness, love and passion of experiencing tea to as many people as possible! As a pioneer of new wave Indonesian-based tea brands, Havilla Tea is offering a range of premium loose-leaf teas from all around the globe, both single origin and blended teas. Being a Certified Tea Master, Tea Taster and Tea Blender from Australian Tea Masters doesn’t make her tea-learning journey stopped there. As she believes learning is a lifetime process, she really enjoys learning and cross-path with new people. Her background in Psychology has helped her a lot in building the brand for managing relationships with customers and developing every approach the company takes. This also the foundation of her interest to initiate the project with KPAS (Komunitas Peduli Anak Spesial), a community of teenagers and young adults with disability-mostly autism. She believes everyone is able and wants to support the community to build a business entity for them to independently earn money at their productive ages.'
    },
    "AboutDetail 5": {
        'en': "Ajeng is a cheerful, passionate, sociable and strong-willed person, no wonder she has so many acquaintances here and there. Her strong passion in doing whatever she does including in this tea business has brought her to learn and grow personally in this past few years. She always says, “who doesn’t love tea and how many people have the opportunity to work on what they love with their best friend?” such a strong statement showing how she enjoys the journey with Havilla Tea. Being a certified Tea Blender, Ajeng also loves tea mixology, creating both tea-based cocktails and mocktails. “Do what you love and you'll never work a day in your life, I know this is a cliche but that is exactly what I experience in Havilla Gourmet Tea” Ajeng said. She is so grateful that through Havilla Tea, she got the opportunity to share good quality teas and happiness with others in Indonesia and abroad.",
        'id': "Ajeng is a cheerful, passionate, sociable and strong-willed person, no wonder she has so many acquaintances here and there. Her strong passion in doing whatever she does including in this tea business has brought her to learn and grow personally in this past few years. She always says, “who doesn’t love tea and how many people have the opportunity to work on what they love with their best friend?” such a strong statement showing how she enjoys the journey with Havilla Tea. Being a certified Tea Blender, Ajeng also loves tea mixology, creating both tea-based cocktails and mocktails. “Do what you love and you'll never work a day in your life, I know this is a cliche but that is exactly what I experience in Havilla Gourmet Tea” Ajeng said. She is so grateful that through Havilla Tea, she got the opportunity to share good quality teas and happiness with others in Indonesia and abroad."
    },

    // Login
    "Welcome Back": {
        'en': 'Welcome Back',
        'id': 'Selamat Datang Kembali'
    },
    "Click here": {
        'en': 'Click here',
        'id': 'Klik disini'
    },
    "Forgot Password": {
        'en': 'Forgot Password',
        'id': 'Lupa Password'
    },
    "Login": {
        'en': 'Login',
        'id': 'Masuk'
    },
    "Login Sub Title": {
        'en': 'Please Sign in with your Havilla Account. Want to create one?',
        'id': 'Silakan Masuk dengan Akun Havilla Kamu. Ingin membuat akun?'
    },

    // FAQ
    "Frequently Asked Questions": {
        'en': 'Frequently Asked Questions',
        'id': 'Pertanyaan yang Sering Diajukan'
    },
    "FAQ Question 1": {
        'en': 'How should I brew my tea?',
        'id': 'Bagaimana cara menyeduh teh Havilla?'
    },
    "FAQ Answer 1": {
        'en': 'All the brewing directions are explained on the label and website. However please note that the direction is for recommendation only, so you can always adjust everything to your own liking. Be adventurous and curious when it comes to tea and you’ll be amazed of what it can bring you!',
        'id': 'Semua petunjuk penyeduhan teh dijelaskan pada label dan situs web. Namun harap dicatat bahwa petunjuk penyeduhan hanya untuk rekomendasi, sehingga Kamu selalu dapat menyesuaikan segala sesuatunya sesuai dengan preferensi Kamu. Cobalah sesuatu yang baru, Kamu mungkin akan menyukai hasilnya'
    },
    "FAQ Question 2": {
        'en': 'Why does the tea I bought and brewed at home taste different with the one I had in the cafe?',
        'id': 'Mengapa teh yang saya beli dan saya seduh di rumah berbeda rasanya dengan teh yang saya minum di cafe?'
    },
    "FAQ Answer 2": {
        'en': 'There are several factors affecting the taste of tea and most likely it’s because of the water. Water is said as the “mother” of tea therefore it plays a big role on affecting how a particular tea tastes. Also the brewing ratio, timing, water temperature are the keys to make a good tea.',
        'id': 'Ada beberapa faktor yang memengaruhi rasa teh dan kemungkinan besar itu karena air. Air dikatakan sebagai "ibu" teh karena memainkan peranan besar dalam memengaruhi rasa teh tertentu. Selain itu rasio pembuatan teh, waktu, suhu air adalah kunci untuk membuat teh yang baik.'
    },
    "FAQ Question 3": {
        'en': 'I read on the packaging not to add sugar to the tea. But why?',
        'id': 'Saya membaca saran penyajian agar tidak ditambahkan gula. Kenapa?'
    },
    "FAQ Answer 3": {
        'en': 'Yes we strongly encourage you to taste and enjoy the real natural flavor of the tea especially for the single origin ones. You’ll be surprised by how rich natural aroma and flavor can actually show up and give a whole new experience of drinking tea.',
        'id': 'Kami sangat menyarankan Kamu untuk mencicipi dan menikmati rasa alami dari teh terutama untuk teh single origin. Kamu akan terkejut dengan kekayaan aroma dan rasa alami yang muncul sehingga memberikan pengalaman baru minum teh.'
    },
    "FAQ Question 4": {
        'en': 'How long is the shelf-life of the tea?',
        'id': 'Berapa lama masa kadaluwarsa teh?'
    },
    "FAQ Answer 4": {
        'en': 'You can check the bottom of our packaging to see the best-before-date. Please note that as long as you store the tea well (in dark, air-tight, dry area) they will last long. It is important to follow storage directions in order to preserve the flavor and quality of your loose tea.',
        'id': 'Kamu dapat memeriksa bagian bawah kemasan untuk melihat yang tanggal sebelum kadaluwarsa. Harap dicatat bahwa selama Kamu menyimpan teh dengan baik (di tempat yang tidak terkena matahari langsung, kedap udara,  dan  kering) maka teh akan bertahan lama. Oleh karena itu, sangat penting untuk mengikuti petunjuk penyimpanan untuk menjaga rasa dan kualitas teh Kamu.'
    },
    "FAQ Question 5": {
        'en': 'What payment types do you accept?',
        'id': 'Apa saja metode pembayaran yang dapat digunakan?'
    },
    "FAQ Answer 5": {
        'en': 'All payments are made via BCA virtual account.',
        'id': 'Seluruh pembayaran dilakukan melalui virtual account BCA.'
    },
    "FAQ Question 6": {
        'en': 'How long does my order will be proceed after I made the payment?',
        'id': 'Berapa lama pesanan saya akan diproses setelah saya melakukan pembayaran?'
    },
    "FAQ Answer 6": {
        'en': 'All orders made, paid and confirmed at 2 PM to the latest will be processed and sent on the same day. Our shipment is at 4 PM on Monday to Friday by JNE.',
        'id': "Semua pesanan yang masuk dan pembayarannya telah terkonfirmasi sebelum jam 2 siang, akan diproses pada hari yang sama. Pengiriman dilakukan pada hari Senin - Jumat pukul 16.00 WIB, melalui JNE."
    },
    "FAQ Question 7": {
        'en': 'Can I track my order shipment?',
        'id': 'Apakah saya bisa melacak pesanan saya?'
    },
    "FAQ Answer 7": {
        'en': 'Yes of course! You can easily check your shipment through “Track My Order” menu on the website.',
        'id': 'Ya tentu saja! Kamu dapat dengan mudah memeriksa pengiriman Kamu melalui menu "Lacak pesanan saya" di situs web.'
    },
    "FAQ Question 8": {
        'en': 'What are the recommended variants?',
        'id': 'Apa saja varian teh yang paling direkomendasikan?'
    },
    "FAQ Answer 8": {
        'en': "Surprisingly, tea is somewhat very personal too. Please contact us directly, our customer service will be happy to assist you!",
        'id': 'Teh adalah hal yang sesuatu yang sangat personal. Silakan kontak kami secara langsung, Customer care kami akan dengan senang hati memberikan rekomendasi teh untuk Kamu.'
    },
    "FAQ Question 9": {
        'en': "What is the health benefits of consuming Havilla's teas?",
        'id': 'Apakah manfaat kesehatan dari Havilla Tea?'
    },
    "FAQ Answer 9": {
        'en': "All types of tea and herbs have various health benefits for human body as they contain vitamins, minerals, antioxidants and etc. Please note that the benefits also depend on each person's health condition. If you are looking for more specific healthy options, please refer to our Havilla Wellness Series.",
        'id': 'Secara umum teh memiliki manfaat kesehatan karena mengandung vitamin, mineral, anti-oksidan yang tinggi. Mohon dipahami juga bahwa manfaat the bergantung pada kondisi kesehatan seseorang juga. Jika Kamu mencari manfaat kesehatan yang lebih spesifik, Kamu bisa mencoba varian Wellness kami.'
    },
    "FAQ Question 10": {
        'en': "What is cold brew tea? Could all teas be made into a cold brew tea?",
        'id': 'Apa yang dimaksud dengan cold brew? Apakah semua teh bisa dibuat menjadi cold brew?'
    },
    "FAQ Answer 10": {
        'en': "Cold brew is a tea brewing method using cold water for a long period of time (6-10 hours). And yes all teas can be made into a cold brew tea but not all of them will taste exceptional. Don't hesitate to try and find your favorite!",
        'id': 'Cold brew merupakan salah satu teknik penyeduhan dengan air dingin yang kemudian didiamkan dalam lemari pendingin selama 6-10 jam ( tergantung jenis teh). Ada varian teh yang cocok dibuat untuk cold brew ada juga yang kurang cocok, tapi jangan ragu untuk mencoba!'
    },

    // Havilla Gifts
    "Havilla Gifts Title" : {
        'en' : "Havilla Gifts",
        'id' : 'Havilla Gifts'
    },
    "Havilla Gifts Sub Title" : {
        'en' : 'Tea-gifting with Havilla is truly a PERSONAL and UNIQUE experience you can have to deliver for your loved-ones. We understand that every person is special, therefore we are more than happy to create you something matched to your utmost liking.',
        'id' : 'Tea-gifting with Havilla is truly a PERSONAL and UNIQUE experience you can have to deliver for your loved-ones. We understand that every person is special, therefore we are more than happy to create you something matched to your utmost liking.'
    },
    'Havilla Gifts Point 1 Title' : {
        'en' : 'Highly Personal & Customized',
        'id' : 'Highly Personal & Customized'
    },
    'Havilla Gifts Point 1 Desc' : {
        'en' : 'We understand that every person is special, therefore we are more than happy to create something matched to your utmost liking.',
        'id' : 'We understand that every person is special, therefore we are more than happy to create something matched to your utmost liking.'
    },
    'Havilla Gifts Point 2 Title' : {
        'en' : 'Excellent Service',
        'id' : 'Excellent Service'
    },
    'Havilla Gifts Point 2 Desc' : {
        'en' : 'Our team will be available to assist you in choosing the right tea and discussing anything about tea including the pricing.',
        'id' : 'Our team will be available to assist you in choosing the right tea and discussing anything about tea including the pricing.'
    },
    'Havilla Gifts Point 3 Title' : {
        'en' : 'Attention to Details',
        'id' : 'Attention to Details'
    },
    'Havilla Gifts Point 3 Desc' : {
        'en' : 'We love details and always put our attention towards it, because we believe that small things matter.',
        'id' : 'We love details and always put our attention towards it, because we believe that small things matter.'
    },
    'Havilla Gifts Content Title' : {
        'en' : 'Customize Your Havilla-Gift Now!',
        'id' : 'Customize Your Havilla-Gift Now!'
    },
    'Havilla Gifts Content Desc' : {
        'en' : 'Fill the form down below to give us some brief information about yourself and we will get back to you!',
        'id' : 'Fill the form down below to give us some brief information about yourself and we will get back to you!'
    },

    // Havilla Partnership
    "Havilla Partnership Title" : {
        'en' : "Be Our Precious Clientele",
        'id' : 'Be Our Precious Clientele'
    },
    "Havilla Partnership Sub Title" : {
        'en' : "Yes, you can SERVE Havilla Tea at your place and we are more than happy to assist you.",
        'id' : 'Yes, you can SERVE Havilla Tea at your place and we are more than happy to assist you.'
    },
    'Havilla Partnership Point 1 Title' : {
        'en' : 'Consistent Quality Product',
        'id' : 'Consistent Quality Product'
    },
    'Havilla Partnership Point 1 Desc' : {
        'en' : 'We commit and work our best to offer you quality teas and be consistent about it.',
        'id' : 'We commit and work our best to offer you quality teas and be consistent about it.'
    },
    'Havilla Partnership Point 2 Title' : {
        'en' : 'Excellent Service & Assistance',
        'id' : 'Excellent Service & Assistance'
    },
    'Havilla Partnership Point 2 Desc' : {
        'en' : 'We will be available to assist you choosing the right tea, learning new tea knowledges and discussing about anything tea.',
        'id' : 'We will be available to assist you choosing the right tea, learning new tea knowledges and discussing about anything tea.'
    },
    'Havilla Partnership Point 3 Title' : {
        'en' : 'Tea Training & Development',
        'id' : 'Tea Training & Development'
    },
    'Havilla Partnership Point 3 Desc' : {
        'en' : 'We support you with tea displays, online tea training and discussion. We also believe that tea is truly versatile and there are so many possibilities we could create with it!',
        'id' : 'We support you with tea displays, online tea training and discussion. We also believe that tea is truly versatile and there are so many possibilities we could create with it!'
    },
    'Havilla Partnership Content Title' : {
        'en' : 'Let’s be Our Partner!',
        'id' : 'Let’s be Our Partner!'
    },
    'Havilla Partnership Content Desc' : {
        'en' : 'Let us help you to deliver the GOODNESS and HAPPINESS in a cup of tea  to your customers! Because they are precious and you are too. Fill the form down below and we will get back to you!',
        'id' : 'Let us help you to deliver the GOODNESS and HAPPINESS in a cup of tea  to your customers! Because they are precious and you are too. Fill the form down below and we will get back to you!'
    },
}