import React, { Component } from 'react'
import axios from 'axios';
import { baseUrl } from 'Environment';
import { requestConfig } from 'Environment';
import { ClientSideContext } from '../../ClientSideContext';
import styles from "assets/jss/client-side/App.js";
import { withStyles, Container, Button, Grid } from '@material-ui/core';
import LoadingScreenTimer from 'client-side/components/LoadingScreen/LoadingScreenTimer';
import FlickityIngredient from './FlickityIngridient';
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp';
import { dictionary } from '../../../Dictionary';
import Fade from '@material-ui/core/Fade';

const useStyles = theme => ({
    ...styles(theme),
    pageStepContainer: {
        padding: '0px',
    },
    stepContainer: {
    },
    pageTitle: {
        paddingLeft: '12px',
        paddingRight: '12px',
        marginTop: '2rem',
        fontSize: '28px',
        textAlign: 'center',
        fontFamily: 'BlaakLight',
    },
    pageDesc: {
        paddingLeft: '12px',
        paddingRight: '12px',
        marginTop: '16px',
        fontSize: '16px',
        fontFamily: 'Martel Sans',
        textAlign: 'center',
    },
});

export class PageStep extends Component {
    static contextType = ClientSideContext;
    constructor(props) {
        super(props)

        this.state = {
            openBackdrop: false,
            language: '',

            currentStep: 1,

            own_product_step: null,
            previous_step: null,
            own_product_ingredients: [],

            selected_ingredient_flickity: null,
            selected_index_flickity: 0,
            selected_ingredients: [],

            own_product: [],
            own_product_text: "",
            last_step: null,

            snackbarType: 'info',
            snackbarMessage: '',
            snackbarCounter: 0,
            loadIngredients: false
        }

    }

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    openBackdrop = () => {
        this.setState({
            openBackdrop: true
        })
    }

    closeBackdrop = () => {
        this.setState({
            openBackdrop: false
        })
    }

    handleMakeTextIngredient = () => {
        let ingredients = this.state.own_product;
        let temp_text = dictionary['My Blend Is'][this.state.language];

        for (let i = 0; i < ingredients.length; i++) {
            const ingredient = ingredients[i];

            if (ingredient.own_product_step_id !== this.state.own_product_step.id) {
                temp_text += i === 0 ? ` ${ingredient[`name_${this.state.language}`]}` : ` + ${ingredient[`name_${this.state.language}`]}`
            }
        }

        if (this.state.selected_ingredients.length > 0) {
            let ingredients = this.state.selected_ingredients;

            for (let i = 0; i < ingredients.length; i++) {
                const ingredient = ingredients[i];

                temp_text += ` + ${ingredient[`name_${this.state.language}`]}`
            }
        }

        this.setState({
            own_product_text: temp_text
        })
    }

    checkExistingValue = () => {
        // Check ada value exist ga di LS
        // kalau ada
        if (this.state.own_product.length > 0) {
            //Check max pick nya 1 / lebih dari 1
            if (this.state.own_product_step.max_pick === 1) {
                let ingredient = this.state.own_product.find(o => o.own_product_step_id === this.state.own_product_step.id);

                //check ada di DB tidak
                let check_ingredient_exist = this.state.own_product_ingredients.findIndex(o => o.id === ingredient.id);
                
                // klo tidak ada di DB
                if(check_ingredient_exist === -1){
                    ingredient = undefined;
                    this.setState(prevState => {
                        let own_product = [...prevState.own_product];
                        let ingredient_removed = prevState.own_product.findIndex(o => o.own_product_step_id === this.state.own_product_step.id);
                        own_product.splice(ingredient_removed, 1);
        
        
                        return {
                            ...prevState,
                            own_product
                        };
                    });

                }

                // kalau ada yang sudah di save di LS Ambil value yang ada buat di set ke current state
                if (ingredient !== undefined) {
                    let selected_index = this.state.own_product_ingredients.findIndex(o => o.id === ingredient.id);

                    this.setState({
                        selected_index_flickity: selected_index >= 0 ? selected_index : Math.round((this.state.own_product_ingredients.length - 1) / 2),
                        selected_ingredient_flickity: selected_index >= 0 ? this.state.own_product_ingredients[selected_index] : this.state.own_product_ingredients[Math.round((this.state.own_product_ingredients.length - 1) / 2)]
                    }, () => {
                        if (this.state.own_product_step[`page_desc_${this.state.language}`] === null) {
                            this.handleMakeTextIngredient();
                        }
                    })

                    // klo ga ada set default
                } else {
                    this.setState({
                        selected_index_flickity: Math.round((this.state.own_product_ingredients.length - 1) / 2),
                        selected_ingredient_flickity: this.state.own_product_ingredients[Math.round((this.state.own_product_ingredients.length - 1) / 2)]
                    }, () => {
                        if (this.state.own_product_step[`page_desc_${this.state.language}`] === null) {
                            this.handleMakeTextIngredient();
                        }
                    })
                }
                // klo max pick lbh dr 1
            } else {
                // ambil ingredient dgn step yang sama
                let selected_ingredients = this.state.own_product.filter(o => {
                    let check_exist = this.state.own_product_ingredients.findIndex(opi => opi.id === o.id);
                    return o.own_product_step_id === this.state.own_product_step.id && check_exist != -1
                })

                // cek di DB apakah ada
                for (let i = 0; i < selected_ingredients.length; i++) {
                    const selected_ingredient = selected_ingredients[i];
                    let ingredient = this.state.own_product_ingredients.findIndex(o => o.id === selected_ingredient.id);

                    // klo tidak ada di DB
                    if(ingredient === -1){
                        this.setState(prevState => {
                            let own_product = [...prevState.own_product];
                            let ingredient_removed = prevState.own_product.findIndex(o => o.own_product_step_id === this.state.own_product_step.id);
                            own_product.splice(ingredient_removed, 1);
            
            
                            return {
                                ...prevState,
                                own_product
                            };
                        });
    
                    }
                }


                // klo ada set value tsb current state
                if (selected_ingredients.length > 0) {
                    this.setState({
                        selected_ingredients: selected_ingredients,
                        selected_index_flickity: Math.round((this.state.own_product_ingredients.length - 1) / 2),
                        selected_ingredient_flickity: this.state.own_product_ingredients[Math.round((this.state.own_product_ingredients.length - 1) / 2)]
                    }, () => {
                        this.setState(prevState => {
                            const selected_ingredients = [...prevState.selected_ingredients];
                            let own_product_ingredients = [...prevState.own_product_ingredients];

                            for (let i = 0; i < selected_ingredients.length; i++) {
                                const selected_ingredient = selected_ingredients[i];
                                let ingredient = prevState.own_product_ingredients.find(o => o.id === selected_ingredient.id);

                                let new_data = {
                                    ...ingredient,
                                    checked: true
                                }
                                own_product_ingredients[own_product_ingredients.findIndex(o => o.id === ingredient.id)] = new_data;
                            }

                            return {
                                selected_ingredients: selected_ingredients,
                                selected_index_flickity: Math.round((this.state.own_product_ingredients.length - 1) / 2),
                                selected_ingredient_flickity: this.state.own_product_ingredients[Math.round((this.state.own_product_ingredients.length - 1) / 2)],
                                own_product_ingredients
                            };
                        }, () => {
                            if (this.state.own_product_step[`page_desc_${this.state.language}`] === null) {
                                this.handleMakeTextIngredient();
                            }
                        });
                    })
                    // klo ga ada set default
                } else {
                    this.setState({
                        selected_index_flickity: Math.round((this.state.own_product_ingredients.length - 1) / 2),
                        selected_ingredient_flickity: this.state.own_product_ingredients[Math.round((this.state.own_product_ingredients.length - 1) / 2)]
                    }, () => {
                        if (this.state.own_product_step[`page_desc_${this.state.language}`] === null) {
                            this.handleMakeTextIngredient();
                        }
                    })
                }
            }
            // klo ga ada set default
        } else {
            this.setState({
                selected_index_flickity: Math.round((this.state.own_product_ingredients.length - 1) / 2),
                selected_ingredient_flickity: this.state.own_product_ingredients[Math.round((this.state.own_product_ingredients.length - 1) / 2)]
            }, () => {
                if (this.state.own_product_step[`page_desc_${this.state.language}`] === null) {
                    this.handleMakeTextIngredient();
                }
            })
        }
    }

    getCurrentStep = () => {
        this.openBackdrop();
        axios.get(`${baseUrl}/own_product_steps/by_step/${this.state.currentStep}`, requestConfig).then((res) => {
            if (res.data.success) {
                this.setState({
                    own_product_step: res.data.data,
                }, () => {
                    this.closeBackdrop();
                    this.getOwnProductIngredients();
                })
            } else {
                this.handleOpenSnackbar({
                    type: "error",
                    message: res.data.message
                })
                this.closeBackdrop();
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            this.closeBackdrop();
            console.log("Error : ", e);
        })
    }

    getLastStep = () => {
        this.openBackdrop();
        axios.get(`${baseUrl}/own_product_steps/get_last_step`, requestConfig).then((res) => {
            if (res.data.success) {
                this.setState({
                    last_step: res.data.data.last_step,
                }, () => {
                    this.openBackdrop();
                })
            } else {
                this.handleOpenSnackbar({
                    type: "error",
                    message: res.data.message
                })
                this.closeBackdrop();
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            this.closeBackdrop();
            console.log("Error : ", e);
        })
    }

    getOwnProductIngredients = () => {
        this.openBackdrop();
        let data = {
            own_product_step_id: this.state.own_product_step.id
        }
        axios.post(`${baseUrl}/own_product_ingredients/get_by_step`, data, requestConfig).then((res) => {
            if (res.data.success) {
                this.setState({
                    own_product_ingredients: res.data.data.active,
                    loadIngredients: true
                }, () => {
                    this.closeBackdrop();
                    this.checkExistingValue();
                })
            } else {
                this.handleOpenSnackbar({
                    type: "error",
                    message: res.data.message
                })
                this.closeBackdrop();
                console.log(res.data.message, res.data.data)
            }
        }, (e) => {
            this.handleOpenSnackbar({
                type: "error",
                message: `${e}`
            })
            this.closeBackdrop();
            console.log("Error : ", e);
        })
    }

    componentDidUpdate(nextProps, nextState) {
        // for (let i = 0; i < field_names.length; i++) {
        //     let field_name = field_names[i];
        //     if (this.state[field_name + "_error"] !== "") {
        //         if (this.state[field_name] !== nextState[field_name]) {
        //             this.setState({ [field_name + "_error"]: "" });
        //         }
        //     }
        // }

        if (this.state.language !== this.context.language) {
            this.setState({
                language: this.context.language
            }, () => {
                this.handleMakeTextIngredient();
            })
        }

        return true;
    }

    componentDidMount() {
        this.setState({
            language: this.context.language
        })
        const { ownProductIngredients } = this.context;
        const ownProductIngredientsContext = JSON.parse(ownProductIngredients);
        if (this.props.location.state !== undefined) {
            let last_step = this.props.location.state.last_step
            let prev_step = this.props.location.state.prev_step
            let step = this.props.location.state.step

            this.setState({
                currentStep: step,
                own_product: ownProductIngredientsContext,
                previous_step: prev_step,
                last_step: last_step,
            }, () => {
                this.getCurrentStep();
            });
        } else {
            this.getLastStep();
            this.setState({
                own_product: ownProductIngredientsContext,
            }, () => {
                this.getCurrentStep();
            });
        }

    }

    handleChangeIngredient = (index, newValue) => {
        this.setState({
            selected_index_flickity: index,
            selected_ingredient_flickity: newValue
        }, () => {
        })
    }

    handleSelectedIngredient = (id, value) => {
        let ingredient = this.state.own_product_ingredients.find(o => o.id === id);

        if (ingredient !== undefined) {
            if (value) {
                this.setState(prevState => {
                    const selected_ingredients = [...prevState.selected_ingredients, ingredient];

                    let new_data = {
                        ...ingredient,
                        checked: true
                    }
                    let own_product_ingredients = [...prevState.own_product_ingredients];
                    own_product_ingredients[own_product_ingredients.findIndex(o => o.id === ingredient.id)] = new_data;

                    return {
                        selected_ingredients,
                        own_product_ingredients
                    };
                }, () => {
                    if (this.state.own_product_step[`page_desc_${this.state.language}`] === null) {
                        this.handleMakeTextIngredient();
                    }
                });
            } else {
                this.setState(prevState => {
                    const selected_ingredients = [...prevState.selected_ingredients];

                    let index_delete = selected_ingredients.findIndex(o => o.id === ingredient.id)
                    if (index_delete > -1) {
                        selected_ingredients.splice(selected_ingredients.findIndex(o => o.id === ingredient.id), 1);
                    }

                    let new_data = {
                        ...ingredient,
                        checked: false
                    }
                    let own_product_ingredients = [...prevState.own_product_ingredients];
                    own_product_ingredients[own_product_ingredients.findIndex(o => o.id === ingredient.id)] = new_data;

                    return {
                        selected_ingredients,
                        own_product_ingredients
                    };
                }, () => {
                    if (this.state.own_product_step[`page_desc_${this.state.language}`] === null) {
                        this.handleMakeTextIngredient();
                    }
                });

            }
        }

    }

    handleSubmitStep = () => {
        const { dispatchOwnProductIngredients } = this.context;

        let min_pick = this.state.own_product_step.min_pick
        let max_pick = this.state.own_product_step.max_pick

        let is_ok = true;
        if (max_pick !== 1) {
            if (min_pick > this.state.selected_ingredients.length) {
                is_ok = false;
                let pick_left = min_pick - this.state.selected_ingredients.length;

                this.handleOpenSnackbar({
                    type: "error",
                    message: `Please pick ${pick_left} more`
                })
            }
        }

        if (is_ok) {
            this.setState(prevState => {
                let own_product = [...prevState.own_product];
                if (this.state.own_product_step.max_pick === 1) {
                    let ingredient = prevState.own_product.find(o => o.own_product_step_id === this.state.own_product_step.id);
                    if (ingredient !== undefined) {
                        own_product[own_product.findIndex(o => o.id === ingredient.id)] = this.state.selected_ingredient_flickity;
                    } else {
                        own_product = [...own_product, this.state.selected_ingredient_flickity]
                    }

                } else {
                    let deleted_indexes = [];
                    for (let i = 0; i < own_product.length; i++) {
                        const ingredient = own_product[i];
                        if (ingredient.own_product_step_id === this.state.own_product_step.id) {
                            deleted_indexes.push(i);
                        }
                    }

                    for (var i = deleted_indexes.length - 1; i >= 0; i--) {
                        own_product.splice(deleted_indexes[i], 1);
                    }

                    own_product = [...own_product, ...this.state.selected_ingredients]
                }

                return {
                    ...prevState,
                    own_product
                };
            }, () => {
                dispatchOwnProductIngredients({
                    type: "UPDATE_OWN_PRODUCT_INGREDIENTS",
                    new_own_product_ingredients: this.state.own_product
                })

                let complete = this.state.currentStep === this.state.last_step;
                if (complete) {
                    this.props.history.push({
                        pathname: this.props.prefix + "/create_your_own/summary",
                        state: {
                            prev_step: this.state.own_product_step,
                        }
                    })
                } else {
                    this.props.history.push({
                        pathname: this.props.prefix + "/create_your_own/create",
                        state: {
                            step: this.state.currentStep + 1,
                            prev_step: this.state.own_product_step,
                            last_step: this.state.last_step,
                        }
                    })
                }
            });
        }
    }


    render() {
        const { classes } = this.props

        return (
            <Container maxWidth="xl" className={`${classes.container} ${classes.pageStepContainer}`}>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.snackbarCounter}
                />
                {
                    this.state.openBackdrop ?
                        <LoadingScreenTimer language={this.state.language} />
                        :
                        <Grid container justify="center" className={classes.stepContainer}>
                            {
                                this.state.own_product_step ?
                                    <React.Fragment>
                                        <Grid item xs={12} className={classes.pageTitle}>
                                            {this.state.own_product_step[`page_title_${this.state.language}`]}
                                        </Grid>
                                        <Grid item xs={12} className={classes.pageDesc}>
                                            {this.state.own_product_step[`page_desc_${this.state.language}`] !== null ? this.state.own_product_step[`page_desc_${this.state.language}`] : this.state.own_product_text}
                                        </Grid>
                                        <Fade in={this.state.loadIngredients} timeout={700}>
                                            <Grid item xs={12}>
                                                <FlickityIngredient
                                                    selected_index={this.state.selected_index_flickity}
                                                    selected_ingredients={this.state.selected_ingredients}
                                                    own_product_step={this.state.own_product_step}
                                                    own_product_ingredients={this.state.own_product_ingredients}
                                                    handleChangeIngredient={this.handleChangeIngredient}
                                                    handleSelectedIngredient={this.handleSelectedIngredient}
                                                    language={this.state.language}
                                                />
                                            </Grid>
                                        </Fade>
                                        <Grid item xs={12}
                                            container
                                            spacing={2}
                                            justify="center"
                                            alignItems="center"
                                            style={{
                                                marginTop: '20px',
                                                marginBottom: '5px'
                                            }}
                                        >
                                            {
                                                this.state.currentStep !== 1 ?
                                                    <React.Fragment>
                                                        <Grid item xs={12} md={2}>
                                                            <Button variant="outlined" className={classes.buttonLight} style={{ width: '100%' }}
                                                                onClick={() => this.props.history.goBack()}
                                                            >
                                                                {`${dictionary['Back To'][this.state.language]} ${this.state.previous_step[`name_${this.state.language}`]}`}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} md={2}>
                                                            <Button variant="outlined" className={classes.buttonDark} style={{ width: '100%' }}
                                                                onClick={this.handleSubmitStep}
                                                            >

                                                                {
                                                                    this.state.own_product_step.max_pick > 1 ?
                                                                        dictionary['Continue'][this.state.language]
                                                                        :
                                                                        this.state.selected_ingredients.length > 0 ?
                                                                            `${dictionary['Pick this'][this.state.language]} ${this.state.own_product_step[`name_${this.state.language}`]}${dictionary['Imbuhan Ini'][this.state.language]}`
                                                                            :
                                                                            `${dictionary['Skip this'][this.state.language]} ${this.state.own_product_step[`name_${this.state.language}`]}${dictionary['Imbuhan Ini'][this.state.language]}`

                                                                }
                                                            </Button>
                                                        </Grid>
                                                    </React.Fragment>
                                                    :
                                                    <Grid item xs={12} md={2}>
                                                        <Button variant="outlined" className={classes.buttonDark} style={{ width: '100%' }}
                                                            onClick={this.handleSubmitStep}
                                                        >
                                                            {
                                                                this.state.own_product_step.max_pick > 1 ?
                                                                    dictionary['Continue'][this.state.language]
                                                                    :
                                                                    `${dictionary['Pick this'][this.state.language]} ${this.state.own_product_step[`name_${this.state.language}`]}${dictionary['Imbuhan Ini'][this.state.language]}`
                                                            }
                                                        </Button>
                                                    </Grid>
                                            }
                                        </Grid>
                                    </React.Fragment>
                                    :
                                    null
                            }
                        </Grid>
                }

            </Container>
        )
    }
}

export default withStyles(useStyles)(PageStep)
