import React, { Component } from 'react'
import { Container, withStyles, Grid, Backdrop, CircularProgress, Button } from '@material-ui/core'
import styles from "assets/jss/client-side/App.js";
import axios from 'axios';
import {baseUrl, 
    requestConfig
} from '../../../Environment.js';
import { dictionary } from '../../../Dictionary';
import { ClientSideContext } from '../../ClientSideContext';
import PostLargeGeneral from 'client-side/components/Post/PostLargeGeneral.js';
import SocialActsHeader from "assets/img/havilla/social-acts-header.png";
import SnackbarComp from 'client-side/components/Snackbar/SnackbarComp.js';

const useStyles = theme => ({
    ...styles(theme),
    teaTalksTitle: {
        marginTop: '48px',
        marginBottom: '24px',
        fontSize: '40px',
        textAlign : 'center'
    },
    postsContainer : {
        marginTop : '12px',
    },
    titleDesc : {
        fontFamily: 'Martel Sans',
        fontSize: '16px',
        textAlign : 'center',
        marginBottom: '54px',
    },
    socialActsContainer : {
        padding : '0px'
    },
    headerImg : {
        width : '100%',
    },
});

export class SocialActs extends Component {
    static contextType = ClientSideContext;
    constructor(props) {
        super(props)

        this.state = {
            openBackdrop : false,
            language : 'en',

            articles : [],
            articleCategories : [],
            articleCategoryId : null,
            nextPageLink : null,

            snackbarType: 'info',
            snackbarMessage: '',
            snackbarCounter: 0,
        }
    }

    handleOpenSnackbar = (snackbar) => {
        this.setState((prevState, props) => ({
            snackbarCounter: prevState.snackbarCounter + 1,
            snackbarType: snackbar.type,
            snackbarMessage: snackbar.message,
        }), () => {
        });
    }

    componentDidUpdate() {
        
    }
    
    componentDidMount() {
        this.setState({ openBackdrop:true});
        this.getArticles();
        this.setState({
            language: this.context.language
        })
    }

    getArticles = () =>{
        this.setState({ openBackdrop:true});
        let data = {
            category_id : 1,
            limit : 6
        }
        axios.post(`${baseUrl}/articles/get_articles_by_category`,
            data,
            requestConfig
        ).then((res)=>{
            if(res.data.success){
                let data = res.data.data;
                this.setState({
                    articles : data.data,
                    nextPageLink : data.next_page_url
                })
                this.setState({ openBackdrop:false});
            }else{
                this.setState({ openBackdrop:false});
                this.handleOpenSnackbar({
                    type : "error",
                    message : `${res.data.message}`
                })
                console.log(res.data.message,res.data.data)
            }
        },(e)=>{
            this.setState({ openBackdrop:false});
            this.handleOpenSnackbar({
                type : "error",
                message : `${e}`
            })
            console.log("Error : ",e);
        })
    } 
    
    handleArticleCategoryChange = (e, newValue) => {
        e.preventDefault()
        this.setState({
            articleCategoryId: newValue
        }, () => {
            this.getArticles();
        });
    }

    handleNextPage = () =>{
        this.setState({ openBackdrop:true});
        let data = {
            category_id : this.state.articleCategoryId,
            limit : 6
        }
        axios.post(`${this.state.nextPageLink}`,
            data,
            requestConfig
        ).then((res)=>{
            if(res.data.success){
                let data = res.data.data;
                this.setState((prevState,props) =>({
                    articles : [...prevState.articles,...data.data],
                    nextPageLink : data.next_page_url
                }))
                this.setState({ openBackdrop:false});
            }else{
                this.setState({ openBackdrop:false});
                this.handleOpenSnackbar({
                    type : "error",
                    message : `${res.data.message}`
                })
                console.log(res.data.message,res.data.data)
            }
        },(e)=>{
            this.setState({ openBackdrop:false});
            this.handleOpenSnackbar({
                type : "error",
                message : `${e}`
            })
            console.log("Error : ",e);
        })
    }

    render() {
        const {classes} = this.props

        return (
            <Container maxWidth="xl" className={`${classes.container} ${classes.socialActsContainer}`}>
                <SnackbarComp
                    snackbarType={this.state.snackbarType}
                    snackbarMessage={this.state.snackbarMessage}
                    counter={this.state.counter}
                />
                <Backdrop className={classes.backdrop} open={this.state.openBackdrop}>
                    <CircularProgress style={{'color': '#AF986D'}} />
                </Backdrop>
                <div className={classes.thumbnailContainer}>
                    <img className={classes.headerImg} src={SocialActsHeader} alt={`social acts header`}/>
                </div>
                <Grid container alignItems='center' justify="center">
                    <Grid item xs={12} className={`${classes.teaTalksTitle} ${classes.title}`}>
                        {dictionary['Social Acts'][this.context.language]}
                    </Grid>
                    <Grid item xs={12} className={classes.titleDesc}>
                        Small bag of tea with Big Impact for the Society.
                    </Grid>
                    <Grid container item xs={12} md={11} spacing={5} className={classes.postsContainer} justify="flex-start">
                        {
                            this.state.articles.length > 0 ?
                                this.state.articles.map(item => {
                                    return (
                                        <PostLargeGeneral
                                            key={item.id}
                                            xs={12}
                                            md={6}
                                            img={item.thumbnail_url}
                                            title={item.title}
                                            desc={item.caption}
                                            imgAlt={`article thumbnail ${item.url_slug}`}
                                            linkText='See the stories'
                                            linkTo={`/about_us/social_acts/${item.url_slug}`}
                                        />
                                    )
                                })
                            :
                            <Grid item xs={12} className={classes.noResult}>No articles at the moment...</Grid>
                        }
                    </Grid>
                    {
                        this.state.articles.length > 0 && this.state.nextPageLink !== null?
                            <Grid item xs={8} md={2}>
                                <Button onClick={this.handleNextPage} variant="outlined" className={classes.buttonLight} style={{marginTop:'48px',width:'100%'}}>
                                    See More
                                </Button>
                            </Grid>
                        :
                        null
                    }

                </Grid>
            </Container>
        )
    }
}

export default withStyles(useStyles)(SocialActs)