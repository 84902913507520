import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    img: {
        // maxHeight: '292px',
        width: '100%',
        maxHeight: '300px',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '100%',
            maxWidth: '98vw'
        },
        // [theme.breakpoints.down('xs')]: {
        //     width: '100%',
        //     height: '140px',
        //     minWidth: '260px',
        //     minHeight: '140px',
        //     maxWidth: '650px',
        //     maxHeight: '300px',
        // },
    },
    articleTitle: {
        fontFamily: 'Martel Sans',
        fontSize: '24px',
        textAlign: 'left',
        padding: '14px 32px 0px 32px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        },
    },
    articleDesc: {
        fontFamily: 'Martel Sans',
        fontSize: '16px',
        textAlign: 'left',
        padding: '20px 32px 0px 32px',
    },
    postContainer: {
        // maxWidth : '616px',
        width: 'auto',
    },
    articleLink: {
        fontFamily: 'Martel Sans',
        fontSize: "14px",
        textDecorationLine: 'underline',
        '&:hover': {
            color: 'white'
            // textDecorationLine: 'underline',
        }
    },
    articleLinkContainer: {
        textAlign: 'left',
        padding: '17px 32px 0px 32px'
    },
    emptyLastItem: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        marginLeft: '0rem',
    },
    imgContainer: {
        [theme.breakpoints.up('sm')]: {
            height: 'auto'
        },
    },
    borderBox: {
        // [theme.breakpoints.up('sm')]: {
        //     marginTop: '-16px'
        // },
        // border: '1px solid #f4f4f4',
        background: '#121212',
        paddingBottom: 20
    }
}));

function PostLargeGeneral(props) {
    const classes = useStyles();

    return (
        <Grid className={`${classes.postContainer}`}
            container
            style={props.customContainerStyle}
            item
            md={props.md}
            xs={props.xs}
            lg={props.lg}
        >
            <Grid item xs={12} className={classes.imgContainer}>
                <img className={classes.img} src={props.img} alt={props.imgAlt} />
            </Grid>
            <Grid item xs={12} className={classes.borderBox}>
                <Grid className={classes.articleTitle} item xs={12}>
                    {props.title}
                </Grid>
                <Grid className={classes.articleDesc} item xs={12}>
                    {props.desc}
                </Grid>
                <Grid className={classes.articleLinkContainer} item xs={12}>
                    {
                        props.linkType === "ahref" ?
                            <a href={props.linkTo} target="_blank" className={classes.articleLink} rel="noopener noreferrer">
                                {props.linkText}
                            </a>
                            :
                            <Link to={props.linkTo} className={classes.articleLink}>
                                {props.linkText}
                            </Link>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PostLargeGeneral

PostLargeGeneral.defaultProps = {
    linkTo: "#",
    style: {}
}